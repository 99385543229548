import { Injectable } from '@angular/core';
import { ServerSideLoggerService } from '../../../providers/server-side-logger/server-side-logger.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class XmppLogger {
    private static readonly LOG_ID = 'XMPP_LOGGER';

    private readonly isProduction: boolean = environment.production;

    constructor(private readonly logger: ServerSideLoggerService) {
    }

    /**
     *
     * @param msg
     * @param data
     */
    public info(msg: string, data = null) {
        this.log(msg, data);
    }

    /**
     *
     * @param msg
     * @param xml
     */
    public logXml(msg, xml) {
        console.log(msg);
        console.dirxml(xml);
    }

    public error(msg, data = null) {
        this.logger.error(XmppLogger.LOG_ID + ' ERROR ' + msg + ' data: ' + JSON.stringify(data));
    }

    public debug(msg, data = null) {
        this.logger.error(XmppLogger.LOG_ID + ' DEBUG ' + msg + ' data: ' + JSON.stringify(data));
    }

    public warn(msg, data = null) {
        this.logger.warn(XmppLogger.LOG_ID + ' WARN ' + msg + ' data: ' + JSON.stringify(data));
    }

    private log(msg, data = null) {
        if (this.isProduction) {
            this.logger.info(msg);
        }

        console.log(msg, data);
    }

}
