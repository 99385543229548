import { AfterViewInit, Directive, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[appInvalidDirective]'
})
export class InvalidDirective implements AfterViewInit {
    private readonly control: NgControl;
    private readonly tooltip: NgbTooltip;

    constructor(control: NgControl, tooltip: NgbTooltip) {
        this.control = control;
        this.tooltip = tooltip;
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.loadDirective(this.control, this.tooltip));
    }

    private loadDirective(control: NgControl, tooltip: NgbTooltip) {
        if (control) {
            control.statusChanges.subscribe((status) => {
                    if (control.dirty && control.invalid) {
                        tooltip.open();
                    } else {
                        tooltip.close();
                    }
                }
            );
        }
    }

    triggerTooltip() {
        if (this.control.invalid) {
            this.tooltip.open();
        } else {
            this.tooltip.close();
        }
    }

    isOpen(): boolean {
        return this.tooltip.isOpen();
    }
}
