export interface DataChannelProtocol {
    id: string;
    protocol: 'system' | 'file';
    action: 'start' | 'start-answer' | 'cancel' | 'cancel-answer' | 'complete' | 'complete-answer' | 'send';
}

export interface DataChannelStartFile extends DataChannelProtocol {
    protocol: 'file';
    action: 'start';
}

export interface DataChannelFileMetaData {
    file: {
        name: string;
        size: number;
        type: string;
    };
    chunkAmount: number;
}

export interface DataChannelSendFile extends DataChannelProtocol {
    protocol: 'file';
    action: 'send';
    chunk: number;
}

export interface DataChannelComplete extends DataChannelProtocol {
    protocol: 'file';
    action: 'complete';
}

export interface DataChannelCancel extends DataChannelProtocol {
    protocol: 'file';
    action: 'cancel';
}

export class DataChannelProtocolService {

    public static readonly PROTOCOL_SIZE = 100;
    public static readonly CHUNK_SIZE = 16284; // 16384;
    private static readonly PROTOCOL_END_STRING = '###--####';

    public static createFileMetaData(file: File): DataChannelFileMetaData {
        const fileSize = file.size;
        let chunkAmount = 1;
        if (fileSize > DataChannelProtocolService.CHUNK_SIZE) {
            chunkAmount = Math.ceil(fileSize / DataChannelProtocolService.CHUNK_SIZE);
        }
        return <DataChannelFileMetaData>{ file: {
                name: file.name,
                size: fileSize,
                type: file.type
            },
            chunkAmount
        };
    }

    public static createStartFileProtocol(id: string, fileData: DataChannelFileMetaData): ArrayBuffer | SharedArrayBuffer {
        const json = <DataChannelStartFile>{
            protocol: 'file',
            action: 'start',
            id
        };
        const data = this.str2ArrayBuffer(JSON.stringify(fileData));
        return this.createProtocolArrayBuffer(JSON.stringify(json), data);
    }

    public static createSendFileProtocol(id: string, chunkNumber: number, data: ArrayBuffer): ArrayBuffer | SharedArrayBuffer {
        const json = <DataChannelSendFile>{
            protocol: 'file',
            action: 'send',
            chunk: chunkNumber,
            id
        };

        return this.createProtocolArrayBuffer(JSON.stringify(json), data);
    }

    public static createCompleteFileProtocol(id: string): ArrayBuffer | SharedArrayBuffer {
        const json = <DataChannelComplete>{
            protocol: 'file',
            action: 'complete',
            id
        };
        return this.createProtocolArrayBuffer(JSON.stringify(json));
    }

    public static createCancelFileProtocol(id: string) {
        const json = <DataChannelCancel>{
            protocol: 'file',
            action: 'cancel',
            id
        };
        return this.createProtocolArrayBuffer(JSON.stringify(json));
    }

    public static readProtocol(buffer: ArrayBuffer): DataChannelProtocol {
        const protocolBuffer = buffer.slice(0, DataChannelProtocolService.PROTOCOL_SIZE - 1);
        const protocolRawString = String.fromCharCode.apply(null, new Uint8Array(protocolBuffer));
        const n = protocolRawString.indexOf(this.PROTOCOL_END_STRING);
        const protocolString = protocolRawString.substring(0, n);
        // console.log('Protocol: ', protocolString);
        return <DataChannelProtocol>JSON.parse(protocolString.trim());
    }

    public static readFileMetaData(buffer: ArrayBuffer): DataChannelFileMetaData {
        // console.log('readFileMetaData buffer', buffer);
        const dataBuffer = this.readFileChunk(buffer);
        // console.log('readFileMetaData Chunk', dataBuffer);
        // console.log('readFileMetaData', this.arrayBuffer2str(dataBuffer));
        return <DataChannelFileMetaData>JSON.parse(this.arrayBuffer2str(dataBuffer).trim());
    }

    public static readFileChunk(buffer: ArrayBuffer): ArrayBuffer {
        return buffer.slice(this.PROTOCOL_SIZE);
    }

    // In the case of reading file we use utf-8 encoding
    public static createProtocolArrayBuffer(protocol: string, data: ArrayBuffer = new ArrayBuffer(0)): ArrayBuffer | SharedArrayBuffer {
        protocol = protocol + this.PROTOCOL_END_STRING;

        const buf = new ArrayBuffer(this.PROTOCOL_SIZE);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = protocol.length; i < strLen; i++) {
            bufView[i] = protocol.charCodeAt(i);
        }
        const newChunk = new Uint8Array(buf.byteLength + data.byteLength);
        newChunk.set(bufView, 0);
        newChunk.set(new Uint8Array(data), this.PROTOCOL_SIZE);
        return newChunk.buffer;
    }

    // Use an extra converter for application strings. Because in this case the encoding is controlled by us
    private static str2ArrayBuffer(str: string): ArrayBuffer {
        const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
        const bufView = new Uint16Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    // Use an extra converter for application strings. Because in this case the encoding is controlled by us
    private static arrayBuffer2str(buf): string {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }
}
