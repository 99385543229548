import { Component } from '@angular/core';

@Component({
  selector: 'app-disposed-table',
  templateUrl: './disposed-table.component.html',
  styleUrls: ['./disposed-table.component.scss']
})
export class DisposedTableComponent {

  constructor() { }

}
