import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CustomValidators} from '../../../forms/validators/custom-validator';

export class StepVoucherFormBuilder {

    public static build(formBuilder: FormBuilder): FormGroup {

        return formBuilder.group({
            voucherCode: [''],
            voucherAgreement: new FormControl({value: null, disabled: true}, CustomValidators.conditionalRequiredTrue('voucherCode'))
        });
    }
}
