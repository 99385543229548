import { OnlineStateService } from '../../signal/providers/online-state.service';
import { Injectable } from '@angular/core';
import { Conference } from '../../conference/store/conference.entity';
import { Store } from '@ngrx/store';
import { WaitingroomSwitchConferenceAction } from '../../waitingroom/store/waitingroom.action';
import { AppState } from '../../../providers/store/app.state';

@Injectable()
export class CommunicationService {

    constructor(
        private onlineStateService: OnlineStateService,
        private store: Store<AppState>) {
    }

    public updateTokens(confernces: Conference[]): Conference {
        if (confernces.length > 0) {
            const tokens = this.extractTokens(confernces);
            this.setTokens(tokens);
            this.activateConference(confernces[0]);
            return confernces[0];
        } else {
            return null;
        }
    }

    /**
     * Use this methode to activate a conference session
     *
     * @param conference
     */
    public switchConference(conference: Conference) {
        this.activateConference(conference);
        this.store.dispatch(new WaitingroomSwitchConferenceAction(conference));
    }

    private extractTokens(confernces: Conference[]): { myTokens; userTokens } {
        const myTokens = [];
        const userTokens = [];
        confernces.forEach((token) => {
            myTokens.push(token.myToken.token);
            Object.keys(token.userTokens).forEach((id) => {
                userTokens.push(token.userTokens[id].token);
            });
        });
        return {myTokens, userTokens};
    }

    private setTokens(tokens: { myTokens; userTokens }) {
        this.onlineStateService.setTokens(tokens.myTokens, tokens.userTokens);
    }

    private activateConference(conference: Conference) {
        // @TODO: At the moment we can only start one signal Communication, later one we had to change this here.
        const conferenceId = Object.keys(conference.userTokens)[0];
        this.store.dispatch(new WaitingroomSwitchConferenceAction(conference));
        this.onlineStateService.loadSession(conference.userTokens[conferenceId].token);
    }
}
