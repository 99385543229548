import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userPresencesFeatureKey, selectAll, selectEntities, UserPresenceState } from './user-presence.reducer';

export const selectUserPresenceState = createFeatureSelector<UserPresenceState>(userPresencesFeatureKey);

export const selectAllUserPresences = createSelector(
    selectUserPresenceState,
    selectAll
);

export const selectUserPresencesEntities = createSelector(
    selectUserPresenceState,
    selectEntities
);

export const selectUserPresences = (profileId: number) => createSelector(
    selectUserPresencesEntities,
    userPresences => userPresences.hasOwnProperty(profileId) ? userPresences[profileId] : null
);
