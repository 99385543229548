import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountSettingsModule } from './account-settings/account-settings.module';
import { PaymentSettingsModule } from './payment-settings/payment-settings.module';
import { ProfileSettingsModule } from './profile-settings/profile-settings.module';

@NgModule({
    imports: [
        CommonModule,
        ProfileSettingsModule,
        PaymentSettingsModule,
        AccountSettingsModule
    ],
    declarations: [],
    exports: [],
})
export class SettingModule {
}
