import * as mime from 'mime-types';

export class FileUtil {

    public static isSupportedFileType(file: {name: string; type: string}): boolean {
        const supported = [
            'image/jpeg',
            'image/png',
            'image/bmp',
            'image/gif',
            'application/pdf',
            'text/csv',
            'text/plain'
        ];
        let isSupported = false;
        supported.forEach(type => {
            if (type === file.type) {
                isSupported = true;
            }
        });
        return isSupported;
    }

    public static matchesMimeTypeTheExtension(file: {name: string; type: string}): boolean {
        const fileExtension = this.getFileExtension(file.name);
        if (fileExtension === null) {
            return false;
        }
        const type = mime.lookup(fileExtension);

        // filter executable files
        if (file.type === 'application/x-msdownload' || file.type === 'application/x-sh' || file.type === 'application/octet-stream') {
            return false;
        }

        return type === file.type;
    }

    public static getFileExtension(filename: string): string {
        const result = /[^.]+$/.exec(filename);
        return (result !== null && result.length > 0) ? result[0] : null;
    }
}
