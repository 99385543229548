import { Profile } from '../../modules/profile/store/profile.entity';

export interface JitsiIframeDataInterface {
    isMUC?: boolean;
    participants?: Profile[];
    user?: Profile;
    userType?: string;
}

export class JitsiIframeData implements JitsiIframeDataInterface {
    isMUC: boolean;
    participants: Profile[];
    user: Profile;
    userType: string;
    constructor(data?: JitsiIframeDataInterface) {
        this.isMUC = data?.isMUC || false;
        this.participants = data?.participants || [];
        this.user = data?.user || {} as Profile;
        this.userType = data?.userType || '';
    }

}
