import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../forms/validators/custom-validator';
import { COUNTRY_GERMANY } from '../../../../../entities/payment-country/provider/country.service';

export class BankAccountFormBuilder {
    public static build(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            iban: ['', Validators.compose([Validators.required, CustomValidators.validateIban()])],
            bic: ['', Validators.compose([Validators.required, CustomValidators.validateBic()])],
            firstName: ['', Validators.compose([Validators.required, CustomValidators.validateBankAccTextFields()])],
            lastName: ['', Validators.compose([Validators.required, CustomValidators.validateBankAccTextFields()])],
            country: [{value: COUNTRY_GERMANY, disabled: true}, Validators.compose([Validators.required])],
            city: ['', Validators.compose([Validators.required, CustomValidators.validateBankAccTextFields()])],
            plz: ['', Validators.compose([Validators.required, CustomValidators.validateBankAccTextFields()])],
            addressLine: ['', Validators.compose([Validators.required, CustomValidators.validateBankAccTextFields()])],
        });
    }
}
