import {
    MEDIA_CALL_END,
    MEDIA_CALL_START_SUCCESS,
    MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS,
    MEDIA_CLOSED_LOCAL_STREAM_SUCCESS,
    MEDIA_MIC_MUTE,
    MEDIA_MIC_UNMUTE,
    MEDIA_MICROPHONE_STATE,
    MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS,
    MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS,
    MEDIA_SAVE_REMOTE_SCREEN_STREAM,
    MEDIA_SAVE_REMOTE_STREAM,
    MEDIA_START_CLOSING_REMOTE_SCREEN_STREAM, MEDIA_TOGGLE_FULLSIZED_MAIN_VIDEO,
    MEDIA_VIDEOCAM_ON,
    MEDIA_VIDEOTRACK_OFF,
    MediaAction,
    MediaMicrophoneStateAction,
    MediaReceivedLocalStreamSuccessAction,
    MediaSaveRemoteScreenStreamAction,
    MediaSaveRemoteStreamAction
} from './media.action';
import { MediaInitialState, MediaState } from './media.state';

export function mediaReducer(state: MediaState = new MediaInitialState(), action: MediaAction): MediaState {
    switch (action.type) {
        // Local video cam actions
        case MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS:
            return {
                ...state,
                localStream: {
                    ...state.localStream,
                    active: true,
                    play: true,
                    media: (<MediaReceivedLocalStreamSuccessAction>action).payload
                }
            };
        // Local screenvideo actions
        case MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS:
            return {
                ...state,
                localScreenStream: {
                    ...state.localScreenStream,
                    active: true,
                    play: true,
                    media: (<MediaReceivedLocalStreamSuccessAction>action).payload
                }
            };
        case MEDIA_CLOSED_LOCAL_STREAM_SUCCESS:
            return new MediaInitialState();
        // Mute, deactivate came etc.
        case MEDIA_VIDEOCAM_ON:
            state.localStream.media.play();
            return {...state, localStream: {...state.localStream, play: true}};
        case MEDIA_VIDEOTRACK_OFF:
            state.localStream.media.stop();
            return {...state, localStream: {...state.localStream, play: false}};
        case MEDIA_MIC_MUTE:
            state.localStream.media.mute();
            return {...state, localStream: {...state.localStream, mute: true}};
        case MEDIA_MIC_UNMUTE:
            state.localStream.media.unmute();
            return {...state, localStream: {...state.localStream, mute: false}};
        // Video call actions
        // first: get a remote stream from peer
        case MEDIA_SAVE_REMOTE_STREAM:
            return {
                ...state,
                remoteStream: {
                    ...state.remoteStream,
                    play: true,
                    active: false,
                    media: (<MediaSaveRemoteStreamAction>action).payload
                }
            };
        // second: is timer finish activate remote stream in view
        case MEDIA_CALL_START_SUCCESS:
            return {
                ...state,
                remoteStream: {
                    ...state.remoteStream,
                    active: true
                }
            };
        case MEDIA_SAVE_REMOTE_SCREEN_STREAM:
            return {
                ...state,
                remoteScreenStream: {
                    ...state.remoteScreenStream,
                    play: true,
                    active: true,
                    media: (<MediaSaveRemoteScreenStreamAction>action).payload
                }
            };
        case MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS:
            return {
                ...state,
                localScreenStream: {
                    ...state.remoteScreenStream,
                    play: false,
                    active: false,
                    media: null
                }
            };
        case MEDIA_START_CLOSING_REMOTE_SCREEN_STREAM:
            return {
                ...state,
                remoteScreenStream: {
                    ...state.remoteScreenStream,
                    play: false,
                    active: false,
                    media: null
                }
            };
        case MEDIA_CALL_END:
            return {...state, remoteStream: {...state.remoteStream, play: false, active: false, media: null}, remoteScreenStream: {
                    ...state.remoteScreenStream,
                    play: false,
                    active: false,
                    media: null
                }};
        case MEDIA_MICROPHONE_STATE:
            return {...state, microphone: {...state.microphone, active: (<MediaMicrophoneStateAction>action).payload}};
        case MEDIA_TOGGLE_FULLSIZED_MAIN_VIDEO:
            return {...state, mainVideoFullsized: !state.mainVideoFullsized};
        default:
            return state;
    }
}
