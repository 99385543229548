export enum OvsProvider {
    JITSI = 'jitsi',
    VONAGE = 'vonage'
}

export interface VonageBrandingSettings {
    ovsProvider?: OvsProvider;
    turnConfig?: {
        turnServers: RTCIceServer[];
        useDefaultTurnServersForPatients?: boolean;
    };
    ipProxy?: string;
    logoUrl?: string;
    hasNetworkTest?: boolean;
}
