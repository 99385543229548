export const CFG_USERS = [
    2191,  // test-user-doctor-99@patientus.de
    2117,
    2118,
    2200,
    2202,
    2204,
    2206,
    2207,
    2208,
    2210,
    2211,
    2212,
    2213,
    2214,
    2215,
    2217,
    2218,
    2219,
    2220,
    2221,
    2223,
    2662,
    2663,
    3253,
    3254,
    3255,
    3268,
    3269,
    3270,
    3271,
    3272,
    3277,
    3278,
    3280,
    3282,
    3284,
    3285,
    3286,
    3287,
    17107
];
