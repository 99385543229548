import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FileTransmitter } from '../../provider/data-channel/file-transmitter';
import { WebrtcDataChannelService } from '../../provider/data-channel/webrtc-data-channel.service';
import { OncallProvider } from '../../provider/oncall.provider';
import { FileTransferService } from '../../provider/file-transfer.service';

@Component({
    selector: 'app-file-transfer-pop-up',
    templateUrl: './pop-up.component.html',
    styleUrls: ['./pop-up.component.scss']
})

export class PopUpComponent implements OnInit, OnDestroy {
    @ViewChild('content') popupContentRef: ElementRef;

    private modal: NgbModalRef;
    private isModalOpen = false;
    public displayButton = false;
    public receiverList: FileTransmitter[] = [];
    public downloadedIdList = [];
    private onCallOnlineSubscription: Subscription = null;
    private onCallSubscription: Subscription = null;

    constructor(
        private modalService: NgbModal,
        private onCallProvider: OncallProvider,
        private webRtcProvider: WebrtcDataChannelService,
        private fileTransferService: FileTransferService
    ) {
        this.webRtcProvider.onReceiveFileCallback = this.receiveData.bind(this);
    }

    ngOnInit() {
        this.onCallOnlineSubscription = this.onCallProvider.isSwitchOnline().subscribe(() => {
            this.displayButton = true;
        });

        this.onCallSubscription = this.onCallProvider.isSwitchOffline().subscribe(() => {
            this.closeModal();

            if (this.receiverList.length > 0) {
                this.receiverList = [];
                this.downloadedIdList = [];
            }
            this.displayButton = false;
        });

        this.fileTransferService.isModalOpen$.subscribe((shouldOpen: boolean) => {
           if (shouldOpen) {
               this.open(this.popupContentRef);
           } else {
               this.close();
           }
        });
    }

    open(content) {
        if (this.isModalOpen === false) {
            this.modal = this.modalService.open(content, {
                windowClass: 'fileTransfer-modal',
                backdropClass: 'custom-modal-overlay',
                backdrop: false
            });
            this.isModalOpen = true;
        }
    }

    close(): void {
        if (this.isModalOpen === true) {
            this.modal.close();
            this.isModalOpen = false;
        }
    }

    closeModal(): void {
        this.fileTransferService.closeModal();
    }

    private receiveData(receiver: FileTransmitter) {
        this.receiverList.push(receiver);
        if (this.isModalOpen === false) {
            this.fileTransferService.openModal();
        }
    }

    public onItemDownloaded($event) {
        this.downloadedIdList.push($event);
    }

    public onItemUploaded($event: FileTransmitter) {
        this.receiverList.push($event);
    }

    ngOnDestroy(): void {
        if (this.onCallSubscription !== null) {
            this.onCallSubscription.unsubscribe();
        }

        if (this.onCallOnlineSubscription !== null) {
            this.onCallOnlineSubscription.unsubscribe();
        }
    }
}
