import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserPresence } from './user-presence.model';
import { UserPresenceActions, UserPresenceActionTypes } from './user-presence.actions';
import { UserPresenceStatus } from '../user-presence-status';

export const userPresencesFeatureKey = 'userPresences';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserPresenceState extends EntityState<UserPresence> {
    // ids: number[];
    // entities: { [id: number]: UserPresence };
    // activeId: number | null;
}

export const adapter: EntityAdapter<UserPresence> = createEntityAdapter<UserPresence>();

export const initialState: UserPresenceState = adapter.getInitialState({
    // additional entity state properties
});

export function reducer(state = initialState, action: UserPresenceActions): UserPresenceState {
    switch (action.type) {
        case UserPresenceActionTypes.UpsertUserPresence: {
            const userPresence = action.payload.userPresence;
            const resource = (userPresence.resource.length === 1) ? userPresence.resource[0] : null;

            // User was already subscribed or online
            if ((state.ids as number[]).includes(userPresence.id)) {
                const currentPresence = state.entities[userPresence.id];
                const currentUserCount = currentPresence.resource.length;

                // User goes offline but is not the last one
                if (userPresence.status === UserPresenceStatus.OFFLINE) {
                    // Only if Last user then we going offline
                    if (!(currentUserCount === 1 && currentPresence.resource[0] === resource)) {
                        userPresence.status = currentPresence.status;
                    }
                    userPresence.resource = currentPresence.resource.filter((currentResource) => currentResource !== resource);
                } else {
                    userPresence.resource = currentPresence.resource.filter(re => re !== resource);
                    userPresence.resource.push(resource);
                }
                // New User
            } else {
                // User get offline means remove him from status means remove resource even he joining first time
                if (userPresence.status === UserPresenceStatus.OFFLINE) {
                    userPresence.resource = [];
                }
            }

            return adapter.upsertOne(userPresence, state);
        }

        case UserPresenceActionTypes.DeleteUserPresence: {
            return adapter.removeOne(action.payload.id, state);
        }

        case UserPresenceActionTypes.ClearUserPresences: {
            return adapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
