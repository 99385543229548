import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediaComponent} from './media.component';
import {StoreModule} from '@ngrx/store';
import {mediaReducer} from './store/media.reducer';
import {SmallVideoComponent} from './component/small-video/small-video.component';
import {MainVideoComponent} from './component/main-video/main-video.component';
import {ControlBarComponent} from './component/control-bar/control-bar.component';
import {MediaStreamProvider} from './provider/media-stream.provider';
import {MediaEffects} from './store/media.effect';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';
import {ChromeInlineInstallationDirective} from './directives/chrome-inline-installation.directive';
import {ChromeExtensionService} from './provider/chrome-extension.service';
import {BigControlBarComponent} from './component/big-control-bar/big-control-bar.component';
import {MicrophoneSoundMeterComponent} from './component/microphone/microphone-sound-meter.component';
import {SoundTestComponent} from './component/sound/sound-test.component';
import {MediaService} from './provider/media.service';
import {BrowserService} from './provider/browser.service';
import {LocalScreenVideoTagComponent} from './component/local-screen-video-tag/local-screen-video-tag.component';
import {RemoteScreenVideoTagComponent} from './component/remote-screen-video-tag/remote-screen-video-tag.component';
import {LocalCamVideoTagComponent} from './component/local-cam-video-tag/local-cam-video-tag.component';
import {RemoteCamVideoTagComponent} from './component/remote-cam-video-tag/remote-cam-video-tag.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SoundService} from './provider/sound.service';
import {BackgroundTransitionsComponent} from './component/background-transitions/background-transitions.component';
import {FileTransferModule} from '../file-transfer/file-transfer.module';
import {NoticeComponent} from './component/notice/notice.component';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ClipboardModule} from 'ngx-clipboard';
import {InternetIconComponent} from './component/internet-icon/internet-icon.component';
import { LogoComponent } from './component/logo/logo.component';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('media', mediaReducer),
        EffectsModule.forFeature([MediaEffects]),
        TranslateModule.forChild(),
        NgxPermissionsModule,
        NgbModule,
        FormsModule,
        BrowserModule,
        ClipboardModule,
        FileTransferModule
    ],
    declarations: [
        MediaComponent,
        SmallVideoComponent,
        MainVideoComponent,
        ControlBarComponent,
        BigControlBarComponent,
        ChromeInlineInstallationDirective,
        ControlBarComponent,
        MicrophoneSoundMeterComponent,
        SoundTestComponent,
        LocalScreenVideoTagComponent,
        RemoteScreenVideoTagComponent,
        LocalCamVideoTagComponent,
        RemoteCamVideoTagComponent,
        BackgroundTransitionsComponent,
        NoticeComponent,
        InternetIconComponent,
        LogoComponent
    ],
    exports: [
        MediaComponent,
        SmallVideoComponent,
        MicrophoneSoundMeterComponent,
        SoundTestComponent,
        LocalCamVideoTagComponent,
        NoticeComponent,
        InternetIconComponent
    ],
    providers: [
        MediaStreamProvider,
        ChromeExtensionService,
        MediaService,
        BrowserService,
        SoundService
    ]
})
export class MediaModule {
}
