import { Component, Input } from '@angular/core';


export interface BitrateStats {
    maxBitrate: number;
    currentBitrate: number;
}

@Component({
    selector: 'app-dispaly-bitrate',
    templateUrl: './dispaly-bitrate.component.html',
    styleUrls: ['./dispaly-bitrate.component.scss']
})
export class DispalyBitrateComponent {
    @Input() bitrateStats: BitrateStats;
    constructor() { }

}
