<div class="setting-profile-content">
    <div class='page-header content-wrapper row'>
        <div class="col-sm-4 col-md-4 col-lg-3">
            <div class="profile-image profile-image-wrapper">
                <img *ngIf="(profileObserver$ | async)?.imageUrl"
                     src="{{ (profileObserver$ | async)?.imageUrl }}" alt="">
                <div class="profile-change-link-wrapper">
                    <a (click)="showImageUpload()">
                        <i class="fas fa-cloud-upload-alt"></i>
                        {{ 'SETTING.PROFILE-TAB.PROFILE-PICTURE-MODIFY' | translate }}
                    </a>
                </div>
            </div>
        </div>
        <app-image-upload
            *ngIf="imageUploadIsOpen"
            (onCloseButtonClicked)="hideImageUpload()"
        ></app-image-upload>
        <div class="col-md-8">
            <h1>
                <span>{{ 'SETTING.PROFILE-TAB.TITLE' | translate }} </span>
                <span class="modify-badge" (click)="goToEdit()">
                <i class="fas fa-pen-alt"></i> {{ 'SETTING.PROFILE-TAB.PROFILE-MODIFY' | translate }}
                </span>
            </h1>
            <span class="sub-title">
                <a target="_blank" href="{{publicProfileDomain + (profileObserver$ | async)?.publicProfileUrl }}">
                    <i class="fa fa-external-link"></i> {{ 'SETTING.PROFILE-TAB.PROFILE-PAGE-PREVIEW' | translate }}
                </a>
            </span>
        </div>
    </div>
    <div *ngIf="currentState === viewState" class="view-profile-settings">
        <app-profile-view [profile]="profile"
                          (profileSettingsStateEmitter)="profileSettingsStateEmitterHandler($event)"></app-profile-view>
    </div>
    <div *ngIf="currentState === updateState" class="update-profile-settings">
        <app-profile-update [profile]="profile"
                            (profileSettingsStateEmitter)="profileSettingsStateEmitterHandler($event)"></app-profile-update>
    </div>
    <div class='page-header content-wrapper'>
        <h1><i class="fal fa-building"></i> {{ 'SETTING.PROFILE-TAB.PRAXIS' | translate }}
            <a class="modify-badge" target="_blank"
               href="https://survey.zohopublic.eu/zs/XcCCdR?email={{ (profileObserver$ | async)?.email }}&id={{ (profileObserver$ | async)?.id }}">
                <i class="fas fa-pen-alt"></i> {{ 'SETTING.PROFILE-TAB.MODIFY' | translate }}
            </a>
        </h1>
    </div>
    <div class="card-header-beam">
        <div class='content-container content-wrapper'>
            <p>{{ 'SETTING.PROFILE-TAB.PRAXIS-DESC' | translate }}</p>
        </div>
    </div>
    <div class='content-wrapper card move-up'>
        <div class='tan-form-container card-content'>
            <div class="row">
                <div class="col-md-4 col-lg-3 bold">
                    {{ 'SETTING.PROFILE-TAB.ADDRESS' | translate }}
                </div>
                <div class="col-md-8 dr-info bold" *ngIf="(profileObserver$ | async)?.contacts as contacts">
                    <span *ngIf="contacts.street">{{ contacts.street }}</span>
                    <span *ngIf="contacts.houseNumber"> {{ contacts.houseNumber }}</span>
                    <span *ngIf="contacts.zipcode"> | {{ contacts.zipcode }}</span>
                    <span *ngIf="contacts.location"> | {{ contacts.location }}</span>
                    <span *ngIf="contacts.country"> | {{ contacts.country }}</span>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-4 col-lg-3 bold">
                    {{ 'SETTING.PROFILE-TAB.CONTACT' | translate }}
                </div>
                <div class="col-md-8 dr-info">
                    <div class="kontakt">
                        <span class="bold" *ngIf="(profileObserver$ | async)?.telephoneNumber as phoneNumber">
                            {{ 'SETTING.PROFILE-TAB.CONTACT-TYPE.PHONE' | translate }}
                        </span>
                        <span>{{ (profileObserver$ | async)?.telephoneNumber }}</span>
                    </div>
                    <div class="kontakt">
                        <span class="bold" *ngIf="(profileObserver$ | async)?.email">
                            {{ 'SETTING.PROFILE-TAB.CONTACT-TYPE.E-MAIL' | translate }}
                        </span>
                        <span>{{ (profileObserver$ | async)?.email }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
