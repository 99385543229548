import { RtcDetectionInitialState, RtcDetectionState } from './rtc-detection.state';
import {
    RTC_DETECTION_CLEANUP,
    RTC_DETECTION_FINISHED,
    RTC_DETECTION_MODAL_MANUALLY_CLOSED,
    RTC_DETECTION_NEXT_STEP,
    RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION,
    RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION,
    RTC_DETECTION_START_CAM_ACTION,
    RtcDetectionAction
} from './rtc-detection.action';
import { StepType } from '../rtc-detection.entities';

export function rtcDetectionReducer(state: RtcDetectionState = new RtcDetectionInitialState(),
                                    action: RtcDetectionAction): RtcDetectionState {
    switch (action.type) {
        case RTC_DETECTION_START_CAM_ACTION: {
            return { ...state, aboveModalOverlayIsOpen: true};
        }
        case RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION: {
            return { ...state, aboveModalOverlayIsOpen: false};
        }
        case RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION: {
            return { ...state, aboveModalOverlayIsOpen: false, currentStep: StepType.WARNING};
        }
        case RTC_DETECTION_NEXT_STEP: {
            return { ...state, currentStep: action.payload.next};
        }
        case RTC_DETECTION_MODAL_MANUALLY_CLOSED: {
            return { ...state, aboveModalOverlayIsOpen: false, currentStep: StepType.BROWSER_CHECK};
        }
        case RTC_DETECTION_FINISHED: {
            return { ...state, isFinished: true};
        }
        case RTC_DETECTION_CLEANUP: {
            return new RtcDetectionInitialState();
        }
        default: {
            return state;
        }
    }
}
