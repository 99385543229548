import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { SidebarEffects } from './store/sidebar.effect';
import { StoreModule } from '@ngrx/store';
import { sidebarReducer } from './store/sidebar.reducer';
import { RouterModule } from '@angular/router';
import { InstitutionSettingsModule } from '../institution-settings/institution-settings.module';

@NgModule({
    imports: [
        CommonModule,
        NgxPermissionsModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('sidebar', sidebarReducer),
        EffectsModule.forFeature([SidebarEffects]),
        RouterModule,
        InstitutionSettingsModule
    ],
    declarations: [
        SidebarComponent
    ],
    exports: [
        SidebarComponent
    ],
    providers: []
})
export class SidebarModule {
}
