<div class="modal-header">
    <i class="far fa-list"></i>
    <h4 class="modal-title">
        {{ 'RTC_DETECTION.TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelStep()"><span
            aria-hidden="true"><i class="far fa-times"></i></span></button>
</div>
<div class="modal-body">
    <div class="body-headline">
        <i class="fal fa-browser"></i><h5><span>{{'RTC_DETECTION.BROWSER.HEADLINE' | translate }}</span></h5>
    </div>

    <div class="body-content">
        <p>{{ 'RTC_DETECTION.HARDWARE.TEXT' | translate }}</p>
        <p class="bold">{{ 'RTC_DETECTION.BROWSER.TEXT2' | translate }}</p>

        <div class="inner-space">
            <div class="row">
                <div class="col-2">
                    <img src="/assets/img/browser/Google_Chrome_icon.svg" alt="">
                </div>
                <div class="col-10">
                    <a href="https://www.google.com/chrome/browser/features.html" target="_blank"
                       class="btn btn-primary"><i class="fal fa-download"></i> Google Chrome</a>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <img src="/assets/img/browser/Firefox_Logo_2017.svg" alt="">
                </div>
                <div class="col-10">
                    <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" class="btn btn-primary"><i
                            class="fal fa-download"></i> Firefox</a>
                </div>
            </div>
            <div class="row promotion">
                <div class="col-2">
                    <img src="/assets/img/browser/Primary-Cliqz-Logo-1.png" alt="">
                </div>
                <div class="col-10">
                    <a href="https://cliqz.com/download" target="_blank" class="btn btn-primary"><i
                            class="fal fa-download"></i> Cliqz</a>
                    <span class="promotion-text">{{ 'RTC_DETECTION.BROWSER.PROMOTION' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="button-line">
        <ng-template ngxPermissionsOnly="doctor">

            <button type="button" class="btn btn-link" (click)="ignoreNoticeClicked()">
                {{'RTC_DETECTION.CANCEL' | translate }}
            </button>
        </ng-template>

        <ng-template ngxPermissionsOnly="patient">

            <button type="button" class="btn btn-link" (click)="logoutClicked()">
                {{'RTC_DETECTION.BROWSER.LOGOUT' | translate }}
            </button>
            <button type="button"
                    class="btn btn-primary">
                <a href="https://www.patientus.de/browser-info/"
                   target="_blank">
                    {{'RTC_DETECTION.BROWSER.INFO' | translate }}
                    <i class="fas fa-arrow-right"></i>
                </a>
            </button>
        </ng-template>
    </div>

    <app-rtc-footer></app-rtc-footer>

</div>

