import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RtcDetectionStepComponent } from '../rtc-detection-step-component.interface';
import { AppState } from '../../waitingroom/store/waitingroom.state';
import { Store } from '@ngrx/store';
import { RtcDetectionStartCamAction } from '../store/rtc-detection.action';


@Component({
    templateUrl: './step-hardware-test.component.html',
    styleUrls: ['../rtc-detection.component.scss']
})
export class StepHardwareTestComponent implements RtcDetectionStepComponent {
    nextStep: () => void;
    dismissModal: () => void;
    closeModal: () => void;
    cancelStep: () => void;

    constructor(private translate: TranslateService,
                private store: Store<AppState>) {
    }

    public startCamera() {
        this.store.dispatch(new RtcDetectionStartCamAction());
    }
}

