export interface Media {
    stream: MediaStream;

    mute(): void;

    unmute(): void;

    play(): void;

    stop(): void;

    closeCam(): void;

    closeScreenShare(): void;
}

export class MediaVideoModel implements Media {
    constructor(public stream: MediaStream) {}

    public mute(): void {
        this.stream.getAudioTracks().forEach(function(track) {
            track.enabled = false;
        });
    }

    public unmute(): void {
        this.stream.getAudioTracks().forEach(function(track) {
            track.enabled = true;
        });
    }

    public closeCam() {
        this.stream.getAudioTracks().forEach(function(track) {
            track.stop();
        });
        this.stream.getVideoTracks().forEach(function(track) {
            track.stop();
        });
    }

    public stop(): void {
        this.stream.getVideoTracks().forEach(function(track) {
            track.enabled = false;
        });
    }

    public play(): void {
        this.stream.getVideoTracks().forEach(function(track) {
            track.enabled = true;
        });
    }

    public closeScreenShare() {
        this.stream.getVideoTracks().forEach(function(track) {
            track.stop();
        });
    }
}
