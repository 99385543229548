import { Type } from '@angular/core';

export class RtcDetectionStep<T> {
    constructor(public component: Type<T>) {
    }
}

export enum StepType {
    CAM_TEST = 'CAM_TEST',
    WARNING = 'WARNING',
    CAM_VERIFY = 'CAM_VERIFY',
    CHROME_EXTENSION = 'CHROME_EXTENSION',
    SUCCESS = 'SUCCESS',
    NO_CAMERA_ACCESS = 'NO_CAMERA_ACCESS',
    BROWSER_CHECK = 'BROWSER_CHECK'
}
