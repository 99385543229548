<div id='sound-meter-play-button-container' [ngClass]="{'no-display' : (isSoundTestRunning)}">
    <button class="btn btn-outline-primary" (click)="startSoundTest()">
        {{ 'SOUND.PLAY-TEST' | translate }}<i class="fal fa-volume-up"></i>
    </button>
</div>
<div id='sound-meter-container' [ngClass]="{'no-display' : !(isSoundTestRunning)}" class="clearfix">
    <button class="btn btn-outline-primary pause" (click)="stopSoundTest()">
        <i class="fas fa-pause"></i>
    </button>
    <canvas #soundtest class="sound-meter" width="400" height="38"></canvas>
</div>

