import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState, BrandingState } from '../store/branding.state';
import {
    BrandingSettingsLoadCustomBranding,
    BrandingSettingsLoadDefault
} from '../store/branding.action';
import {
    aokCfg,
    audiBKK,
    ikkSuedwest,
    kkh,
    sigma,
    bmwBKK,
    kinzigtal,
    agaplesion,
    asdbgn,
    homedok,
    albatros,
    bad,
    BKKmelitta,
    jameda,
    betriebsarztservice,
    medimentum,
    agaplesionKassel,
    ddc,
    sanaso,
    acura,
    asz,
    helios,
    BKKpwc,
    aok,
    lanae,
    uksh,
    epileptologieBonn,
    bkkRwe,
    bkkMhplus,
    fernarzt,
    itzehoe,
    polavis,
    medatixx,
    timerbee,
    enmedify,
    enmedifych,
    invirto,
    zotzklimas,
    synlab,
    sthetic,
    ckellerStudien
} from '../branding-settings';

export enum Subdomains {
    DEFAULT = 'security',
    FOUR_SIGMA = '4sigma',
    AUDI_BKK = 'audibkk',
    KKH = 'kkh',
    CFG_AOK = 'cfg-aok',
    IKK_SUEDWEST = 'ikk-suedwest',
    BMW_BKK = 'bmwbkk',
    KINZIGTAL = 'kinzigtal',
    AGAPLESION = 'agaplesion',
    ASDBGN = 'asd-bgn',
    HOMEDOK = 'homedok',
    ALBATROS = 'albatros',
    BAD = 'bad',
    BKK_MELITTA = 'bkk-melitta',
    JAMEDA = 'jameda',
    BETRIEBSARZTSERVICE = 'betriebsarztservice-telemedizin',
    MEDIMENTUM = 'medimentum',
    AGAPLESION_KASSEL = 'agaplesion-kassel',
    DDC = 'ddc',
    SANASO = 'sanaso',
    ACURA = 'rheuma-vor',
    HELIOS = 'helios-videosprechstunde',
    ASZ = 'asz',
    BKK_PWC = 'bkk-pwc',
    AOK = 'aok',
    LANAE = 'telemedizin-lanae',
    UKSH = 'uksh',
    EPILEPTOLOGIE_BONN = 'epileptologie-bonn',
    BKK_RWE = 'bkk-rwe',
    BKK_MHPLUS = 'bkk-mhplus',
    FERNARZT = 'fernarzt',
    ITZEHOE = 'klinikum-itzehoe-mvzs',
    POLAVIS = 'polavis',
    MEDATIXX = 'xonvid',
    TIMERBEE = 'timerbee',
    ENMEDIFY = 'enmedify',
    ENMEDIFYCH = 'enmedifych',
    INVIRTO = 'invirto',
    ZOTZKLIMAS = 'zotzklimas',
    SYNLAB = 'synlab-humangenetik',
    S_THETIC = 's-thetic',
    CKELLER_STUDIEN = 'ckeller-studien'
}

export const CERTIFIED_SUBDOMAINS: Subdomains[] = [
    Subdomains.DEFAULT,
    Subdomains.AGAPLESION,
    Subdomains.HELIOS,
    Subdomains.HOMEDOK,
    Subdomains.JAMEDA,
    Subdomains.UKSH,
    Subdomains.FERNARZT,
    Subdomains.ENMEDIFY,
    Subdomains.ENMEDIFYCH,
    Subdomains.INVIRTO,
    Subdomains.ZOTZKLIMAS
];

@Injectable()
export class BrandingService {
    constructor(private store: Store<AppState>) {
    }

    /**
     * Tries to extract the subdomain part from the url.
     *
     * @param hostname
     */
    public getSubdomain(hostname: string): string {
        const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
        const urlParts = regexParse.exec(hostname);
        if (!urlParts || urlParts.length === 0) {
            return '';
        }

        const subdomains = hostname.replace(urlParts[0], '').slice(0, -1);
        if (!subdomains || subdomains.length === 0) {
            return '';
        }

        return subdomains.split('.')[0];
    }

    /**
     * Init the branding, by checking the subdomain part. If we have a
     * configuration for it, we will update the store with the correct values.
     */
    public initBranding(): void {
        const subdomain: string = this.getSubdomain(window.location.hostname);

        switch (subdomain) {
            case Subdomains.FOUR_SIGMA:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(sigma));
                break;
            case Subdomains.AUDI_BKK:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(audiBKK));
                break;
            case Subdomains.KKH:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(kkh));
                break;
            case Subdomains.CFG_AOK:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(aokCfg));
                break;
            case Subdomains.IKK_SUEDWEST:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(ikkSuedwest));
                break;
            case Subdomains.BMW_BKK:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(bmwBKK));
                break;
            case Subdomains.KINZIGTAL:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(kinzigtal));
                break;
            case Subdomains.AGAPLESION:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(agaplesion));
                break;
            case Subdomains.ASDBGN:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(asdbgn));
                break;
            case Subdomains.HOMEDOK:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(homedok));
                break;
            case Subdomains.ALBATROS:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(albatros));
                break;
            case Subdomains.BAD:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(bad));
                break;
            case Subdomains.BKK_MELITTA:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(BKKmelitta));
                break;
            case Subdomains.JAMEDA:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(jameda));
                break;
            case Subdomains.BETRIEBSARZTSERVICE:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(betriebsarztservice));
                break;
            case Subdomains.MEDIMENTUM:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(medimentum));
                break;
            case Subdomains.AGAPLESION_KASSEL:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(agaplesionKassel));
                break;
            case Subdomains.DDC:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(ddc));
                break;
            case Subdomains.SANASO:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(sanaso));
                break;
            case Subdomains.ACURA:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(acura));
                break;
            case Subdomains.BKK_PWC:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(BKKpwc));
                break;
            case Subdomains.HELIOS:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(helios));
                break;
            case Subdomains.ASZ:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(asz));
                break;
            case Subdomains.AOK:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(aok));
                break;
            case Subdomains.LANAE:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(lanae));
                break;
            case Subdomains.UKSH:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(uksh));
                break;
            case Subdomains.EPILEPTOLOGIE_BONN:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(epileptologieBonn));
                break;
            case Subdomains.BKK_RWE:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(bkkRwe));
                break;
            case Subdomains.BKK_MHPLUS:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(bkkMhplus));
                break;
            case Subdomains.FERNARZT:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(fernarzt));
                break;
            case Subdomains.ITZEHOE:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(itzehoe));
                break;
            case Subdomains.POLAVIS:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(polavis));
                break;
            case Subdomains.MEDATIXX:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(medatixx));
                break;
            case Subdomains.TIMERBEE:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(timerbee));
                break;
            case Subdomains.ENMEDIFY:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(enmedify));
                break;
            case Subdomains.ENMEDIFYCH:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(enmedifych));
                break;
            case Subdomains.INVIRTO:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(invirto));
                break;
            case Subdomains.ZOTZKLIMAS:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(zotzklimas));
                break;
            case Subdomains.SYNLAB:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(synlab));
                break;
            case Subdomains.S_THETIC:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(sthetic));
                break;
            case Subdomains.CKELLER_STUDIEN:
                this.store.dispatch(new BrandingSettingsLoadCustomBranding(ckellerStudien));
                break;
            default:
                this.store.dispatch(new BrandingSettingsLoadDefault());
                break;
        }

        this.getBrandingSettingsFromStore().pipe(
            map(state => this.createCssVarsObj(state)))
            .subscribe(e => this.setCssVarsToBody(e));
    }

    /**
     * Returns the branding store state.
     *
     * @returns
     */
    public getBrandingSettingsFromStore(): Observable<BrandingState> {
        return this.store.select((appState: AppState) => appState.branding);
    }

    public createCssVarsObj(obj: any): any {
        const cssVarsObj = {
            '--mainColor': obj.mainColor,
            '--secondaryColor': obj.secondaryColor,
            '--fontColor': obj.fontColor,
            '--contrastColor': obj.contractColor,
            '--hoverPrimaryColoredBtn': obj.hoverPrimaryColoredBtn,
            '--hoverSecondaryColoredBtn': obj.hoverSecondaryColoredBtn,
            '--coloredShadow': obj.coloredShadow,
            '--fontColorOnSecondaryColor': obj.fontColorOnSecondaryColor,
            '--logoBackgroundColor': obj.logoBackgroundColor
        };
        return this.cleanFromEmptyAttributes(cssVarsObj);
    }

    private cleanFromEmptyAttributes(obj) {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    }

    public setCssVarsToBody(cssObj: any) {
        Object.keys(cssObj).map(key => {
            document.body.style.setProperty(key, cssObj[key]);
        });
    }
}

