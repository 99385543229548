import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddPaymentBankAccountInfo, UpdatePaymentBankAccountInfo } from '../store/payment-bank-account.actions';
import * as fromPaymentBankAccountInfo from '../store/payment-bank-account.reducer';
import { PaymentBankAccount } from '../store/payment-bank-account.model';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentBankAccountStoreService {

    constructor(private store: Store<fromPaymentBankAccountInfo.PaymentBankAccountInfoState>) {
    }

    public addPaymentBankAccountInfoInStore(paymentBankAccountInfo: PaymentBankAccount): void {
        this.store.dispatch(new AddPaymentBankAccountInfo({paymentBankAccountInfo}));
    }

    public updatePaymentBankAccountInfoInStore(paymentBankAccountInfo: PaymentBankAccount): void {
        this.store.dispatch(new UpdatePaymentBankAccountInfo({
            paymentBankAccountInfo: {
                id: paymentBankAccountInfo.id,
                changes: paymentBankAccountInfo
            }
        }));
    }

    public getPaymentBankAccountInfoFromStore(): Observable<PaymentBankAccount | null> {
        return this.store.select(fromPaymentBankAccountInfo.selectBankAccountPaymentInfo);
    }
}
