import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {webrtcReducer} from './store/webrtc.reducer';
import {EffectsModule} from '@ngrx/effects';
import {WebrtcEffects} from './store/webrtc.effect';
import {LogAppointmentService} from '../logging/providers/log-appointment.service';

import {OncallProvider} from './provider/oncall.provider';
import {PeerService} from './provider/peer.service';
import { SignalService } from './provider/signal.service';
import { HeartbeatService } from './provider/heartbeat/heartbeat.service';
import { ConnectionConfigurationService } from './provider/configurations/connection-configuration.service';
import { SchedulerFactory} from './provider/scheduler/scheduler.service';
import { MonitorService } from './provider/monitor/monitor.service';
import { LogClientSideService } from '../custom-error-handler/providers/log-client-side/log-client-side.service';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('webrtc', webrtcReducer),
        EffectsModule.forFeature([WebrtcEffects]),
    ],
    providers: [
        HeartbeatService,
        ConnectionConfigurationService,
        LogAppointmentService,
        OncallProvider,
        PeerService,
        SignalService,
        SchedulerFactory,
        MonitorService,
        LogClientSideService
    ]
})
export class WebrtcModule {}
