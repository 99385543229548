export interface CompareVersionNumbersOptions {
    zeroExtend?: boolean;
    lexicographical?: string;
}

const zeroExtended: CompareVersionNumbersOptions = {
    zeroExtend: true
};

export class StaticUtils {
    static sortAscending<T>(items: T[], property: string): T[] {
        return items.sort((a: T, b: T) => a[property].localeCompare(b[property]));
    }

    /* Taken from https://gist.github.com/TheDistantSea/8021359 */
    static compareVersionNumbers(v1: string, v2: string, options = zeroExtended): number {
        const lexicographical = options.lexicographical;
        const zeroExtend = options.zeroExtend;
        let v1parts: Array<string | number> = v1.split('.');
        let v2parts: Array<string | number> = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            throw new Error('Numbers have invalid parts');
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) {
                v1parts.push('0');
            }
            while (v2parts.length < v1parts.length) {
                v2parts.push('0');
            }
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (let i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }

            if (v1parts[i] === v2parts[i]) {
            } else if (v1parts[i] > v2parts[i]) {
                return 1;
            } else {
                return -1;
            }
        }

        if (v1parts.length !== v2parts.length) {
            return -1;
        }

        return 0;
    }

    static toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    static isNumber(value: any): value is number {
        return !isNaN(this.toInteger(value));
    }

    static padNumber(value: number): string {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }
}
