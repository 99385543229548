import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { LoggingType } from '../../modules/custom-error-handler/models/LoggingType';
import { version } from '../../modules/utils/version';
import { ProfileService } from '../../modules/profile/providers/profile.service';
import { Profile } from '../../modules/profile/store/profile.entity';

@Injectable({
    providedIn: 'root'
})
export class ServerSideLoggerService {

    private clientSideEndpoint = environment.clientSideLoggingEndpoint;
    private appVersion = version.version;

    constructor(
        private http: HttpClient,
        private profileService: ProfileService,
        private router: Router
    ) {

        window.onerror = (msg, url, lineNo, columnNo, error) => {
            const stringMsg = JSON.stringify(msg).toLowerCase();
            const substring = 'script error';
            if (stringMsg.indexOf(substring) > -1) {
                this.log(LoggingType.FATAL, 'Script Error: See Browser Console for Detail');
            } else {
                const message = [
                    'Message: ' + stringMsg,
                    'URL: ' + url,
                    'Line: ' + lineNo,
                    'Column: ' + columnNo,
                    'Error object: ' + JSON.stringify(error)
                ].join(' - ');

                this.log(LoggingType.FATAL, message);
            }

            return false;
        };
    }

    public info(message: string) {
        if (environment.logInfo) {
            this.log(LoggingType.INFO, message);
        }
    }

    public debug(message: string) {
        if (environment.debug) {
            this.log(LoggingType.DEBUG, message);
        }
    }

    public error(message: string) {
        this.log(LoggingType.ERROR, message);
    }

    public warn(message: string) {
        this.log(LoggingType.WARNING, message);
    }

    public fatal(message: string) {
        this.log(LoggingType.FATAL, message);
    }

    public log(messageType: LoggingType, message: string): void {
        if (environment.production) {
            this.send(messageType, message);
        }

        console.log('ServerSideLogger: ', message);
    }

    public send(messageType: LoggingType, message: string): void {
        this.profileService.getCurrentProfileDataObserver().pipe(
            take(1),
            filter((profile: Profile) => !!profile),
            map((profile: Profile) => ({
                        stackTrace: '',
                        profileId: profile.id,
                        type: profile.type,
                        tan: '#AAAAA',
                        message: message + ' -- ' + profile.institution,
                        messageType,
                        version: this.appVersion,
                        clientTimestamp: new Date().getTime(),
                        url: this.getRouterUrl(),
                        userAgent: navigator.userAgent
                    })
            ),
            mergeMap((data) => this.http.post(this.clientSideEndpoint, data))
        ).subscribe();
    }

    private getRouterUrl(): string {
        return this.router.url;
    }
}
