import { getGenderNameById } from '../../utils/genders';
import { Profile, profileTypeMap } from '../store/profile.entity';
import { ProfileDataResponse } from './profile.types';

export const PROFILE_IMAGES_DIRECTORY = '/arzt/assets/img/profile_images/';

export class MapService {

    public static getProfileImage(imageUrl): string {
        if (imageUrl !== undefined || imageUrl !== null) {
            return PROFILE_IMAGES_DIRECTORY + imageUrl;
        }
    }

    public static mapProfile(raw: ProfileDataResponse): Profile {
        const rawProfile = raw;

        const imageUrl = rawProfile.imageUrl.toString();
        const salutationId = rawProfile.salutation;

        if (salutationId !== null || salutationId !== undefined) {
            rawProfile.salutation = getGenderNameById(salutationId);
        }

        rawProfile.imageUrl = MapService.getProfileImage(imageUrl);

        let fullName = rawProfile.firstName + ' ' + rawProfile.lastName;

        if (rawProfile.title) {
            fullName = rawProfile.title + ' ' + fullName;
        }

        if (rawProfile.salutation) {
            fullName = rawProfile.salutation + ' ' + fullName;
        }

        rawProfile.fullName = fullName;

        rawProfile.id = Number(rawProfile.id);

        return <Profile>{
            ...rawProfile,
            type: profileTypeMap(rawProfile.type)
        };
    }
}
