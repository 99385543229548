import { UAParser } from 'ua-parser-js';
import { WindowToken } from '../../tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserAgentByProfileId } from '../../store/user-agent.selector';
import { UserAgentState } from '../../store/user-agent.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class UserAgentService {
    private uaParser: any;

    constructor(
        private store: Store<UserAgentState>,
        @Inject(WindowToken) private window: Window
    ) {
        this.uaParser = new UAParser(window.navigator.userAgent);
    }

    public getBrowserName(): string {
        return this.uaParser.getBrowser().name;
    }

    public getBrowserNameByProfileId(profileId: number): Observable<string> {
        return this.store.select(selectUserAgentByProfileId(profileId)).pipe(
            map(userAgent => {
                if (userAgent === null) {
                    return '';
                }
                return userAgent.browserName;
            })
        );
    }

}
