import { catchError, map, mapTo, mergeMap, retry, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStateProfile, Profile, ProfileImage, ProfileSettings, ProfileType } from '../store/profile.entity';
import { Store } from '@ngrx/store';
import { getIsInitSelector, selectCurrentProfile, selectCurrentProfileType, selectEntitites } from '../store/profile.selector';
import { ProfileUpdateAction } from '../store/profile.action';
import { MapService } from './map.service';
import { ProfileDataResponse, ProfileImageResponse } from './profile.types';

@Injectable()
export class ProfileService {

    constructor(private http: HttpClient, private store: Store<EntityStateProfile>) {}

    public getCurrentProfileData(): Observable<Profile> {
        return this.http.get(environment.currentProfileDataEndpoint, {}).pipe(
            map((rawProfile: ProfileDataResponse) => {
                const profile = MapService.mapProfile(rawProfile);
                const hasAllProperties = profile.hasOwnProperty('imageUrl') && profile.hasOwnProperty('type');
                if (hasAllProperties) {
                    const isResponseValid = profile.fullName && profile.imageUrl && profile.type;

                    if (isResponseValid) {
                        return profile;
                    } else {
                        throw new Error('Wrong Response Object');
                    }
                } else {
                    throw new Error('Wrong Response Object');
                }
            }));
    }

    public getCurrentProfileDataObserver(): Observable<Profile> {
        return this.store.select(selectCurrentProfile);
    }

    public getCurrentProfileTypeObserver(): Observable<ProfileType> {
        return this.store.select(selectCurrentProfileType);
    }

    public getProfilesList(): Observable<{ [id: number]: Profile }> {
        return this.store.select(selectEntitites);
    }

    public acceptDataProcess(profile: Profile): Observable<Profile> {
        return this.http.post(environment.termAcceptDataProtection, {
            acceptDataProcessTerm: true
        }).pipe(
            mapTo(<Profile>{...profile, dataProcessTerm: true})
        );
    }

    public updateProfileSettings(profileSettings: ProfileSettings): Observable<Profile> {
        return this.http.put(environment.profileUpdateEndpoint, profileSettings)
            .pipe(
                map((rawProfile: ProfileDataResponse) => {
                    const profile = MapService.mapProfile(rawProfile);
                    this.store.dispatch(new ProfileUpdateAction(profile));

                    return profile;
                }),
                catchError(this.handleError)
            );
    }

    public updateProfileImage(payload: ProfileImage): Observable<Profile> {
        const response = this.http.put(environment.profileImageUpdateEndpoint, payload).pipe(
            retry(3),
            take(1)
        );
        return this.handleProfileUploadResponse(response as Observable<ProfileImageResponse>)
            .pipe(
                catchError(httpError => this.handleErrorInProfileUpload(payload, httpError))
            );
    }

    public isInit$(): Observable<boolean> {
        return this.store.select(getIsInitSelector);
    }

    private handleProfileUploadResponse(response: Observable<ProfileDataResponse | ProfileImageResponse>): Observable<Profile> {
        return response.pipe(
            map((imageResponse: ProfileImageResponse | ProfileDataResponse) => {
                if (!!(imageResponse as any).type) {
                    return {imageUrl: (imageResponse as ProfileDataResponse).imageUrl};
                } else {
                    return {imageUrl: imageResponse.id};
                }
            }),
            map((profileInput: Profile) => {
                return this.getCurrentProfileDataObserver().pipe(
                    take(1),
                    map(
                        (profile) => {
                            profile.imageUrl = MapService.getProfileImage(profileInput.imageUrl);
                            return profile;
                        }
                    ));
            }),
            mergeMap((profile) => profile),
            tap((profile) => {
                return this.store.dispatch(new ProfileUpdateAction(profile));
            })
        );
    }

    private handleErrorInProfileUpload(payload: ProfileImage, httpErrorResponse: HttpErrorResponse): Observable<Profile> {
        if (!!httpErrorResponse.status && httpErrorResponse.status === 500) {
            const response = this.http.put(environment.profileImageUpdateUserPermissionEndpoint, payload)
                .pipe(
                    retry(3),
                    take(1)
                );
            return this.handleProfileUploadResponse(response as Observable<ProfileDataResponse>);
        } else {
            this.handleError(httpErrorResponse);
        }
    }

    private handleError(httpErrorResponse: HttpErrorResponse) {
        if (httpErrorResponse.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in ProfileService:', httpErrorResponse.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${httpErrorResponse.status}, ` +
                `body was: ${httpErrorResponse.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
