import { filter, take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    APPOINTMENT_CREATE_CONSULTATIONHOURS,
    ROUTE_CANCELED,
    ROUTE_DISPOSED,
    ROUTE_OUTSTANDING,
    ROUTE_PAID
} from '../../../../../routes';
import { PaymentInfoStoreService } from '../../../../../entities/payment-info/provider/payment-info-store.service';
import { PaymentBankAccountStoreService } from '../../../../../entities/payment-bank-account/provider/payment-bank-account-store.service';
import { PaymentInfo, PaymentInfoStatuses } from '../../../../../entities/payment-info/store/payment-info.model';
import { PaymentBankAccount, BankAccountStatus } from '../../../../../entities/payment-bank-account/store/payment-bank-account.model';

@Component({
    selector: 'app-payment-account-tab',
    templateUrl: './payment-account-tab.component.html',
    styleUrls: ['./payment-account-tab.component.scss']
})
export class PaymentAccountTabComponent implements OnInit, OnDestroy {

    paymentAlreadyActive = false;
    paymentHistory: any[] = [];

    activeRoute: string;
    private routerSubscription: Subscription = null;

    public DISPOSED_LINK = ROUTE_DISPOSED;
    public OUSTANDING_LINK = ROUTE_OUTSTANDING;
    public CANCELED_LINK = ROUTE_CANCELED;
    public PAID_LINK = ROUTE_PAID;
    public paymentInfo: PaymentInfo;
    public paymentBankAccount: PaymentBankAccount;

    private paymentInfoSubscription: Subscription = null;
    private paymentBankAccountSubscription: Subscription = null;

    PAYMENT_ROUTE_INFO = '/payment/info';
    APPOINTMENT_CREATE_CONSULTATIONHOURS = APPOINTMENT_CREATE_CONSULTATIONHOURS;

    constructor(private router: Router,
                private paymentInfoStoreService: PaymentInfoStoreService,
                private paymentBankAccountStoreService: PaymentBankAccountStoreService,
    ) {
        this.listenForPathChanges();
    }

    ngOnInit(): void {
        this.paymentInfoSubscription = this.paymentInfoStoreService.getPaymentInfoFromStore().pipe(
            filter(v => !!v),
            take(1)
        ).subscribe(v => this.paymentInfo = v);

        this.paymentBankAccountSubscription = this.paymentBankAccountStoreService.getPaymentBankAccountInfoFromStore().pipe(
            filter(v => !!v),
            take(1)
        ).subscribe(v => this.paymentBankAccount = v);
    }

    ngOnDestroy(): void {
        if (this.routerSubscription !== null) {
            this.routerSubscription.unsubscribe();
        }

        if (this.paymentInfoSubscription !== null) {
            this.paymentInfoSubscription.unsubscribe();
        }

        if (this.paymentBankAccountSubscription !== null) {
            this.paymentBankAccountSubscription.unsubscribe();
        }
    }

    private listenForPathChanges(): void {
        this.routerSubscription = this.router.events.pipe(
            filter((e: any) => !!e.url))
            .subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.activeRoute = event.urlAfterRedirects;
                }
            });
    }

    public isActiveTab(tabUrl: string): boolean {
        return (!!this.activeRoute && this.activeRoute.indexOf(tabUrl) >= 0);
    }

    public isPaymentActive(): boolean {
        if (!this.paymentBankAccount || !this.paymentInfo) {
            return false;
        }

        return this.paymentBankAccount.status === BankAccountStatus.FINISHED &&
            this.paymentInfo.status === PaymentInfoStatuses.FINISHED;
    }
}
