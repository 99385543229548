import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createSelector, createFeatureSelector} from '@ngrx/store';
import {Dictionary} from '@ngrx/entity/src/models';
import {PaymentBankAccount} from './payment-bank-account.model';
import {PaymentBankAccountActions, PaymentBankAccountActionTypes} from './payment-bank-account.actions';

export type PaymentBankAccountInfoState = EntityState<PaymentBankAccount>;

export const adapter: EntityAdapter<PaymentBankAccount> = createEntityAdapter<PaymentBankAccount>();

export const initialState: PaymentBankAccountInfoState = adapter.getInitialState({});

export function reducer(state = initialState, action: PaymentBankAccountActions): PaymentBankAccountInfoState {
    switch (action.type) {
        case PaymentBankAccountActionTypes.AddPaymentBankAccountInfo: {
            return adapter.addOne(action.payload.paymentBankAccountInfo, state);
        }

        case PaymentBankAccountActionTypes.AddPaymentBankAccountInfos: {
            return adapter.addMany(action.payload.paymentBankAccountInfos, state);
        }

        case PaymentBankAccountActionTypes.UpdatePaymentBankAccountInfo: {
            return adapter.updateOne(action.payload.paymentBankAccountInfo, state);
        }

        case PaymentBankAccountActionTypes.UpdatePaymentBankAccountInfos: {
            return adapter.updateMany(action.payload.paymentBankAccountInfos, state);
        }

        case PaymentBankAccountActionTypes.DeletePaymentBankAccountInfo: {
            return adapter.removeOne(action.payload.id, state);
        }

        case PaymentBankAccountActionTypes.DeletePaymentBankAccountInfos: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case PaymentBankAccountActionTypes.LoadPaymentBankAccountInfos: {
            return adapter.setAll(action.payload.paymentBankAccountInfos, state);
        }

        case PaymentBankAccountActionTypes.ClearPaymentBankAccountInfos: {
            return adapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}

export const selectPaymentBankAccountState =
    createFeatureSelector<PaymentBankAccountInfoState>('payment-bank-account-info');

export const {
    selectEntities,
    selectAll,
} = adapter.getSelectors(selectPaymentBankAccountState);

export const selectBankAccountPaymentInfo = createSelector(selectEntities, (allEntities: Dictionary<PaymentBankAccount>) => {
        if (!allEntities || Object.keys(allEntities).length !== 1) {
            return null;
        }
        const key = Object.keys(allEntities)[0];
        return allEntities[key];
    }
);
