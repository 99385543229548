import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserPresenceStatus } from '../user-presence-status';
import { map } from 'rxjs/operators';
import { UserPresenceStoreService } from './user-presence-store.service';
import { XmppRosterService } from './xmpp-roster.service';
import { UserPresence } from '../store/user-presence.model';
import { XmppLogger } from '../xmpp/xmpp.logger';

@Injectable({
    providedIn: 'root'
})
export class UserPresenceService {

    constructor(
        private storeService: UserPresenceStoreService,
        private xmppRoster: XmppRosterService,
        private log: XmppLogger
    ) {
    }

    public getUserPresenceStatus(profileId: number): Observable<UserPresenceStatus> {
        this.log.info('get Presence for', profileId);
        return this.storeService.getPresence(profileId).pipe(
            map(userPresence => (userPresence === null || userPresence === undefined ? {
                id: profileId,
                status: UserPresenceStatus.OFFLINE
            } : userPresence)),
            map(userPresence => userPresence.status)
        );
    }

    public getCountOnlinePeers(): Observable<number> {
        return this.storeService.getAllUserPresences().pipe(
            map(userPresences => (userPresences === null ? [] : userPresences)),
            map(userPresences => this.mapOnlineCount(userPresences))
        );
    }

    private mapOnlineCount(userPresences: UserPresence[]): number {
        let count = 0;
        userPresences.forEach(s => {
            count = (
                s.status === UserPresenceStatus.ONLINE ||
                s.status === UserPresenceStatus.AWAY ||
                s.status === UserPresenceStatus.DND) ? count + 1 : count;
        });
        return count;
    }

    public setReady(): void {
        this.xmppRoster.setMyStatus(UserPresenceStatus.ONLINE);
    }

    public setAway(): void {
        this.xmppRoster.setMyStatus(UserPresenceStatus.AWAY);
    }

    public setBusy(): void {
        this.xmppRoster.setMyStatus(UserPresenceStatus.DND);
    }

    public isDeviceDetected(isDeviceDetected: boolean) {
        this.xmppRoster.setIsDeviceDetected(isDeviceDetected);
    }
}
