import { Injectable } from '@angular/core';
import { WebrtcDataChannelService } from './data-channel/webrtc-data-channel.service';
import { Observable, Subject } from 'rxjs';
import { SignalService } from './signal.service';
import { TurnConfigService } from '../../../providers/turn-config/turn-config.service';
import { ServerSideLoggerService } from '../../../providers/server-side-logger/server-side-logger.service';
import { PeerOps, SimplePeer } from '../../../../typings/typings';

@Injectable()
export class PeerService {

    private pc: SimplePeer = undefined;
    private connected = new Subject<boolean>();

    constructor(protected signal: SignalService,
                protected dataChannel: WebrtcDataChannelService,
                protected turnConfigService: TurnConfigService,
                protected logger: ServerSideLoggerService
    ) {
        this.signal.getSignal((msg) => {
            if (this.pc === undefined) {
                this.pc = this.create(false, msg.callerProfileId);
            }
            this.pc.signal(msg.signal);
        });
        this.connected.next(false);
    }

    public startCall(peerProfileId: number): void {
        this.log(PeerLoggingEvent.CALL_START);
        this.pc = this.create(true, peerProfileId);
    }

    public close(): void {
        this.log(PeerLoggingEvent.CALL_CLOSE);
        this.connected.next(false);
        if (this.pc !== undefined) {
            try {
                this.pc.destroy();
            } catch (err) {
                this.forceCloseConnection();
            }
        }
    }

    public isConnected(): Observable<boolean> {
        return this.connected;
    }

    private forceCloseConnection() {
        this.dataChannel.onClose();
        this.pc = undefined;
        this.connected.next(false);
    }

    /**
     * Create a simple peer connection
     */
    private create(initiator: boolean, peerProfileId: number): SimplePeer {
        const turnServerConfig = this.turnConfigService.defaultTurnServers;
        const ops = {
            initiator,
            channelConfig: {},
            channelName: 'default',
            config: {iceServers: turnServerConfig},
            offerOptions: {},
            answerOptions: {},
            sdpTransform(sdp) {
                return sdp;
            },
            stream: true,
            streams: [],
            trickle: true,
            allowHalfTrickle: false,
            objectMode: false
        };

        const pc = this.createSimplePeer(ops);

        pc.on('stream', stream => {
        });

        pc.on('signal', data => {
            this.signal.sendSignal(data, peerProfileId);
        });

        pc.on('track', (data) => {
        });

        pc.on('connect', (data) => {
            this.dataChannel.setChannel(pc._channel);
            this.connected.next(true);
        });

        pc.on('error', (error) => {
            let message = '';
            if (!!error) {
                message = error.message !== undefined ? error.message : error.toString();
            }
            this.log(PeerLoggingEvent.ERROR, message);
        });

        pc.on('close', () => {
            this.log(PeerLoggingEvent.CLOSE);
            this.forceCloseConnection();
        });

        pc.on('iceStateChange', (state) => {
            this.log(PeerLoggingEvent.ICE_STATE_CHANGE, state);
            if (state === 'disconnected') {
                setTimeout(() => {
                    const {connectionState} = (pc === null || pc === undefined
                        || pc._pc === undefined || pc._pc === null) ? {connectionState: 'disconnected'} : <any>pc._pc;
                    if (connectionState === 'disconnected') {
                        this.log(PeerLoggingEvent.CONNECTION_GOT_INTERRUPTED);
                    }
                }, 3000);
            }
        });
        return pc;
    }

    createSimplePeer(ops: PeerOps): SimplePeer {
        this.log(PeerLoggingEvent.NEW_CONNECTION);
        return new (window as any).SimplePeer(ops);
    }

    private log(event: PeerLoggingEvent, message: string = ''): void {
        if (message === '') {
            this.logger.info(event);
        } else {
            this.logger.info(event + ' ' + message);
        }
    }
}

enum PeerLoggingEvent {
    CONNECTION_GOT_INTERRUPTED = 'PEER_CONNECTION_GOT_INTERRUPTED',
    ICE_STATE_CHANGE = 'PEER_ICE_STATE_CHANGE',
    CLOSE = 'PEER_CLOSE',
    ERROR = 'PEER_ERROR',
    NEW_CONNECTION = 'PEER_NEW_CONNECTION',
    CONNECTED = 'PEER_CONNECTED',
    CALL_START = 'PEER_CALL_START',
    CALL_CLOSE = 'PEER_CALL_CLOSE'
}
