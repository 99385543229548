<div id="image-upload__modal">
    <div class="modal-container">
        <div class="modal-header">
            {{ 'UTIL.IMAGE-UPLOAD.UPLOAD-IMAGE' | translate }}
            <button id="image-upload__close" (click)="handleCloseButtonClick()">
                <i class="fal fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="file-area"
                 *ngIf="!previewFile"
                 (dragenter)="onDragEnter($event)"
                 (dragleave)="onDragLeave($event)">
                <p class="error" *ngIf="error">
                    <i class="fal fa-exclamation-triangle"></i>
                    {{ error }}
                </p>
                <label for="images">
                    <p>
                        <i class="fal fa-hand-paper-o"></i>
                        <span> {{ 'UTIL.IMAGE-UPLOAD.FILE' | translate }}
                            <strong>(jpg, jpeg, gif, png)</strong> {{ 'UTIL.IMAGE-UPLOAD.DROP-DESCRIPTION' | translate }}
                        </span>
                    </p>
                    <span class="file-area__button">
                        {{ 'UTIL.IMAGE-UPLOAD.BROWSE' | translate }}
                        <i class="fal fa-folder-open"></i>
                    </span>
                </label>
                <input type="file"
                       name="images"
                       id="images"
                       required="required"
                       (change)="fileSelected($event)"/>
            </div>
            <div *ngIf="!!previewFile && options?.locale" style="height: 515px">
                <pintura-editor #myDoka
                                [src]="previewFile"
                                (process)="handleDokaConfirm($event)"
                                (close)="handleCloseButtonClick()"
                                [options]="options">
                </pintura-editor>
            </div>
        </div>
    </div>
</div>
