import { distinctUntilKeyChanged } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponentClass } from '../modal/model/interface';
import { RtcDetectionStepDirective } from './directive/rtc-detection-step-directive';
import { NextStepService } from './providers/next-step.service';
import { Store } from '@ngrx/store';
import { AppState } from './store/rtc-detection.state';
import { RtcDetectionModalManuallyClosed, RtcDetectionNextStepAction } from './store/rtc-detection.action';
import { StepType } from './rtc-detection.entities';
import { ModalCarrierService } from './providers/modal-carrier.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-rtc-detection-content',
    templateUrl: './rtc-detection.component.html',
    styleUrls: ['./rtc-detection.component.scss']
})
export class RtcDetectionComponent implements ModalContentComponentClass, AfterViewInit, OnDestroy {
    private storeSubscription: Subscription;

    public aboveModalOverlayIsOpen = false;

    @ViewChild(RtcDetectionStepDirective) appRtcDetectionStep: RtcDetectionStepDirective;

    constructor(public activeModal: NgbActiveModal,
                private modalControlService: ModalCarrierService,
                private nextStepService: NextStepService,
                private componentFactoryResolver: ComponentFactoryResolver,
                private store: Store<AppState>,
                private ref: ChangeDetectorRef) {

        this.store.select('rtcDetection').pipe(distinctUntilKeyChanged('aboveModalOverlayIsOpen')).subscribe(state => {
            this.aboveModalOverlayIsOpen = state.aboveModalOverlayIsOpen;
        });
        this.modalControlService.setActiveModal(this.activeModal);
    }

    public dismissModal(): void {
        this.store.dispatch(new RtcDetectionModalManuallyClosed());
        this.activeModal.dismiss('DismissModal click');
    }

    public closeModal(): void {
        this.activeModal.close('Close click');
        this.store.dispatch(new RtcDetectionModalManuallyClosed());
    }

    public nextStep(next: StepType) {
        this.store.dispatch(new RtcDetectionNextStepAction(next));
    }

    public cancelStep() {
        this.store.dispatch(new RtcDetectionNextStepAction(StepType.WARNING));
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.storeSubscription = this.store.select('rtcDetection').pipe(distinctUntilKeyChanged('currentStep')).subscribe(state => {
                this.loadStep(state.currentStep);
            });
        });
    }

    ngOnDestroy(): void {
        if (this.storeSubscription instanceof Subscription) {
            this.storeSubscription.unsubscribe();
        }
    }

    private loadStep(stepType: StepType) {
        const step = this.nextStepService.getStepByType(stepType);

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(step.component);
        const viewContainerRef = this.appRtcDetectionStep.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.changeDetectorRef.detectChanges();
        this.ref.detectChanges();

        componentRef.instance.closeModal = this.closeModal.bind(this);
        componentRef.instance.dismissModal = this.dismissModal.bind(this);
        componentRef.instance.nextStep = this.nextStep.bind(this);
        componentRef.instance.cancelStep = this.cancelStep.bind(this);
    }
}
