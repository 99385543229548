import { Injectable } from '@angular/core';
import { XmppLogger } from '../xmpp/xmpp.logger';
import { UserPresenceStoreService } from './user-presence-store.service';
import { UserPresence } from '../store/user-presence.model';
import { UserPresenceStatus } from '../user-presence-status';
import { XmppUtil } from '../xmpp/xmpp.util';
import { XmppMessageBuilder } from '../xmpp/xmpp.message.builder';
import { XmppUser } from '../xmpp/xmpp-user.model';
import { BrowserService } from '../../media/provider/browser.service';
import { Profile, ProfileType } from '../../profile/store/profile.entity';
import { ProfileService } from '../../profile/providers/profile.service';
import { filter, mergeMap, take, tap } from 'rxjs/operators';

@Injectable()
export class XmppRosterService {

    /**
     * Strophe connection object
     */
    private connection;

    private onPresence: (p1: any) => boolean;

    private xmppUser: XmppUser;
    private isDeviceDetected = false;

    constructor(
        private store: UserPresenceStoreService,
        private messageBuilder: XmppMessageBuilder,
        private browser: BrowserService,
        private profileService: ProfileService,
        private log: XmppLogger) {
    }

    /**
     * Read Roster from backend and subscribe for changes
     *
     * @param connection
     */
    public init(connection, user: XmppUser, onPresence: (p1: any) => boolean): void {
        this.connection = connection;
        this.xmppUser = user;
        this.onPresence = onPresence;

        this.connection.addHandler(this.onRosterChanged.bind(this), 'jabber:iq:roster', 'iq', 'set');

        const iq = $iq({type: 'get'}).c('query', {xmlns: 'jabber:iq:roster'});
        this.connection.sendIQ(iq, this.onRoster.bind(this), this.errorHandler.bind(this));
    }

    public destroy() {
        //
    }

    public addContact(profileId: number): void {
        const jid = XmppUtil.createJid(profileId);
        const data = {jid};

        const iq = $iq({type: 'set'}).c('query', {xmlns: 'jabber:iq:roster'}).c('item', data);
        this.connection.sendIQ(iq, null, this.errorHandler.bind(this));

        const subscribe = $pres({to: data.jid, type: 'subscribe'});
        this.connection.send(subscribe, null, this.errorHandler.bind(this));
    }

    private onRoster(iq): boolean {
        let subscriberCount = 0;
        const self = this;

        $(iq).find('item').each(function() {
            subscriberCount = subscriberCount + 1;
            const jid = $(this).attr('jid');
            const profileId = XmppUtil.getIdFromJid(jid);
            const resource = XmppUtil.getResourceFromJid(jid);
            const userPresence: UserPresence = UserPresence.createNewUserPresence(profileId, UserPresenceStatus.OFFLINE, resource);
            self.store.upsertPresence(userPresence);
        });

        if (this.xmppUser.role === ProfileType.PATIENT && subscriberCount === 0) {
            this.profileService.getProfilesList().pipe(
                take(1),
                mergeMap((profileList: { [id: number]: Profile }) => Object.keys(profileList)),
                filter((profileId) => Number(profileId) !== this.xmppUser.profileId),
                tap((profileId) => this.addContact(Number(profileId)))
            ).subscribe();
        }

        // set up presence handler and send initial presence
        this.connection.addHandler(this.onPresence, null, 'presence');
        this.connection.send($pres());
        if (!this.browser.isMobile() && this.xmppUser.role === ProfileType.PATIENT && this.isDeviceDetected) {
            this.setMyStatus(UserPresenceStatus.AWAY);
        } else {
            // FIXME: Temporary disabled device test on mobile
            // this.setMyStatus(PresenceStatus.AWAY);
            this.setMyStatus(UserPresenceStatus.ONLINE);
        }
        return false;
    }

    public setMyStatus(userPresence: UserPresenceStatus) {
        const presence = UserPresence.mapUserPresenceStatusToPresenceStatus(userPresence);
        this.connection.send(this.messageBuilder.buildPresenceStatusMsg(presence));
    }

    private onRosterChanged(iq): boolean {
        const self = this;

        $(iq).find('item').each(function() {
            const sub = $(this).attr('subscription');
            const jid = $(this).attr('jid');
            const profileId = XmppUtil.getIdFromJid(jid);
            const bareJid = XmppUtil.getBareJidFromJid(jid);
            const resource = XmppUtil.getResourceFromJid(jid);
            const presence = UserPresence.createNewUserPresence(profileId, UserPresenceStatus.OFFLINE, resource);

            if (sub === 'remove') {
                // contact is being removed
                self.store.removePresence(profileId);
            } else {
                // contact is being added or modified
                self.store.upsertPresence(presence);
            }
        });

        return true;
    }

    private errorHandler(event) {
        this.log.error(event);
    }

    public setIsDeviceDetected(isDetected: boolean) {
        this.isDeviceDetected = isDetected;
    }
}
