import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { MangoPayPayOutStatus } from '../dto/pay-out-dto.model';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class PayOutService {
    private readPayOutStatusEndpoint = environment.mangopayPayOutStatusEndpoint;
    private createPayOutEndpoint = environment.createPayOutEndpoint;

    constructor(private http: HttpClient) {
    }

    public readPayOutStatus(): Observable<MangoPayPayOutStatus> {
        return this.http
            .get<MangoPayPayOutStatus>(this.readPayOutStatusEndpoint, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                retry(3),
                catchError(this.handleError)
            );
    }

    public createPayOut(timestamp: number): Observable<MangoPayPayOutStatus> {
        return this.http
            .post<MangoPayPayOutStatus>(
                this.createPayOutEndpoint,
                {timestamp},
                {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in PayOutService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
