import { ErrorHandler, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { LoggingType } from '../../models/LoggingType';
import { ServerSideLoggerService } from '../../../../providers/server-side-logger/server-side-logger.service';


@Injectable()
export class LogClientSideService implements ErrorHandler {

    constructor(private serverSideLoggerService: ServerSideLoggerService) {
    }

    handleError(error): void {
        const message = error.message ? error.message : error.toString();

        console.log('LogClientSideService: ', message);

        this.serverSideLoggerService.log(LoggingType.ERROR, message);
        throwError(error);
    }
}
