import { Injectable } from '@angular/core';

@Injectable()
export class SoundService {

    public countdownAudio: HTMLAudioElement;
    constructor() {
        this.countdownAudio = new Audio('/assets/sounds/bing_short.mp3');
    }

    public playCountdownSound(): void {
        this.countdownAudio.play();
    }

}
