
import {filter} from 'rxjs/operators';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-small-video',
    templateUrl: './small-video.component.html',
    styleUrls: ['./small-video.component.scss']
})
export class SmallVideoComponent {

    isLocalStreamActive$: Observable<boolean>;
    collapsed = false;

    localScreenStreamActive$: Observable<boolean>;
    remoteScreenStreamActive$: Observable<boolean>;


    constructor(private elRef: ElementRef, private store: Store<AppState>) {
        this.isLocalStreamActive$ = this.store.select((state: AppState) => state.media.localStream.active).pipe(filter(e => !!e));

        this.localScreenStreamActive$ = this.store.select((state: AppState) => state.media.localScreenStream.active);
        this.remoteScreenStreamActive$ = this.store.select((state: AppState) => state.media.remoteScreenStream.active);
    }

    collapseLocalVideoBox() {
        this.collapsed = !this.collapsed;
    }
}
