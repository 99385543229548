import { Injectable } from '@angular/core';
import { RTC } from './rtc.interface';
import { MediaSaveRemoteScreenStreamAction, MediaSaveRemoteStreamAction } from '../../media/store/media.action';
import { Store } from '@ngrx/store';
import { AppState } from '../store/webrtc.state';
import { SignalService } from './signal.service';
import { WebrtcConnectionGotInterrupted } from '../store/webrtc.action';
import { Observable, Subject } from 'rxjs';
import { MonitorService } from './monitor/monitor.service';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';
import { ServerSideLoggerService } from '../../../providers/server-side-logger/server-side-logger.service';
import { PeerOps, SimplePeer } from '../../../../typings/typings';
import { TURN_SERVERS } from '../../../providers/turn-config/turn-config';

@Injectable()
export class PeerService implements RTC {

    protected configuration = <RTCConfiguration>TURN_SERVERS.default;
    protected localStream: MediaStream;
    protected screenShareStream: MediaStream = undefined;

    private pc: SimplePeer = undefined;
    private connected = new Subject<boolean>();

    constructor(protected signal: SignalService,
                protected store: Store<AppState>,
                protected monitor: MonitorService,
                protected tracer: LogAppointmentService,
                protected logger: ServerSideLoggerService
    ) {
        this.signal.getSignal((event) => {
            if (this.pc === undefined) {
                this.pc = this.create(false);
            }
            this.pc.signal(event);
        });
        this.connected.next(false);
    }

    public setLocalStream(stream: MediaStream) {
        this.localStream = stream;
    }

    public startCall(): void {
        this.log(PeerLoggingEvent.CALL_START);
        this.pc = this.create(true);
    }

    public startScreenShare(stream: MediaStream) {
        this.log(PeerLoggingEvent.CALL_START_SCREEN_SHARE);
        this.signal.sendStartScreenShare();
        this.screenShareStream = stream;
        this.pc.addStream(stream);
    }

    public stopScreenShare() {
        this.log(PeerLoggingEvent.CALL_STOP_SCREEN_SHARE);
        if (this.screenShareStream !== undefined && this.pc !== undefined) {
            this.pc.removeStream(this.screenShareStream);
            this.signal.sendCloseScreenShare();
            this.screenShareStream = undefined;
        }
    }

    public close(): void {
        this.log(PeerLoggingEvent.CALL_CLOSE);
        if (this.pc !== undefined) {
            try {
                this.pc.destroy();
            } catch (err) {
                this.forceCloseConnection();
            }
        }
    }

    public isConnected(): Observable<boolean> {
        return this.connected;
    }

    private forceCloseConnection() {
        this.monitor.stop();
        this.pc = undefined;
        this.signal.isScreenShareActive = false;
        this.screenShareStream = undefined;
        this.connected.next(false);
    }

    /**
     * Create a simple peer connection
     */
    private create(initiator: boolean): SimplePeer {
        const ops = {
            initiator,
            channelConfig: {},
            channelName: 'default',
            config: {iceServers: this.configuration.iceServers},
            offerOptions: {},
            answerOptions: {},
            sdpTransform(sdp) {
                return sdp;
            },
            stream: true,
            streams: [this.localStream],
            trickle: true,
            allowHalfTrickle: false,
            objectMode: false
        };

        const pc = this.createSimplePeer(ops);

        pc.on('stream', stream => {
            if (this.isScreenShare()) {
                this.store.dispatch(new MediaSaveRemoteScreenStreamAction(stream));
            } else {
                this.store.dispatch(new MediaSaveRemoteStreamAction(stream));
            }
        });
        pc.on('signal', data => {
            this.signal.sendSignal(data);
        });

        pc.on('track', (data) => {
        });

        pc.on('connect', (data) => {
            this.monitor.start(pc._pc);
            this.connected.next(true);
            this.log(PeerLoggingEvent.CONNECTED);
            this.tracer.logAppointmentVideoConnectionEstablished();
        });

        pc.on('error', (error) => {
            this.store.dispatch(new WebrtcConnectionGotInterrupted());
            let message = '';
            if (!!error) {
                message = error.message !== undefined ? error.message : error.toString();
            }
            this.log(PeerLoggingEvent.ERROR, message);
            this.tracer.logAppointmentVideoConnectionNotEstablished();
        });

        pc.on('close', () => {
            this.log(PeerLoggingEvent.CLOSE);
            this.forceCloseConnection();
        });

        pc.on('iceStateChange', (state) => {
            this.log(PeerLoggingEvent.ICE_STATE_CHANGE, state);
            if (state === 'disconnected') {
                setTimeout(() => {
                    const {connectionState} = (pc === null || pc === undefined
                        || pc._pc === undefined || pc._pc === null) ? {connectionState: 'disconnected'} : <any>pc._pc;
                    if (connectionState === 'disconnected') {
                        this.log(PeerLoggingEvent.CONNECTION_GOT_INTERRUPTED);
                        this.store.dispatch(new WebrtcConnectionGotInterrupted());
                    }
                }, 3000);
            }
        });
        return pc;
    }

    private isScreenShare(): boolean {
        return this.signal.isScreenShareActive;
    }

    createSimplePeer(ops: PeerOps): SimplePeer {
        this.log(PeerLoggingEvent.NEW_CONNECTION);
        return new (window as any).SimplePeer(ops);
    }

    private log(event: PeerLoggingEvent, message: string = ''): void {
        if (message === '') {
            this.logger.info(event);
        } else {
            this.logger.info(event + ' ' + message);
        }
    }
}

enum PeerLoggingEvent {
    CONNECTION_GOT_INTERRUPTED = 'PEER_CONNECTION_GOT_INTERRUPTED',
    ICE_STATE_CHANGE = 'PEER_ICE_STATE_CHANGE',
    CLOSE = 'PEER_CLOSE',
    ERROR = 'PEER_ERROR',
    NEW_CONNECTION = 'PEER_NEW_CONNECTION',
    CONNECTED = 'PEER_CONNECTED',
    CALL_START = 'PEER_CALL_START',
    CALL_CLOSE = 'PEER_CALL_CLOSE',
    CALL_START_SCREEN_SHARE = 'PEER_CALL_START_SCREEN_SHARE',
    CALL_STOP_SCREEN_SHARE = 'PEER_CALL_STOP_SCREEN_SHARE',
}
