import { environment } from '../../../../environments/environment';

export class XmppUtil {
    static DOMAIN = environment.jitsi.domain;

    /**
     * 123 => user-123@patientus.de
     *
     * @param id
     */
    public static createJid(id: number): string {
        return 'user-' + id + '@' + XmppUtil.DOMAIN;
    }

    /**
     * user-123@patientus.de/library => 123
     *
     * @param jid
     */
    public static getIdFromJid(jid: string): number {
        return Number(jid.match(/(\d){1,}/gm)[0]);
    }

    /**
     * user-123@patientus.de/library => user-123
     *
     * @param jid
     */
    public static getUserFromJid(jid: string): string {
        return Strophe.getNodeFromJid(jid);
    }

    /**
     * user-123@patientus.de/library => user-123@patientus.de
     *
     * @param jid
     */
    public static getBareJidFromJid(jid: string): string {
        return Strophe.getBareJidFromJid(jid);
    }

    /**
     * user-123@patientus.de/library => library
     *
     * @param jid
     */
    public static getResourceFromJid(jid: string): string {
        return Strophe.getResourceFromJid(jid);
    }

    /**
     * user-123@patientus.de/library => patientus.de
     *
     * @param jid
     */
    public static getDomainFromJid(jid: string): string {
        return Strophe.getDomainFromJid(jid);
    }

}
