import { Injectable } from '@angular/core';
import { XmppPresenceStatus } from './xmpp-presence-type.model';

declare const $msg: any;
declare const $: any;
declare const Strophe: any;


@Injectable()
export class XmppMessageBuilder {
    public buildChatMessage<E>(to: string, from: string, event: E): any {
        const stringEvent = JSON.stringify(event);
        return $msg({to, from, type: 'chat'}).c('body').t(stringEvent);
    }

    public readMessage(stanza: any): string {
        const $stanza = $(stanza);
        const messageId = $stanza.attr('id') || null;
        const to = $stanza.attr('to');
        const from = $stanza.attr('from').toLowerCase();
        const barejid = Strophe.getBareJidFromJid(from);

        const type = $stanza.attr('type');
        const bodies = $stanza.find('body');
        return bodies.length ? Strophe.xmlunescape(Strophe.getText(bodies[0])) : '';
    }

    public buildPresenceStatusMsg(status: XmppPresenceStatus): any {
        return $pres().c('show').t(status);
    }

    public pingMsg(jId: string) {
        const ping = $iq({
            to: jId,
            type: 'get',
            id: 'ping1'
        }).c('ping', {xmlns: 'urn:xmpp:ping'});
    }
}
