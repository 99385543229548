import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { MediaStreamProvider } from '../../media/provider/media-stream.provider';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppointmentService } from '../../appointment/providers/appointment.service';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Appointment } from '../../appointment/store/one-time-appointment/appointment.entity';
import { AppState } from '../store/waitingroom.state';
import { TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProfileType } from '../../profile/store/profile.entity';
import { PatientusRole } from '../../auth/auth.roles';
import { PreInitConditionService } from '../../pre-init-condition/providers/pre-init-condition.service';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';
import { AppointmentInfoType } from '../../appointment/components/appointment-info/appointment-info';
import { BrowserService } from '../../media/provider/browser.service';
import { BrandingService } from '../../branding/providers/branding.service';
import { JitsiSettingsDetectionService } from '../../jitsi/provider/jitsi-settings-detection.service';

export enum AppointmentListTabs {
    UPCOMING = 'upcoming',
    HISTORY = 'history',
}

@Component({
    selector: 'app-waitingroom',
    templateUrl: './waitingroom.component.html',
    styleUrls: ['./waitingroom.component.scss']
})
export class WaitingroomComponent implements OnInit, OnDestroy {

    public isInit$: Observable<boolean>;
    public activeAppointmentListTab = AppointmentListTabs.UPCOMING;

    public appointments$: Observable<Appointment[]>;
    public isRemoteStreamActive$: Observable<boolean>;
    public upComingAppointments$: Observable<Appointment[]>;
    public historyAppointments$: Observable<Appointment[]>;
    public backgroundImg$: Observable<any>;

    public UPCOMING: string = AppointmentListTabs.UPCOMING;
    public HISTORY: string = AppointmentListTabs.HISTORY;

    public ROLE_DOCTOR: number = ProfileType.DOCTOR;
    public ROLE_PATIENT: number = ProfileType.PATIENT;
    public CURRENT_ROLE: number;

    public WAITINGROOM_APPOINTMENT_INFO_TYPE = AppointmentInfoType.WAITINGROOM;
    public HISTORY_APPOINTMENT_INFO_TYPE = AppointmentInfoType.HISTORY;

    private isPreInitCompleted = false;
    private isOnCall = false;

    private subscriptionPreInitComplete: Subscription = null;
    private subscriptionIsOnCall: Subscription = null;

    constructor(private store: Store<AppState>,
                private appointmentService: AppointmentService,
                private mediaStreamService: MediaStreamProvider,
                private permissionsService: NgxPermissionsService,
                private settingsService: JitsiSettingsDetectionService,
                private preInitService: PreInitConditionService,
                private tracer: LogAppointmentService,
                private translate: TranslateService,
                private browserService: BrowserService,
                private brandingService: BrandingService) {
        this.isInit$ = appointmentService.isInit();

        this.appointments$ = appointmentService
            .getAllAppointmentsObserver();

        this.upComingAppointments$ = appointmentService
            .getAllAppointmentsObserver().pipe(
                filter(a => a !== null),
                map(appointmentList => appointmentList.filter(a => this.isUpComingAppointment(a))),
                map(appointmentList => appointmentList.sort(this.compareByAppointmentDate))
            );

        this.historyAppointments$ = appointmentService
            .getAllAppointmentsObserver().pipe(
                filter(a => a !== null),
                map(appointmentList => appointmentList.filter(a => !this.isUpComingAppointment(a))),
                map(appointmentList => appointmentList.sort((a, b) => this.compareByAppointmentDate(b, a)))
            );

        this.subscriptionPreInitComplete = this.preInitService
            .isPreInitCompleted()
            .subscribe(isCompleted => this.isPreInitCompleted = isCompleted);

        this.subscriptionIsOnCall = this.store
            .select(state => state.waitingroom.onCall).pipe(
                distinctUntilChanged())
            .subscribe(onCall => this.isOnCall = onCall);

        this.backgroundImg$ = this.brandingService.getBrandingSettingsFromStore().pipe(
            map(settings => {
                const backgroundImg = settings.bgImage_patientWaitingRoom
                    || '/assets/img/waitingroom/waitingroom-patients.jpg';
                return {backgroundImage: backgroundImg};
            }));
    }

    ngOnInit() {
        this.isRemoteStreamActive$ = this.mediaStreamService.isRemoteStreamActive();
        this.setCurrentRole();

        setTimeout(() => {
            if (!this.browserService.isMobile()) {
                setTimeout(() => this.settingsService.checkSettings());
            }
        }, 700);
    }

    public hasNoAppointments(appointmentsLength, isInit): boolean {
        return appointmentsLength === 0 && (isInit === false);
    }

    public changeActiveTab(tabName: AppointmentListTabs): void {
        this.activeAppointmentListTab = tabName;
    }

    public isActiveTab(tabName: AppointmentListTabs): boolean {
        return this.activeAppointmentListTab === tabName;
    }

    private setCurrentRole(): void {
        this.permissionsService.permissions$.pipe(filter(p => Object.keys(p).length > 0),
            take(1))
            .subscribe(p => {
                if (p[PatientusRole.PATIENT]) {
                    this.CURRENT_ROLE = this.ROLE_PATIENT;
                } else if (p[PatientusRole.DOCTOR]) {
                    this.CURRENT_ROLE = this.ROLE_DOCTOR;
                }
            });
    }

    public isCurrentRole(role: ProfileType): boolean {
        return this.CURRENT_ROLE === role;
    }

    private compareByAppointmentDate(a: Appointment, b: Appointment): number {
        if (new Date(a.startDateTime) < new Date(b.startDateTime)) {
            return -1;
        } else if (new Date(a.startDateTime) > new Date(b.startDateTime)) {
            return 1;
        } else {
            return 0;
        }
    }

    private isUpComingAppointment(appointment: Appointment): boolean {
        return (new Date(appointment.startDateTime) > new Date()) || this.isToday(appointment.startDateTime);
    }

    private isToday(dateString: string): boolean {
        return TimeHelperService.isToday(dateString);
    }

    ngOnDestroy(): void {
        if (this.subscriptionPreInitComplete !== null) {
            this.subscriptionPreInitComplete.unsubscribe();
        }

        if (this.subscriptionIsOnCall !== null) {
            this.subscriptionIsOnCall.unsubscribe();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: BeforeUnloadEvent) {
        if (this.isOnCall) {
            this.tracer.logAppointmentQuit();
            event.stopPropagation();
        } else {
            event.stopPropagation();
        }
    }
}
