import { Action } from '@ngrx/store';
import { Profile, ProfileSettings } from './profile.entity';
export const PROFILE_LOAD_CURRENT_PROFILE_FROM_BACKEND = 'PROFILE_LOAD_CURRENT_PROFILE_FROM_BACKEND';
export const PROFILE_DATA_REQUESTING = 'PROFILE_DATA_REQUESTING';
export const PROFILE_DATA_RECEIVED_FAILED = 'PROFILE_DATA_RECEIVED_FAILED';
export const PROFILE_INITIAL_STATE_FAILED_ACTION = 'PROFILE_INITIAL_STATE_FAILED_ACTION';
export const PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL = 'PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL';
export const PROFILE_LIST_RECEIVED_SUCCESSFUL = 'PROFILE_LIST_RECEIVED_SUCCESSFUL';

export const PROFILE_ADD = 'PROFILE_ADD';
export const PROFILE_REMOVE_ALL = 'PROFILE_REMOVE_ALL';

export const PROFILE_REQUIRED_ACCEPT_DATA_PROCESS = 'PROFILE_REQUIRED_ACCEPT_DATA_PROCESS';
export const PROFILE_ACCEPT_DATA_PROCESS = 'PROFILE_ACCEPT_DATA_PROCESS';
export const PROFILE_ACCEPT_DATA_PROCESS_SUCESS = 'PROFILE_ACCEPT_DATA_PROCESS_SUCESS';
export const PROFILE_ACCEPT_DATA_PROCESS_FAILED = 'PROFILE_ACCEPT_DATA_PROCESS_FAILED';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export interface ProfileAction extends Action {
    readonly type: string;
    payload: Profile | ProfileSettings | Profile[] | null;
    errors: string[] | null;
}


export class ProfileLoadCurrentUserProfileFromBackendAction implements ProfileAction {
    readonly type = PROFILE_LOAD_CURRENT_PROFILE_FROM_BACKEND;
    payload = null;
    errors = null;

    constructor() {
    }
}



export class ProfileDataRequestedAction implements ProfileAction {
    readonly type = PROFILE_DATA_REQUESTING;
    payload = null;
    errors = null;

    constructor() {
    }
}

export class ProfileDataReceivedSuccessfulAction implements ProfileAction {
    readonly type = PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL;
    errors = null;

    constructor(public payload: Profile) {
    }
}

export class ProfileDataReceivedFailedAction implements ProfileAction {
    readonly type = PROFILE_DATA_RECEIVED_FAILED;
    payload = null;

    constructor(readonly errors: string[]) {
    }
}

export class ProfileRemoveAllAction implements ProfileAction {
    readonly type = PROFILE_REMOVE_ALL;
    errors = null;
    payload = null;
}

export class ProfileInitialStateFailedAction implements ProfileAction {

    readonly type = PROFILE_INITIAL_STATE_FAILED_ACTION;
    payload = null;

    constructor(readonly errors: string[]) {
    }
}

export class ProfileListReceivedSuccessfulAction implements ProfileAction {
    readonly type = PROFILE_LIST_RECEIVED_SUCCESSFUL;
    errors = null;

    constructor(public payload: Profile[]) {
    }
}

export class ProfileAddAction implements ProfileAction {
    readonly type = PROFILE_ADD;
    errors = null;

    constructor(public payload: Profile) {
    }
}

export class ProfileAcceptDataProcessAction implements ProfileAction {
    readonly type = PROFILE_ACCEPT_DATA_PROCESS;
    errors = null;

    constructor(public payload: Profile) {
    }
}

export class ProfileAcceptDataProcessSuccessAction implements ProfileAction {
    readonly type = PROFILE_ACCEPT_DATA_PROCESS_SUCESS;
    errors = null;

    constructor(public payload: Profile) {
    }
}

export class ProfileAcceptDataProcessFailedAction implements ProfileAction {
    readonly type = PROFILE_ACCEPT_DATA_PROCESS_FAILED;
    payload = null;

    constructor(readonly errors: string[]) {
    }
}

export class ProfileUpdateAction implements ProfileAction {
    readonly type = PROFILE_UPDATE;
    errors = null;

    constructor(public payload: Profile) {
    }
}

export class ProfileUpdateSuccessAction implements ProfileAction {
    readonly type = PROFILE_UPDATE_SUCCESS;
    errors = null;

    constructor(public payload: ProfileSettings) {
    }
}

export class ProfileUpdateFailedAction implements ProfileAction {
    readonly type = PROFILE_UPDATE_FAILED;
    payload = null;

    constructor(readonly errors: string[]) {
    }
}
