import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Institution } from "../../../entities/institution/institution.model";

@Injectable()
export class XmppConnectionSetting {
    public readonly APP_ID = environment.jitsi.appId;
    public readonly BOSH = environment.jitsi.bosh;
    public readonly DOMAIN = environment.jitsi.domain;
}
