<app-login-layout [questions]="'patient'">
    <div class="page-wrapper" [ngStyle]="{backgroundImage: 'url(' + (backgroundImg$ | async).backgroundImage + ')'}">
        <div class="content-wrapper">
            <div class="option-card">
                <form [formGroup]="tanLoginForm" (ngSubmit)="onSubmit()" role="form">
                    <div class="option-card-body">
                        <div class="option-card-header">
                            <h3 class="form-signin-heading option-card-heading">{{ 'LOGIN.TAN-TITLE' | translate }}</h3>
                            <span class="option-card-subtitle weight-300">{{ 'LOGIN.TAN-SUBTITLE' | translate }}</span>
                        </div>
                        <div class="tan-input-line">
                            <input type="text"
                                   #code1
                                   name="code1"
                                   formControlName="code1"
                                   class="tan-input"
                                   size="1"
                                   maxlength="1"
                                   (keyup.backspace)="handleBackspace($event)"
                                   (keyup.arrowRight)="goToNextInput($event)"
                                   (input)="handleInput($event)"
                                   (focus)="handleFocus($event)"
                                   (paste)="onPaste($event)">
                            <input type="text"
                                   #code2
                                   name="code2"
                                   formControlName="code2"
                                   class="tan-input"
                                   size="1"
                                   maxlength="1"
                                   (keyup.backspace)="handleBackspace($event)"
                                   (keyup.arrowRight)="goToNextInput($event)"
                                   (keyup.arrowLeft)="goToPreviousInput($event)"
                                   (input)="handleInput($event)"
                                   (focus)="handleFocus($event)"
                                   (paste)="onPaste($event)">
                            <input type="text"
                                   #code3
                                   name="code3"
                                   formControlName="code3"
                                   class="tan-input"
                                   size="1"
                                   maxlength="1"
                                   (keyup.backspace)="handleBackspace($event)"
                                   (keyup.arrowRight)="goToNextInput($event)"
                                   (keyup.arrowLeft)="goToPreviousInput($event)"
                                   (input)="handleInput($event)"
                                   (focus)="handleFocus($event)"
                                   (paste)="onPaste($event)">
                            <input type="text"
                                   #code4
                                   name="code4"
                                   formControlName="code4"
                                   class="tan-input"
                                   size="1"
                                   maxlength="1"
                                   (keyup.backspace)="handleBackspace($event)"
                                   (keyup.arrowRight)="goToNextInput($event)"
                                   (keyup.arrowLeft)="goToPreviousInput($event)"
                                   (input)="handleInput($event)"
                                   (focus)="handleFocus($event)"
                                   (paste)="onPaste($event)">
                            <input type="text"
                                   #code5
                                   name="code5"
                                   formControlName="code5"
                                   class="tan-input"
                                   size="1"
                                   maxlength="1"
                                   (keyup.backspace)="handleBackspace($event)"
                                   (keyup.arrowRight)="goToNextInput($event)"
                                   (keyup.arrowLeft)="goToPreviousInput($event)"
                                   (input)="handleInput($event)"
                                   (focus)="handleFocus($event)"
                                   (paste)="onPaste($event)">
                            <input type="text"
                                   #code6
                                   id="code6"
                                   name="code6"
                                   formControlName="code6"
                                   class="tan-input"
                                   size="1"
                                   maxlength="1"
                                   (keyup.backspace)="handleBackspace($event)"
                                   (keyup.arrowRight)="goToNextInput($event)"
                                   (keyup.arrowLeft)="goToPreviousInput($event)"
                                   (input)="handleInput($event)"
                                   (focus)="handleFocus($event)"
                                   (paste)="onPaste($event)">
                        </div>
                        <div class="checkbox agb-and-security-check" [hidden]="hideDataProcessTerms$ | async">
                            <div class="checkbox-wrapper outlined-checkbox">
                                <input type="checkbox"
                                       #dataProcessTerm
                                       id="data-process-term"
                                       name="dataProcessTerm"
                                       formControlName="dataProcessTerm"
                                       ngbTooltip="{{ 'GENERAL.ERROR.DATA-PROCESS-TERM' | translate }}"
                                       placement="bottom"
                                       triggers="manual"
                                       appInvalidDirective>
                            </div>
                            <label for="data-process-term">
                                <span>
                                    {{ 'GENERAL.DATA-PROCESS-TERM-PREFIX' | translate }}
                                    <a [routerLink]="dataProcessTermsRoute" target="_blank">
                                        {{ 'GENERAL.DATA-PROCESS-TERM-LINKNAME' | translate }}</a>
                                    {{ 'GENERAL.DATA-PROCESS-TERM-POSTFIX' | translate }}
                                </span>
                            </label>
                        </div>
                        <div class="checkbox agb-and-security-check" [hidden]="removeAgbCheckbox$ | async">
                            <div class="checkbox-wrapper outlined-checkbox">
                                <input type="checkbox"
                                       #agbTerm
                                       id="term-agb"
                                       name="agbTerm"
                                       formControlName="agbTerm"
                                       ngbTooltip="{{ 'GENERAL.ERROR.AGB' | translate }}"
                                       placement="bottom"
                                       triggers="manual"
                                       appInvalidDirective>
                            </div>
                            <label for="term-agb" class="data-process-agreement">
                                <span>
                                    {{ 'LOGIN.TAN-ACCEPT-PREFIX' | translate }}
                                    <a target="_blank" [routerLink]="termsRoute">
                                        {{ 'LOGIN.TAN-AGB' | translate }}</a>
                                    {{ 'LOGIN.TAN-ACCEPT-MIDFIX' | translate }}
                                    <a target="_blank" [routerLink]="dataSecurityRoute">
                                        {{ 'LOGIN.TAN-DATASECURITY' | translate }}</a>
                                    {{ 'LOGIN.TAN-ACCEPT-POSTFIX' | translate }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="tanLoginForm.hasError('notValid')" class="alert alert-danger tan-login-error">
                        {{ 'LOGIN.TAN-ERROR' | translate }}
                    </div>
                    <div *ngIf="tanLoginForm.hasError('rateLimitReached')" class="alert alert-danger tan-login-error">
                        <i class="fas fa-exclamation-triangle"></i> {{ 'LOGIN.TAN-ERROR-RATE-LIMIT' | translate }}
                    </div>
                    <app-browser-info></app-browser-info>
                    <div class="option-card-footer flex-to-side ">
                        <button class="button btn-secondary"
                                type="submit"
                                id="TAN-form-submit">
                            {{ 'LOGIN.BUTTON' | translate }} <i class="fal fa-check-circle"></i>
                        </button>
                    </div>
                </form>
            </div>
            <div *ngIf="!((brandingData$ | async)?.removeDoctorLink)" class="further-links-section">
                <span>
                    {{ 'LOGIN.YOU-ARE-DOCTOR' | translate }}
                    <a routerLink="/{{doctorLoginRoute}}"
                       class="link-dark">{{ 'LOGIN.DOCTOR-LOGIN-LINK' | translate }}</a>
                </span>
            </div>
            <div class="data-security-seal" *ngIf="showDataSecuritySeal">
                <img class="img" src="/assets/img/general/siegel_ips.png" alt="data-security-seal">
            </div>
        </div>
    </div>
</app-login-layout>
