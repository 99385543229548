<div class="fileDetails">
    <div *ngIf="receiverList.length === 0">
        <span class="initMessage">
            {{'FILE_TRANSFER.BODY.NO_INCOMING_FILE' | translate}}
        </span>
    </div>
    <div class="fileContainer" *ngIf="receiverList.length > 0">
        <app-progressbar *ngFor="let receiver of receiverList"
        [transmitter]="receiver"
        (downloadedEvent) ="onFileDownloaded($event)"
        [checkFileDownloaded] = "isItemDownloaded(receiver.getId())"
        [type]="getProgressbarType(receiver)"></app-progressbar>
    </div>
</div>
