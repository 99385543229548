import { Injectable } from '@angular/core';
import { Order, PaymentAccountSum } from '../store/payment-account.model';
import * as fromPaymentAccount from '../store/payment-account.reducer';
import { Store } from '@ngrx/store';
import {
    AddPaymentAccounts,
    SetPaymentAccountPaidSum,
    SetPaymentAccountPayInSum,
    SetPaymentAccountTimestamp
} from '../store/payment-account.actions';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentAccountStoreService {

    constructor(private store: Store<fromPaymentAccount.PaymentAccountState>) {
    }

    public getPaymentAccountFromStore(): Observable<Order | null> {
        return this.store.select(fromPaymentAccount.selectPaymentAccount);
    }

    public getPaymentAccountSumFromStore(): Observable<PaymentAccountSum | null> {
        return this.store.select(fromPaymentAccount.selectStatusSums);
    }

    public getPaymentRequestTimestamp(): Observable<number | null> {
        return this.store.select(fromPaymentAccount.selectTimestamp);
    }

    public addPaymentAccountOrdersInStore(orders: Order[]): void {
        this.store.dispatch(new AddPaymentAccounts({paymentAccounts: orders}));
    }

    public setPaymentAccountPayInSumInStore(payInSum: string): void {
        this.store.dispatch(new SetPaymentAccountPayInSum(payInSum));
    }

    public setPaymentAccountPaidSumInStore(paidSum: string): void {
        this.store.dispatch(new SetPaymentAccountPaidSum(paidSum));
    }

    public setPaymentAccountTimestampInStore(ts: number): void {
        this.store.dispatch(new SetPaymentAccountTimestamp(ts));
    }

}
