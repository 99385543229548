import { catchError, take, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentInfo, PaymentInfoStatuses } from '../../../../../entities/payment-info/store/payment-info.model';
import { Subscription } from 'rxjs';
import { BankAccountStatus, PaymentBankAccount } from '../../../../../entities/payment-bank-account/store/payment-bank-account.model';
import { PaymentBankAccountStoreService } from '../../../../../entities/payment-bank-account/provider/payment-bank-account-store.service';
import { PaymentInfoStoreService } from '../../../../../entities/payment-info/provider/payment-info-store.service';
import { LoadingSpinnerService } from '../../../../../loading-spinner/loading-spinner.service';
import { PaymentInfoService } from '../../../../../entities/payment-info/provider/payment-info.service';
import { MAX_POLLING_NUMBER_REACHED } from '../../../../../http/polling-client.service';
import { CREATE_PAYMENT_INFO } from '../../../../../entities/payment-info/provider/payment-info-polling.service';

export enum ResponseType {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    CANCELED = 'CANCELED'
}

export enum Child {
    USERINFO = 'USERINFO',
    BANKINFO = 'BANKINFO'
}

export interface ChildResponseType {
    Child: Child;
    ResponseType: ResponseType;
    response?: PaymentInfo;
}

@Component({
    selector: 'app-payment-info-tab',
    templateUrl: './payment-info-tab.component.html',
    styleUrls: ['./payment-info-tab.component.scss']
})
export class PaymentInfoTabComponent implements OnInit, OnDestroy {

    paymentInfo: PaymentInfo;
    paymentBankAccount: PaymentBankAccount;
    paymentInfoActivated: boolean;
    bankAccountInfoActivated: boolean;
    dataUnderReview: boolean;
    paymentInfoLoading = true;
    paymentInfoSubscription: Subscription = null;
    bankAccountSubscription: Subscription = null;
    isUserInfoEditing = false;
    isBankInfoEditing = false;
    childResponse: ChildResponseType;

    constructor(
        private paymentInfoService: PaymentInfoService,
        private paymentInfoStoreService: PaymentInfoStoreService,
        private paymentBankAccountStoreService: PaymentBankAccountStoreService,
        private spinner: LoadingSpinnerService
    ) {
    }

    ngOnInit() {
        this.paymentInfoSubscription = this.paymentInfoStoreService.getPaymentInfoFromStore().subscribe(pi => {
            this.paymentInfoLoading = false;

            if (!pi) {
                this.paymentInfo = undefined;
                this.paymentInfoActivated = undefined;
                this.dataUnderReview = false;
                return;
            }
            this.paymentInfo = pi;
            this.paymentInfoActivated = this.paymentInfo.status === PaymentInfoStatuses.FINISHED;
            this.dataUnderReview = this.paymentInfo.status === PaymentInfoStatuses.PROCESSING;
        });

        this.bankAccountSubscription = this.paymentBankAccountStoreService.getPaymentBankAccountInfoFromStore()
            .subscribe(bankAccount => {
                if (!bankAccount) {
                    this.paymentBankAccount = undefined;
                    this.bankAccountInfoActivated = undefined;
                    return;
                }
                this.paymentBankAccount = bankAccount;
                this.bankAccountInfoActivated = this.paymentBankAccount.status === BankAccountStatus.FINISHED;
            });
    }

    public hasActivePaymentInfo(): boolean {
        if (this.paymentInfo !== null || this.paymentInfo !== undefined) {
            return this.paymentInfoActivated;
        } else {
            return false;
        }
    }

    public editUserInfo() {
        this.isUserInfoEditing = true;
    }

    public userInfoEditFinished(state: ChildResponseType) {
        this.childResponse = state;
        if (this.childResponse.ResponseType === ResponseType.CREATED) {
            this.spinner.activate();
            this.paymentInfoService.createPaymentInfo(state.response as PaymentInfo)
                .pipe(
                    take(1),
                    tap(() => this.spinner.deactivate()),
                    catchError((error) => this.handleError(error))
                ).subscribe(() => this.isUserInfoEditing = false);
        }

        if (this.childResponse.ResponseType === ResponseType.UPDATED) {
            this.spinner.activate();
            this.paymentInfoService.updatePaymentInfo(state.response as PaymentInfo)
                .pipe(
                    take(1),
                    tap(() => this.spinner.deactivate()),
                    catchError((error) => this.handleError(error))
                ).subscribe(() => this.isUserInfoEditing = false);
        }

        if (this.childResponse.ResponseType === ResponseType.CANCELED) {
            this.isUserInfoEditing = false;
        }
    }

    public editBankInfo() {
        this.isBankInfoEditing = true;
    }

    public bankInfoEditFinished(state: ChildResponseType) {
        this.childResponse = state;
        this.isBankInfoEditing = false;
    }

    ngOnDestroy(): void {
        if (this.paymentInfoSubscription !== null) {
            this.paymentInfoSubscription.unsubscribe();
        }
        if (this.bankAccountSubscription !== null) {
            this.bankAccountSubscription.unsubscribe();
        }
    }

    private handleError(error) {
        switch (error.message) {
            case MAX_POLLING_NUMBER_REACHED: {
                this.spinner.deactivate();
                break;
            }
            case CREATE_PAYMENT_INFO: {
                this.spinner.deactivate();
                break;
            }
            default: {
                this.spinner.deactivate();
            }
                return error;
        }
    }
}
