import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEventPattern, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, take } from 'rxjs/operators';
import { MediaCallEndAction } from '../../media/store/media.action';
import { GeneralMessageBusService } from '../../message-bus/providers/general-message-bus.service';
import { ModalOpenSurveyAction } from '../../modal/store/modal.action';
import { JitsiMeetEventListenerModel } from '../models/jitsi-meet-event-listener.model';
import { WaitingroomCloseVideoConference } from '../../waitingroom/store/waitingroom.action';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';
import { BodyService } from '../../../providers/body/body.service';
import { FileTransferService } from '../../file-transfer/provider/file-transfer.service';

@Injectable()
export class JitsiEventService implements OnDestroy {
    private subscription: Subscription;

    constructor(
        private store: Store<any>,
        private generalMessageBusService: GeneralMessageBusService,
        private trace: LogAppointmentService,
        private bodyService: BodyService,
        private fileTransferService: FileTransferService
    ) {
    }

    public registerListeners(api: JitsiMeetExternalAPI, listener: JitsiMeetEventListenerModel = null): void {
        this.subscription = new Subscription();

        this.subscription.add(this.addEventListener(api, 'participantLeft')
            .pipe(distinctUntilKeyChanged('id'))
            .subscribe(() => {
                const numberOfParticipants = api.getNumberOfParticipants();
                if (numberOfParticipants <= 1) {
                    api.executeCommand('hangup');
                }
            })
        );

        this.subscription.add(this.addEventListener(api, 'participantJoined')
            .pipe(distinctUntilKeyChanged('id'))
            .subscribe(() => {
                const numberOfParticipants = api.getNumberOfParticipants();
                if (numberOfParticipants > 2) {
                    this.fileTransferService.closeModal();
                }
            })
        );

        this.subscription.add(this.addEventListener(api, 'toolbarButtonClicked')
            .subscribe((event: {key: string}) => {
                if (event?.key === 'fileTransfer') {
                    this.fileTransferService.openModal();
                }
            })
        );

        this.subscription.add(this.addEventListener(api, 'readyToClose')
            .pipe(take(1))
            .subscribe((data: { extras: any }) => {
                api.dispose();
                const {isPrejoin} = data.extras;
                this.performActionAfterCloseConference(isPrejoin);
            })
        );

        this.subscription.add(this.addEventListener(api, 'videoConferenceJoined')
            .pipe(take(1))
            .subscribe(() => {
                this.performActionAfterConferenceStart();
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private performActionAfterCloseConference(isPrejoin = false): void {
        this.subscription.unsubscribe();
        this.bodyService.removeClass('disable-ovs-scrollbars');
        this.store.dispatch(new WaitingroomCloseVideoConference());
        this.store.dispatch(new MediaCallEndAction());

        if (!isPrejoin) {
            this.store.dispatch(new ModalOpenSurveyAction());
        }
    }

    private performActionAfterConferenceStart(): void {
        this.trace.logAppointmentVideoConnectionEstablished();
    }

    private addEventListener(api, eventName: any): any {
        return fromEventPattern(
            handler => api.on(eventName, handler)
        );
    }
}

