
import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subscription } from 'rxjs';
import { AppState } from '../store/webrtc.state';
import { Store } from '@ngrx/store';
import { SignalConnection, SignalConnectorInterface } from '../../signal/providers/signal.connection';
import {
    WebrtcCloseMainConnectionAction,
    WebrtcCloseScreenShareConnectionAction,
} from '../store/webrtc.action';
import { ModalOpenConnectionStopNoticeAction } from '../../modal/store/modal.action';


export interface SignalMessage {
    signal: any;
    hangup?: boolean;
    screenShare?: 'start' | 'stop';
}

@Injectable()
export class SignalService implements SignalConnectorInterface {

    public signal$ = new BehaviorSubject<SignalMessage>(null);
    public hangUps$ = new BehaviorSubject<boolean>(null);
    public isScreenShareActive = false;

    constructor(private connection: SignalConnection<SignalMessage>,
                private store: Store<AppState>) {
        this.connection.register(this);
    }

    onOpenConnection() {
        this.connection.subscribeMessages(this.handle.bind(this));
    }

    onCloseConnection() {
        // --> <-- //
    }

    public sendSignal(data: any) {
        this.connection.send({signal: data});
    }

    public getSignal(next: (value: SignalMessage) => void): Subscription {
        return this.signal$.pipe(filter(v => v !== null)).subscribe(next);
    }

    public sendHangup(dd) {
        this.connection.send(<SignalMessage>{hangup: true});
    }

    public sendCloseScreenShare() {
        this.connection.send(<SignalMessage>{screenShare: 'stop'});
    }

    public sendStartScreenShare() {
        this.connection.send(<SignalMessage>{screenShare: 'start'});
    }

    private handle(msg: SignalMessage): void {
        if (msg.signal) {
            this.signal$.next(msg.signal);
        }

        if (msg.hangup) {
            this.hangUps$.next(msg.hangup);
            this.store.dispatch(new WebrtcCloseMainConnectionAction(true));
            this.store.dispatch(new ModalOpenConnectionStopNoticeAction());
        }
        if (msg.screenShare) {
            if (msg.screenShare === 'stop') {
                this.isScreenShareActive = false;
                this.store.dispatch(new WebrtcCloseScreenShareConnectionAction(true));
            }
            if (msg.screenShare === 'start') {
                this.isScreenShareActive = true;
            }
        }
    }
}
