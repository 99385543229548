import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userAgentsFeatureKey, UserAgentState, selectEntities } from './user-agent.reducer';

export const selectUserAgentState = createFeatureSelector<UserAgentState>(userAgentsFeatureKey);

export const selectAllEntitiesUserAgent = createSelector(selectUserAgentState, selectEntities);

export const selectUserAgentByProfileId = (profileId: number) => createSelector(
    selectAllEntitiesUserAgent,
    userAgents => {
        if (profileId in userAgents) {
            return userAgents[profileId];
        } else {
            return null;
        }
    }
);
