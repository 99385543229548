import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { take } from 'rxjs/operators';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { Store } from '@ngrx/store';
import { OncallProvider } from '../../../webrtc/provider/oncall.provider';
import { Subscription, Observable, interval } from 'rxjs';

@Component({
    selector: 'app-notice',
    templateUrl: './notice.component.html',
    styleUrls: ['./notice.component.scss']
})

export class NoticeComponent implements OnInit {
    text: string;
    fileName: string;
    modal: NgbModalRef;
    oncallSubscriber: Subscription;
    checkIfModalIsOpened: boolean;
    copyBtnStyle = false;
    copyIconClass = false;
    isRemoteActive = false;
    remoteStream$: Observable<MediaStreamElement>;

    constructor(
        private modalService: NgbModal,
        private store: Store<AppState>,
        private oncallProvider: OncallProvider
    ) {
        this.remoteStream$ = store.select((state: AppState) => state.media.remoteStream);
    }

    ngOnInit() {
        this.remoteStream$.subscribe(() => {
            this.text = '';
            this.checkIfModalIsOpened = false;
        });
    }

    public open(content: TemplateRef<any>) {
        if (this.checkIfModalIsOpened === false) {
            this.modal = this.modalService.open(content, {
                windowClass: 'custom-modal',
                backdropClass: 'custom-modal-shadow',
                backdrop: 'static'
            });
            this.checkIfModalIsOpened = true;
        }

        this.oncallSubscriber = this.oncallProvider.isSwitchOffline().subscribe(() => {
            this.close('Close!');
        });
    }

    public close(reason: string) {
        this.modal.close(reason);
        this.checkIfModalIsOpened = false;

        if (this.oncallSubscriber !== null) {
            this.oncallSubscriber.unsubscribe();
        }
    }

    public downloadFile() {
        const inputText = this.text;
        const fileName = 'Notizen';
        const blob = new Blob([inputText], {type: 'text/plain;charset=utf-8'});
        saveAs(blob, fileName + '.txt');
    }

    public adjustBtn() {
        const numbers = interval(1000);
        const takeFourNumbers = numbers.pipe(take(2));

        this.copyBtnStyle = true;
        this.copyIconClass = true;

        takeFourNumbers.subscribe(() => {
            this.copyBtnStyle = false;
            this.copyIconClass = false;
        });
    }
}
