import {filter, mapTo} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {
    RTC_DETECTION_MODAL_MANUALLY_CLOSED,
    RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION,
    RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION,
    RTC_DETECTION_START_CAM_ACTION,
    RtcDetectionCleanupAction,
    RtcDetectionFinishedAction,
    RtcDetectionNextStepAction,
    RtcDetectionNoAccessAllowedToStreamAction,
    RtcDetectionReceiveCamStreamAction
} from './rtc-detection.action';
import {StepType} from '../rtc-detection.entities';
import {
    MEDIA_CAN_NOT_ACCESS_LOCAL_STREAM,
    MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS,
    MediaStartRequestingLocalStreamAction
} from '../../media/store/media.action';
import {ModalCloseAction} from '../../modal/store/modal.action';
import {APP_CHANGE_TO_ANONYMOUS_STATE} from '../../../providers/store/app.action';
import {BrowserService} from '../../media/provider/browser.service';

@Injectable()
export class RtcDetectionEffects {
    constructor(private actions$: Actions, private browserService: BrowserService) {
    }

     startCam$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(RTC_DETECTION_START_CAM_ACTION),
        mapTo(new MediaStartRequestingLocalStreamAction())));

     receiveCamStream$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS),
        mapTo(new RtcDetectionReceiveCamStreamAction())));

     noAccessToStream$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CAN_NOT_ACCESS_LOCAL_STREAM),
        mapTo(new RtcDetectionNoAccessAllowedToStreamAction())));


     showCamVerifyStep: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION),
        mapTo(new RtcDetectionNextStepAction(StepType.CAM_VERIFY))));

     showNoAccessStep: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION),
        mapTo(new RtcDetectionNextStepAction(StepType.NO_CAMERA_ACCESS))));

     closeRTCDetection: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(RTC_DETECTION_MODAL_MANUALLY_CLOSED),
        mapTo(new ModalCloseAction())));

     doRemoveAllAppointments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new RtcDetectionCleanupAction())));

     receiveCamStreamOnMobile$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS),
        filter(_ => this.browserService.isMobile()),
        mapTo(new RtcDetectionFinishedAction())));

}
