import { Moment } from 'moment';
export interface PaymentAccount {
    orders: Order [];
    timestamp: number;
    statusSums: PaymentAccountSum;
}

export interface Order {
    id: string;
    appointmentId: number;
    appointmentStartDatetime: Moment;
    status: OrderStatus;
    amount: string;
}

export interface PaymentAccountSum {
    payInSum?: string;
    paidSum?: string;
    paidOutSum?: string;
}

export enum OrderStatus {
    PAY_IN = 'PAY_IN',
    PAID = 'PAID',
    PAID_OUT = 'PAID_OUT',
    CANCELED = 'CANCELED'
}
