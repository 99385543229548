import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineStateService } from './providers/online-state.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { signalReducer } from './store/state.reducer';
import { SignalEffects } from './store/signal.effect';
import { OnlineUserStateService } from './providers/online-user-state.service';
import { OnlineStateConnector } from './providers/connectors/online-state.connector';
import { SignalConnection } from './providers/signal.connection';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('signal', signalReducer ),
        EffectsModule.forFeature([SignalEffects]),
    ],
    exports: [],
    declarations: [],
    providers: [{
        provide: SignalConnection, useFactory: () => {
            const connection =  new SignalConnection();
            connection.connect();
            return connection;
        }
    },
        OnlineStateService,
        OnlineUserStateService,
        OnlineStateConnector
    ]

})
export class SignalModule {
}
