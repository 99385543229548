import {map, mapTo, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {
    SIGNAL_ACTIVATE_SESSION,
    SIGNAL_SET_TOKENS,
    SIGNAL_START_OBSERVE_USER,
    SignalAction,
    SignalActivateSessionSuccessAction,
    SignalCleanSessionAction,
    SignalLocalConnectionReadyChangedAction,
    SignalStartObserveUserAction,
    SignalStartObserveUserSuccessAction
} from './signal.action';
import {APP_CHANGE_TO_ANONYMOUS_STATE, APP_CHANGE_TO_AUTHENTICATED_STATE} from '../../../providers/store/app.action';
import {SignalConnection} from '../providers/signal.connection';
import {OnlineStateConnector} from '../providers/connectors/online-state.connector';
import {MEDIA_CLOSED_LOCAL_STREAM_SUCCESS} from '../../media/store/media.action';
import {RTC_DETECTION_FINISHED} from '../../rtc-detection/store/rtc-detection.action';
import Timer = NodeJS.Timer;

@Injectable()
export class SignalEffects {
    constructor(private actions$: Actions,
                private onlineState: OnlineStateConnector,
                private signalConnection: SignalConnection<any>) {
    }

     initSession$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(SIGNAL_SET_TOKENS),
        mapTo(new SignalStartObserveUserAction())));

     startObserveUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(SIGNAL_START_OBSERVE_USER),
        map(() => this.onlineState.startObserveUsers()),
        map((timer: Timer) => new SignalStartObserveUserSuccessAction(timer))));

     activateSession$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(SIGNAL_ACTIVATE_SESSION),
        map(toActiveUserToken),
        map(activeUserToken => this.onlineState.loadSession(activeUserToken)),
        map(activeUserToken => new SignalActivateSessionSuccessAction(activeUserToken))));

     doCloseSignalWebSocket$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        map(() => this.signalConnection.close()),
        mapTo(new SignalCleanSessionAction())));

     doOpenSignalWebSocket$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_AUTHENTICATED_STATE),
        tap(() => this.signalConnection.reConnect())), {dispatch: false});

     changeToConnectionReady$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(RTC_DETECTION_FINISHED),
        map(() => new SignalLocalConnectionReadyChangedAction(true))));

     changeToNotConnectionReady$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CLOSED_LOCAL_STREAM_SUCCESS),
        map(() => new SignalLocalConnectionReadyChangedAction(false))));
}

const toActiveUserToken = action => (<SignalAction>action).payload.activeUserToken;
