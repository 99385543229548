<div class="appointment-info active"
     *ngIf="!!appointment"
     [ngClass]="{'online': ( patientStatus$ | async ) === 'online', 'busy': ( patientStatus$ | async ) === 'busy'}">
    <div class="dashboard-container">
        <div class="container-fluid">
            <div class="appointment-container row">
                <div class="col-md-3 no-padding">
                    <span class="appointment-start-time">
                        <i class="fa "
                           [ngClass]="{'fa-alarm-clock':( patientStatus$ | async ) === 'online', 'fa-clock':( patientStatus$ | async ) !== 'online'}">
                        </i>
                        <span *ngIf="isToday(appointment.startDateTime)">
                            {{ 'APPOINTMENT.INFO.TODAY' | translate }} {{appointment.startDateTime | date:'HH:mm'}} {{'APPOINTMENT.INFO.TIME-MAIN-SPECIFIER' | translate}}
                        </span>
                        <span *ngIf="!isToday(appointment.startDateTime)">
                            {{appointment.startDateTime | date:'dd.LL.yyyy HH:mm'}} {{'APPOINTMENT.INFO.TIME-MAIN-SPECIFIER' | translate}}
                        </span>
                    </span>
                    <div class="appointment-duration">
                        <span>{{appointment.period}}</span>
                    </div>
                    <span class="appointment-end-time">
                        <i class="fa fa-dot-circle"></i> {{appointment.endDateTime | date:'HH:mm'}} {{'APPOINTMENT.INFO.TIME-MAIN-SPECIFIER' | translate}}</span>

                </div>
                <div class="col-md-7 no-padding patient-info-wrapper">
                    <div *ngFor='let participant of (participants$| async )'>
                        <h3 *ngIf='!!participant'>
                            {{participant.fullName}}
                        </h3>
                    </div>
                    <div class="patient-info clearfix">
                        <div class="online-status-bubble"></div>
                        <span> {{ patientStatusName$ | async }} </span>
                        <span class='tan' ngxClipboard
                              [cbContent]="getTanLoginLink(appointment.tan)" (click)="copiedSuccessfully()">{{appointment.tan}}</span>
                        <ng-container *ngFor='let participant of (participants$| async )'>
                            <span *ngIf='!!participant && !!participant.insuranceNumber'>
                                {{ participant.insuranceNumber }}
                            </span>
                        </ng-container>
                    </div>
                    <div class="show-copied-tan" *ngIf="!hideCopiedSuccessfully">
                        {{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN-COPIED' | translate }}
                    </div>
                    <div *ngFor='let participant of (participants$| async ) '>
                        <div class='email-holder' *ngIf='!!participant && !!participant.email'>
                            <a href="mailto:{{participant.email}}">
                                <i class="fa fa-envelope email-space"></i>{{ participant.email }}
                            </a>
                        </div>
                    </div>
                    <div class="message" *ngIf="appointment.appointmentMessage">
                        <span class="bold">{{ 'APPOINTMENT.INFO.APPOINTMENT_MESSAGE' | translate }}: </span>
                        {{ appointment.appointmentMessage }}
                    </div>
                    <button class="button btn-primary call-now"
                            *ngIf="isAllowToShowCallNow()"
                            (click)="startVideoCall()"
                            [disabled]="(patientStatus$ | async) !== 'online'">
                        <span><i class="fa fa-caret-right"></i>{{ 'APPOINTMENT.INFO.START' | translate }}</span>
                    </button>
                    <button class="button btn-primary goto-waitingroom" *ngIf="isAllowShowToGoToWaitingRoom()"
                            (click)="goToWaitingRoom()"
                            [attr.disabled]="( patientStatus$ | async ) !== 'online' ? 'true' : null">
                        <span>{{ 'APPOINTMENT.INFO.GOTO_WAITINGROOM' | translate }}</span>
                    </button>
                </div>
                <div class="col-md-2 no-padding">
                    <div *ngFor='let participant of (participants$| async )'>
                        <div class='action-icons' *ngIf='!!participant && !!participant.email'>
                            <a href="mailto:{{participant.email}}" placement="bottom"
                               ngbTooltip="{{participant.email}}">
                                <i class="fa fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
