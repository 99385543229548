
import {Injectable} from '@angular/core';
import {AppState} from '../store/media.state';
import {Media} from '../model/media-video.model';
import {Store} from '@ngrx/store';
import {MediaStartClosingLocalScreenStreamAction} from '../store/media.action';
import {Observable, from} from 'rxjs';


@Injectable()
export class MediaStreamProvider {

    private constrains = {
        audio: true,
        video: {
            facingMode: 'user'
        }
    };

    constructor(private store: Store<AppState>) {
    }

    public getLocalVideoStream(): Observable<MediaStream> {
        return from(
            navigator.mediaDevices.getUserMedia(this.constrains)
        );
    }

    public closeLocalVideoStream(): void {
        try {
            this.store.select((state: AppState) => state.media.localStream.media)
                .subscribe((media: Media) => {
                    if (media !== null) {
                        media.closeCam();
                    }
                }).unsubscribe();
        } catch (error) {
            throw new Error('Can not close local video cam!');
        }
    }

    public closeLocalScreenStream(): void {
        try {
            this.store.select((state: AppState) => state.media.localScreenStream.media)
                .subscribe((media: Media) => {
                    if (media !== null) {
                        media.closeScreenShare();
                    }
                }).unsubscribe();
        } catch (error) {
            throw new Error('Can not close local screen share !');
        }
    }

    public isRemoteStreamActive(): Observable<boolean> {
        return this.store.select((state: AppState) => state.media.remoteStream.active);
    }

    public getLocalScreenStream(sourceId: string = null): Observable<MediaStream> {
        let screenConstraints;
        if (sourceId) {
            screenConstraints = {
                video: {
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        minWidth: 320,
                        minHeight: 240,
                        maxWidth: 1920,
                        maxHeight: 1080,
                        maxFrameRate: 24,
                        chromeMediaSourceId: sourceId
                    }
                }
            };
        } else {
            screenConstraints = {
                video: {
                    mediaSource: 'screen',
                    width: {
                        max: '1920',
                        min: '320'
                    },
                    height: {
                        max: '1080',
                        min: '240'
                    },
                    frameRate: {
                        min: '16',
                        ideal: '20',
                        max: '24'
                    }
                }
            };
        }

        return from(navigator.mediaDevices.getUserMedia(screenConstraints));
    }

    public initializeStreamEventHandler(stream) {
        stream.getTracks().forEach((track) => {
            track.onended = this.handleScreenStreamOnEnd.bind(this);
        });
    }

    public handleScreenStreamOnEnd() {
        this.store.dispatch(new MediaStartClosingLocalScreenStreamAction());
    }
}
