import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PollingClientService } from '../../../http/polling-client.service';
import { PaymentInfoDto } from './dto/payment-info-dto';
import { Observable } from 'rxjs';

export const CREATE_PAYMENT_INFO = 'There is an Error to create Payment Info';

@Injectable()
export class PaymentInfoPollingService {

    private paymentInfoEndpoint = environment.paymentInfoEndpoint;

    constructor(private client: PollingClientService) {
    }

    startPolling(): Observable<PaymentInfoDto> {
        const filterCondition = filter(
            (response: any) => response.status === 'FINISHED' || response.status === 'FAILED'
        );

        const errorCondition = tap((response: PaymentInfoDto) => {
            if (response.status === 'FAILED') {
                throw new Error(CREATE_PAYMENT_INFO);
            }
            return response;
        });

        const mapResponse = map((response: PaymentInfoDto) => response);

        return this.client.get(this.paymentInfoEndpoint).pipe(filterCondition, errorCondition, mapResponse);
    }
}
