import { FormBuilder, FormGroup } from '@angular/forms';

export class ProfileUpdateFormBuilder {

    public static build(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            gender: [''],
            title: [''],
            about: [''],
            specialities: ['']
        });
    }
}
