import { Component } from '@angular/core';
import { MediaStreamProvider } from '../../../../media/provider/media-stream.provider';
import { RtcDetectionService } from '../../../../rtc-detection/providers/rtc-detection.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    APPOINTMENT_OVERVIEW_MULTIUSER_CANCELED,
    APPOINTMENT_OVERVIEW_MULTIUSER_HISTORY,
    APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING
} from '../../../../../routes';

export enum AppointmentListTabs {
    UPCOMING = 'upcoming',
    HISTORY = 'history',
    CANCELED = 'canceled'
}

@Component({
    selector: 'app-appointment-overview',
    templateUrl: './multi-user-appointment-overview.component.html',
    styleUrls: ['./multi-user-appointment-overview.component.scss']
})
export class MultiUserAppointmentOverviewComponent {
    public activeAppointmentListTab = AppointmentListTabs.UPCOMING;

    public UPCOMING: AppointmentListTabs = AppointmentListTabs.UPCOMING;
    public HISTORY: AppointmentListTabs = AppointmentListTabs.HISTORY;
    public CANCELED: AppointmentListTabs = AppointmentListTabs.CANCELED;

    public historyLink = APPOINTMENT_OVERVIEW_MULTIUSER_HISTORY;
    public upcomingLink = APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING;
    public canceledLink = APPOINTMENT_OVERVIEW_MULTIUSER_CANCELED;

    constructor(private mediaStreamService: MediaStreamProvider,
                private rtcDetectionService: RtcDetectionService,
                private translate: TranslateService,
                private router: Router) {
        this.listForPathChanges();
    }

    private listForPathChanges(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveTab(event.url);
            }
        });
    }

    private setActiveTab(url: string): void {
        this.changeActiveTab(this.mapUrlToTab(url));
    }

    private mapUrlToTab(url: string): AppointmentListTabs {
        switch (url) {
            case APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING: {
                return AppointmentListTabs.UPCOMING;
            }
            case APPOINTMENT_OVERVIEW_MULTIUSER_HISTORY: {
                return AppointmentListTabs.HISTORY;
            }
            case APPOINTMENT_OVERVIEW_MULTIUSER_CANCELED: {
                return AppointmentListTabs.CANCELED;
            }
            default: {
                return AppointmentListTabs.UPCOMING;
            }
        }
    }

    public changeActiveTab(tabName: AppointmentListTabs): void {
        this.activeAppointmentListTab = tabName;
    }

    public isActiveTab(tabName: AppointmentListTabs): boolean {
        return this.activeAppointmentListTab === tabName;
    }
}
