<div class="modal-header">
    <i class="far fa-list"></i>
    <h4 class="modal-title">
        {{ 'RTC_DETECTION.TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()"><span
        aria-hidden="true"><i class="far fa-times"></i></span></button>
</div>
<div class="modal-body">

    <div class="body-content">
        <i class="fal fa-check text-success"></i>
        <span>{{ 'RTC_DETECTION.SUCCESS.MSG' | translate }}</span>
    </div>
</div>
<div class="modal-footer">
    <div class="button-line">
        <button type="button" class="btn btn-primary" (click)="finish()">
        <span>
            {{'RTC_DETECTION.FINISH' | translate }}
             <i class="fal fa-check-circle"></i>
        </span>
        </button>
    </div>

    <app-rtc-footer></app-rtc-footer>

</div>

