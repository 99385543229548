export const WAITINGROOM_ROUTE = '/waitingroom';
export const WAITINGROOM_MULTIUSER_ROUTE = '/waitingroom-multiuser';
export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const TAN_LOGIN_ROUTE = '/tan-login';

export const REGISTER_ROUTE = 'register';
export const REGISTER_SUCCESSFUL_ROUTE = 'register/successful';
export const REGISTER_FAILED_ROUTE = 'register/failed';

export const TAN_APPOINTMENT_CREATE_ROUTE = '/appointment/create/tan';
export const TAN_APPOINTMENT_ROUTE = '/appointment/create';
export const CREATE_APPOINTMENT_ROUTE = '/appointment/create';
export const CREATE_MULTIUSER_APPOINTMENT_ROUTE = '/appointment/create/multiuser';
export const APPOINTMENT_OVERVIEW_ROUTE = '/appointment/overview';
export const APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE = '/appointment/overview-multiuser';

export const APPOINTMENT_OVERVIEW_UPCOMING = '/appointment/overview/upcoming';
export const APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING = '/appointment/overview-multiuser/upcoming';
export const APPOINTMENT_OVERVIEW_REQUESTS = '/appointment/overview/requests';
export const APPOINTMENT_OVERVIEW_HISTORY = '/appointment/overview/history';
export const APPOINTMENT_OVERVIEW_MULTIUSER_HISTORY = '/appointment/overview-multiuser/history';
export const APPOINTMENT_OVERVIEW_MULTIUSER_CANCELED = '/appointment/overview-multiuser/canceled';

export const APPOINTMENT_MULTIUSER_REQUESTS = '/appointment/multiuser-requests';

export const APPOINTMENT_CREATE_TAN = '/appointment/create/tan';
export const APPOINTMENT_CREATE_MULTIUSER = '/appointment/create/multiuser';
export const APPOINTMENT_CREATE_CONSULTATIONHOURS = '/appointment/create/consultation-hour';
export const APPOINTMENT_CREATE_SUCCESS = '/appointment/create/success';
export const APPOINTMENT_CREATE_FAILURE = '/appointment/create/failed';
export const CREATE_APPOINTMENT_FAILURE = '/appointment/create/multiuser-failure';
export const CREATE_APPOINTMENT_SUCCESS = '/appointment/create/multiuser-success';

export const SETTING_ROUTE = '/setting';
export const SETTING_PROFILE = '/setting/profile';
export const SETTING_ACCOUNT = '/setting/account';
export const SETTING_PAYMENT = '/setting/payment';

export const DASHBOARD_ROUTE = '/dashboard';
export const HELP_ROUTE = '/help';

export const PASSWORD_RECOVERY_REQUEST = '/password-recovery/request';
export const PASSWORD_RECOVERY_CREATE = '/password-recovery/create';
export const PASSWORD_RECOVERY_SUCCESS = '/password-recovery/success';
export const PASSWORD_RECOVERY_FAILED = '/password-recovery/failed';
export const PASSWORD_RECOVERY_INVALID_TOKEN = '/password-recovery/invalid-token';

export const PAYMENT_ROUTE = '/payment';
export const PAYMENT_ROUTE_ACCOUNT = '/payment/account';
export const PAYMENT_ROUTE_INFO = '/payment/info';

export const ROUTE_DISPOSED = PAYMENT_ROUTE_ACCOUNT + '/disposed';
export const ROUTE_OUTSTANDING = PAYMENT_ROUTE_ACCOUNT + '/outstanding';
export const ROUTE_CANCELED = PAYMENT_ROUTE_ACCOUNT + '/canceled';
export const ROUTE_PAID = PAYMENT_ROUTE_ACCOUNT + '/paid';

export const LEGAL_ROUTE = '/legal';
export const LEGAL_DATA_SECURITY = '/legal/data-security';
export const LEGAL_IMPRINT = '/legal/imprint';
export const LEGAL_TERMS = '/legal/terms';
export const LEGAL_DATA_PROCESS_TERMS = '/legal/data-process-terms';

export const VONAGE = 'online-video-consultation';
