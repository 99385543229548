import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularPinturaModule } from '@pqina/angular-pintura';

@NgModule({
    declarations: [
        ImageUploadComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        AngularPinturaModule
    ],
    exports: [
        ImageUploadComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImageUploadModule {
}
