import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../forms/validators/custom-validator';

export class CreateTanFormBuilder {
    public static build(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            tanDate: ['', [Validators.required, CustomValidators.DateIsTodayOrAfter()]],
            tanBegining: ['', Validators.compose([Validators.required])],
            tanDuration: [{id: '10', name: '10 min'}, Validators.compose([Validators.required])],
            salutation: ['', Validators.compose([Validators.required])],
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            patientEmailCheckBox: [false],
            patientEmail: [''],
            isEbmCheckBox: [false],
            birthday: formBuilder.group(
                {
                    birthDay: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'})
                    ])],
                    birthMonth: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'})
                    ])],
                    birthYear: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'})
                    ])],
                }
            ),
            patientEmailMessage: [null],
        });
    }
}
