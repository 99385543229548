
import {filter} from 'rxjs/operators';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-remote-cam-video-tag',
  templateUrl: './remote-cam-video-tag.component.html',
  styleUrls: ['./remote-cam-video-tag.component.scss']
})
export class RemoteCamVideoTagComponent implements OnInit {

    @Input() fullsized: boolean;

    remoteStream$: Observable<MediaStreamElement>;

    constructor(private elRef: ElementRef,
                store: Store<AppState>) {
        this.remoteStream$ = store.select(state => state.media.remoteStream).pipe(filter(e => !!e));
    }

    ngOnInit(): void {
        this.remoteStream$.subscribe((mediaElement: MediaStreamElement) => {
            if (mediaElement.active && mediaElement.media !== null) {
                const video = this.elRef.nativeElement.querySelector('#remote-cam-video-tag');
                video.srcObject = mediaElement.media.stream;
            }
            if (!mediaElement.active) {
                const video = this.elRef.nativeElement.querySelector('#remote-cam-video-tag');
                video.srcObject = null;
            }
        });
    }

}
