import { Subject } from 'rxjs';
import { FileTransferEvent, FileTransferEventType } from './file-transfer-event.model';

abstract class FileTransferState {
    constructor(protected queue: FileTransferEventQueue, protected stack: FileTransferEvent[]) {
    }

    public handle(event: FileTransferEvent): void {
        if (this.isMyEvent(event)) {
            this.queue.setNextEvent(event);
            this.queue.setNewState(this.getNextState());
        } else {
            this.stack.push(event);
        }
    }

    protected abstract isMyEvent(event: FileTransferEvent): boolean;

    protected abstract getNextState(): FileTransferState;

    public handleStash() {
        let myEvent: FileTransferEvent = null;
        const newStash: FileTransferEvent[] = [];
        this.stack.forEach(event => {
            if (this.isMyEvent(event)) {
                myEvent = event;
            } else {
                newStash.push(event);
            }
        });
        this.stack = newStash;
        if (myEvent !== null) {
            this.handle(myEvent);
        }
    }
}

class FileTransferInitState extends FileTransferState {
    protected type = FileTransferEventType.Init;

    protected getNextState(): FileTransferState {
        return new FileTransferStartState(this.queue, this.stack);
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        return event.type === this.type;
    }
}

class FileTransferStartState extends FileTransferState {
    protected type = FileTransferEventType.Start;
    private amountChunk: number;

    protected getNextState(): FileTransferState {
        return new FileTransferChunkState(this.queue, this.stack, 1, this.amountChunk);
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        if (event.type === this.type) {
            this.amountChunk = event.amountChunk;
            return true;
        }
        return false;
    }
}

class FileTransferChunkState extends FileTransferState {
    protected type = FileTransferEventType.Chunk;

    constructor(
        protected queue: FileTransferEventQueue,
        protected stack: FileTransferEvent[],
        private chunkNumber: number,
        private amountChunk: number) {
        super(queue, stack);
    }

    protected getNextState(): FileTransferState {
        if (this.chunkNumber === this.amountChunk) {
            return new FileTransferCompleteState(this.queue, this.stack);
        } else {
            return new FileTransferChunkState(this.queue, this.stack, this.chunkNumber + 1, this.amountChunk);
        }
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        return event.type === this.type && event.chunkNumber === this.chunkNumber;
    }

}

class FileTransferCompleteState extends FileTransferState {
    private type = FileTransferEventType.Complete;

    protected getNextState(): FileTransferState {
        return new FileTransferCloseState(this.queue, []);
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        return event.type === this.type;
    }
}

export class FileTransferCancelledState extends FileTransferState {
    private type = FileTransferEventType.Cancelled;

    protected getNextState(): FileTransferState {
        return this;
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        return event.type === this.type;
    }
}

export class FileTransferErrorState extends FileTransferState {
    private type = FileTransferEventType.Error;

    constructor(
        protected queue: FileTransferEventQueue,
        protected stack: FileTransferEvent[],
        private error: string) {
        super(queue, stack);
    }

    public getError(): string {
        return this.error;
    }

    protected getNextState(): FileTransferState {
        return this;
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        return event.type === this.type;
    }
}

export class FileTransferCloseState extends FileTransferState {
    protected getNextState(): FileTransferState {
        return this;
    }

    protected isMyEvent(event: FileTransferEvent): boolean {
        return false;
    }
}

export class FileTransferEventQueue extends Subject<FileTransferEvent> {
    private events: FileTransferEvent[] = [];
    public state: FileTransferState = new FileTransferInitState(this, []);

    add(event: FileTransferEvent) {
        if (event.type === FileTransferEventType.Error) {
            this.state = new FileTransferErrorState(this, [], event.msg);
        }

        if (event.type === FileTransferEventType.Cancelled) {
            this.state = new FileTransferCancelledState(this, []);
        }

        this.state.handle(event);
    }

    public setNextEvent(event: FileTransferEvent) {
        if (this.observers.length > 0) {
            this.next(event);
        } else {
            this.events.push(event);
        }
    }

    subscribe(observer) {
        const s = super.subscribe(observer);
        this.events.forEach(event => this.next(event));
        this.events = [];
        return s;
    }

    public setNewState(nextState: FileTransferState) {
        this.state = nextState;
        nextState.handleStash();
    }

    public getCurrentState(): FileTransferState {
        return this.state;
    }
}
