import { Component, ElementRef, ViewChild } from '@angular/core';
import { SoundMeter } from '../../model/sound-meter';
import { AudioTestModel } from '../../model/audio.model';

@Component({
    selector: 'app-sound-test',
    templateUrl: './sound-test.component.html',
    styleUrls: ['./sound-test.component.scss']
})
export class SoundTestComponent {
    @ViewChild('soundtest') elementRef: ElementRef;
    private audioForSpeaker: AudioTestModel;
    private soundMeterAudio: AudioTestModel;
    public isSoundTestRunning = false;

    public startSoundTest() {
        this.isSoundTestRunning = true;
        this.startSoundMeter();
        this.startSpeaker();
    }

    public stopSoundTest() {
        this.soundMeterAudio.stop();
        this.audioForSpeaker.stop();
    }

    private startSpeaker() {
        this.audioForSpeaker = new AudioTestModel();
        this.audioForSpeaker.onEnded((): void => {
            this.isSoundTestRunning = false;
        });

        this.audioForSpeaker.onPause((): void => {
            this.isSoundTestRunning = false;
        });
        this.audioForSpeaker.play();
    }

    private startSoundMeter() {
        const soundMeter = new SoundMeter();
        this.soundMeterAudio = new AudioTestModel();
        const canvas = this.elementRef.nativeElement;

        soundMeter.connectToAudioSource(this.soundMeterAudio.getSound());
        soundMeter.visualize(canvas);

        this.soundMeterAudio.onEnded((): void => {
            soundMeter.cleanup();
        });

        this.soundMeterAudio.onPause((): void => {
            soundMeter.cleanup();
        });

        this.soundMeterAudio.play();
    }
}


