import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { SpecialityType } from '../../../entities/speciality/provider/speciality.service';
import { BrandingType } from '../providers/profile.types';
import { Institution } from '../../../entities/institution/institution.model';

export enum ProfileType {
    DOCTOR = 1,
    PATIENT,
}

export enum ProfileTypeString {
    DOCTOR = 'doctor',
    PATIENT = 'patient'
}

export function profileTypeMap(profileTypeString) {
    profileTypeString = profileTypeString.toLowerCase();
    switch (profileTypeString) {
        case 'doctor':
            return ProfileType.DOCTOR;
        case 'patient':
            return ProfileType.PATIENT;
    }
}

export function getProfileType(profileType: ProfileType): string {
    const type = ProfileType[profileType];
    return ProfileTypeString[type];
}

export interface ProfileSettings {
    id: number;
    title?: string;
    salutation?: string;
    details?: {
        about?: string;
        isPublic?: boolean;
    };
    specialities?: SpecialityType[];
}

export interface ProfileImage {
    profileImage: string;
}

export interface Profile {
    id: number;
    type: ProfileType;
    fullName: string;
    imageUrl: string;
    publicProfileUrl?: string;
    title?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    insuranceNumber?: string;
    telephoneNumber?: string;
    birthday?: string;
    dataProcessTerm?: boolean;
    specialities?: SpecialityType[];
    contacts?: Contact;
    details?: {
        about?: string;
        isPublic?: boolean;
    };
    branding?: BrandingType;
    institution?: Institution;
    tan?: string;
}

export interface ProfileResponse {
    id: number | string;
    type: number | string;
    fullName: string;
    firstName?: string;
    lastName?: string;
    title?: string;
    salutation?: string;
    imageUrl: string;
    email?: string;
    insuranceNumber?: string;
    tan?: string;
    birthday?: string;
    specialities?: SpecialityType[];
}

export interface ProfileListResponse {
    items: ProfileResponse[]
}

export interface Contact {
    street: string;
    practiceName: string;
    houseNumber: string;
    zipcode: string;
    location: string;
    telephone: string;
}

export const profileAdapter = createEntityAdapter<Profile>();

export interface EntityStateProfile extends EntityState<Profile> {
    ids: number[];
    entities: { [id: number]: Profile };
    currentId: number | null;
    isInit: boolean | null;
}

const myDefaultProfileList: EntityStateProfile = {
    currentId: null,
    ids: [],
    entities: {},
    isInit: true
};

export const initialProfileListState: EntityStateProfile = myDefaultProfileList;

