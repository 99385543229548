import { Injectable } from '@angular/core';
import { JitsiEventService } from './jitsi-event.service';
import { JitsiExternalApiService } from './jitsi-external-api.service';
import { InstitutionSettings } from '../../waitingroom/store/waitingroom.effect';
import { BodyService } from '../../../providers/body/body.service';

@Injectable()
export class JitsiConferenceService {
    private JWT_TOKEN: string;

    constructor(
        private apiService: JitsiExternalApiService,
        private eventService: JitsiEventService,
        private bodyService: BodyService
    ) {
    }

    public startConference(conferenceId: number, token: string, inst: InstitutionSettings, peerBrowser: string): void {
        const room = this.buildRoomName(conferenceId);
        this.apiService.start(token, room, inst, peerBrowser);
        this.eventService.registerListeners(this.apiService.getApi());
        this.bodyService.addClass('disable-ovs-scrollbars');
    }

    public setToken(token: string) {
        this.JWT_TOKEN = token;
    }

    private buildRoomName(conferenceId: number): string {
        return 'conference' + conferenceId;
    }

    toggleAudio(): void {
        this.apiService.getApi().executeCommand('toggleAudio');
    }

    toggleVideo(): void {
        // this.apiService.getApi().executeCommand('toggleVideo');
    }
}
