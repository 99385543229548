<div class="screenshare-overlay"
     *ngIf="isScreenShareButtonHovered"
     [@enterAnimation]>
    <div class="overlay-content row">
        <div class="col-md-3">
            <img src="assets/img/general/screenshare.svg" alt="">
        </div>
        <div class="col-md-9">
            <h3>{{'MEDIA.OVERLAY.TITLE' | translate}}</h3>
            <p>{{'MEDIA.OVERLAY.TEXT' | translate}}</p>
        </div>
    </div>
</div>

<div class="screenshare-overlay"
     *ngIf="isNoticeButtonOnHover"
     [@enterAnimation]>
    <div class="overlay-content row">
        <div class="col-md-3">
            <img src="assets/img/general/notes-icon.svg" alt="">
        </div>
        <div class="col-md-9">
            <h3>{{'NOTICE.OVERLAY.TITLE' | translate}}</h3>
            <p>{{'NOTICE.OVERLAY.TEXT' | translate}}</p>
        </div>
    </div>
</div>

<div class="screenshare-overlay"
     *ngIf="isFileTransferButtonHovered"
     [@enterAnimation]>
    <div class="overlay-content row">
        <div class="col-md-3">
            <img src="assets/img/general/file-transfer-OnHoverIcon.svg" alt="">
        </div>
        <div class="col-md-9 fileText">
            <h3>{{'FILE_TRANSFER.OVERLAY.TITLE' | translate}}</h3>
            <p>{{'FILE_TRANSFER.OVERLAY.TEXT' | translate}}</p>
        </div>
    </div>
</div>

<div class="control-bar-row">
    <div class="control-bar-item start-screenshare-button"
         id="chromeInstallationButton"
         *ngIf="!((isLocalScreenShareActive$ | async) || (isRemoteScreenShareActive$ | async)) && !browser.isSafari()"
         appChromeInlineInstallation
         (click)="startScreenShare()"
         (mouseenter)="changeScreenShareOverlayVisibility(true)"
         (mouseleave)="changeScreenShareOverlayVisibility(false)">
        <img src="assets/img/general/screenshare-icon-white.svg" class="control-item-icon" alt="">
        <img src="assets/img/general/screenshare-icon.svg" class="control-item-icon on-hover" alt="">
    </div>
    <div class="start-screenshare-button"
         (mouseenter)="changeFileTransferOverlayVisibility(true)"
         (mouseleave)="changeFileTransferOverlayVisibility(false)">
        <app-file-transfer-pop-up></app-file-transfer-pop-up>
    </div>
    <div class="control-bar-item start-screenshare-button"
         (mouseenter)="changeNoticeOverlayVisibility(true)"
         (mouseleave)="changeNoticeOverlayVisibility(false)">
        <app-notice></app-notice>
    </div>
</div>

<div class="close-screenshare-button"
     *ngIf="(isLocalScreenShareActive$ | async)"
     (click)="closeScreenShare()">
    {{'MEDIA.CLOSE-SCREENSHARE' | translate}} <i class="fas fa-times"></i>
</div>

