import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

    transform(val: string | null): string {
        if (val !== undefined && val !== null) {
            return this.replaceAll(val.toString(), ',', '');
        } else {
            return '';
        }
    }

    private replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }
}
