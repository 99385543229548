import { filter } from 'rxjs/operators';
import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-local-cam-video-tag',
    templateUrl: './local-cam-video-tag.component.html',
    styleUrls: ['./local-cam-video-tag.component.scss']
})
export class LocalCamVideoTagComponent implements OnInit, OnDestroy {

    @Input() fullsized: boolean;
    localStreamElement$: Observable<MediaStreamElement>;
    private localStreamSubscription: Subscription = null;

    constructor(private elRef: ElementRef, private store: Store<AppState>) {
        this.localStreamElement$ = this.store.select((state: AppState) => state.media.localStream).pipe(filter(e => !!e));
    }

    ngOnInit(): void {
        this.localStreamSubscription = this.localStreamElement$.subscribe((mediaElement: MediaStreamElement) => {
            if (mediaElement.active) {
                const video = this.elRef.nativeElement.querySelector('#local-cam-video-tag');
                video.srcObject = mediaElement.media.stream;
                video.muted = true;
            }
            if (!mediaElement.active) {
                const video = this.elRef.nativeElement.querySelector('#local-cam-video-tag');
                video.srcObject = null;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.localStreamSubscription !== null) {
            this.localStreamSubscription.unsubscribe();
        }
    }
}
