import {
    APPOINTMENT_MULTIUSER_REQUESTS,
    APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE,
    APPOINTMENT_OVERVIEW_ROUTE,
    CREATE_APPOINTMENT_ROUTE,
    DASHBOARD_ROUTE,
    LEGAL_ROUTE,
    SETTING_ROUTE,
    TAN_APPOINTMENT_ROUTE,
    WAITINGROOM_MULTIUSER_ROUTE,
    WAITINGROOM_ROUTE
} from '../../routes';

export enum Institution {
    PATIENTUS = 'patientus',
    AGAPLESION = 'agaplesion',
    MEDATIXX = 'medatixx',
    BETRIEBSARZTSERVICE = 'betriebsarztservice',
    HOMEDOK = 'homedok',
    ALBATROS = 'albatros',
    JAMEDA = 'jameda',
    SIGMA = '4sigma',
    AUDIBKK = 'audibkk',
    KKH = 'kkh',
    CFG_AOK = 'cfg-aok',
    IKK_SUEDWEST = 'ikk-suedwest',
    BMWBKK = 'bmwbkk',
    KINZIGTAL = 'kinzigtal',
    ASD_BGN = 'asd-bgn',
    BAD = 'bad',
    BKK_MELITTA = 'bkk-melitta',
    MEDIMENTUM = 'medimentum',
    AGAPLESION_KASSEL = 'agaplesion-kassel',
    SANASO = 'sanaso',
    DDC = 'ddc',
    HELIOS = 'helios',
    ACURA = 'acura',
    ASZ = 'asz',
    BKK_PWC = 'bkk-pwc',
    AOK = 'aok',
    LANAE = 'telemedizin-lanae',
    UKSH = 'uksh',
    EPILEPTOLOGIE_BONN = 'epileptologie-bonn',
    BKK_RWE = 'bkk-rwe',
    BKK_MHPLUS = 'bkk-mhplus',
    FERNARZT = 'fernarzt',
    ITZEHOE = 'itzehoe',
    POLAVIS = 'polavis',
    ENMEDIFY = 'enmedify',
    ENMEDIFY_CH = 'enmedifych',
    INVIRTO = 'invirto',
    ZOTZKLIMAS = 'zotzklimas',
    SYNLAB = 'synlab',
    S_THETIC = 's-thetic',
    CKELLER_STUDIEN = 'ckeller-studien'
}

export const InstitutionTermsObject = {
    default: ['patientus'],
    medatixx: ['medatixx'],
    homedok: ['homedok'],
    betriebsarztservice: ['betriebsarztservice'],
    jameda: ['jameda'],
    agaplesion: ['agaplesion'],
    agaplesion_kassel: ['agaplesion-kassel'],
    medimentum: ['medimentum'],
    sanaso: ['sanaso'],
    ddc: ['ddc'],
    acura: ['acura'],
    asz: ['asz'],
    helios: ['helios'],
    aok: ['aok'],
    'telemedizin-lanae': ['telemedizin-lanae'],
    uksh: ['uksh'],
    'epileptologie-bonn': ['epileptologie-bonn'],
    fernarzt: ['fernarzt'],
    itzehoe: ['klinikum-itzehoe-mvzs'],
    polavis: ['polavis'],
    enmedify: ['enmedify'],
    enmedifych: ['enmedifych'],
    invirto: ['invirto'],
    zotzklimas: ['zotzklimas'],
    synlab: ['synlab'],
    's-thetic': ['s-thetic'],
    'ckeller-studien': ['ckeller-studien']
};

export const COMMON_ROUTES: string[] = [
    DASHBOARD_ROUTE,
    WAITINGROOM_ROUTE,
    WAITINGROOM_MULTIUSER_ROUTE,
    TAN_APPOINTMENT_ROUTE,
    APPOINTMENT_OVERVIEW_ROUTE,
    APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE,
    APPOINTMENT_MULTIUSER_REQUESTS,
    SETTING_ROUTE,
    LEGAL_ROUTE
];

const WHITE_LABEL_DR_ROUTES: string[] = [
    WAITINGROOM_MULTIUSER_ROUTE,
    APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE
];

export const RestrictedFeatureRouteMap = {
    medatixx: [...WHITE_LABEL_DR_ROUTES, SETTING_ROUTE],
    polavis: [...WHITE_LABEL_DR_ROUTES, CREATE_APPOINTMENT_ROUTE],
    timerbee: WHITE_LABEL_DR_ROUTES,
    jameda: WHITE_LABEL_DR_ROUTES,
    homedok: [...COMMON_ROUTES],
    helios: [...COMMON_ROUTES],
    betriebsarztservice: [...COMMON_ROUTES],
    zotzklimas: [...WHITE_LABEL_DR_ROUTES, SETTING_ROUTE],
    synlab: [...WHITE_LABEL_DR_ROUTES, SETTING_ROUTE],
    's-thetic': [...COMMON_ROUTES],
    'ckeller-studien': [...COMMON_ROUTES]
};
