import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingSpinnerService {
    public spinnerControl$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() {
    }

    public activate() {
        this.spinnerControl$.next(true);
    }

    public deactivate() {
        this.spinnerControl$.next(false);
    }

    public getControl(): Observable<boolean> {
        return this.spinnerControl$;
    }
}
