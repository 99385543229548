import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentInfoTabComponent} from './components/payment-info-tab/payment-info-tab.component';
import {UtilsModule} from '../../utils/utils.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {BankAccountComponent} from './components/bank-account/bank-account.component';
import { LegalUserComponent } from './components/legal-user/legal-user.component';

export const ROUTE = '/info';

@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        PaymentInfoTabComponent,
        LegalUserComponent,
        BankAccountComponent
    ],
    exports: [
        PaymentInfoTabComponent,
        LegalUserComponent
    ]
})
export class PaymentInfoModule {
}
