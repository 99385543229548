import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/auth-guard.service';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { AuthGuardExternal } from './modules/auth/auth-guard-external.service';
import { TanLoginComponent } from './modules/auth/pages/tan-login/tan-login.component';
import { RegistartionSuccessfulComponent } from './modules/register/pages/registartion-successful/registartion-successful.component';
import { RegistartionFailedComponent } from './modules/register/pages/registartion-failed/registartion-failed.component';
import { DashboardLayoutComponent, SimpleLayoutComponent } from './modules/layout/components';
import { HelpComponent } from './modules/help/help.component';
import { WaitingroomComponent } from './modules/waitingroom/single-user-waitingroom/waitingroom.component';
import { MultiUserWaitingroomComponent } from './modules/waitingroom/multi-user-waitingroom/multi-user-waitingroom.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PatientusRole } from './modules/auth/auth.roles';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ProfileComponent } from './modules/profile/profile.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { SettingPageComponent } from './pages/settings-page/setting-page.component';
import { InstitutionAuthGuard } from './modules/auth/institution-auth-guard.service';
import {
    DASHBOARD_ROUTE,
    HOME_ROUTE,
    ROUTE_DISPOSED,
    SETTING_PROFILE,
    VONAGE,
    WAITINGROOM_MULTIUSER_ROUTE,
    WAITINGROOM_ROUTE
} from './routes';
import { FeatureFlag, getFeatureFlagRouteSettings } from './modules/feature-flag/feature-flag.types';
import { FeatureFlagGuard } from './modules/feature-flag/guard/feature-flag.guard';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: WAITINGROOM_ROUTE
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
        canActivate: [AuthGuardExternal]
    },
    {
        path: 'tan-login',
        component: TanLoginComponent,
        pathMatch: 'full',
        canActivate: [AuthGuardExternal]
    },
    {
        path: '',
        component: SimpleLayoutComponent,
        children: [
            {
                path: 'register/successful',
                component: RegistartionSuccessfulComponent,
                pathMatch: 'full'
            },
            {
                path: 'register/failed',
                component: RegistartionFailedComponent,
                pathMatch: 'full'
            },
            {
                path: 'password-recovery',
                loadChildren: () => import('./modules/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryModule),
            },
            {
                path: 'email',
                loadChildren: () =>
                    import('./modules/register-token-accept/register-token-accept.module').then(m => m.RegisterTokenAcceptModule),
            },
            {
                path: 'legal',
                loadChildren: () => import('./modules/legal/legal-routing.module').then(m => m.LegalRoutingModule)
            }
        ]
    },
    {
        path: '',
        component: DashboardLayoutComponent,
        canActivate: [AuthGuard, InstitutionAuthGuard],
        children: [
            {
                path: 'help',
                component: HelpComponent,
                pathMatch: 'full',
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        redirectTo: WAITINGROOM_ROUTE
                    }
                }
            },
            {
                path: 'waitingroom',
                component: WaitingroomComponent,
                pathMatch: 'full',
                canActivate: [FeatureFlagGuard, NgxPermissionsGuard],
                data: {
                    permissions: {
                        redirectTo: WAITINGROOM_ROUTE
                    },
                    featureFlagSettings: getFeatureFlagRouteSettings(
                        true,
                        FeatureFlag.MULTI_USER_CALL,
                        WAITINGROOM_MULTIUSER_ROUTE
                    )
                }
            },
            {
                path: 'waitingroom-multiuser',
                component: MultiUserWaitingroomComponent,
                pathMatch: 'full',
                canActivate: [FeatureFlagGuard, NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: PatientusRole.PATIENT,
                        redirectTo: DASHBOARD_ROUTE
                    },
                    featureFlagSettings: getFeatureFlagRouteSettings(
                        false,
                        FeatureFlag.MULTI_USER_CALL,
                        WAITINGROOM_ROUTE
                    )
                }
            },
            {
                path: 'dashboard',
                canActivate: [NgxPermissionsGuard],
                component: DashboardComponent,
                data: {
                    permissions: {
                        only: PatientusRole.DOCTOR,
                        redirectTo: HOME_ROUTE
                    }
                }
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: PatientusRole.DOCTOR,
                        redirectTo: SETTING_PROFILE
                    }
                }
            },
            {
                path: 'appointment',
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./modules/appointment/appointment-routing.module').then(m => m.AppointmentRoutingModule),
                data: {
                    permissions: {
                        only: PatientusRole.DOCTOR,
                        redirectTo: HOME_ROUTE
                    }
                }
            },
            {
                path: 'payment',
                component: PaymentPageComponent,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./modules/payment/payment-routing.module').then(m => m.PaymentRoutingModule),
                data: {
                    permissions: {
                        only: PatientusRole.DOCTOR,
                        redirectTo: ROUTE_DISPOSED
                    }
                }
            },
            {
                path: 'setting',
                canActivate: [NgxPermissionsGuard],
                component: SettingPageComponent,
                loadChildren: () => import('./modules/setting/setting-routing.module').then(m => m.SettingRoutingModule),
                data: {
                    permissions: {
                        only: PatientusRole.DOCTOR,
                        redirectTo: SETTING_PROFILE
                    }
                }
            }
        ]
    },
    {
        path: VONAGE,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/vonage/vonage.module').then( m => m.VonageModule)
    },
    {
        path: '**',
        redirectTo: DASHBOARD_ROUTE
    },
    {
        path: '',
        loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule),
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'corrected' })
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard
    ]
})
export class AppRoutingModule {
}
