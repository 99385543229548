import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'onlyDecimal'
})
export class OnlyDecimalPipe implements PipeTransform {

    transform(val: number): string {
        if (val !== undefined && val !== null) {
            return val.toFixed(2).slice(-2);
        } else {
            return '';
        }
    }

    private replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }
}
