import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '../../../environments/environment';

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        Apollo,
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => ({
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: environment.graphQLEndpoint
                    })
                }),
            deps: [HttpLink]
        }
    ]

})

export class GraphqlModule {
}
