<div class="modal-header">
    <i class="far fa-list"></i>
    <h4 class="modal-title">
        {{ 'RTC_DETECTION.TITLE' | translate }}
    </h4>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="cancelStep()"><span aria-hidden="true"><i class="far fa-times"></i></span></button>
</div>
<div class="modal-body">
    <div class="modal-body-wrapper">
        <h5 class="camera-headline" [ngClass]="{'error' : !(cameraIsRunning)}">
            {{'RTC_DETECTION.HARDWARE.CAMERA' | translate}}
            <i *ngIf="cameraIsRunning" class="fas fa-check"></i>
            <i *ngIf="!cameraIsRunning" class="fas fa-exclamation-triangle"></i>
        </h5>

        <div id="camera-preview" class="camera-prieview-box">
            <div id="eye-close">
                <i class="fa fa-eye-slash" aria-hidden="true"></i>
            </div>
            <app-local-cam-video-tag></app-local-cam-video-tag>
        </div>

        <h5 class="microphone-headline" [ngClass]="{'error' : !(microIsOn)}">
            {{'RTC_DETECTION.HARDWARE.MICROPHONE' | translate}}
            <i *ngIf="microIsOn" class="fas fa-check"></i>
            <i *ngIf="!microIsOn" class="fas fa-exclamation-triangle"></i>
        </h5>

        <div id="micro-preview-bar" class="">
            <app-microphone-sound-meter></app-microphone-sound-meter>
            <div class="message" *ngIf="!microIsOn">{{'RTC_DETECTION.HARDWARE.MAKE_NOISE' | translate}}</div>
        </div>

        <h5>{{'RTC_DETECTION.HARDWARE.SOUND' | translate}}</h5>
        <app-sound-test></app-sound-test>

    </div>
</div>
<div class="modal-footer">
    <div class="button-line">
        <button type="button" class="btn btn-outline-dark" (click)="cancelStep()">
            {{'RTC_DETECTION.CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-primary" [disabled]="!microIsOn" (click)="continue()">
        <span>
            {{'RTC_DETECTION.START' | translate }}
            <i class="fas fa-arrow-right"></i>
        </span>
        </button>
    </div>

    <app-rtc-footer></app-rtc-footer>

</div>
