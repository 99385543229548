import { FileData } from './../../provider/data-channel/file-transmitter';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
    FileTransferChunkEvent,
    FileTransferCompleteEvent,
    FileTransferInitEvent,
    FileTransferStartEvent,
    FileTransferCancelledEvent,
    FileTransferErrorEvent
} from '../../provider/data-channel/file-transfer-event.model';
import { getSizeWithType } from '../../../utils/string-helper/bytes';
import { FileTransmitter } from '../../provider/data-channel/file-transmitter';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-progressbar',
    templateUrl: './progressbar.component.html',
    styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {
    @Input() transmitter: FileTransmitter;
    @Input() type: ProgressbarComponentType;
    @Input() checkFileDownloaded = false;
    @Output() progressEvent = new EventEmitter<ProgressbarParentNotifyType>();
    @Output() downloadedEvent = new EventEmitter<string>();

    public readonly ProgressBarStates = ProgressBarStates;
    public readonly ProgressbarComponentType = ProgressbarComponentType;

    public fileStats: ProgressBarStats = null;
    public errorMessage: string;
    public showCancelText = false;

    constructor() {
    }

    ngOnInit() {

        if (this.transmitter.isFinished() && (this.type === ProgressbarComponentType.RECEIVING || ProgressbarComponentType.UPLOADED)) {
            this.onReceivedCompletedFile(this.transmitter.getFileData());
            return;
        }

        if (this.transmitter.isFinished()) {
            return;
        }

        this.transmitter.transferEvents.subscribe(event => {
            if (event instanceof FileTransferInitEvent) {
                this.onInit(event);
            }

            if (event instanceof FileTransferStartEvent) {
                this.onStart(event);
            }

            if (event instanceof FileTransferChunkEvent) {
                this.onProgress(event);
            }

            if (event instanceof FileTransferCompleteEvent) {
                this.onComplete(event);
            }

            if (event instanceof FileTransferErrorEvent) {
                this.onError(event);
            }

            if (event instanceof FileTransferCancelledEvent) {
                this.onCancel();
            }
        });
    }

    private onInit(event: FileTransferInitEvent) {
        this.fileStats = {
            max: 0,
            value: 0,
            fileName: '',
            state: ProgressBarStates.STATE_IN_INIT,
        };
    }

    private onStart(event: FileTransferStartEvent) {
        this.fileStats.id = this.transmitter.getId();
        this.fileStats.max = event.file.size;
        this.fileStats.value = 0;
        this.fileStats.fileName = event.file.name;
        this.fileStats.state = ProgressBarStates.STATE_IN_PROGRESS;
    }

    private onProgress(event: FileTransferChunkEvent) {
        this.fileStats.value += event.chunkSize;
    }

    private onComplete(event: FileTransferCompleteEvent) {
        if (this.type === ProgressbarComponentType.RECEIVING) {
            this.fileStats.blob = event.blob;
        }
        this.fileStats.state = ProgressBarStates.STATE_COMPLETED;
        this.transmitter.transferEvents.unsubscribe();
        this.progressEvent.emit(ProgressbarParentNotifyType.COMPLETE);
    }

    private onReceivedCompletedFile(fileData: FileData) {
        this.fileStats = {
            id: this.transmitter.getId(),
            max: fileData.size,
            value: 0,
            fileName: fileData.name,
            state: ProgressBarStates.STATE_COMPLETED,
        };
        if (this.type === ProgressbarComponentType.RECEIVING) {
            this.fileStats.blob = fileData.blob;
        }
    }

    public onCancel(): void {
        this.fileStats.state = ProgressBarStates.STATE_CANCELED;
        this.progressEvent.emit(ProgressbarParentNotifyType.CANCELED);
        this.showCancelText = true;
        setTimeout(() => {
            this.showCancelText = false;
        }, 2000);
    }

    public doCancel(): void {
        this.transmitter.cancel();
        this.onCancel();
    }

    public onDownload() {
        saveAs(this.fileStats.blob, this.fileStats.fileName);
        this.checkFileDownloaded = true;
        this.downloadedEvent.emit(this.fileStats.id);
    }

    public onError(event: FileTransferErrorEvent) {
        this.fileStats.state = ProgressBarStates.STATE_ERROR;
        this.errorMessage = event.msg;
        this.progressEvent.emit(ProgressbarParentNotifyType.ERROR);
    }

    public getPercentage(): string {
        return Math.round((this.fileStats.value / this.fileStats.max) * 100) + '%';
    }

    public getFileSizeInMb(): string {
        return getSizeWithType(this.fileStats.max);
    }

    public getFileNameRefactored(): string {

        if (this.fileStats.fileName.length < 25) {
            return this.fileStats.fileName;
        }

        const extension = this.fileStats.fileName.split(/[. ]+/).pop();
        const fileNameLength = this.fileStats.fileName.length;
        const fileName = this.fileStats.fileName.substring(0, fileNameLength - (extension.length + 1));
        const firstPart = fileName.substring(0, 12);
        const secondPart = fileName.substring(fileName.length - 4, fileName.length) + '.' + extension;

        return firstPart + '...' + secondPart;
    }
}

// Private structs .........................................................................................................................
interface ProgressBarStats {
    id?: string;
    max: number;
    value?: number;
    fileName: string;
    state?: ProgressBarStates;
    blob?: Blob;
}

enum ProgressBarStates {
    STATE_IN_INIT = 'INIT',
    STATE_IN_PROGRESS = 'IN_PROGRESS',
    STATE_CANCELED = 'CANCELED',
    STATE_ERROR = 'ERROR',
    STATE_COMPLETED = 'COMPLETED'
}

export enum ProgressbarComponentType {
    SENDING = 'sending',
    RECEIVING = 'receiving',
    UPLOADED = 'uploaded'
}

export enum ProgressbarParentNotifyType {
    COMPLETE = 'COMPLETE',
    CANCELED = 'CANCELED',
    ERROR = 'ERROR',
}
