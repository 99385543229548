import { PaymentInfoTypes } from '../../store/payment-info.model';

export interface PaymentInfoDto {
    paymentInfoId?: number;
    type: PaymentInfoTypes;
    firstName: string;
    lastName: string;
    birthday: string;
    email: string;
    country: {id: number; name: string};
    nationality: {id: number; name: string};
    status: PaymentInfoDtoStatuses;
    companyName: string;
    companyType: string;
}

export enum PaymentInfoDtoStatuses {
    'PROCESSING' = 'PROCESSING',
    'FINISHED' = 'FINISHED',
    'FAILED' = 'FAILED',
}
