import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { JitsiEffects } from './store/jitsi.effects';
import { JitsiMeetComponent } from './components/jitsi-meet/jitsi-meet.component';
import { JitsiConferenceService } from './provider/jitsi-conference.service';
import { JitsiEventService } from './provider/jitsi-event.service';
import { JitsiExternalApiService } from './provider/jitsi-external-api.service';
import { JitsiStateService } from './provider/jitsi-state.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { JitsiSettingsDetectionService } from './provider/jitsi-settings-detection.service';
import { StoreModule } from '@ngrx/store';
import { reducer as jitsiRdeucer, jitsiFeatureKey } from './store/jitsi.reducer';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
    declarations: [
        JitsiMeetComponent,
        PopUpComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        EffectsModule.forFeature([JitsiEffects]),
        StoreModule.forFeature(jitsiFeatureKey, jitsiRdeucer),
        TranslateModule.forChild(),
        UtilsModule
    ],
    providers: [
        JitsiConferenceService,
        JitsiEventService,
        JitsiExternalApiService,
        JitsiStateService,
        JitsiSettingsDetectionService
    ],
    exports: [
        PopUpComponent,
        JitsiMeetComponent
    ]

})
export class JitsiModule {
}
