import moment from 'moment';

export const DEFAULT_START_DATE = '1970-01-01';
export const DEFAULT_START_DATE_TIME = '1970-01-01 00:00:00';

export const DATE_TIME_FORMAT_FRONTEND = 'YYYY-MM-DD\THH:mm:ss';
export const DATE_TIME_FORMAT_EXPORT = 'YYYY-MM-DD HH:mm:ss';

export const TIME_FORMAT_EXPORT = 'HH:mm:ss';

export class TimeHelperService {

    constructor() { }

    public static isToday(dateString: string): boolean {
        const date = new Date(dateString);
        const today = new Date();

        return (date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate());
    }

    // return Timestamp in Seconds
    public static getCurrentTimestamp(): number {
        return moment().unix();
    }

    public static getMomentLocalNow(): moment.Moment {
        return moment();
    }

    public static getCurrentUtcDateTime(): string {
        return moment.utc().format(DATE_TIME_FORMAT_EXPORT);
    }

    public static getMomentDateTime(dateTime: string): moment.Moment {
        return moment(dateTime);
    }

    public static utcToLocalMomentDateTime(utcDateTime: string): moment.Moment {
        return moment.utc(utcDateTime).local();
    }

    public static localToUtcMomentDateTime(localDateTime: string): moment.Moment {
        return moment(localDateTime).utc();
    }

    public static utcToLocalTimeString(utcTime: string): string {
        return moment.utc(utcTime, 'HH:mm:ss').local().format(TIME_FORMAT_EXPORT);
    }

    public static localToUtcTimeString(localTime: string): string {
        return moment(localTime, 'HH:mm:ss').utc().format(TIME_FORMAT_EXPORT);
    }

    public static utcToLocalTimeMoment(utcTime: string): moment.Moment {
        return moment.utc(utcTime, 'HH:mm:ss').local();
    }

    public static localToUtcTimeMoment(localTime: string): moment.Moment {
        return moment(localTime, 'HH:mm:ss').utc();
    }

    public static timeMoment(time: string): moment.Moment {
        return moment(time, 'HH:mm:ss');
    }

    public static getDurationInMinutes(startMoment: moment.Moment, endMoment: moment.Moment): number {
        return Math.abs(endMoment.diff(startMoment, 'minutes'));
    }

    public static secondsToMiliseconds(seconds: number): number {
        return seconds * 1000;
    }
}
