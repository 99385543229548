import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { JitsiExternalApiService } from '../../provider/jitsi-external-api.service';

@Component({
    selector: 'app-jitsi-meet',
    templateUrl: './jitsi-meet.component.html',
    styleUrls: ['./jitsi-meet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitsiMeetComponent implements OnInit {
    @ViewChild('meet', {static: true}) meet: ElementRef;

    constructor(private jitsiExternalApiService: JitsiExternalApiService) {
    }

    public ngOnInit(): void {
        this.jitsiExternalApiService.setParentNode(this.meet);
    }
}
