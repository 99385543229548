import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity/src/models';
import { Order } from './payment-account.model';

export enum PaymentAccountActionTypes {
    LoadPaymentAccounts = '[Order] Load PaymentAccounts',
    AddPaymentAccount = '[Order] Add Order',
    UpsertPaymentAccount = '[Order] Upsert Order',
    AddPaymentAccounts = '[Order] Add PaymentAccounts',
    UpsertPaymentAccounts = '[Order] Upsert PaymentAccounts',
    UpdatePaymentAccount = '[Order] Update Order',
    UpdatePaymentAccounts = '[Order] Update PaymentAccounts',
    DeletePaymentAccount = '[Order] Delete Order',
    DeletePaymentAccounts = '[Order] Delete PaymentAccounts',
    ClearPaymentAccounts = '[Order] Clear PaymentAccounts',
    SetPaymentAccountPayInSum = '[Order] Set SetPaymentAccountPayInSum',
    SetPaymentAccountPaidSum = '[Order] Set SetPaymentAccountPaidSum',
    SetPaymentAccountTimestamp = '[Order] Set SetPaymentAccountTimestamp'

}

export class LoadPaymentAccounts implements Action {
    readonly type = PaymentAccountActionTypes.LoadPaymentAccounts;

    constructor(public payload: { paymentAccounts: Order[] }) {
    }
}

export class AddPaymentAccount implements Action {
    readonly type = PaymentAccountActionTypes.AddPaymentAccount;

    constructor(public payload: { paymentAccount: Order }) {
    }
}

export class UpsertPaymentAccount implements Action {
    readonly type = PaymentAccountActionTypes.UpsertPaymentAccount;

    constructor(public payload: { paymentAccount: Update<Order> }) {
    }
}

export class AddPaymentAccounts implements Action {
    readonly type = PaymentAccountActionTypes.AddPaymentAccounts;

    constructor(public payload: { paymentAccounts: Order[] }) {
    }
}

export class UpsertPaymentAccounts implements Action {
    readonly type = PaymentAccountActionTypes.UpsertPaymentAccounts;

    constructor(public payload: { paymentAccounts: Update<Order>[] }) {
    }
}

export class UpdatePaymentAccount implements Action {
    readonly type = PaymentAccountActionTypes.UpdatePaymentAccount;

    constructor(public payload: { paymentAccount: Update<Order> }) {
    }
}

export class UpdatePaymentAccounts implements Action {
    readonly type = PaymentAccountActionTypes.UpdatePaymentAccounts;

    constructor(public payload: { paymentAccounts: Update<Order>[] }) {
    }
}

export class DeletePaymentAccount implements Action {
    readonly type = PaymentAccountActionTypes.DeletePaymentAccount;

    constructor(public payload: { id: string }) {
    }
}

export class DeletePaymentAccounts implements Action {
    readonly type = PaymentAccountActionTypes.DeletePaymentAccounts;

    constructor(public payload: { ids: string[] }) {
    }
}

export class ClearPaymentAccounts implements Action {
    readonly type = PaymentAccountActionTypes.ClearPaymentAccounts;
}

export class SetPaymentAccountPayInSum implements Action {
    readonly type = PaymentAccountActionTypes.SetPaymentAccountPayInSum;

    constructor(public payload: string) {
    }
}

export class SetPaymentAccountPaidSum implements Action {
    readonly type = PaymentAccountActionTypes.SetPaymentAccountPaidSum;

    constructor(public payload: string) {
    }
}

export class SetPaymentAccountTimestamp implements Action {
    readonly type = PaymentAccountActionTypes.SetPaymentAccountTimestamp;

    constructor(public payload: number) {
    }
}

export type PaymentAccountActions =
    LoadPaymentAccounts
    | AddPaymentAccount
    | UpsertPaymentAccount
    | AddPaymentAccounts
    | UpsertPaymentAccounts
    | UpdatePaymentAccount
    | UpdatePaymentAccounts
    | DeletePaymentAccount
    | DeletePaymentAccounts
    | ClearPaymentAccounts
    | SetPaymentAccountPayInSum
    | SetPaymentAccountPaidSum
    | SetPaymentAccountTimestamp;
