import { Store } from '@ngrx/store';
import { AppState } from '../store/media.state';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MediaService {

    constructor(private store: Store<AppState>) {

    }

    public localStreamIsActive(): Observable<boolean> {
        return this.store.select(state => {
            if (state.media.localStream !== null) {
                return state.media.localStream.active;
            } else {
                return false;
            }
        });
    }

    public microphoneIsActive() {
        return this.store.select(state => {
            if (state.media.microphone !== null) {
                return state.media.microphone.active;
            } else {
                return false;
            }
        });
    }
}
