import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterHelperService {

    constructor(private ngZone: NgZone, private router: Router) {
    }

    public navigate(commands: any[], options = null): Promise<any> {
        if (!options) {
            return this.ngZone.run(() => this.router.navigate(commands));
        } else {
            return this.ngZone.run(() => this.router.navigate(commands, options));
        }
    }
}
