import { BehaviorSubject } from 'rxjs';

export class SoundMeter {

    public soundIsOn = new BehaviorSubject<boolean>(false);

    private audioCtx: AudioContext;
    private analyser: AnalyserNode;
    private distortion: WaveShaperNode;
    private gainNode: GainNode;
    private biquadFilter: BiquadFilterNode;
    private convolver: ConvolverNode;
    private source: AudioNode;

    constructor() {
        const AudioContext = (<any>window).AudioContext || (<any>window).webkitAudioContext;
        this.audioCtx = new AudioContext();
        this.analyser = this.audioCtx.createAnalyser();
        this.analyser.minDecibels = -90;
        this.analyser.maxDecibels = -10;
        this.analyser.smoothingTimeConstant = 0.85;

        this.distortion = this.audioCtx.createWaveShaper();
        this.gainNode = this.audioCtx.createGain();
        this.biquadFilter = this.audioCtx.createBiquadFilter();
        this.convolver = this.audioCtx.createConvolver();
    }

    public connectToAudioSource(audio: HTMLAudioElement) {
        this.source = this.audioCtx.createMediaElementSource(audio);
        this.connectToSource();
    }

    public connectToMediaStreamSource(stream: MediaStream) {
        this.source = this.audioCtx.createMediaStreamSource(stream);
        this.connectToSource();
    }

    private connectToSource() {
        this.source.connect(this.analyser);
        this.analyser.connect(this.distortion);
        this.distortion.connect(this.biquadFilter);
        this.biquadFilter.connect(this.convolver);
        this.convolver.connect(this.gainNode);
        this.gainNode.connect(this.audioCtx.destination);
    }

    public visualize(canvas: HTMLCanvasElement) {
        const canvasCtx: CanvasRenderingContext2D = canvas.getContext('2d');
        this.drawFrequencybars(canvasCtx, canvas.width, canvas.height);
        this.voiceChange('distortion');
    }

    private voiceChange(voiceSetting: 'distortion' | 'biquad' | 'off') {
        this.distortion.oversample = '4x';
        this.biquadFilter.gain.value = 0;
        this.convolver.buffer = undefined;

        if (voiceSetting === 'distortion') {
            this.distortion.curve = this.makeDistortionCurve(400);
        } else if (voiceSetting === 'biquad') {
            this.biquadFilter.type = 'lowshelf';
            this.biquadFilter.frequency.value = 1000;
            this.biquadFilter.gain.value = 25;
        } else if (voiceSetting === 'off') {
            // console.log('Voice settings turned off');
        }
    }

    private makeDistortionCurve(amount: number) {
        const k = typeof amount === 'number' ? amount : 50;
        const n_samples = 44100;
        const curve = new Float32Array(n_samples);
        const deg = Math.PI / 180;

        let i = 0;
        let x;

        for (; i < n_samples; ++i) {
            x = i * 2 / n_samples - 1;
            curve[i] = ( 3 + k ) * x * 20 * deg / ( Math.PI + k * Math.abs(x) );
        }
        return curve;
    }

    private drawFrequencybars(canvasCtx: CanvasRenderingContext2D, WIDTH: number, HEIGHT: number): void {
        this.analyser.fftSize = 256;
        const bufferLengthAlt = this.analyser.frequencyBinCount;
        const dataArrayAlt = new Uint8Array(bufferLengthAlt);

        const analyserAlt = this.analyser;

        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
        let isOn = false;
        const self = this;

        const drawAlt = function() {
            const drawVisual = requestAnimationFrame(drawAlt);
            const barWidth = (WIDTH / bufferLengthAlt) * 2.5;

            analyserAlt.getByteFrequencyData(dataArrayAlt);
            canvasCtx.fillStyle = 'rgb(248, 249, 250)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLengthAlt; i++) {
                barHeight = dataArrayAlt[i];
                canvasCtx.fillStyle = 'rgb(0,123,255)';
                canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);

                x += barWidth + 1;
                if (barHeight > 0) {
                    isOn = true;
                }
            }
            if (isOn) {
                self.soundIsOn.next(true);
            }
        };

        drawAlt();
    }

    public cleanup() {
        if (!! this.source) {
 this.source.disconnect();
}
        if (!! this.analyser) {
 this.analyser.disconnect();
}
        if (!! this.distortion) {
 this.distortion.disconnect();
}
        if (!! this.gainNode) {
 this.gainNode.disconnect();
}
        if (!! this.biquadFilter) {
 this.biquadFilter.disconnect();
}
        if (!! this.convolver) {
 this.convolver.disconnect();
}

        if ( !! this.audioCtx && this.audioCtx.state !== 'closed') {
            this.audioCtx.close();
        }
        this.audioCtx = null;
        this.source = null;
        this.analyser = null;
        this.distortion = null;
        this.gainNode = null;
        this.biquadFilter = null;
        this.convolver = null;
    }
}
