import { filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { UserPresenceActionTypes } from '../../user-presence/store/user-presence.actions';
import { AppointmentService } from '../../appointment/providers/appointment.service';
import { Appointment } from '../../appointment/store/one-time-appointment/appointment.entity';
import { ProfileService } from '../../profile/providers/profile.service';
import { Profile } from '../../profile/store/profile.entity';
import { UserAgentSyncService } from '../providers/user-agent-sync/user-agent-sync.service';
import { Action } from '@ngrx/store';
import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../providers/store/app.action';
import { clearUserAgents } from './user-agent.actions';

@Injectable()
export class UserAgentEffects {
    constructor(
        private actions$: Actions,
        private userAgentSyncService: UserAgentSyncService,
        private appointmentService: AppointmentService,
        private profileService: ProfileService,
    ) {
    }

    // FIXME: Try to get ID of current user profile ID and other participants in another way.
    //  It gives ability to get rid of services that we aren't want to place here.
     sendUserAgent$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(UserPresenceActionTypes.UpsertUserPresence),
        filter((action: any) => action.payload.userPresence.status === 'online'),
        switchMap(() => this.profileService.getCurrentProfileDataObserver().pipe(take(1))),
        mergeMap((profile: Profile) => this.appointmentService.getAllAppointmentsObserver().pipe(
            take(1),
            map((appointments: Appointment[]) => this.getUniqueParticipantsIdFromAppointments(appointments, profile.id)))
        ),
        tap((data) => {
            const participantsId: number[] = data.participantsId;

            participantsId.forEach((participantId: number) =>
                this.userAgentSyncService.sendUserAgentSyncMessage(data.profileId, participantId));
        })
    ), {dispatch: false});

     doReset$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        map(() => clearUserAgents())
    ));

    private getUniqueParticipantsIdFromAppointments(appointments: Appointment[], profileId: number): any {
        const uniqueParticipantsId: Set<number> = new Set(appointments
            .reduce((profileIds: number[], appointment: Appointment) => profileIds.concat(appointment.profileIds), [])
            .filter((id: number) => id !== profileId)
        );

        return {
            profileId,
            participantsId: Array.from(uniqueParticipantsId)
        };
    }
}
