import { XmppMessage } from '../../user-presence/provider/xmpp-message.service';

export const COUNTDOWN_SYNC_MESSAGE_TYPE = 'COUNTDOWN_MESSAGE';

export class CountdownSyncMessage implements XmppMessage {
    type = COUNTDOWN_SYNC_MESSAGE_TYPE;

    constructor(public isStarted: boolean, public running: boolean, public participantId: number) {
    }
}
