<div class="browser-info">
    <div class="incompatible">
        <span>{{ 'LOGIN.BROWSER-INFO.INCOMPATIBLE' | translate}}</span>
        <div class="row">
            <div class="explorer browser-icon " >
                <img src="/assets/img/browser/no_explorer.png" alt="Internet Explorer">
                <span>{{ 'LOGIN.BROWSER-INFO.EXPLORER' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="compatible">
        <span>{{ 'LOGIN.BROWSER-INFO.COMPATIBLE' | translate}}</span>
        <div class="row">
            <a class="browser-icon" href="{{ 'LOGIN.BROWSER-INFO.EDGE-LINK' | translate}}" target="_blank">
                <img src="/assets/img/browser/edge.png" alt="Edge">
                <span>{{ 'LOGIN.BROWSER-INFO.EDGE' | translate}}</span>
            </a>
            <a class="browser-icon" href="{{ 'LOGIN.BROWSER-INFO.CHROME-LINK' | translate}}" target="_blank">
                <img src="/assets/img/browser/Chrome.png" alt="Chrome">
                <span>{{ 'LOGIN.BROWSER-INFO.CHROME' | translate}}</span>
            </a>
            <a class="browser-icon" href="{{ 'LOGIN.BROWSER-INFO.FIREFOX-LINK' | translate}}" target="_blank">
                <img src="/assets/img/browser/firefox.png" alt="Firefox">
                <span>{{ 'LOGIN.BROWSER-INFO.FIREFOX' | translate}}</span>
            </a>
            <a class="browser-icon" href="{{ 'LOGIN.BROWSER-INFO.SAFARI-LINK' | translate}}" target="_blank">
                <img src="/assets/img/browser/safari.png" alt="Safari">
                <span>{{ 'LOGIN.BROWSER-INFO.SAFARI' | translate}}</span>
            </a>
        </div>

    </div>
</div>
