import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { UserPresenceEffects } from './store/user-presence.effects';
import { StoreModule } from '@ngrx/store';
import { reducer as userPresenceReducer, userPresencesFeatureKey } from './store/user-presence.reducer';
import { UserPresenceService } from './provider/user-presence.service';
import { ProfileModule } from '../profile/profile.module';
import { XmppStropheService } from './provider/xmpp-strophe.service';
import { XmppRosterService } from './provider/xmpp-roster.service';
import { XmppPresenceService } from './provider/xmpp-presence.service';
import { UserPresenceStoreService } from './provider/user-presence-store.service';
import { XmppMessageService } from './provider/xmpp-message.service';
import { XmppMessageBuilder } from './xmpp/xmpp.message.builder';
import { XmppLogger } from './xmpp/xmpp.logger';
import { XmppConnectionSetting } from './xmpp/xmpp-connection.setting';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(userPresencesFeatureKey, userPresenceReducer),
        EffectsModule.forFeature([UserPresenceEffects]),
        ProfileModule
    ],
    providers: [
        UserPresenceService,
        XmppStropheService,
        XmppRosterService,
        XmppPresenceService,
        XmppMessageService,
        XmppMessageBuilder,
        XmppLogger,
        XmppConnectionSetting,
        UserPresenceStoreService
    ]
})
export class UserPresenceModule {
}
