<div class="money-overview" [class.no-payout]="!isPayoutPossible() | async">
    <div class="content-wrapper card">
        <span class="amount-sum roboto"><span class="euro">
            {{ (payoutObj$ | async)?.paidSum | beforeDot }},</span><span
            class="cent">{{(payoutObj$ | async)?.paidSum | afterDot}}</span>
            <span class="currency">€</span>
        </span>
        <span class="amount-open roboto"><span>{{ 'PAYMENT.PAYOUT.OUTSTANDING' | translate }} </span>
            <span class="amount">
                {{(payoutObj$ | async)?.payInSum | beforeDot}},{{(payoutObj$ | async)?.payInSum | afterDot }} €</span>
            <span class='fal fa-question-circle'
                  aria-hidden="true"
                  placement="bottom"
                  ngbTooltip="{{ 'PAYMENT.PAYOUT.HELP' |translate }}"></span>
        </span>
        <button
        class="btn btn-success payout-button roboto"
        id="payout-button"
        *ngIf="isPayoutPossible() | async"
        (click)="doPayOut()"
        [disabled]="buttonClicked">
            {{ 'PAYMENT.PAYOUT.WIRE' | translate:'{euro: "'
            + ((payoutObj$ | async)?.paidSum | beforeDot)
            + '", cent:"'
            + ((payoutObj$ | async)?.paidSum | afterDot) + '"}' }}
            <i class="fas fa-arrow-down"></i>
        </button>
        <div class="pay-out-status" >
            <span class="payout-not-finished" *ngIf="bankAccountStatus?.status === STATUS_EMPTY"> {{ 'PAYMENT.PAYOUT.ERROR.NO-BANK-ACCOUNT' |translate }}</span>
            <span class="payout-not-finished" *ngIf="bankAccountStatus?.status === STATUS_PROCESSING"> {{ 'PAYMENT.PAYOUT.ERROR.BANK-ACCOUNT-PROCESSING' |translate }}  </span>
            <span class="payout-not-finished" *ngIf="bankAccountStatus?.status === STATUS_FAILED"> {{ 'PAYMENT.PAYOUT.ERROR.BANK-ACCOUNT-FAILED' |translate }} </span>
            <span class="payout-not-finished" *ngIf="payOutStatus?.status === STATUS_PROCESSING"> {{ 'PAYMENT.PAYOUT.ERROR.PAY-OUT-PROCESSING' |translate }} </span>
            <span class="payout-not-finished" *ngIf="payOutStatus?.status === STATUS_FAILED"> {{ 'PAYMENT.PAYOUT.ERROR.PAY-OUT-FAILED' |translate }} </span>
        </div>
        <span class="timespan roboto">
            {{ requestTimestamp | date:dateTimeFormat }}
            <i class="fas fa-sync-alt" [class.requesting]="isLoading" (click)="reload()"></i>
        </span>
    </div>
</div>
