import { Directive, ElementRef, Input } from '@angular/core';
import { ChromeExtensionService } from '../provider/chrome-extension.service';
import { BrowserService } from '../provider/browser.service';

@Directive({
    selector: '[appChromeInlineInstallation]'
})
export class ChromeInlineInstallationDirective {

    constructor(private el: ElementRef, private chromeExtensionService: ChromeExtensionService, browserService: BrowserService) {
        if (browserService.isChrome() && typeof chromeExtensionService.isChromeExtensionInstalled() !== 'undefined') {
            chromeExtensionService.isChromeExtensionInstalled().subscribe(isInstalled => {
                if (isInstalled) {
                    this.remoteOnClickListener();
                } else {
                    this.addOnClickListener();
                }
            });
        }
    }

    addOnClickListener() {
        this.el.nativeElement.onclick = this.chromeExtensionService.installChromeExtension.bind(this.chromeExtensionService);
    }

    remoteOnClickListener() {
        this.el.nativeElement.onclick = null;

    }
}
