import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'afterDot'
})
export class AfterDotPipe implements PipeTransform {

    transform(val: string): string {
        if (val !== undefined && val !== null) {
            return val.substring(val.indexOf('.') + 1);
        } else {
            return '00';
        }
    }
}
