import {switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {MEDIA_CALL_END, MEDIA_CALL_START} from '../../media/store/media.action';
import {COUNTDOWN_INIT} from '../../countdown/store/countdown.action';
import {SidebarHideAction, SidebarSetRemoteVisibilityAction} from './sidebar.action';
import {Observable, of} from 'rxjs';

@Injectable()
export class SidebarEffects {
    constructor(private actions$: Actions) {
    }

     startCallHideSidebar$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CALL_START),
        switchMap(() => of(new SidebarHideAction(true)))));

     endCallEnlargeSidebar$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CALL_END),
        switchMap(() => of(new SidebarSetRemoteVisibilityAction(false)))));

     startCallCollapseSidebar$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(COUNTDOWN_INIT),
        switchMap(() => of(new SidebarSetRemoteVisibilityAction(true)))));
}
