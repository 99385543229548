import { Injectable } from '@angular/core';
import { PeerService } from './peer.service';
import { Profile, ProfileType } from '../../profile/store/profile.entity';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root'
})
export class FileTransferService {
    isModalOpen$ = new BehaviorSubject(false);

    constructor(private peerService: PeerService) {
    }

    public start(peerProfileId: number, profile: Profile) {
        if (profile.type === ProfileType.DOCTOR) {
            this.peerService.startCall(peerProfileId);
        }
    }

    public closePeerConnection() {
        this.peerService.close();
    }

    public openModal(): void {
        this.isModalOpen$.next(true);
    }

    public closeModal(): void {
        this.isModalOpen$.next(false);
    }
}
