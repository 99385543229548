import { Injectable } from '@angular/core';
import { XmppUser } from '../xmpp/xmpp-user.model';
import { XmppConnectionSetting } from '../xmpp/xmpp-connection.setting';
import { XmppLogger } from '../xmpp/xmpp.logger';
import { XmppPresenceService } from './xmpp-presence.service';
import { XmppRosterService } from './xmpp-roster.service';
import { Profile } from '../../profile/store/profile.entity';
import { XmppMessageService } from './xmpp-message.service';
import { environment } from '../../../../environments/environment';

enum StropheServiceEvent {
    CONNECTED,
    DISCONNECTED
}

@Injectable()
export class XmppStropheService {

    private static DEBUG = false;
    private connected = false;
    private doDisconnecting = false;
    private connection: any;

    constructor(
        private xmppSettings: XmppConnectionSetting,
        private xmppMessage: XmppMessageService,
        private xmppPresence: XmppPresenceService,
        private xmppRoster: XmppRosterService,
        private log: XmppLogger) {
    }

    /**
     * Connection Method
     *
     * @param profileId
     * @param role
     */
    public connect(profile: Profile): void {
        const xmppUser = new XmppUser(profile);

        let connection = new Strophe.Connection(environment.jitsi.bosh);

        this.setUpLogging(connection);

        const logincallback = (status) => {
            if (status === Strophe.Status.REGISTER) {
                this.log.info('Do register!', xmppUser.user);
                connection.register.fields.username = xmppUser.user;
                connection.register.fields.password = xmppUser.pass;
                connection.register.submit();
            } else if (status === Strophe.Status.REGISTERED) {
                this.log.info('Registered!', xmppUser.jId);
                connection.authenticate();
            } else if (status === Strophe.Status.CONFLICT) {
                this.log.warn('Contact already existed!', xmppUser.user);
                connection.authenticate();
            } else if (status === Strophe.Status.NOTACCEPTABLE) {
                this.log.error('Registration not acceptable', xmppUser.user);
                this.connected = false;
            } else if (status === Strophe.Status.REGIFAIL) {
                this.log.error('The Server does not support In-Band Registration', xmppUser.user);
                this.connected = false;
            } else if (status === Strophe.Status.CONNECTING) {
                this.log.info('Strophe is connecting.', xmppUser.user);
            } else if (status === Strophe.Status.CONNFAIL) {
                this.log.error('Strophe failed to connect.', xmppUser.user);
                this.connected = false;
            } else if (status === Strophe.Status.DISCONNECTING) {
                this.doDisconnecting = true;
                this.log.warn('Strophe is disconnecting.', xmppUser.user);
            } else if (status === Strophe.Status.DISCONNECTED) {
                this.log.warn('Strophe is disconnected.', xmppUser.user);
                this.connected = false;
                this.handleDisconnection();
                if (!this.doDisconnecting) {
                    this.log.warn('Strophe is reconnecting.', xmppUser.user);

                    connection = new Strophe.Connection(environment.jitsi.bosh);

                    this.setUpLogging(connection);
                    connection.connect(xmppUser.fullJId, xmppUser.pass, logincallback.bind(this));
                    this.connection = connection;
                }
                this.doDisconnecting = false;
            } else if (status === Strophe.Status.CONNECTED) {
                this.log.info('Strophe is connected.', connection.jid);
                this.connected = true;
                xmppUser.fullJId = connection.jid;
                this.handleConnection(connection, xmppUser);
            } else if (status === Strophe.Status.CONNTIMEOUT) {
                this.log.debug('Strophe is connection timeout.', xmppUser.user);
            } else if (status === Strophe.Status.ATTACHED) {
                this.log.debug('Strophe connection has been attached.', xmppUser.user);
            } else if (status === Strophe.Status.REDIRECT) {
                this.log.debug('Strophe connection has been redirected.', xmppUser.user);
            } else if (status === Strophe.Status.AUTHENTICATING) {
                this.log.debug('Strophe connection is authenticating.', xmppUser.user);
            } else if (status === Strophe.Status.ERROR) {
                this.log.error('Strophe error', xmppUser.user);
            }
        };

        connection.register.connect(this.xmppSettings.DOMAIN, logincallback.bind(this));
        this.connection = connection;
    }

    // private main methods ::::::::::::::::::::::::::::::::::::::::::::::::::::::

    private handleConnection(connection, user: XmppUser): void {
        this.xmppMessage.init(connection, user);
        this.xmppPresence.init(connection, user);
        this.xmppRoster.init(connection, user, this.xmppPresence.onPresence.bind(this.xmppPresence));
    }

    public disconnect() {
        this.connection.disconnect();
    }

    private handleDisconnection(): void {
        this.xmppRoster.destroy();
        this.xmppPresence.destroy();
    }

    /**
     * Setup request logging for debugging
     *
     * @param conn
     */
    private setUpLogging(conn): void {
        conn.rawInput = (data) => {
            if (XmppStropheService.DEBUG === true) {
                this.log.logXml('Response:', data);
            }
        };

        conn.rawOutput = (data) => {
            if (XmppStropheService.DEBUG === true) {
                this.log.logXml('Request:', data);
            }
        };
    }
}
