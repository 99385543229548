import { RtcDetectionStepComponent } from '../rtc-detection-step-component.interface';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from '../rtc-detection.entities';

@Component({
    templateUrl: './step-warning.component.html',
    styleUrls: ['../rtc-detection.component.scss']
})
export class StepWarningComponent implements RtcDetectionStepComponent {
    nextStep: (arg) => void;
    dismissModal: () => void;
    closeModal: () => void;
    cancelStep: () => void;


    constructor(private translate: TranslateService) {
    }

    public again() {
        this.nextStep(StepType.CAM_TEST);
    }
}
