import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownComponent } from './components/countdown.component';
import { StoreModule } from '@ngrx/store';
import { countdownReducer } from './store/countdown.reducer';
import { CountdownEffects } from './store/countdown.effect';
import { EffectsModule } from '@ngrx/effects';
import { CountdownService } from './providers/countdown.service';
import { CountdownSyncService } from './providers/countdown-sync.service';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('countdown', countdownReducer),
        EffectsModule.forFeature([CountdownEffects]),
        TranslateModule.forChild(),
    ],
    providers: [CountdownService],
    declarations: [CountdownComponent],
    exports: [CountdownComponent]
})
export class CountdownModule {
    public static forRoot(): ModuleWithProviders<CountdownModule> {
        return {ngModule: CountdownModule, providers: [CountdownSyncService]};
    }
}
