<div [ngClass]="{'local-icon': type === 'local', 'remote-icon': type === 'remote'}"
     [ngSwitch]="(bandwidthConnection$| async)">
    <div class="icon color-green" *ngSwitchCase="high">
        <i class="fas fa-wifi fa-sm icon-style"></i>
        <div class="tooltip-text">
            <p class="main-text">
                {{'INTERNET-CONNECTION.HIGH' | translate}}
            </p>
            <p class="description" *ngIf="type === 'local'">
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE2' | translate}}
            </p>
            <p class="description" *ngIf="type === 'remote'">
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE2' | translate}}
            </p>
        </div>
    </div>
    <div class="icon color-yellow" *ngSwitchCase="medium">
        <i class="fas fa-wifi fa-sm icon-style"></i>
        <div class="tooltip-text">
            <p class="main-text">
                {{'INTERNET-CONNECTION.MEDIUM' | translate}}
            </p>
            <p class="description" *ngIf="type === 'local'">
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE2' | translate}}
            </p>
            <p class="description" *ngIf="type === 'remote'">
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE2' | translate}}
            </p>
        </div>
    </div>
    <div class="icon color-red" *ngSwitchCase="low">
        <i class="fas fa-wifi fa-sm icon-style"></i>
        <div class="tooltip-text">
            <p class="main-text">
                {{'INTERNET-CONNECTION.LOW' | translate}}
            </p>
            <p class="description" *ngIf="type === 'local'">
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE2' | translate}}
            </p>
            <p class="description" *ngIf="type === 'remote'">
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE2' | translate}}
            </p>
        </div>
    </div>
    <div class="icon color-dark-gray" *ngSwitchCase="noConnection">
        <i class="fas fa-wifi fa-sm icon-style"></i>
        <div class="tooltip-text">
            <p class="main-text">
                {{'INTERNET-CONNECTION.NOCONNECTION' | translate}}
            </p>
            <p class="description" *ngIf="type === 'local'">
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.LOCAL-TEXT-LINE2' | translate}}
            </p>
            <p class="description" *ngIf="type === 'remote'">
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE1' | translate}}<br/>
                {{'INTERNET-CONNECTION.REMOTE-TEXT-LINE2' | translate}}
            </p>
        </div>
    </div>
</div>
