import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Appointment } from '../../store/one-time-appointment/appointment.entity';
import { AppointmentService } from '../../providers/appointment.service';
import { MediaStreamProvider } from '../../../media/provider/media-stream.provider';
import { RtcDetectionService } from '../../../rtc-detection/providers/rtc-detection.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppointmentRequestService } from '../../../appointment-request/providers/appointment-request.service';
import { InstitutionService } from '../../../institution-settings/providers/institution.service';
import {
    APPOINTMENT_OVERVIEW_HISTORY,
    APPOINTMENT_OVERVIEW_REQUESTS,
    APPOINTMENT_OVERVIEW_UPCOMING
} from '../../../../routes';

export enum AppointmentListTabs {
    UPCOMING = 'upcoming',
    REQUESTS = 'requests',
    HISTORY = 'history',
}

@Component({
    selector: 'app-appointment-overview',
    templateUrl: './appointment-overview.component.html',
    styleUrls: ['./appointment-overview.component.scss']
})
export class AppointmentOverviewComponent implements OnInit {

    public isInit$: Observable<boolean>;
    public activeAppointmentListTab = AppointmentListTabs.UPCOMING;

    public appointments$: Observable<Appointment[]>;
    public isRemoteStreamActive$: Observable<boolean>;
    public openRequests$: Observable<number>;
    public hideTabBasedOnInstitution: boolean;

    public UPCOMING: AppointmentListTabs = AppointmentListTabs.UPCOMING;
    public REQUESTS: AppointmentListTabs = AppointmentListTabs.REQUESTS;
    public HISTORY: AppointmentListTabs = AppointmentListTabs.HISTORY;

    public historyLink = APPOINTMENT_OVERVIEW_HISTORY;
    public requestLink = APPOINTMENT_OVERVIEW_REQUESTS;
    public upcomingLink = APPOINTMENT_OVERVIEW_UPCOMING;

    constructor(private appointmentService: AppointmentService,
                private mediaStreamService: MediaStreamProvider,
                private rtcDetectionService: RtcDetectionService,
                private translate: TranslateService,
                private router: Router,
                private institutionService: InstitutionService,
                appointmentRequestService: AppointmentRequestService) {
        this.isInit$ = appointmentService.isInit();
        this.appointments$ = appointmentService.getAllAppointmentsObserver();
        this.openRequests$ = appointmentRequestService.getNumberOfOpenAppointmentRequests();
        this.listForPathChanges();
    }

    ngOnInit() {
        this.isRemoteStreamActive$ = this.mediaStreamService.isRemoteStreamActive();
        // bug-report: ExpressionChangedAfterItHasBeenCheckedError -> setTimeout is a work around
        this.hideTabBasedOnInstitution = this.institutionService.hideTabsBasedOnInstitutionName();
    }

    private listForPathChanges(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveTab(event.url);
            }
        });
    }

    private setActiveTab(url: string): void {
        this.changeActiveTab(this.mapUrlToTab(url));
    }

    private mapUrlToTab(url: string): AppointmentListTabs {
        switch (url) {
            case APPOINTMENT_OVERVIEW_UPCOMING: {
                return AppointmentListTabs.UPCOMING;
            }
            case APPOINTMENT_OVERVIEW_REQUESTS: {
                return AppointmentListTabs.REQUESTS;
            }
            case APPOINTMENT_OVERVIEW_HISTORY: {
                return AppointmentListTabs.HISTORY;
            }
            default: {
                return AppointmentListTabs.UPCOMING;
            }
        }
    }

    public changeActiveTab(tabName: AppointmentListTabs): void {
        this.activeAppointmentListTab = tabName;
    }

    public isActiveTab(tabName: AppointmentListTabs): boolean {
        return this.activeAppointmentListTab === tabName;
    }
}
