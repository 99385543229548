import { AppState as RootAppState } from '../../../providers/store/app.state';
import { TURN_SERVERS } from '../../../providers/turn-config/turn-config';

export interface WebrtcState {
    connectSettings: RTCConfiguration;
}

export class WebrtcInitialState implements WebrtcState {
    public connectSettings = <RTCConfiguration>TURN_SERVERS.default;
}

// Extend App States #######################################################################################################################
export interface AppState extends RootAppState {
    webrtc: WebrtcState;
}
