import { Injectable } from '@angular/core';
import { JitsiState } from '../store/jitsi.reducer';
import { JitsiStateService } from './jitsi-state.service';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JitsiSettingsDetectionService {

    constructor(private stateService: JitsiStateService) {

    }

    public checkSettings() {
        this.stateService.isJitsiDeviceDetectionFinished().pipe(
            take(1),
            filter((isFinished) => !isFinished),
            tap(() => this.stateService.openSettings())
        ).subscribe();
    }
}
