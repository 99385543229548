import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { PaymentAccountService } from './provider/payment-account.service';
import { PaymentAccountStoreService } from './provider/payment-account-store.service';
import * as paymentAccount from './store/payment-account.reducer';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('payment-account', paymentAccount.reducer),
    ],
    providers: [
        PaymentAccountService,
        PaymentAccountStoreService
    ],
    declarations: []
})
export class PaymentAccountModule {
}
