import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilsModule } from '../../utils/utils.module';
import { ProfileTabComponent } from './components/profile-tab/profile-tab.component';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
import { ProfileUpdateComponent } from './components/profile-update/profile-update.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ImageUploadModule } from '../../image-upload/image-upload.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RouterModule,
        ImageUploadModule,
        NgbModule,
        UtilsModule,
        AngularMultiSelectModule
    ],
    declarations: [
        ProfileTabComponent,
        ProfileViewComponent,
        ProfileUpdateComponent
    ],
    exports: [
        ProfileTabComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileSettingsModule {
}
