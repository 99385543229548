import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { Observable, Subscription } from 'rxjs';
import { SoundMeter } from '../../model/sound-meter';
import { MediaMicrophoneStateAction } from '../../store/media.action';

@Component({
    selector: 'app-microphone-sound-meter',
    template: '<canvas #visualizer class="sound-meter" width="458" height="20"></canvas>',
    styleUrls: ['./microphone-sound-meter.component.scss']
})
export class MicrophoneSoundMeterComponent implements OnInit, OnDestroy {

    @ViewChild('visualizer', {static: true}) elementRef: ElementRef;

    private localStreamElement$: Observable<MediaStreamElement>;
    private soundMeter: SoundMeter = null;
    private subscription: Subscription = null;

    constructor(private store: Store<AppState>) {
        this.localStreamElement$ = this.store.select((state: AppState) => state.media.localStream).pipe(filter(e => !!e));
    }

    ngOnInit(): void {
        this.subscription = this.localStreamElement$.subscribe((mediaElement: MediaStreamElement) => {
            if (mediaElement.active) {
                const canvas = this.elementRef.nativeElement;
                this.soundMeter = new SoundMeter();
                this.soundMeter.connectToMediaStreamSource(mediaElement.media.stream);
                this.soundMeter.visualize(canvas);
                this.soundMeter.soundIsOn.pipe(distinctUntilChanged()).subscribe((isOn: boolean) => {
                    if (isOn) {
                        this.store.dispatch(new MediaMicrophoneStateAction(true));
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.soundMeter !== null) {
            this.soundMeter.cleanup();
            this.soundMeter = null;
        }

        if (this.subscription !== null) {
            this.subscription.unsubscribe();
        }
    }
}


