import { Component, Input } from '@angular/core';
import { fastFadeInOut } from '../../../../animations';

@Component({
    selector: 'app-background-transitions',
    templateUrl: './background-transitions.component.html',
    styleUrls: ['./background-transitions.component.scss'],
    animations: [fastFadeInOut]

})
export class BackgroundTransitionsComponent {

    @Input() mouseMoved: boolean;

    constructor() {
    }
}
