import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserAgentService } from './providers/user-agent/user-agent.service';
import { UserAgentSyncService } from './providers/user-agent-sync/user-agent-sync.service';
import { windowProvider, WindowToken } from './tokens/window.token';
import { UserAgentEffects } from './store/user-agent.effect';
import { userAgentsFeatureKey, reducer as userAgentReducer } from './store/user-agent.reducer';
import { UserPresenceModule } from '../user-presence/user-presence.module';

@NgModule({
    imports: [
        UserPresenceModule,
        EffectsModule.forFeature([UserAgentEffects]),
        StoreModule.forFeature(userAgentsFeatureKey, userAgentReducer)
    ],
    providers: [
        UserAgentService,
        UserAgentSyncService,
        {
            provide: WindowToken,
            useFactory: windowProvider
        }
    ]
})

export class UserAgentModule {
}
