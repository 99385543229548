import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SendFormBuilder } from './send-form-builder';

import { getSizeWithType } from '../../../utils/string-helper/bytes';
import { ProgressbarComponentType, ProgressbarParentNotifyType } from '../progressbar/progressbar.component';
import { FileTransmitter } from '../../provider/data-channel/file-transmitter';
import { WebrtcDataChannelService } from '../../provider/data-channel/webrtc-data-channel.service';

export enum SenderContainerState {
    STATE_INIT = 'STATE_INIT',
    STATE_FILE_SELECTED = 'STATE_FILE_SELECTED',
    STATE_UPLOADING = 'STATE_UPLOADING',
    STATE_FILE_SENT = 'STATE_FILE_SENT',
    STATE_SEND_CANCELED = 'STATE_SEND_CANCELED',
    STATE_ERROR = 'STATE_ERROR',
}

@Component({
    selector: 'app-send-container',
    templateUrl: './send-container.component.html',
    styleUrls: ['./send-container.component.scss']
})
export class SendContainerComponent implements OnInit {

    @Output() public itemUploadedEvent = new EventEmitter<FileTransmitter>();

    public SenderContainerState = SenderContainerState;
    public ProgressbarComponentType = ProgressbarComponentType;
    public state;
    private file: File;

    public fileUploaderForm: FormGroup;
    public fileSender: FileTransmitter;

    constructor(
        private formBuilder: FormBuilder,
        private webRtcProvider: WebrtcDataChannelService
    ) {
    }

    ngOnInit() {
        this.fileUploaderForm = SendFormBuilder.build(this.formBuilder);
        this.init();
    }

    private init() {
        this.state = SenderContainerState.STATE_INIT;
    }

    private sendData() {
        this.fileSender = this.webRtcProvider.sendFile(this.file);
        this.state = SenderContainerState.STATE_UPLOADING;
    }

    public send() {
        this.sendData();
    }

    public onChildEvent($event) {
        let timeoutMilliseconds = 2000;

        switch ($event) {
            case ProgressbarParentNotifyType.COMPLETE:
                this.state = SenderContainerState.STATE_FILE_SENT;
                this.itemUploadedEvent.emit(this.fileSender);
                break;
            case ProgressbarParentNotifyType.CANCELED:
                this.state = SenderContainerState.STATE_SEND_CANCELED;
                break;
            case ProgressbarParentNotifyType.ERROR:
                this.state = SenderContainerState.STATE_ERROR;
                timeoutMilliseconds = 5000;
                break;
        }

        setTimeout(() => {
            this.init();
        }, timeoutMilliseconds);
    }

    public clearFile() {
        this.file = null;
        this.init();
    }

    public getFileNameRefactored(): string {

        if (this.file.name.length < 18) {
            return this.file.name;
        }

        const extension = this.file.name.split(/[. ]+/).pop();
        const fileNameLength = this.file.name.length;
        const fileName = this.file.name.substring(0, fileNameLength - (extension.length + 1));
        const firstPart = fileName.substring(0, 7);
        const secondPart = fileName.substring(fileName.length - 4, fileName.length) + '.' + extension;

        return firstPart + '...' + secondPart + ' (' + getSizeWithType(this.file.size) + ')';
    }

    public getFileName(): string {
        return this.file.name;
    }

    public getError(): string {
        return this.fileSender.getError();
    }

    public onFileSelect(event: Event) {
        const input = event.target as HTMLInputElement;
        if (!input.files?.length) {
            this.state = SenderContainerState.STATE_ERROR;
        } else {
            this.state = SenderContainerState.STATE_FILE_SELECTED;
        }

    }
}
