import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'beforeDot'
})
export class BeforeDotPipe implements PipeTransform {

    transform(val: string): string {
        if (val !== undefined && val !== null) {
            return val.substring(0, val.indexOf('.'));
        } else {
            return '0';
        }
    }
}
