import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../forms/validators/custom-validator';

export class StepAccountFormBuilder {
    public static build(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            email: ['', Validators.compose([Validators.required, CustomValidators.email()])],
            confirmEmail: ['', Validators.compose([Validators.required, CustomValidators.confirm('email')])],
            password: ['', Validators.compose([
                Validators.required, Validators.minLength(8), Validators.maxLength(32), CustomValidators.password()
            ])],
            confirmPassword: ['', Validators.compose([Validators.required, CustomValidators.confirm('password')])],
            newsletter: [null],
            terms: [null, Validators.compose([Validators.requiredTrue])]
        });
    }
}
