import { tap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileService } from '../../../../profile/providers/profile.service';
import { Profile } from '../../../../profile/store/profile.entity';
import { environment } from '../../../../../../environments/environment';

export enum ProfileSettingState {
    PROFILE_SETTINGS_VIEW = 'profileSettingsView',
    PROFILE_SETTINGS_UPDATE = 'profileSettingsUpdate',
}

@Component({
    selector: 'app-profile-tab',
    templateUrl: './profile-tab.component.html',
    styleUrls: ['./profile-tab.component.scss', '../../../tabs.scss']
})
export class ProfileTabComponent {

    public publicProfileDomain: string;
    public profile: Profile;
    public profileObserver$: Observable<Profile>;

    public currentState = ProfileSettingState.PROFILE_SETTINGS_VIEW;

    public viewState = ProfileSettingState.PROFILE_SETTINGS_VIEW;
    public updateState = ProfileSettingState.PROFILE_SETTINGS_UPDATE;

    public imageUploadIsOpen = false;

    constructor(private profileService: ProfileService) {
        this.publicProfileDomain = environment.publicProfileEndpoint;
        this.profileObserver$ = this.profileService.getCurrentProfileDataObserver().pipe(tap((profile) => {
            if (!!profile) {
                this.profile = profile;
            }
        }));
    }

    profileSettingsStateEmitterHandler(state: ProfileSettingState) {
        this.currentState = state;
    }

    goToEdit() {
        this.currentState = ProfileSettingState.PROFILE_SETTINGS_UPDATE;
    }

    showImageUpload() {
        this.imageUploadIsOpen = true;
    }

    hideImageUpload() {
        this.imageUploadIsOpen = false;
    }
}
