import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '../store/rtc-detection.state';
import { Store } from '@ngrx/store';
import { ModalOverlayComponentAction } from '../../modal/store/modal.action';
import { RtcDetectionComponent } from '../rtc-detection.component';
import { ModalStyle } from '../../modal/store/modal.state';
import { Observable } from 'rxjs';
import { UserPresenceService } from '../../user-presence/provider/user-presence.service';

@Injectable()
export class RtcDetectionService {
    private isFinished: boolean;
    private isDetectionOff = false; // @FIXME this is a workaround in mobile case we dont waiting

    constructor(
        private store: Store<AppState>,
        private userPresenceService: UserPresenceService
    ) {
        this.isRtcDetectionFinished().subscribe(isFinished => {
            this.isFinished = isFinished;
            if (isFinished && !this.isDetectionOff) {
                userPresenceService.isDeviceDetected(true);
                userPresenceService.setReady();
            }
        });
    }

    public isRtcDetectionFinished(): Observable<boolean> {
        return this.store.select(s => s.rtcDetection.isFinished).pipe(distinctUntilChanged());
    }

    public startDetection() {
        if (!this.isFinished && !this.isDetectionOff) {
            this.store.dispatch(new ModalOverlayComponentAction(RtcDetectionComponent, ModalStyle.DARK));
        }
    }

    public skipDetection() {
        this.isDetectionOff = true;
    }
}
