import { CountdownInitialState, CountdownState } from './countdown.state';
import {
    COUNTDOWN_FINISHED,
    COUNTDOWN_INIT,
    COUNTDOWN_NEW_SYNC_VALUE,
    COUNTDOWN_NEW_VALUE,
    COUNTDOWN_STOP,
    CountdownAction
} from './countdown.action';

export function countdownReducer(state: CountdownState = new CountdownInitialState(), action: CountdownAction): CountdownState {
    switch (action.type) {
        case COUNTDOWN_INIT: {
            return {
                ...state,
                running: true,
                participantId: action.payload
            };
        }
        case COUNTDOWN_NEW_VALUE: {
            return {
                ...state,
                value: action.payload.value
            };
        }
        case COUNTDOWN_NEW_SYNC_VALUE: {
            return {
                ...state,
                running: action.payload.running
            };
        }
        case COUNTDOWN_STOP: {
            return {
                ...state,
                isStopped: true
            };
        }
        case COUNTDOWN_FINISHED: {
            return new CountdownInitialState();
        }
        default: {
            return state;
        }
    }
}
