import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ModalCarrierService {

    constructor(public activeModal: NgbActiveModal) {
    }

    public getActiveModal(): NgbActiveModal {
        return this.activeModal;
    }

    public setActiveModal(modal: NgbActiveModal) {
        this.activeModal = modal;
    }
}
