<ng-template #content let-modal>
    <div class="modal-header header-modal">
        <img class="icon-header" src="assets/img/general/file-transfer-white.svg" alt="">
        <h4 class="modal-title weight-600">{{'FILE_TRANSFER.OVERLAY.TITLE' | translate}}</h4>
        <i class="fa fa-window-minimize close minimizeIcon-size" (click)="closeModal()"></i>
    </div>
    <div class="modal-body padding-body">
        <section>
            <app-send-container (itemUploadedEvent)="onItemUploaded($event)"></app-send-container>
            <app-receive-container [receiverList]="receiverList"
                                   [downloadedIdList]="downloadedIdList"
                                   (itemDownloadedEvent)="onItemDownloaded($event)"></app-receive-container>
        </section>
    </div>
</ng-template>
