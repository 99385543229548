import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../models/languages';
import { Store } from '@ngrx/store';
import { AppState } from '../store/translate.state';
import { Observable } from 'rxjs';
import { selectTranslateFeatureDefaultLanguage } from '../store/translate.selector';
import { ChangeLanguageAction } from '../store/translate.action';
import { take } from 'rxjs/operators';

export const CookieTranslationKey = 'patientus_lang';

@Injectable({
    providedIn: 'root'
})
export class CustomTranslateService {

    constructor(
        private translate: TranslateService,
        private store: Store<AppState>
    ) {
    }

    initTranslate(): void {
        this.getLangFromStore().pipe(take(1))
            .subscribe((language: Languages) => {
                this.translate.setDefaultLang(language);
                this.setLangToStore(language);
        });
    }

    changeLang(language: Languages): void {
        this.translate.use(language);
        this.setLangToStore(language);
    }

    getLangFromStore(): Observable<Languages> {
        return this.store.select(selectTranslateFeatureDefaultLanguage);
    }

    private setLangToStore(language: Languages): void {
        this.store.dispatch(new ChangeLanguageAction(language));
    }
}
