export const environment = {
    production: true,
    debug: false,
    logInfo: false,
    credentialAuthEndpoint: 'api/authentication-service/auth/account',
    tanAuthEndpoint: 'api/authentication-service/auth/tan',
    authLogoutEndpoint: 'api/authentication-service/auth/logout',
    graphQLEndpoint: 'api/graphql-service/graphql',
    currentProfileDataEndpoint: 'api/profile-service/profile',
    profileListEndpoint: 'api/profile-service/profile/',
    multiUserProfileListEndpoint: '/api/profile-service/v2/profile',
    appointmentsEndpoint: 'api/payment-service/appointment',
    appointmentsV2Endpoint: 'api/payment-service/v2/appointment',
    profileUpdateEndpoint: 'api/profile-service/profile',
    profileImageUpdateEndpoint: 'api/open-api-service/doctor/profile-image',
    profileImageUpdateUserPermissionEndpoint: 'api/profile-service/profile/image-url',
    getAppointmentRequestsEndpoint: 'api/payment-service/appointment-request/open',
    putAppointmentRequestRejectionEndpoint: 'api/payment-service/appointment-request/reject',
    putAppointmentRequestAcceptEndpoint: 'api/payment-service/appointment-request/accept',
    appointmentsProductsEndpoint: '/api/payment-service/product',
    consultationHourEndpoint: '/api/payment-service/consultation-hour',
    conferenceListEndpoint: 'api/payment-service/conference',
    conferenceTokenEndpoint: 'api/payment-service/conference/token',
    conferenceOnlineTokenEndpoint: 'api/payment-service/conference/online-token',
    webSocketUrl: 'wss://peerserver.patientus.de',
    createTanEndpoint: 'api/profile-service/tan',
    createMultiUserTanEndpoint: 'api/open-api-service/appointment',
    createTanConferenceEndpoint: 'api/payment-service/conference',
    createTanAppointmentEndpoint: 'api/payment-service/appointment',
    emailEndPoint: 'api/payment-service/technology/mail/send',
    emailEndPointOneTimeAppointment: 'api/payment-service/technology/mail/send/one-time-appointment',
    publicProfileEndpoint: '',
    termAcceptDataProtection: 'api/profile-service/term/data-process',
    passwordRecoveryValidateTokenEndpoint: 'api/profile-service/public/password-recovery/validate-token',
    logAppointmentCreated: '/api/payment-service/log/appointment/created',
    logAppointmentStarted: '/api/payment-service/log/appointment/started',
    logAppointmentCountdownStarted: '/api/payment-service/log/appointment/countdown-started',
    logAppointmentCountdownFinished: '/api/payment-service/log/appointment/countdown-finished',
    logAppointmentVideoConnectionEstablished: '/api/payment-service/log/appointment/connection-established',
    logAppointmentHangup: '/api/payment-service/log/appointment/hangup',
    logAppointmentQuit: '/api/payment-service/log/appointment/quit',
    logAppointmentVideoConnectionNotEstablished: '/api/payment-service/log/appointment/connection-not-established',
    logAppointmentCanceled: '/api/payment-service/log/appointment/canceled',
    logAppointmentNoShow: '/api/payment-service/log/appointment/no-show',
    logAppointmentVideoConnectionTimedOut: '/api/payment-service/log/appointment/connection-timed-out',
    paymentInfoEndpoint: '/api/payment-service/payment/payment-info',
    paymentInfoStatusEndpoint: '/api/payment-service/public/payment/payment-info/${paymentInfoHash}/status',
    paymentGetAllCountriesEndpoint: '/api/payment-service/country',
    paymentGetOrdersStatusPayIn: '/api/payment-service/payment/order/status/pay-in',
    paymentGetOrdersStatusPaid: '/api/payment-service/payment/order/status/paid',
    paymentBankAccountEndpoint: '/api/payment-service/payment/bank-account',
    mangopayBankAccountStatusEndpoint: '/api/payment-service/payment/bank-account/status',
    mangopayPayOutStatusEndpoint: '/api/payment-service/payment/pay-out/status',
    createPayOutEndpoint: '/api/payment-service/payment/pay-out',
    webrtcLoggingEndpoint: '/api/logging-service/webrtc/connection/stats',
    clientSideLoggingEndpoint: '/api/logging-service/web/message',
    chromeExtensionId: 'onlgpihoakknioeppcehlhppfflcbieo',
    getAllSpecialitiesEndpoint: 'api/profile-service/speciality',
    getSurveyEndpoint: 'api/profile-service/survey',
    ovsSessionEndpoint: 'api/open-api-service/ovs-session/conference',
    featureToggles: {},
    userlane: {
        userId: 32584
    },
    jitsi: {
        domain: 'xmpp-online.patientus.de',
        muc: 'xmpp-online.patientus.de',
        bosh: 'https://xmpp-testing-1.patientus.de/http-bind',
        appId: 'patientus',
        // The name of client node advertised in XEP-0115 'c' stanza
        clientNode: 'http://patientus.de/jitsimeet',
        enableUserRolesBasedOnToken: true,
        externalApi: {
            iframePrefix: '/video',
            noSSL: false
        }
    },
    appConfigEndpoint: './assets/config/app-config.json',
    ovsProvider: 'vonage'
};

