import { Injectable } from '@angular/core';
import { OrderDto, PaymentAccountDto } from './payment-account-dto.model';
import { Order, OrderStatus, PaymentAccount } from '../../store/payment-account.model';
import { TimeHelperService } from '../../../../modules/utils/time-helpers/time-helper.service';

@Injectable()
export class PaymentAccountMapperService {

    constructor() {
    }

    public static mapPayInDtoToEntity(dto: PaymentAccountDto): PaymentAccount {
        if (!dto) {
            return null;
        }
        const entity = {
            ...dto,
            statusSums: {
                payInSum: String(dto.sum)
            },
            orders: dto.orders.map(PaymentAccountMapperService.mapOrderDtoToOrder),
            timestamp: Number(dto.timestamp)
        };
        delete entity.sum;

        return entity;
    }

    public static mapPaidDtoToEntity(dto: PaymentAccountDto): PaymentAccount {
        if (!dto) {
            return null;
        }
        const entity = {
            ...dto,
            statusSums: {
                paidSum: String(dto.sum)
            },
            orders: dto.orders.map(PaymentAccountMapperService.mapOrderDtoToOrder),
            timestamp: Number(dto.timestamp)
        };
        delete entity.sum;

        return entity;
    }

    public static mapOrderDtoToOrder(dto: OrderDto): Order {
        if (!dto) {
            return null;
        }
        const entity = {
            ...dto,
            id: String(dto.appointmentOrderId),
            appointmentStartDatetime: TimeHelperService.utcToLocalMomentDateTime(dto.appointmentStartDatetime),
            status: OrderStatus[dto.status],
            amount: String(dto.amount)
        };

        delete entity.appointmentOrderId;

        return entity;
    }
}
