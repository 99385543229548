import { TranslateState } from './translate.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const translateFeatureKey = 'translate';

export const selectTranslateFeature = createFeatureSelector<TranslateState>(translateFeatureKey);

export const selectTranslateFeatureDefaultLanguage = createSelector(
    selectTranslateFeature,
    (state: TranslateState) => state.defaultLanguage
);
