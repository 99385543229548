import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { JitsiStateService } from '../../provider/jitsi-state.service';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Languages } from '../../../../providers/translate/models/languages';
import { CustomTranslateService } from '../../../../providers/translate/services/custom-translate.service';

@Component({
    selector: 'app-jitsi-settings-pop-up',
    templateUrl: './pop-up.component.html',
    styleUrls: ['./pop-up.component.scss']
})

export class PopUpComponent implements OnInit, OnDestroy {
    @ViewChild('content') popupContentRef: ElementRef;
    @Input()
    buttonType: 'none' | 'button' | 'small-button' | 'link' = 'button';
    deviceTestIframeUrl$: Observable<string>;

    private modal: NgbModalRef;
    private isModalOpen = false;
    private checkIfModalIsOpened = false;
    private readonly openSettingsEvent: Subscription;

    constructor(
        public translate: TranslateService,
        private modalService: NgbModal,
        private stateService: JitsiStateService,
        private customTranslateService: CustomTranslateService
    ) {
        this.openSettingsEvent = this.stateService.openSettingsEvenTriggered().pipe(
            tap(() => this.open(this.popupContentRef))
        ).subscribe();
    }

    ngOnInit() {
        this.deviceTestIframeUrl$ = this.customTranslateService.getLangFromStore().pipe(
            map((language: Languages) => this.getDeviceTestIframeUrl(language))
        );

        window.addEventListener('message', (e: any) => {
            if (e.data === 'close' || e.message === 'close') {
                this.closePopUp('');
            }

        }, false);
    }

    open(content) {
        if (this.checkIfModalIsOpened === false) {
            this.modal = this.modalService.open(content, {
                windowClass: 'deviceSetting-modal',
                backdrop: 'static'
            });
            this.isModalOpen = true;
            this.checkIfModalIsOpened = true;
        }
    }

    closePopUp(reason: string) {
        this.modal.close(reason);
        this.stateService.setSettingsFinish();
        this.isModalOpen = false;
        this.checkIfModalIsOpened = false;
    }

    ngOnDestroy(): void {
        if (this.openSettingsEvent !== null ) {
            this.openSettingsEvent.unsubscribe();
        }
    }

    private getDeviceTestIframeUrl(language: Languages): string {
        return `/assets/device-test/index.html?lang=${(language)}`;
    }
}
