import { Injectable } from '@angular/core';
import { JitsiIframeData, JitsiIframeDataInterface } from './jitsi-iframe-data';

@Injectable({
    providedIn: 'root'
})
export class JitsiIframeDataService {

    private data: JitsiIframeData;

    constructor() {
    }

    public init() {
        this.data = new JitsiIframeData();
        this.set();
    }

    public update(data: JitsiIframeDataInterface) {
        Object.keys(data).map(key => this.data[key] = data[key]);
        this.set();
    }

    private set() {
        (window as any).jameda = this.data;
    }
}
