import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlineStateEvent, OnlineStateConnector } from './connectors/online-state.connector';

@Injectable()
export class OnlineUserStateService {

    constructor(private webSocket: OnlineStateConnector) {
    }

    public isUserOnline(token: string): Observable<OnlineStateEvent> {
        return this.webSocket.isUserOnline(token);
    }

    public getCountOnlinePeers(): Observable<any> {
        return this.webSocket.getCountOnlinePeers();
    }

    public setReady(isReady: boolean) {
        this.webSocket.setReady(isReady);
    }
}
