import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { UserPresence } from './user-presence.model';

export enum UserPresenceActionTypes {
  LoadUserPresences = '[UserPresence] Load UserPresences',
  AddUserPresence = '[UserPresence] Add UserPresence',
  UpsertUserPresence = '[UserPresence] Upsert UserPresence',
  AddUserPresences = '[UserPresence] Add UserPresences',
  UpsertUserPresences = '[UserPresence] Upsert UserPresences',
  UpdateUserPresence = '[UserPresence] Update UserPresence',
  UpdateUserPresences = '[UserPresence] Update UserPresences',
  DeleteUserPresence = '[UserPresence] Delete UserPresence',
  DeleteUserPresences = '[UserPresence] Delete UserPresences',
  ClearUserPresences = '[UserPresence] Clear UserPresences'
}

export class LoadUserPresences implements Action {
  readonly type = UserPresenceActionTypes.LoadUserPresences;

  constructor(public payload: { userPresences: UserPresence[] }) {}
}

export class AddUserPresence implements Action {
  readonly type = UserPresenceActionTypes.AddUserPresence;

  constructor(public payload: { userPresence: UserPresence }) {}
}

export class UpsertUserPresence implements Action {
  readonly type = UserPresenceActionTypes.UpsertUserPresence;

  constructor(public payload: { userPresence: UserPresence }) {}
}

export class AddUserPresences implements Action {
  readonly type = UserPresenceActionTypes.AddUserPresences;

  constructor(public payload: { userPresences: UserPresence[] }) {}
}

export class UpsertUserPresences implements Action {
  readonly type = UserPresenceActionTypes.UpsertUserPresences;

  constructor(public payload: { userPresences: UserPresence[] }) {}
}

export class UpdateUserPresence implements Action {
  readonly type = UserPresenceActionTypes.UpdateUserPresence;

  constructor(public payload: { userPresence: Update<UserPresence> }) {}
}

export class UpdateUserPresences implements Action {
  readonly type = UserPresenceActionTypes.UpdateUserPresences;

  constructor(public payload: { userPresences: Update<UserPresence>[] }) {}
}

export class DeleteUserPresence implements Action {
  readonly type = UserPresenceActionTypes.DeleteUserPresence;

  constructor(public payload: { id: number }) {}
}

export class DeleteUserPresences implements Action {
  readonly type = UserPresenceActionTypes.DeleteUserPresences;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearUserPresences implements Action {
  readonly type = UserPresenceActionTypes.ClearUserPresences;
}

export type UserPresenceActions =
 LoadUserPresences
 | AddUserPresence
 | UpsertUserPresence
 | AddUserPresences
 | UpsertUserPresences
 | UpdateUserPresence
 | UpdateUserPresences
 | DeleteUserPresence
 | DeleteUserPresences
 | ClearUserPresences;
