import { Injectable } from '@angular/core';
import Timer = NodeJS.Timer;

@Injectable()
export class SchedulerFactory {
    public create() {
        return new SchedulerService();
    }
}

export class SchedulerService {

    private intervalTimer: Timer = undefined;
    private listeners = new Map(); // { [key: string]: SchedulerListener; }

    constructor() {
    }

    public static create() {
        return new SchedulerService();
    }

    public start(pc: RTCPeerConnection, interval = 5000) {
        this.startSchedule();
        this.intervalTimer = setInterval(() => {
            this.doSchedule(pc);
        }, interval);
    }

    public stop() {
        if (this.intervalTimer !== undefined) {
            clearInterval(this.intervalTimer);
            this.intervalTimer = undefined;
            this.stopSchedule();
        }
    }

    private doSchedule(pc: RTCPeerConnection) {
        this.listeners.forEach((listener: SchedulerListener, key: string) => {
            listener.doSchedule(pc);
        });
    }

    private startSchedule() {
        this.listeners.forEach((listener: SchedulerListener, key: string) => {
            listener.startSchedule();
        });
    }

    private stopSchedule() {
        this.listeners.forEach((listener: SchedulerListener, key: string) => {
            listener.stopSchedule();
        });
    }

    public register(key: string, listener: SchedulerListener): void {
        this.listeners.set(key, listener);
    }
}


export interface SchedulerListener {
    doSchedule(pc: RTCPeerConnection): void;

    startSchedule(): void;

    stopSchedule(): void;
}
