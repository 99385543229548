import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PaymentInfo } from './payment-info.model';
import { PaymentInfoActions, PaymentInfoActionTypes } from './payment-info.actions';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity/src/models';

export type PaymentInfoState = EntityState<PaymentInfo>;

export const adapter: EntityAdapter<PaymentInfo> = createEntityAdapter<PaymentInfo>();

export const initialState: PaymentInfoState = adapter.getInitialState({
});

export function reducer(state = initialState, action: PaymentInfoActions): PaymentInfoState {
    switch (action.type) {
        case PaymentInfoActionTypes.AddPaymentInfo: {
            return adapter.addOne(action.payload.paymentInfo, state);
        }

        case PaymentInfoActionTypes.AddPaymentInfos: {
            return adapter.addMany(action.payload.paymentInfos, state);
        }

        case PaymentInfoActionTypes.UpdatePaymentInfo: {
            return adapter.updateOne(action.payload.paymentInfo, state);
        }

        case PaymentInfoActionTypes.UpdatePaymentInfos: {
            return adapter.updateMany(action.payload.paymentInfos, state);
        }

        case PaymentInfoActionTypes.DeletePaymentInfo: {
            return adapter.removeOne(action.payload.id, state);
        }

        case PaymentInfoActionTypes.DeletePaymentInfos: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case PaymentInfoActionTypes.LoadPaymentInfos: {
            return adapter.setAll(action.payload.paymentInfos, state);
        }

        case PaymentInfoActionTypes.ClearPaymentInfos: {
            return adapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}

export const selectPaymentInfoState = createFeatureSelector<PaymentInfoState>('payment-info');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors(selectPaymentInfoState);

export const selectPaymentInfo = createSelector(selectEntities, (allEntities: Dictionary<PaymentInfo>) => {

        if (!allEntities || Object.keys(allEntities).length !== 1) {
            return null;
        }
        const key = Object.keys(allEntities)[0];

        return allEntities[key];
    }
);
