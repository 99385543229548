import { Component } from '@angular/core';

@Component({
  selector: 'app-canceled-table',
  templateUrl: './canceled-table.component.html',
  styleUrls: ['./canceled-table.component.scss']
})
export class CanceledTableComponent {

  constructor() { }

}
