<app-payout-info></app-payout-info>

<div *ngIf="paymentHistory.length === 0">
    <div class="green-beam" *ngIf="!paymentAlreadyActive">
        <div class="content-wrapper">
            <img src="/assets/img/payments/wallet.svg" alt="">
            <a [routerLink]="[PAYMENT_ROUTE_INFO]" class="btn">{{ 'PAYMENT.ACCOUNT.CTA-INSERT-INFO' |translate }}</a>
        </div>
    </div>
    <div class="info-space content-wrapper">
        <h2>{{ 'PAYMENT.ACCOUNT.NO-PAYMENT-HISTORY' | translate }}</h2>
        <ul>
            <li *ngIf="!isPaymentActive()">{{ 'PAYMENT.ACCOUNT.NO-PAYMENT-INFO.1' | translate }}
                <a id="no-payment-details"
                   [routerLink]="[PAYMENT_ROUTE_INFO]">{{ 'PAYMENT.ACCOUNT.NO-PAYMENT-INFO.1-LINK' | translate }}</a>
            </li>
            <li>{{ 'PAYMENT.ACCOUNT.NO-PAYMENT-INFO.2' | translate }}
                <a [routerLink]="[APPOINTMENT_CREATE_CONSULTATIONHOURS]">
                    {{ 'PAYMENT.ACCOUNT.NO-PAYMENT-INFO.2-LINK' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="paymentAlreadyActive && (paymentHistory.length > 0)">
    <div class="content-wrapper page-header">
        <h1><i class="fal fa-wallet"></i> {{ 'PAYMENT.ACCOUNT.TITLE' | translate }}</h1>
        <p class="sub-title">{{ 'PAYMENT.ACCOUNT.SUB-TITLE' | translate }}</p>
    </div>
    <div class="content-wrapper card">
        <div class="payment-overview-navigation">
            <a [routerLink]="[DISPOSED_LINK]" [class.active]="isActiveTab(DISPOSED_LINK)">
                {{ 'PAYMENT.ACCOUNT.DISPOSED.TITLE' |translate }}
            </a>
            | <a [routerLink]="[OUSTANDING_LINK]" [class.active]="isActiveTab(OUSTANDING_LINK)">
            {{ 'PAYMENT.ACCOUNT.OUTSTANDING.TITLE' |translate }}
        </a>
            | <a [routerLink]="[CANCELED_LINK]" [class.active]="isActiveTab(CANCELED_LINK)">
            {{ 'PAYMENT.ACCOUNT.CANCELED.TITLE' |translate }}
        </a>
            | <a [routerLink]="[PAID_LINK]" [class.active]="isActiveTab(PAID_LINK)">
            {{ 'PAYMENT.ACCOUNT.PAID.TITLE' |translate }}
        </a>
        </div>
        <div class="payment-table">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
