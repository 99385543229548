import { AppState  as RootAppState } from '../../../providers/store/app.state';
import { Media } from '../model/media-video.model';


export interface MediaStreamElement {
    mute: boolean;
    play: boolean;
    active: boolean;
    type: 'video' | 'screen_share';
    media: Media;
}

export interface Microphone {
    active: boolean;
}

export interface MediaState {
    localStream: MediaStreamElement;
    remoteStream: MediaStreamElement;
    localScreenStream?: MediaStreamElement;
    remoteScreenStream?: MediaStreamElement;
    microphone: Microphone;
    mainVideoFullsized: boolean;
}

export class MediaInitialState implements MediaState {
    localStream: MediaStreamElement = {mute: false, play: false, active: false, type: 'video', media: null};
    remoteStream: MediaStreamElement = {mute: false, play: false, active: false, type: 'video', media: null};
    localScreenStream: MediaStreamElement = {mute: false, play: false, active: false, type: 'screen_share', media: null};
    remoteScreenStream: MediaStreamElement = {mute: false, play: false, active: false, type: 'screen_share', media: null};
    microphone: Microphone = {active: false};
    mainVideoFullsized: false;
}

// Extend App States #######################################################################################################################
export interface AppState extends RootAppState {
    media: MediaState;
}
