import { Languages } from '../models/languages';
import { Action } from '@ngrx/store';

export interface TranslateActionInterface extends Action {
    type: string;
    language?: Languages;
}

export class ChangeLanguageAction implements TranslateActionInterface {
    type = TRANSLATE_CHANGE_LANGUAGE;

    constructor(public language: Languages) {
    }
}

export const TRANSLATE_CHANGE_LANGUAGE = 'TRANSLATE_CHANGE_LANGUAGE';

