import { UserPresenceStatus } from '../user-presence-status';
import { XmppPresenceStatus } from '../xmpp/xmpp-presence-type.model';


export class UserPresence {
    id: number; // profileId
    status: UserPresenceStatus;
    resource: string[]; // multiple session possible

    static createNewUserPresence(profileId: number, status: UserPresenceStatus, resourceString): UserPresence {
        const resource = [];
        if (resourceString !== null) {
            resource.push(resourceString);
        }
        return {id: profileId, status, resource} as UserPresence;
    }

    static mapUserPresenceStatusToPresenceStatus(userStatus: UserPresenceStatus): XmppPresenceStatus {
        let status: XmppPresenceStatus;

        switch (userStatus) {
            case UserPresenceStatus.DND: {
                status = XmppPresenceStatus.DND;
                break;
            }
            case UserPresenceStatus.AWAY: {
                status = XmppPresenceStatus.AWAY;
                break;
            }
            case UserPresenceStatus.ONLINE: {
                status = XmppPresenceStatus.CHAT;
                break;
            }
            default: {
                status = XmppPresenceStatus.AWAY;
            }
        }
        return status;
    }

    // static removeResourceStringIfExists(presence: UserPresence, resource: string): UserPresence {
    //     const newUserPresence = {...presence};
    //     if (UserPresenceUtil.hasResourceString(presence, resource)) {
    //         newUserPresence.resource = presence.resource.filter(element => element !== resource);
    //     }
    //     return newUserPresence;
    // }
    // static addResourceStringIfNotExists(presence: UserPresence, resource: string): UserPresence {
    //     const newUserPresence = {...presence};
    //     if (!UserPresenceUtil.hasResourceString(presence, resource)) {
    //         newUserPresence.resource.push(resource);
    //     }
    //     return newUserPresence;
    // }
    // static hasResourceString(presence: UserPresence, resource: string): boolean {
    //     return presence.resource.some(element => element === resource);
    // }


}
