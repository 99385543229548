import { Action } from '@ngrx/store';
import { SignalInitialState, SignalState } from './signal.state';
import Timer = NodeJS.Timer;

// session .................................................................................................................................
export const SIGNAL_SET_TOKENS = 'SIGNAL_SET_TOKENS';
export const SIGNAL_CLEAN_SESSION = 'SIGNAL_CLEAN_SESSION';
export const SIGNAL_ACTIVATE_SESSION = 'SIGNAL_ACTIVATE_SESSION';
export const SIGNAL_ACTIVATE_SESSION_SUCCESS = 'SIGNAL_ACTIVATE_SESSION_SUCCESS';

// online state ............................................................................................................................
export const SIGNAL_START_OBSERVE_USER = 'SIGNAL_START_OBSERVE_USER';
export const SIGNAL_START_OBSERVE_USER_SUCCESS = 'SIGNAL_START_OBSERVE_USER_SUCCESS';
export const SIGNAL_STOP_OBSERVE_USER = 'SIGNAL_STOP_OBSERVE_USER';
export const SIGNAL_STOP_OBSERVE_USER_SUCCESS = 'SIGNAL_STOP_OBSERVE_USER_SUCCESS';

// connection ready state ..................................................................................................................
export const SIGNAL_LOCAL_CONNECTION_READY_CHANGED = 'SIGNAL_LOCAL_CONNECTION_READY_CHANGED';
export const SIGNAL_REMOTE_CONNECTION_READY_CHANGED = 'SIGNAL_REMOTE_CONNECTION_READY_CHANGED';

export interface SignalAction extends Action {
    payload: SignalState ;
}

// Session :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export class SignalSetTokensAction implements SignalAction {
    public type = SIGNAL_SET_TOKENS;
    public payload: SignalState = new SignalInitialState();

    constructor(myTokens: string[], userTokens: string[]) {
        this.payload.myTokens = myTokens;
        this.payload.userTokens = userTokens;
    }
}

export class SignalCleanSessionAction implements SignalAction {
    public type = SIGNAL_CLEAN_SESSION;
    public payload: SignalState = null;
}

export class SignalActivateSessionAction implements SignalAction {
    public type = SIGNAL_ACTIVATE_SESSION;
    public payload: SignalState = new SignalInitialState();

    constructor(userToken: string) {
        this.payload.activeUserToken = userToken;
    }
}

export class SignalActivateSessionSuccessAction implements SignalAction {
    public type = SIGNAL_ACTIVATE_SESSION_SUCCESS;
    public payload: SignalState = new SignalInitialState();

    constructor(userToken: string) {
        this.payload.activeUserToken = userToken;
    }
}

// Online state ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export class SignalStartObserveUserAction implements SignalAction {
    public type = SIGNAL_START_OBSERVE_USER;
    public payload: SignalState = null;
}

export class SignalStartObserveUserSuccessAction implements SignalAction {
    public type = SIGNAL_START_OBSERVE_USER_SUCCESS;
    public payload: SignalState = new SignalInitialState();

    constructor(timer: Timer) {
        this.payload.activePollingTimer = timer;
    }
}

export class SignalStopObserveUserAction implements SignalAction {
    public type = SIGNAL_STOP_OBSERVE_USER;
    public payload: SignalState = new SignalInitialState();

    constructor(timer: Timer) {
        this.payload.activePollingTimer = timer;
    }
}

export class SignalStopObserveUserSuccessAction implements SignalAction {
    public type = SIGNAL_STOP_OBSERVE_USER_SUCCESS;
    public payload: SignalState = null;
}

// Connection ready changes ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export class SignalLocalConnectionReadyChangedAction implements SignalAction {
    type = SIGNAL_LOCAL_CONNECTION_READY_CHANGED;
    payload: SignalState  = new SignalInitialState();
    constructor(isReady: boolean) {
        this.payload.ready.isLocalConnectionReady = isReady;
    }
}

export class SignalRemoteConnectionReadyChangedAction implements SignalAction {
    type = SIGNAL_REMOTE_CONNECTION_READY_CHANGED;
    payload: SignalState  = new SignalInitialState();
    constructor(isReady: boolean) {
        this.payload.ready.isRemoteConnectionReady = isReady;
    }
}
