import {
    WEBRTC_RESET_STATE, WEBRTC_UPDATE_CONNECTION_CONFIGURATION,
    WebrtcAction, WebrtcUpdateConnectionConfigurationAction
} from './webrtc.action';
import { WebrtcInitialState, WebrtcState } from './webrtc.state';

export function webrtcReducer(state: WebrtcState = new WebrtcInitialState(), action: WebrtcAction): WebrtcState {
    switch (action.type) {
        case WEBRTC_RESET_STATE:
            return new WebrtcInitialState();
        case WEBRTC_UPDATE_CONNECTION_CONFIGURATION:
            return {...state, connectSettings: (<WebrtcUpdateConnectionConfigurationAction>action).configuration};
        default:
            return state;
    }
}
