import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Credential, DoLoginAction } from '../../store/login/login.action';
import { AppState } from '../../store/login/login.state';
import { PASSWORD_RECOVERY_REQUEST, TAN_LOGIN_ROUTE } from '../../../../routes';
import { BrandingService, CERTIFIED_SUBDOMAINS, Subdomains } from '../../../branding/providers/branding.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    passwordRecoveryRoute = PASSWORD_RECOVERY_REQUEST;
    patientLoginRoute = TAN_LOGIN_ROUTE;

    public backgroundImg$: Observable<any>;
    public showDataSecuritySeal: boolean;

    constructor(private store: Store<AppState>,
                private translate: TranslateService, fb: FormBuilder,
                private brandingService: BrandingService) {

        this.loginForm = fb.group({
            email: fb.control('', Validators.compose([Validators.required, Validators.email])),
            password: fb.control(
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(32),
                ])
            )
        });

        store.select(s => s.login.error).subscribe((httpError: HttpErrorResponse) => {
            if (httpError) {
                if (httpError.status === 429) {
                    this.loginForm.setErrors({
                        rateLimitReached: true
                    });
                } else {
                    this.loginForm.setErrors({
                        notValid: true
                    });
                }
            }
        });

        this.backgroundImg$ = this.brandingService.getBrandingSettingsFromStore().pipe(
            map(settings => {
                const backgroundImg = settings.bgImage_doctorLogin || '/assets/img/general/praxis_bg.jpg';
                return {backgroundImage: backgroundImg};
            }));
    }

    ngOnInit(): void {
        this.showDataSecuritySeal = CERTIFIED_SUBDOMAINS.some(
            (subdomain: Subdomains) => subdomain === this.brandingService.getSubdomain(window.location.hostname)
        );
    }

    public onSubmit() {
        if (this.loginForm.valid) {
            const credential: Credential = {
                email: this.loginForm.value.email,
                password: this.loginForm.value.password
            };
            this.store.dispatch(new DoLoginAction(credential));
        } else {
            this.loginForm.setErrors({
                notValid: true
            });
        }
    }
}
