import { Action } from '@ngrx/store';
import { CountdownState } from './countdown.state';

export const COUNTDOWN_INIT = 'COUNTDOWN_INIT';
export const COUNTDOWN_STOP = 'COUNTDOWN_STOP';
export const COUNTDOWN_FINISHED = 'COUNTDOWN_FINISHED';
export const COUNTDOWN_NEW_VALUE = 'COUNTDOWN_NEW_VALUE';
export const COUNTDOWN_NEW_SYNC_VALUE = 'COUNTDOWN_NEW_SYNC_VALUE';
export const COUNTDOWN_REQUEST_INIT = 'COUNTDOWN_REQUEST_INIT';

export interface CountdownAction extends Action {
    payload: CountdownState | any;
}

export class CountdownRequestInitAction implements CountdownAction {
    readonly type = COUNTDOWN_REQUEST_INIT;
    public payload = null;

    constructor(participantId: number) {
        this.payload = participantId;
    }
}

export class CountdownInitAction implements CountdownAction {
    readonly type = COUNTDOWN_INIT;
    public payload = null;
}

export class CountdownNewValueAction implements CountdownAction {
    readonly type = COUNTDOWN_NEW_VALUE;
    public payload = null;

    constructor(value: number) {
        this.payload = {value};
    }
}

export class CountdownStopAction implements CountdownAction {
    readonly type = COUNTDOWN_STOP;
    public payload = null;

    constructor() {
    }
}

export class CountdownFinishedAction implements CountdownAction {
    readonly type = COUNTDOWN_FINISHED;
    payload = null;

    constructor() {
    }
}
