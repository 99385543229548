import { Component } from '@angular/core';

@Component({
  selector: 'app-paid-table',
  templateUrl: './paid-table.component.html',
  styleUrls: ['./paid-table.component.scss']
})
export class PaidTableComponent {

  constructor() { }

}
