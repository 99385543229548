import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaymentInfoMapper } from './dto/payment-info-mapper';
import { catchError, concatMap, map, retry, tap } from 'rxjs/operators';
import { PaymentInfo } from '../store/payment-info.model';
import { PaymentInfoStoreService } from './payment-info-store.service';
import { PaymentInfoDto } from './dto/payment-info-dto';
import { PaymentInfoPollingService } from './payment-info-polling.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class PaymentInfoService {

    private paymentInfoEndpoint = environment.paymentInfoEndpoint;

    constructor(private http: HttpClient,
                private pollingService: PaymentInfoPollingService,
                private storeService: PaymentInfoStoreService) {
    }

    public readPaymentInfo(): Observable<PaymentInfo> {

        return this.http
            .get(this.paymentInfoEndpoint, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3), // retry a failed request up to 3 times
                map((paymentInfoDto: PaymentInfoDto) => PaymentInfoMapper.mapDtoToEntity(paymentInfoDto)),
                tap((paymentInfo: PaymentInfo) => this.storePaymentInfo(paymentInfo)),
                catchError(this.handleError) // then handle the error
            );
    }

    public readWithPolling(): Observable<PaymentInfo> {
        return this.pollingService
            .startPolling()
            .pipe(
                map((paymentInfoDto: PaymentInfoDto) => PaymentInfoMapper.mapDtoToEntity(paymentInfoDto)),
                tap((paymentInfo: PaymentInfo) => this.storePaymentInfo(paymentInfo)),
                catchError(this.handleError) // then handle the error
            );
    }

    public createPaymentInfo(paymentInfo: PaymentInfo): Observable<PaymentInfo> {
        const dto = PaymentInfoMapper.mapEntityToDto(paymentInfo);

        return this.http
            .post(this.paymentInfoEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                concatMap(() => this.pollingService.startPolling()),
                map((paymentInfoDto) => PaymentInfoMapper.mapDtoToEntity(paymentInfoDto)),
                tap((paymentInfoObj: PaymentInfo) => this.storePaymentInfo(paymentInfoObj)),
                catchError(this.handleError)
            );
    }

    public updatePaymentInfo(paymentInfo: PaymentInfo): Observable<PaymentInfo> {
        const dto = PaymentInfoMapper.mapEntityToDto(paymentInfo);
        return this.http
            .put(this.paymentInfoEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                concatMap(() => this.pollingService.startPolling()),
                map((paymentInfoDto) => PaymentInfoMapper.mapDtoToEntity(paymentInfoDto)),
                tap((paymentInfoObj: PaymentInfo) => this.storeService.updatePaymentInfoInStore(paymentInfoObj)),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in PaymentInfoService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    private storePaymentInfo(paymentInfo: PaymentInfo) {
        if (paymentInfo !== null) {
            this.storeService.addPaymentInfoInStore(paymentInfo);
        }
        return paymentInfo;
    }
}
