import { Action } from '@ngrx/store';

// Common state changes ....................................................................................................................
export const WEBRTC_RESET_STATE = 'WEBRTC_RESET_STATE';

// Connection state changes ................................................................................................................
export const WEBRTC_CONNECTION_GOT_INTERRUPTED = 'WEBRTC_CONNECTION_GOT_INTERRUPTED';

export const WEBRTC_CLOSE_MAIN_CONNECTION = 'WEBRTC_CLOSE_MAIN_CONNECTION';
export const WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS = 'WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS';

export const WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION = 'WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION';
export const WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION_SUCCESS = 'WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION_SUCCESS';

// connection configuration
export const WEBRTC_UPDATE_CONNECTION_CONFIGURATION = 'WEBRTC_UPDATE_CONNECTION_CONFIGURATION';

export enum WebrtcConnectionType {
    MAIN_CONNECTION = 1,
    SCREEN_SHARE_CONNECTION = 2,
}

// Common state changes ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export interface WebrtcAction extends Action {
    readonly type: string;
    connectionType?: WebrtcConnectionType;
}

export class WebrtcResetAction implements WebrtcAction {
    type = WEBRTC_RESET_STATE;
}

// Connection state changes ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export class WebrtcConnectionGotInterrupted implements WebrtcAction {
    type = WEBRTC_CONNECTION_GOT_INTERRUPTED;
    constructor() {
    }
}

export class WebrtcCloseMainConnectionAction implements WebrtcAction {
    type = WEBRTC_CLOSE_MAIN_CONNECTION;
    payload: { peerHangup: boolean };
    connectionType = WebrtcConnectionType.MAIN_CONNECTION;

    constructor(peerHangup: boolean = false) {WebrtcUpdateConnectionConfigurationAction
        this.payload = {peerHangup};
    }
}

export class WebrtcCloseMainConnectionSuccessAction implements WebrtcAction {
    type = WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS;
}

export class WebrtcCloseScreenShareConnectionAction implements WebrtcAction {
    type = WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION;
    payload: { peerHangup: boolean };
    connectionType = WebrtcConnectionType.SCREEN_SHARE_CONNECTION;

    constructor(peerHangup: boolean = false) {
        this.payload = {peerHangup};
    }
}

export class WebrtcCloseScreenShareConnectionSuccessAction implements WebrtcAction {
    type = WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION_SUCCESS;
}

export class WebrtcUpdateConnectionConfigurationAction implements WebrtcAction {
    type = WEBRTC_UPDATE_CONNECTION_CONFIGURATION;
    public configuration: RTCConfiguration;

    constructor(configuration: RTCConfiguration) {
        this.configuration = configuration;
    }
}
