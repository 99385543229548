import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentInfoModule } from './payment-info/payment-info.module';
import { ROUTE as INFO_ROUT } from './payment-info/payment-info.module';
import { ROUTE as ACCOUNT_ROUT } from './payment-account/payment-account.module';
import { PaymentAccountModule } from './payment-account/payment-account.module';
import { PaymentBankAccountModule } from '../../entities/payment-bank-account/payment-bank-account.module';

export const ROUTE = '/payment';

export const PAYMENT_ACCOUNT_ROUTE = ROUTE + ACCOUNT_ROUT;
export const PAYMENT_INFO_ROUTE = ROUTE + INFO_ROUT;

@NgModule({
    imports: [
        CommonModule,
        PaymentAccountModule,
        PaymentInfoModule,
        PaymentBankAccountModule
    ],
    declarations: [],
    exports: []
})
export class PaymentModule {
}
