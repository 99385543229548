import { Languages } from '../../../providers/translate/models/languages';

export class LanguageMapper {
    public static mapTranslateToMomentLang(lang: string): Languages {
        switch (lang) {
            case Languages.EN:
                return Languages.EN;
            default:
                return Languages.DE;
        }
    }
}
