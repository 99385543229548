import { ProfileModule } from '../profile/profile.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent, SimpleLayoutComponent } from './components';
import { SidebarModule } from '../sidebar/sidebar.module';
import { WaitingroomModule } from '../waitingroom/waitingroom.module';
import { HeaderModule } from '../header/header.module';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../footer/footer.module';
import { MediaModule } from '../media/media.module';
import { CountdownModule } from '../countdown/countdown.module';
import { AppointmentModule } from '../appointment/appointment.module';
import { AuthModule } from '../auth/auth.module';
import { RegisterModule } from '../register/register.module';
import { SettingModule } from '../setting/setting.module';
import { HelpModule } from '../help/help.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrandingModule } from '../branding/branding.module';
import { PagesModule } from '../../pages/pages.module';
import { PaymentModule } from '../payment/payment.module';
import { FileTransferModule } from '../file-transfer/file-transfer.module';
import { ModalModule } from "../modal/modal.module";

@NgModule({
    imports: [
        CommonModule,
        SidebarModule,
        WaitingroomModule,
        AppointmentModule,
        HeaderModule,
        RouterModule,
        FooterModule,
        MediaModule,
        CountdownModule,
        AuthModule,
        RegisterModule,
        SettingModule,
        DashboardModule,
        HelpModule,
        ProfileModule,
        TranslateModule.forChild(),
        BrandingModule,
        PagesModule,
        PaymentModule,
        FileTransferModule,
        ModalModule
    ],
    declarations: [
        DashboardLayoutComponent,
        SimpleLayoutComponent,
    ],
    exports: [
        RouterModule
    ]
})
export class LayoutModule {
}
