import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserAgent } from './user-agent.model';
import * as UserAgentActions from './user-agent.actions';

export const userAgentsFeatureKey = 'userAgents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserAgentState extends EntityState<UserAgent> {
    // additional entities state properties
}

export const adapter: EntityAdapter<UserAgent> = createEntityAdapter<UserAgent>();

export const initialState: UserAgentState = adapter.getInitialState({
    // additional entity state properties
});

const userAgentReducer = createReducer(
    initialState,
    on(UserAgentActions.addUserAgent,
        (state, action) => adapter.addOne(action.userAgent, state)
    ),
    on(UserAgentActions.upsertUserAgent,
        (state, action) => adapter.upsertOne(action.userAgent, state)
    ),
    on(UserAgentActions.addUserAgents,
        (state, action) => adapter.addMany(action.userAgents, state)
    ),
    on(UserAgentActions.upsertUserAgents,
        (state, action) => adapter.upsertMany(action.userAgents, state)
    ),
    on(UserAgentActions.updateUserAgent,
        (state, action) => adapter.updateOne(action.userAgent, state)
    ),
    on(UserAgentActions.updateUserAgents,
        (state, action) => adapter.updateMany(action.userAgents, state)
    ),
    on(UserAgentActions.deleteUserAgent,
        (state, action) => adapter.removeOne(action.id, state)
    ),
    on(UserAgentActions.deleteUserAgents,
        (state, action) => adapter.removeMany(action.ids, state)
    ),
    on(UserAgentActions.loadUserAgents,
        (state, action) => adapter.setAll(action.userAgents, state)
    ),
    on(UserAgentActions.clearUserAgents,
        state => adapter.removeAll(state)
    ),
);

export function reducer(state: UserAgentState | undefined, action: Action) {
    return userAgentReducer(state, action);
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
