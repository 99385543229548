import { Injectable } from '@angular/core';
import { XmppUtil } from '../xmpp/xmpp.util';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { XmppMessageBuilder } from '../xmpp/xmpp.message.builder';
import { XmppUser } from '../xmpp/xmpp-user.model';

// we are not interested on the real Message, that is the implementation of use cases
export interface XmppMessage {
    type: string;
}

const JAMEDA_MSG = 'JAMEDA_MSG';

export class XmppEvent {
    type = JAMEDA_MSG;
    payload: XmppMessage;
}

@Injectable()
export class XmppMessageService {
    private connection: any;
    private xmppUser: XmppUser;
    private readonly listen$: Subject<XmppEvent> = new Subject<XmppEvent>();

    constructor(
        private msgBuilder: XmppMessageBuilder
    ) {
    }

    public init(connection, xmppUser: XmppUser): void {
        this.connection = connection;
        this.xmppUser = xmppUser;
        this.connection.addHandler(this.onEventMessage.bind(this), null, 'message', null, null);
    }

    public listen(msgType: string): Observable<XmppMessage> {
        return this.listen$.pipe(
            filter((event: XmppEvent) => event.payload.type === msgType),
            map((event: XmppEvent) => event.payload)
        );
    }

    public send(payload: XmppMessage, profileId: number): void {
        const eventMsg: XmppEvent = <XmppEvent>{type: JAMEDA_MSG, payload};
        const to: string = XmppUtil.createJid(profileId);
        const from: string = this.xmppUser.jId;
        const msg: unknown = this.msgBuilder.buildChatMessage<XmppEvent>(to, from, eventMsg);
        this.connection.send(msg);
    }

    private onEventMessage(xmlResponse: string): boolean {
        const jsonNMessage: string = this.msgBuilder.readMessage(xmlResponse);
        if (jsonNMessage.length) {
            const unknownEvent: unknown = JSON.parse(jsonNMessage);
            // if we listen on XMPP we get all chat messages even unknown messages,
            // but we are only interest on our event messages
            if (this.isXmppEventType(unknownEvent)) {
                this.listen$.next(unknownEvent as XmppEvent);
            }
        }
        return true;
    }

    private isXmppEventType(event: any): boolean {
        return (!!event && !!event.type && event.payload && event.type === JAMEDA_MSG);
    }
}
