import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
    createDefaultImageReader,
    createDefaultImageWriter,
    locale_en_gb,
    PinturaDefaultImageWriterResult,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_filter,
    plugin_filter_defaults,
    plugin_filter_locale_en_gb,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_finetune_locale_en_gb,
    setPlugins
} from '@pqina/pintura';
import locale_de_DE from 'pintura-locale/de_DE/core/de_DE';
import locale_crop_de_DE from 'pintura-locale/de_DE/crop/de_DE';
import locale_finetune_de_DE from 'pintura-locale/de_DE/finetune/de_DE';
import locale_filter_de_DE from 'pintura-locale/de_DE/filter/de_DE';
import { ProfileService } from '../../profile/providers/profile.service';
import { CustomTranslateService } from '../../../providers/translate/services/custom-translate.service';

setPlugins(plugin_crop, plugin_finetune, plugin_filter);

const locale = {
    en: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb
    },
    de: {
        ...locale_de_DE,
        ...locale_crop_de_DE,
        ...locale_finetune_de_DE,
        ...locale_filter_de_DE
    }
};

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: [ './image-upload.component.scss' ],
})
export class ImageUploadComponent implements OnDestroy {
    @Output() onCloseButtonClicked = new EventEmitter();
    @ViewChild('myDoka', { static: false }) myDoka: any;
    previewFile = null;
    error = null;
    fileFormatError: string;
    fileSizeError: string;
    src = null;
    result = null;
    options: any = {
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultImageWriter(),
        ...plugin_finetune_defaults,
        ...plugin_filter_defaults,
        imageCropAspectRatio: 1,
        imageTargetSize: 2000000, // 2Mb
        locale: null
    };
    private subscription: Subscription;

    constructor(private profileService: ProfileService,
                private customTranslateService: CustomTranslateService,
                private translationService: TranslateService) {
        this.profileService = profileService;
        this.customTranslateService.getLangFromStore().pipe(take(1))
            .subscribe((lang: string) => {
                this.options.locale = locale[lang];
            });
        this.subscription = this.translationService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.options.locale = locale[event.lang];
        });
        this.translationService.get('UTIL.IMAGE-UPLOAD.ERROR.WRONG-FORMAT').subscribe((translationText: string) => {
            this.fileFormatError = translationText;
        });
        this.translationService.get('UTIL.IMAGE-UPLOAD.ERROR.IMAGE-SIZE').subscribe((translationText: string) => {
            this.fileSizeError = translationText;
        });
    }

    onDragEnter(e) {
        e.currentTarget.classList.add('dragging');
    }

    onDragLeave(e) {
        e.currentTarget.classList.remove('dragging');
    }

    isImage(file) {
        const acceptedImageTypes = [ 'image/gif', 'image/jpg', 'image/jpeg', 'image/png' ];
        return file && acceptedImageTypes.includes(file['type']);
    }

    fileSelected(e: any) {
        this.error = null;

        const files = e.target.files;
        if (files.length) {
            const file: Blob = files[0];

            if (!this.isImage(file)) {
                this.error = this.fileFormatError;
                return;
            }

            if (file.size > this.options.imageTargetSize) {
                this.error = this.fileSizeError;
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.previewFile = reader.result;
            };
            reader.onerror = () => {
                this.error = reader.error;
            };
        }
    }

    handleDokaConfirm(imageWriterResult: PinturaDefaultImageWriterResult) {
        const reader = new FileReader();
        reader.readAsDataURL(imageWriterResult.dest);
        reader.onloadend = () => {
            const base64image = reader.result;
            this.profileService.updateProfileImage({
                profileImage: String(base64image),
            }).subscribe();
            this.handleCloseButtonClick();
        };
    }

    handleCloseButtonClick() {
        this.onCloseButtonClicked.emit();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
