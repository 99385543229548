import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionStatsService } from '../../../../entities/webrtc-connection-stats';

@Component({
    selector: 'app-internet-icon',
    templateUrl: './internet-icon.component.html',
    styleUrls: ['./internet-icon.component.scss']
})
export class InternetIconComponent implements OnInit {

    @Input() type: 'local' | 'remote';

    public bandwidthConnection$: Observable<ConnectionState>;

    public noConnection = ConnectionState.NOCONNECTION;
    public low = ConnectionState.LOW;
    public medium = ConnectionState.MEDIUM;
    public high = ConnectionState.HIGH;

    constructor(private connection: ConnectionStatsService) {
    }

    ngOnInit() {
        const obj = (this.type === 'local') ? this.connection.getLocalBandwidth() : this.connection.getRemoteBandwidth();

        this.bandwidthConnection$ = obj.pipe(
            map((s) => {
                if (s === 0) {
                    return this.noConnection;
                } else if (s <= 100000) { // LOW: s < 0,1Mbps, Normally audio alone needs 0,006Mbps - 0,5Mbps.
                    return this.low;
                } else if (s <= 400000) { // MEDIUM: s < 0,4Mbps, We can do OVS with between 0,3Mbps - 1 Mbps bandwidth
                    return this.medium;
                } else {
                    // HIGH: s > 1Mbps, Normally we need more then 2,5Mbps for HD, but at least a good OVS works with more then 1 Mbps
                    return this.high;
                }
            })
        );
    }
}

enum ConnectionState {
    NOCONNECTION,
    LOW,
    MEDIUM,
    HIGH
}
