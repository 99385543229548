<div class="modal-header">
    <i class="far fa-list"></i>
    <h4 class="modal-title">
        {{ 'RTC_DETECTION.TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()"><span
        aria-hidden="true"><i class="far fa-times"></i></span></button>
</div>
<div class="modal-body">

    <div class="body-headline">
        <i class="fal fa-exclamation-triangle"></i>
        <h5>{{'RTC_DETECTION.NO_CAMERA_ACCESS.HEADLINE' | translate }}</h5>
    </div>

    <div class="body-content">
        <span>{{ 'RTC_DETECTION.NO_CAMERA_ACCESS.MSG' | translate }}</span>

        <div class="link">
            <button type="button" class="btn btn-primary" (click)="getFAQInfo()">
                <i class="fas fa-info-circle"></i> {{'RTC_DETECTION.NO_CAMERA_ACCESS.GET_INFO' | translate }}
            </button>
        </div>
    </div>



</div>
<div class="modal-footer">
    <div class="button-line">
        <button type="button" class="btn btn-outline-dark" (click)="dismissModal()">
            {{'RTC_DETECTION.CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="again()">
        <span>
            {{'RTC_DETECTION.AGAIN' | translate }}
            <i class="fas fa-arrow-right"></i>
        </span>
        </button>
    </div>

    <app-rtc-footer></app-rtc-footer>

</div>

