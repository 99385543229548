import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TimePickerComponent} from './time-picker/time-picker.component';
import {DatepickerTranslationService} from './date-picker/datepicker-translation.service';
import {DatepickerFormatParser} from './date-picker/datepicker-format-parser';
import {DatePickerDateCompareService} from './date-picker/date-picker-date-compare.service';
import {CommaSeperatorPipe} from './pipes/comma-seperator.pipe';
import {NoCommaPipe} from './pipes/noComma.pipe';
import {InvalidDirective} from '../../forms/directives/invalid.directive';
import {SafePipe} from './directives/safe.pipe';
import {OnlyDecimalPipe} from './pipes/onlyDecimal.pipe';
import {AfterDotPipe} from './pipes/afterDot.pipe';
import {BeforeDotPipe} from './pipes/beforeDot.pipe';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';

const datepickerParserFactory = (translate: TranslateService) => new DatepickerFormatParser('ddd, DD. MMMM YYYY', translate);

@NgModule({
    imports: [
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule.forChild()
    ],
    providers: [
        {provide: NgbDatepickerI18n, useClass: DatepickerTranslationService},
        {
            provide: NgbDateParserFormatter,
            useFactory: datepickerParserFactory,
            deps: [TranslateService]
        },
        DatePickerDateCompareService
    ],
    declarations: [
        TimePickerComponent,
        CommaSeperatorPipe,
        NoCommaPipe,
        InvalidDirective,
        SafePipe,
        OnlyDecimalPipe,
        BeforeDotPipe,
        AfterDotPipe
    ],
    exports: [
        TimePickerComponent,
        NgbModule,
        CommaSeperatorPipe,
        NoCommaPipe,
        InvalidDirective,
        SafePipe,
        OnlyDecimalPipe,
        BeforeDotPipe,
        AfterDotPipe,
    ]
})
export class UtilsModule {
}
