import { Injectable } from '@angular/core';
import { PaymentInfo } from '../store/payment-info.model';
import * as fromPaymentInfo from '../store/payment-info.reducer';
import { Store } from '@ngrx/store';
import { AddPaymentInfo, UpdatePaymentInfo } from '../store/payment-info.actions';
import { Observable } from 'rxjs';


@Injectable()
export class PaymentInfoStoreService {

    constructor(private store: Store<fromPaymentInfo.PaymentInfoState>) {
    }

    public addPaymentInfoInStore(paymentInfo: PaymentInfo): void {
        this.store.dispatch(new AddPaymentInfo({paymentInfo}));
    }

    public updatePaymentInfoInStore(paymentInfo: PaymentInfo): void {
        this.store.dispatch(new UpdatePaymentInfo({paymentInfo: {id: paymentInfo.id, changes: paymentInfo}}));
    }

    public getPaymentInfoFromStore(): Observable<PaymentInfo | null> {
        return this.store.select(fromPaymentInfo.selectPaymentInfo);
    }
}
