import { Component } from '@angular/core';

@Component({
  selector: 'app-outstanding-table',
  templateUrl: './outstanding-table.component.html',
  styleUrls: ['./outstanding-table.component.scss']
})
export class OutstandingTableComponent {

  constructor() { }

}
