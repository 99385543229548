<ng-template #content let-modal>
    <div class="modal-body padding-body">
        <div id="spinner">
            <div>
                <img src="/assets/img/spinner/loader.gif"
                     style="position: absolute; top: 50%; left:50%; width: 3rem; height: 3rem;"/>
            </div>
        </div>
        <ng-container *ngIf="(deviceTestIframeUrl$ | async)">
            <iframe id='deviceTestIframe' [src]="(deviceTestIframeUrl$ | async) | safe"
                    title="{{ 'JITSI_SETTING.DEVICE_TEST' | translate }}"
                    allow="camera;microphone"
                    scrolling="no" noresize frameborder="0"
                    onload="document.getElementById('spinner').style.display='none';">
            </iframe>
        </ng-container>

        <script type="text/javascript">
            window.addEventListener("message", receiveMessage, false);

            function receiveMessage(event) {
                console.log('hez i get some thing', event);
            }

            var eventMethod = window.addEventListener
                ? "addEventListener"
                : "attachEvent";
            var eventer = window[eventMethod];
            var messageEvent = eventMethod === "attachEvent"
                ? "onmessage"
                : "message";

            eventer(messageEvent, function (e) {
                if (e.data === "cancel" || e.message === "cancel") {
                    window.closePopUp('cancel');
                }

                if (e.data === "ok" || e.message === "ok") {
                    window.closePopUp('ok');
                }

            });
        </script>
    </div>
</ng-template>

<button type="button" class="button btn-outline-primary" (click)="open(content)" *ngIf="buttonType==='small-button'">
    {{ 'JITSI_SETTING.SYSTEM_TEST' | translate }}
</button>

<button type="button" class="btn btn-primary" (click)="open(content)" style="width: 100%" *ngIf="buttonType==='button'">
        <span>
            <i class="far fa-video"></i>
        </span>
    {{ 'JITSI_SETTING.DEVICE_TEST' | translate }}
</button>

<a id="link" href="javascript:void(0)" (click)="open(content)" style="width: 100%" *ngIf="buttonType==='link'">
        <span class="menu-element">
            <i class="far fa-video"></i>
            <span class="description">{{ 'JITSI_SETTING.SYSTEM_TEST' | translate }}</span>
        </span>
</a>
