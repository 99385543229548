<div *ngIf="(localStream$ | async).active && (remoteStream$ | async).active">
    <app-internet-icon [type]="'local'"></app-internet-icon>
</div>
<div id="control-bar-box"
     class="main-control-bar-box control-bar-box"
     @fastFadeInOut
     *ngIf="(localStream$ | async).active && (remoteStream$ | async).active && (mouseMoved||mouseOver)">
    <div *ngIf="(localStream$ | async).mute; then mic_off else mic_on"></div>
    <ng-template #mic_on>
        <div class="left-button control-bar-btn-wrapper mic-control-button" (click)="micMute()">
            <i class="fas fa-microphone"></i>
        </div>
    </ng-template>
    <ng-template #mic_off>
        <div class="left-button control-bar-btn-wrapper mic-control-button" (click)="micUnmute()">
            <i class="fas fa-microphone-slash"></i>
        </div>
    </ng-template>
    <div *ngIf="(remoteStream$ | async).play; then call_end else call_on"></div>
    <ng-template #call_on>
        <div class="center-button control-bar-btn-wrapper  call-control-button deactivated">
            <i class="fas fa-phone"></i>
        </div>
    </ng-template>
    <ng-template #call_end>
        <div class="center-button control-bar-btn-wrapper  call-control-button" (click)="callEnd()">
            <i class="fas fa-phone"></i>
        </div>
    </ng-template>
    <div *ngIf="(localStream$ | async).play; then videocam_on else videocam_off"></div>
    <ng-template #videocam_on>
        <div class="right-button control-bar-btn-wrapper  video-control-button" (click)="videocamOff()">
            <i class="fas fa-video"></i>
        </div>
    </ng-template>
    <ng-template #videocam_off>
        <div class="right-button control-bar-btn-wrapper  video-control-button" (click)="videocamOn()">
            <i class="fas fa-eye-slash"></i>
        </div>
    </ng-template>
    <div class="toggle-video-fullscreen-mode" (click)="toggleVideoFullScreen()">
        <i class="far fa-expand"></i>
    </div>
</div>
