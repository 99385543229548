import { GendersEnglish, GendersGerman, GenderType } from '../../../genders';
import { Durations, DurationType } from '../../../durations';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_START_DATE, TimeListItem, TimePickerComponent } from '../../../../utils/time-picker/time-picker.component';
import { Store } from '@ngrx/store';
import { EntityStateAppointment } from '../../../store/one-time-appointment/appointment.entity';
import { AppointmentCreateTanAction } from '../../../store/one-time-appointment/appointment.action';
import { CreateTanFormBuilder } from './create-tan.form-builder';
import { AnalyticsService } from '../../../../../providers/analytics/analytics.service';
import { InvalidDirective } from '../../../../../forms/directives/invalid.directive';
import { DatePickerDateCompareService } from '../../../../utils/date-picker/date-picker-date-compare.service';
import { DATE_TIME_FORMAT_EXPORT, TimeHelperService } from '../../../../utils/time-helpers/time-helper.service';
import { CustomValidators } from '../../../../../forms/validators/custom-validator';
import { CreateTanAppointmentDTO, CreateTanFormValues } from '../../../providers/create-tan.types';
import { CustomTranslateService } from '../../../../../providers/translate/services/custom-translate.service';
import { DatepickerFormatParser } from '../../../../utils/date-picker/datepicker-format-parser';

export interface TanCreateFormValuesType {
    tanDate: { year: number; month: number; day: number };
    tanBegining: TimeListItem;
    tanDuration: DurationType;
    salutation: GenderType;
    firstName: string;
    lastName: string;
    birthday?: string;
    email?: string;
    emailMessage?: string;
    isEbm: boolean;
}

@Component({
    selector: 'app-create-tan',
    templateUrl: './create-tan.component.html',
    styleUrls: ['./create-tan.component.scss']
})

export class CreateTanComponent implements OnInit {
    @ViewChild(TimePickerComponent) tanBeginningTimePicker: TimePickerComponent;
    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;

    public tanCreateForm: FormGroup;
    public genders: GenderType[] = [];
    public durations: DurationType[] = [];
    public beggingTextAddon: string;
    public currentTimeObject: TimeListItem;
    public isEbmChecked = false;

    constructor(private formBuilder: FormBuilder,
                private translate: TranslateService,
                private analytics: AnalyticsService,
                private store: Store<EntityStateAppointment>,
                public datePickerDateCompare: DatePickerDateCompareService,
                private customTranslateService: CustomTranslateService) {
    }

    ngOnInit() {
        this.durations = Durations;
        this.tanCreateForm = CreateTanFormBuilder.build(this.formBuilder);
        this.setFormDefaults();

        this.customTranslateService.getLangFromStore().subscribe(
            (currentLanguage) => {
                this.assignGendersBasedOnLanguage(currentLanguage);
            }
        );
    }

    onSubmit(): void {
        let payload;
        let birthday = '';
        Object.keys(this.tanCreateForm.controls).map(key => {
            this.tanCreateForm.get(key).markAsDirty();
        });

        this.addRemovePatientEmailValidators();

        this.invalidDirectives.map((directive) => {
            directive.triggerTooltip();
        });

        if (this.tanCreateForm.valid) {
            this.analytics.trackNewPageView('/createTanAppointment.html', 'Create TAN Appointment');

            const birthdayFormValue = this.tanCreateForm.controls['birthday'].value;
            if (!this.isBirthdayEmpty(birthdayFormValue)) {
                birthday = `${birthdayFormValue.birthYear}-${birthdayFormValue.birthMonth}-${birthdayFormValue.birthDay}`;
            }

            payload = this.prepareRequestPayload(
                {
                    tanDate: this.tanCreateForm.controls['tanDate'].value,
                    tanBegining: this.tanCreateForm.controls['tanBegining'].value,
                    tanDuration: this.tanCreateForm.controls['tanDuration'].value,
                    salutation: this.tanCreateForm.controls['salutation'].value,
                    firstName: this.tanCreateForm.controls['firstName'].value,
                    lastName: this.tanCreateForm.controls['lastName'].value,
                    birthday,
                    isEbm: this.tanCreateForm.controls['isEbmCheckBox'].value,
                    email: this.tanCreateForm.controls['patientEmail'].value,
                    emailMessage: this.tanCreateForm.controls['patientEmailMessage'].value,
                }
            );
            if (payload) {
                const dto = new CreateTanAppointmentDTO(payload);
                this.store.dispatch(new AppointmentCreateTanAction(dto));
            }
        } else {
            this.analytics.trackWrongFormInputEvent(this.tanCreateForm);
        }
    }

    private isBirthdayEmpty(birthdayObject: { birthDay: string; birthMonth: string; birthYear: string }): boolean {
        return (birthdayObject.birthDay === '') || (birthdayObject.birthMonth === '') && (birthdayObject.birthYear === '');
    }

    setEndTime(selectedTimeObject: TimeListItem) {
        this.currentTimeObject = selectedTimeObject;
        const startTime = TimeHelperService.getMomentDateTime(DEFAULT_START_DATE + ' ' + this.currentTimeObject.timeOnly);
        const selectedDurationValue = this.tanCreateForm.get('tanDuration').value;
        if (!selectedDurationValue) {
            return;
        }
        const endTime = startTime.add(+selectedDurationValue.id, 'm');
        this.beggingTextAddon = endTime.format('HH:mm');

        this.tanCreateForm.controls['tanBegining'].setValue(this.currentTimeObject);
    }

    private prepareRequestPayload(formValues: TanCreateFormValuesType): CreateTanFormValues {

        const appointmentDate = this.datePickerDateCompare.formatISO(formValues.tanDate);

        if (!appointmentDate || !formValues.tanBegining.timeOnly) {
            return;
        }

        const startDateTime = TimeHelperService.localToUtcMomentDateTime(`${appointmentDate} ${formValues.tanBegining.timeOnly}`)
            .format(DATE_TIME_FORMAT_EXPORT);

        const endDateTime = TimeHelperService.localToUtcMomentDateTime(`${appointmentDate} ${formValues.tanBegining.timeOnly}`)
            .add(formValues.tanDuration.id, 'm')
            .format(DATE_TIME_FORMAT_EXPORT);

        return {
            user: {
                title: '',
                firstName: formValues.firstName,
                surname: formValues.lastName,
                gender: {
                    id: formValues.salutation.id
                },
                birthday: formValues.birthday
            },
            tan: {
                validFrom: startDateTime,
                validTo: endDateTime,
                email: formValues.email,
                emailMessage: formValues.emailMessage,
            },
            term: {},
            isEbm: formValues.isEbm
        };
    }

    public compareByItem(item1: { id: number }, item2: { id: number }): boolean {
        return item1.id === item2.id;
    }

    public onDurationChange() {
        this.setEndTime(this.currentTimeObject);
    }

    public isBirthdayValid(): boolean {
        return this.tanCreateForm.controls['birthday'].valid;
    }

    public isBirthdayElementDirty(): boolean {
        return this.tanCreateForm.controls['birthday'].dirty;
    }

    public isBirthdayElementTouched(): boolean {
        return this.tanCreateForm.controls['birthday'].touched;
    }

    public onClickPatientEmailCheckbox(): void {
        this.tanCreateForm.controls['patientEmail'].reset();
    }

    private addRemovePatientEmailValidators(): void {
        if (this.tanCreateForm.controls['patientEmailCheckBox'].value === true) {
            this.tanCreateForm.controls['patientEmail'].setValidators(
                Validators.compose([CustomValidators.email()])
            );
        } else {
            this.tanCreateForm.controls['patientEmail'].clearValidators();
        }
        this.tanCreateForm.controls['patientEmail'].updateValueAndValidity();
    }

    public goToNextInput(event) {
        const target = event.srcElement || event.target;
        if (target.nextElementSibling && target.value.length === 2) {
            target.nextElementSibling.focus();
        }
    }

    public onEmailEnter(value: string) {
        if (!this.tanCreateForm.controls['patientEmail'].validator) {
            this.tanCreateForm.controls['patientEmail'].setValidators(
                Validators.compose([CustomValidators.email()])
            );
        }
        this.tanCreateForm.controls['patientEmail'].updateValueAndValidity();
    }

    private setFormDefaults() {
        this.tanCreateForm.controls['tanDate'].setValue('');
        this.currentTimeObject = {id: '08:00', name: '08:00', timeOnly: '08:00:00'};
        this.setEndTime({id: '08:00', name: '08:00', timeOnly: '08:00:00'});
        this.tanCreateForm.controls['tanDuration'].setValue({id: '10', name: '10 min'});
        this.tanCreateForm.controls['salutation'].setValue('');
        this.tanCreateForm.controls['firstName'].setValue('');
        this.tanCreateForm.controls['lastName'].setValue('');
        this.tanCreateForm.controls['patientEmail'].setValue('');
        this.tanCreateForm.controls['isEbmCheckBox'].setValue(false);
        this.tanCreateForm.controls['patientEmailMessage'].setValue('');
        this.tanCreateForm.controls['birthday'].setValue({
            birthDay: '',
            birthMonth: '',
            birthYear: '',
        });
    }

    private assignGendersBasedOnLanguage(language: string): void {
        switch (language) {
            case 'en':
                this.genders = GendersEnglish;
                return;
            default:
                this.genders = GendersGerman;
                return;
        }
    }
}
