import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MultiUserAppointment } from '../../../store/appointment/multi-user-appointment.entity';
import { Observable, Subscription, tap } from 'rxjs';
import { Profile } from '../../../../profile/store/profile.entity';
import { AppointmentInfoService } from '../../../providers/appointment-info.service';
import { JitsiIframeDataService } from '../../../../../providers/jitsi-iframe-data/jitsi-iframe-data.service';
import { Conference } from '../../../../conference-v2/store/conference.model';
import { waitingRoomStartMUCVideoConference } from '../../../../waitingroom/store/waitingroom.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../waitingroom/store/waitingroom.state';
import { environment } from '../../../../../../environments/environment';
import { ProfileSelectService } from '../../../../profile/providers/profile-select.service';
import { VonageConfigurationService } from '../../../../../providers/vonage-configuration/vonage-configuration.service';
import { VonageBrandingSettingsService } from '../../../../../providers/vonage-branding-settings/vonage-branding-settings.service';

@Component({
    selector: 'app-multi-user-appointment-info-for-patients',
    templateUrl: './multi-user-appointment-info-for-patients.component.html',
    styleUrls: ['./multi-user-appointment-info-for-patients.component.scss']
})
export class MultiUserAppointmentInfoForPatientsComponent implements OnInit, OnDestroy {

    @Input() appointment: MultiUserAppointment;
    currentProfileId: number;
    participants$: Observable<Profile[]>;
    participants: Profile[];
    conference: Conference;
    callStarted = false;
    ovsProviderSubscription: Subscription = null;
    ovsProvider: string = environment.ovsProvider;
    vonageLoading$: Observable<boolean>;

    constructor(private appointmentInfoService: AppointmentInfoService,
                private profileSelectService: ProfileSelectService,
                private store: Store<AppState>,
                private jitsiIframeData: JitsiIframeDataService,
                private vonageConfig: VonageConfigurationService,
                private videoLibSettings: VonageBrandingSettingsService) {
        this.ovsProviderSubscription = this.videoLibSettings.ovsProvider$
            .subscribe( ovsProvider => this.ovsProvider = ovsProvider);
        this.vonageLoading$ = this.vonageConfig.loading$;
    }

    ngOnInit() {
        this.currentProfileId = this.appointmentInfoService.getCurrentProfileId();
        this.participants$ = this.appointmentInfoService.loadMUCParticipants(this.appointment, this.currentProfileId)
            .pipe(
                tap((participants: Profile[]) => this.participants = participants)
            );
        this.appointmentInfoService.loadConference(this.appointment.conferenceId)
            .subscribe((conference: Conference) => {
                this.conference = conference;
            });
    }

    ngOnDestroy(): void {
        this.ovsProviderSubscription?.unsubscribe();
    }

    public startVideoCall() {
        const isMUC = this.appointment?.profileIds?.length > 2;
        this.callStarted = true;
        this.jitsiIframeData.update({isMUC});
        this.store.dispatch(waitingRoomStartMUCVideoConference({payload: this.conference}));
    }

    public startVonage() {
        const profile = this.profileSelectService.getCurrentProfile();
        const otherProfiles = this.participants;
        this.vonageConfig.start(this.conference, this.appointment, profile, otherProfiles);
    }

    start() {
        if (this.ovsProvider === 'vonage') {
            this.startVonage();
        } else {
            this.startVideoCall();
        }
    }

}
