import { NgModule } from '@angular/core';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { rootReducer } from './app.reducer';
import { RootEffects } from './app.effect';
import { AppStateService } from './app-state.service';
import { loginStorageSyncReducer } from '../../modules/auth/auth.module';
import { translateStorageSyncReducer } from '../translate/translate.module';

const metaReducers: Array<MetaReducer<any, any>> = [loginStorageSyncReducer, translateStorageSyncReducer];


export const runtimeChecks = {
    strictStateImmutability: false,
    strictActionImmutability: false,
    strictStateSerializability: false,
    strictActionSerializability: false,
};

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forRoot({rootReducer}, {
            metaReducers,
            runtimeChecks
        }),
        EffectsModule.forRoot([RootEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
        })
    ],
    exports: [],
    providers: [AppStateService]

})
export class AppStoreModule {
}
