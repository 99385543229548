import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DispalyBitrateComponent } from './components/dispaly-bitrate/dispaly-bitrate.component';
import { SendContainerComponent } from './components/send-container/send-container.component';
import { ReceiveContainerComponent } from './components/receive-container/receive-container.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { WebrtcDataChannelService } from './provider/data-channel/webrtc-data-channel.service';
import { PeerService } from './provider/peer.service';
import { SignalService } from './provider/signal.service';
import { FileTransferService } from './provider/file-transfer.service';
import { ProfileModule } from '../profile/profile.module';
import { OncallProvider } from './provider/oncall.provider';
import { LogClientSideService } from '../custom-error-handler/providers/log-client-side/log-client-side.service';
import { UserPresenceModule } from '../user-presence/user-presence.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule.forChild(),
        UserPresenceModule,
        ProfileModule
    ],
    declarations: [
        PopUpComponent,
        DispalyBitrateComponent,
        ProgressbarComponent,
        SendContainerComponent,
        ReceiveContainerComponent,
    ],
    providers: [
        WebrtcDataChannelService,
        PeerService,
        OncallProvider,
        SignalService,
        FileTransferService,
        LogClientSideService
    ],
    exports: [PopUpComponent]
})
export class FileTransferModule {
}
