import { Injectable } from '@angular/core';
import { FileSender } from './file-sender';
import { DataChannelProtocolService } from './data-channel-protocol';
import { BehaviorSubject } from 'rxjs';
import { FileReceiver } from './file-receiver';
import { FileTransmitter } from './file-transmitter';
import { getUniqueId } from '../../../utils/string-helper/unique';


@Injectable()
export class WebrtcDataChannelService {

    private channel;

    private receiveMessages = new BehaviorSubject<ArrayBuffer>(null);

    public onReceiveFileCallback;

    constructor() {}

    public setChannel(channel) {
        this.channel = channel;
        this.channel.onmessage = (event) => {
            this.onReceiveMessage(event);
        };
    }

    public sendFile(file: File): FileTransmitter {
        const sender = new FileSender(getUniqueId(), this.channel, this.receiveMessages, file);
        sender.doStart();
        return sender;
    }

    private onReceiveMessage(event) {
        if (event.data instanceof ArrayBuffer) {
            const protocol = DataChannelProtocolService.readProtocol(event.data);
            if (protocol.protocol === 'file' && protocol.action === 'start') {
                this.onReceiveFileCallback(new FileReceiver(protocol.id, this.channel, this.receiveMessages));
            }
        }
        this.receiveMessages.next(event.data);
    }

    public onClose() {
        this.channel = undefined;
    }
}
