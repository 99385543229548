import { Component, EventEmitter, Output, Input } from '@angular/core';

import { ProgressbarComponentType } from '../progressbar/progressbar.component';
import { FileTransmitter } from '../../provider/data-channel/file-transmitter';
import { FileSender } from '../../provider/data-channel/file-sender';


@Component({
    selector: 'app-receive-container',
    templateUrl: './receive-container.component.html',
    styleUrls: ['./receive-container.component.scss']
})
export class ReceiveContainerComponent {
    @Input() receiverList: FileTransmitter[] = [];
    @Input() downloadedIdList = [];
    @Output() itemDownloadedEvent = new EventEmitter<string>();

    constructor() {
    }

    onFileDownloaded($event) {
        this.itemDownloadedEvent.emit($event);
    }

    isItemDownloaded(id: string): boolean {
        return this.downloadedIdList.includes(id);
    }

    getProgressbarType(transmitter: FileTransmitter) {
        return (transmitter instanceof FileSender) ? ProgressbarComponentType.UPLOADED : ProgressbarComponentType.RECEIVING;
    }
}
