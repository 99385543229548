import { ElementRef, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { InstitutionSettings } from '../../waitingroom/store/waitingroom.effect';

@Injectable({
    providedIn: 'root'
})
export class JitsiExternalApiService {

    constructor() {
    }

    private static parentNode: ElementRef;

    private readonly noSSL = environment.jitsi.externalApi.noSSL;
    private api: JitsiMeetExternalAPI;

    public start(jwt: string, roomName: string, inst: InstitutionSettings, peerBrowser: string): void {
        const option: JitsiMeetAPI.Option = {
            roomName,
            jwt,
            parentNode: JitsiExternalApiService.parentNode.nativeElement,
            noSSL: this.noSSL,
            browserName: peerBrowser,
            inst,
            configOverwrite: {
                prejoinPageEnabled: true
            }
        };
        let url = window.location.hostname + environment.jitsi.externalApi.iframePrefix;
        this.api = new JitsiMeetExternalAPI(url, option);
    }

    public getApi(): JitsiMeetExternalAPI {
        return this.api;
    }

    public setParentNode(view: ElementRef) {
        JitsiExternalApiService.parentNode = view;
    }
}
