import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class BodyService {
    private renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    addClass(name: string): void {
        this.renderer.addClass(this.document.body, name);
    }

    removeClass(name: string): void {
        this.renderer.removeClass(this.document.body, name);
    }
}
