<div class='content-wrapper card move-up product-list-space' *ngIf="!paymentInfo || isEditing">
    <div class="legal-user-creation-form">
        <form [formGroup]="createLegalUserForm" role="form">
            <div class='card-content'>
                <input type="hidden"
                       formControlName="companyName"
                       class="form-rounded form-control"
                       id="companyName"
                       [placeholder]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.PLACEHOLDER.COMPANY-NAME' | translate"
                       [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.COMPANY-NAME' | translate"
                       placement="bottom"
                       triggers="manual"
                       appInvalidDirective>
                <div class="form-row">
                    <div class="form-group-header">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.COMPANY-REPRESENT-INFO' |
                        translate }}
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="firstName">
                            <span class='required'>*</span>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.FIRST-NAME' |
                            translate }}
                        </label>
                        <input type="text"
                               formControlName="firstName"
                               class="form-rounded form-control"
                               id="firstName"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.PLACEHOLDER.FIRST-NAME' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.FIRST-NAME' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="lastName">
                            <span class='required'>*</span>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.LAST-NAME' |
                            translate }}
                        </label>
                        <input type="text"
                               formControlName="lastName"
                               class="form-rounded form-control"
                               id="lastName"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.PLACEHOLDER.LAST-NAME' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.LAST-NAME' | translate"
                               placement="bottom"
                               triggers="manual" appInvalidDirective>
                    </div>
                    <div formGroupName="birthday" class="form-group col-md-4">
                        <label for="birthday">
                            <span class='required'>*</span>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.BIRTHDAY' |
                            translate }}
                        </label>
                        <div class="form-group form-inline" id="birthday-container"
                             [class.ng-invalid]="!isBirthdayValid()"
                             [class.ng-valid]="isBirthdayValid()"
                             [class.ng-dirty]="isBirthdayElementDirty()"
                             [class.ng-touched]="isBirthdayElementTouched()">
                            <input type="text"
                                   formControlName="birthDay"
                                   size="2"
                                   (input)="goToNextInput($event)"
                                   maxlength="2"
                                   class="birthday-fields birthday-fields-size-2"
                                   id="birthDay"
                                   [placeholder]="'GENERAL.PLACEHOLDER-DAY' | translate">.
                            <input type="text"
                                   formControlName="birthMonth"
                                   size="2"
                                   (input)="goToNextInput($event)"
                                   maxlength="2"
                                   class="birthday-fields birthday-fields-size-2"
                                   id="birthMonth"
                                   [placeholder]="'GENERAL.PLACEHOLDER-MONTH' | translate">.
                            <input type="text"
                                   formControlName="birthYear"
                                   size="4"
                                   maxlength="4"
                                   class="birthday-fields birthday-fields-size-4"
                                   id="birthYear"
                                   [placeholder]="'GENERAL.PLACEHOLDER-YEAR' | translate"
                                   [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.BIRTHDAY' | translate"
                                   placement="bottom"
                                   triggers="manual"
                                   appInvalidDirective>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="email">
                            <span
                                class='required'>*</span>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.EMAIL' | translate }}
                        </label>
                        <input type="text"
                               formControlName="email"
                               class="form-rounded form-control"
                               id="email"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.PLACEHOLDER.EMAIL' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.EMAIL' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="country">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.COUNTRY' | translate }}
                        </label>
                        <span class='fal fa-question-circle'
                              aria-hidden="true"
                              placement="bottom"
                              ngbTooltip="{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TOOLTIPS.COUNTRY' | translate }}"></span>
                        <select formControlName="country"
                                id="country"
                                class="form-control input-select"
                                placement="bottom"
                                triggers="manual"
                                [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.COUNTRY' | translate"
                                [compareWith]="compareByCountry"
                                appInvalidDirective>
                            <option *ngFor="let country of countries" [ngValue]="country">{{country.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="nationality">
                            <span class='required'>*</span>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.NATIONALITY' |
                            translate }}
                        </label>
                        <select formControlName="nationality"
                                id="nationality"
                                class="form-control input-select"
                                placement="bottom"
                                triggers="manual"
                                [ngbTooltip]="'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.ERRORS.NATIONALITY' | translate"
                                [compareWith]="compareByCountry"
                                appInvalidDirective>
                            <option *ngFor="let country of countries" [ngValue]="country">
                                {{country.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-footer clearfix">
                <div class="footer-left">
                    <span class='required'>*</span>{{ 'GENERAL.REQUIRED' | translate }}
                </div>
                <div class="footer-right">
                    <button *ngIf="!!paymentInfo" id="cancelButton" class="btn btn-link color-white" (click)="cancel()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                    <button type="submit" id='submitButton' class="btn btn-primary" (click)="submit()">
                        {{ 'GENERAL.SUBMIT' | translate }}
                        <span class='fal fa-check-circle' aria-hidden="true"></span>
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>
<div class='content-wrapper card move-up' *ngIf="!!paymentInfo && isEditing === false">
    <div class='card-content'>
        <div class='row'>
            <div class="not-finished" *ngIf="isNotFinished === true && userInfoMessage === true">
                <span class="not-finished-header">{{ 'PAYMENT.PAYMENT-INFO.STATUS.CREATE_PENDING' | translate }}</span>
            </div>
            <div class="not-finished" *ngIf="isNotFinished === true && userInfoMessage === false">
                <span class="not-finished-header">{{ 'PAYMENT.PAYMENT-INFO.STATUS.UPDATE_PENDING' | translate }}</span>
            </div>
        </div>
        <div class='row'>
            <div class='col-md-4'>
                <span class='bold color'>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.COMPANY-INFO' | translate }}</span>
            </div>
            <div class='col-md-8'>
                <div class="item">
                    <span
                        class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.COMPANY-NAME' | translate}}
                        : </span>
                    <span>{{paymentInfo.companyName}}</span>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='col-md-4'>
                <span
                    class='bold color'>{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.COMPANY-REPRESENT-INFO' | translate }}</span>
            </div>
            <div class='col-md-8'>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.FIRST-NAME' | translate }}
                        : </span>
                    <span>{{paymentInfo.firstName}}</span>
                </div>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.LAST-NAME' | translate }}
                        : </span>
                    <span>{{paymentInfo.lastName}}</span>
                </div>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.BIRTHDAY' | translate }}
                        : </span>
                    <span> {{paymentInfo.birthday | date:'dd.MM.yyyy'}}</span>
                </div>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.EMAIL' | translate }}
                        : </span>
                    <span>{{paymentInfo.email}}</span>
                </div>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.COUNTRY' | translate }}
                        : </span>
                    <span>{{paymentInfo.country.name}}</span>
                </div>
                <div class="item">
                    <span
                        class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.CREATE-USER.LEGAL.NATIONALITY' | translate }}
                        : </span>
                    <span>{{paymentInfo.nationality.name}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
