<div class="modal-header clearfix">
    <i class="fal fa-desktop"></i>
    <h4 class="modal-title">
        {{ 'RTC_DETECTION.CHROME_EXTENSION.TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelStep()"><span
        aria-hidden="true"><i class="far fa-times"></i></span></button>
</div>
<div class="modal-body">
    <div class="body-headline clearfix">
        <img src="assets/img/browser/if_chrome.png">
        <h5>{{'RTC_DETECTION.CHROME_EXTENSION.HEADLINE' | translate }}</h5>
    </div>

    <div class="body-content">
        <div class="exclamation">
            <i class="fas fa-exclamation-triangle"></i>
            {{ 'RTC_DETECTION.CHROME_EXTENSION.EXCLAMATION' | translate }}
        </div>

        <div class="text">{{ 'RTC_DETECTION.CHROME_EXTENSION.TEXT' | translate }}</div>

        <div class="note">
            <b>{{ 'RTC_DETECTION.CHROME_EXTENSION.NOTE' | translate }}</b>
            {{ 'RTC_DETECTION.CHROME_EXTENSION.NOTE_TEXT' | translate }}
        </div>

        <div class="link">
        <button type="button" class="btn btn-primary" (click)="install()">
            <i class="fas fa-puzzle-piece"></i> {{'RTC_DETECTION.CHROME_EXTENSION.INSTALL' | translate }}
        </button>
        </div>

    </div>
</div>
<div class="modal-footer">
    <div class="button-line">
        <button type="button" class="btn btn-outline-dark" (click)="cancelStep()">
            {{'RTC_DETECTION.CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="continue()">
        <span>
            {{'RTC_DETECTION.FINISH' | translate }}
             <i class="fal fa-check-circle"></i>
        </span>
        </button>
    </div>

    <app-rtc-footer></app-rtc-footer>

</div>

