import { Injectable, OnDestroy } from '@angular/core';
import { SignalConnection } from '../../signal/providers/signal.connection';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';
import { XmppMessage, XmppMessageService } from '../../user-presence/provider/xmpp-message.service';

const FILE_TRANSFER_MESSAGE_TYPE = 'FILE_TRANSFER_MESSAGE_TYPE';

class FileTransferMessage implements XmppMessage {
    type = FILE_TRANSFER_MESSAGE_TYPE;
    constructor(public signal: string, public callerProfileId) {
    }
}

@Injectable()
export class SignalService implements OnDestroy {
    private readonly subscription: Subscription;
    public signal$ = new BehaviorSubject<FileTransferMessage>(null);

    constructor(
        private xmppMessageService: XmppMessageService,
        private profileSelectService: ProfileSelectService
    ) {
        this.subscription = this.bindEvents().subscribe();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private bindEvents(): Observable<FileTransferMessage> {
        return this.xmppMessageService.listen(FILE_TRANSFER_MESSAGE_TYPE)
            .pipe(
                tap((data: FileTransferMessage) => this.handle(data))
            );
    }

    public sendSignal(data: any, peerProfileId: number) {
        const myProfileId = this.profileSelectService.getCurrentProfileId();
        const message: FileTransferMessage = new FileTransferMessage(data, myProfileId);
        this.xmppMessageService.send(message, peerProfileId);
    }

    public getSignal(next: (value: FileTransferMessage) => void): Subscription {
        return this.signal$.pipe(filter(v => v !== null)).subscribe(next);
    }

    private handle(msg: FileTransferMessage): void {
        if (msg.signal) {
            this.signal$.next(msg);
        }
    }
}
