import { RtcDetectionComponent } from './rtc-detection.component';
import { NextStepService } from './providers/next-step.service';
import { StepHardwareTestComponent } from './step-hardware-test/step-hardware-test.component';
import { RtcDetectionStepDirective } from './directive/rtc-detection-step-directive';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { rtcDetectionReducer } from './store/rtc-detection.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RtcDetectionEffects } from './store/rtc-detection.effect';
import { StepWarningComponent } from './step-warning/step-warning.component';
import { ModalCarrierService } from './providers/modal-carrier.service';
import { StepHardwareVerifyComponent } from './step-hardware-verify/step-hardware-verify.component';
import { MediaModule } from '../media/media.module';
import { StepSuccessComponent } from './step-success/step-success.component';
import { StepChromeExtensionComponent } from './step-chrom-extension/step-chrome-extension.component';
import { RtcDetectionService } from './providers/rtc-detection.service';
import { StepNoCameraAccessComponent } from './step-no-camera-access/step-no-camera-access.component';
import { StepBrowserCheckComponent } from './step-browser-check/step-browser-check.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { PreInitConditionService } from '../pre-init-condition/providers/pre-init-condition.service';
import { PreInitConditionModule } from '../pre-init-condition/pre-init-condition.module';
import { BrandingService } from '../branding/providers/branding.service';
import { UserPresenceModule } from '../user-presence/user-presence.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('rtcDetection', rtcDetectionReducer),
        EffectsModule.forFeature([RtcDetectionEffects]),
        MediaModule,
        NgxPermissionsModule,
        RouterModule,
        PreInitConditionModule,
        // UserPresenceModule
    ],
    declarations: [
        RtcDetectionStepDirective,
        RtcDetectionComponent,
        StepHardwareTestComponent,
        StepHardwareVerifyComponent,
        StepChromeExtensionComponent,
        StepNoCameraAccessComponent,
        StepWarningComponent,
        StepSuccessComponent,
        StepBrowserCheckComponent,
        FooterComponent
    ],
    providers: [
        NextStepService,
        RtcDetectionService,
        BrandingService
    ],
    exports: [RtcDetectionComponent]
})
export class RtcDetectionModule {
    public static forRoot(): ModuleWithProviders<RtcDetectionModule> {
        return {ngModule: RtcDetectionModule, providers: [ModalCarrierService]};
    }
}
