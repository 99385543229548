export enum FileTransferEventType {
    Init = 'INIT',
    Start = 'START',
    Chunk = 'CHUNK',
    Complete = 'COMPLETE',
    Error = 'ERROR',
    Cancelled = 'CANCELLED'
}

export interface FileTransferEvent {
    type: FileTransferEventType;
    chunkNumber?: number;
    amountChunk?: number;
    chunkSize?: number;
    chunk?: ArrayBuffer;
    file?: {name: string; size: number};
    blob?: Blob;
    msg?: string;
}

export class FileTransferInitEvent implements FileTransferEvent {
    public type = FileTransferEventType.Init;
}

export class FileTransferStartEvent implements FileTransferEvent {
    public type = FileTransferEventType.Start;
    constructor(
        public amountChunk: number,
        public file:  {name: string; size: number; type: string}
    ) {}
}

export class FileTransferChunkEvent implements FileTransferEvent {
    public type = FileTransferEventType.Chunk;
    constructor(
        public chunkNumber: number,
        public chunkSize: number,
        public chunk: ArrayBuffer
    ) {}
}

export class FileTransferCompleteEvent implements FileTransferEvent {
    public type = FileTransferEventType.Complete;
    constructor(public blob: Blob) {}
}

export class FileTransferErrorEvent implements FileTransferEvent {
    public static WRONG_FILE_EXTENSION = 'FILE_TRANSFER.ERROR.FILE_HAS_WRONG_EXTENSION';
    public static UNSUPPORTED_FILE_EXTENSION = 'FILE_TRANSFER.ERROR.UNSUPPORTED_FILE_EXTENSION';
    public static FILE_IS_EMPTY = 'FILE_TRANSFER.ERROR.FILE_IS_EMPTY';
    public static CAN_NOT_OPEN_FILE = 'FILE_TRANSFER.ERROR.CAN_NOT_OPEN_FILE';
    public type = FileTransferEventType.Error;
    constructor(public msg: string) {}
}

export class FileTransferCancelledEvent implements FileTransferEvent {
    public type = FileTransferEventType.Cancelled;
    constructor() {}
}
