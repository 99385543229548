import { Injectable } from '@angular/core';
import { PaymentBankAccountDto } from './payment-bank-account-dto.model';
import deleteProperty = Reflect.deleteProperty;
import { PaymentBankAccount } from '../../store/payment-bank-account.model';

@Injectable()
export class PaymentBankAccountMapperService {

    constructor() {
    }

    public static mapDtoToEntity(dto: PaymentBankAccountDto): PaymentBankAccount {
        if (Object.keys(dto).length === 0) {
            return null;
        }
        const entity = {
            ...dto,
            id: (dto.hasOwnProperty('bankAccountId')) ? dto.bankAccountId : null,
            plz: dto.zipCode
        };
        if (entity.hasOwnProperty('bankAccountId')) {
            deleteProperty(entity, 'bankAccountId');
        }

        delete entity.zipCode;

        return entity;
    }

    public static mapEntityToDto(entity: PaymentBankAccount): PaymentBankAccountDto {
        if (!entity) {
            return null;
        }
        const dto = {
            ...entity,
            bankAccountId: entity.id,
            zipCode: entity.plz
        };

        delete dto.plz;
        deleteProperty(dto, 'id');
        if (dto.bankAccountId === null) {
            deleteProperty(dto, 'bankAccountId');
        }

        return dto;
    }

}
