import { Injectable } from '@angular/core';
import { UserPresenceStoreService } from './user-presence-store.service';
import { XmppLogger } from '../xmpp/xmpp.logger';
import { UserPresence } from '../store/user-presence.model';
import { XmppUtil } from '../xmpp/xmpp.util';
import { UserPresenceStatus } from '../user-presence-status';
import { XmppUser } from '../xmpp/xmpp-user.model';

@Injectable()
export class XmppPresenceService {

    private connection;
    private xmppUser: XmppUser;

    constructor(
        private store: UserPresenceStoreService,
        private log: XmppLogger) {
    }

    public init(connection, xmppUser: XmppUser): void {
        this.connection = connection;
        this.xmppUser = xmppUser;
    }

    public onPresence(presence): boolean {
        const ptype = $(presence).attr('type');
        const from = $(presence).attr('from');
        const resource = XmppUtil.getResourceFromJid(from);
        const profileId = XmppUtil.getIdFromJid(from);

        // we are not interested on our own presence
        if (this.xmppUser.jId === XmppUtil.getBareJidFromJid(from)) {
            return true;
        }

        if (ptype === 'subscribe') {
            const subscriber = from;
            this.connection.send($pres({to: subscriber, type: 'subscribed'}));
            this.connection.send($pres({to: subscriber, type: 'subscribe'}));
            // @TODO set subscriber to online
        } else if (ptype !== 'error') {
            const userPresence = UserPresence.createNewUserPresence(profileId, UserPresenceStatus.OFFLINE, resource);
            if (ptype === 'unavailable') {
                this.store.upsertPresence(userPresence);
            } else {
                const show = $(presence).find('show').text();
                if (show === '' || show === 'chat') {
                    userPresence.status = UserPresenceStatus.ONLINE;
                } else {
                    userPresence.status = UserPresenceStatus.AWAY;
                }
                this.store.upsertPresence(userPresence);
            }
        }
        return true;
    }

    destroy() {
        // nothing ro do
    }
}
