import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState, RegisterAccountState } from '../store/register.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StepAccountFormBuilder } from './step-account-form.builder';
import { RegisterAddAccountAction } from '../store/register.action';
import { ScrollService } from '../providers/scroll.service';
import { AnalyticsService } from '../../../providers/analytics/analytics.service';
import { NextStepFrom } from '../navigation/navigation.types';

@Component({
    selector: 'app-step-account',
    templateUrl: './step-account.component.html',
    styleUrls: ['./step-account.component.scss']
})
export class StepAccountComponent {
    public accountForm: FormGroup;

    constructor(private store: Store<AppState>,
                private translate: TranslateService,
                private scroll: ScrollService,
                private analytics: AnalyticsService,
                fb: FormBuilder) {
        this.accountForm = StepAccountFormBuilder.build(fb);
        this.accountForm.valueChanges.subscribe(this.dispatch.bind(this));
    }

    onSubmit() {
        if (this.accountForm.valid) {
            this.scroll.scrollToStep(NextStepFrom.ACCOUNT);
            this.analytics.trackNewPageView('/selectPackage.html', 'Select Package');
        } else {
            Object.keys(this.accountForm.controls).forEach(key => {
                this.accountForm.get(key).markAsTouched();
            });
            this.analytics.trackWrongFormInputEvent(this.accountForm);
        }
    }

    dispatch(formvalue) {
        const isNewsletterReceiving = false;
        const isAgreed = (formvalue.terms === true) ? true : false;
        const account = new RegisterAccountState(
            this.accountForm.valid,
            formvalue.email,
            formvalue.password,
            isNewsletterReceiving,
            isAgreed,
        );
        this.store.dispatch(new RegisterAddAccountAction(account));
        return formvalue;
    }
}
