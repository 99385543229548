import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaSeperated'
})
export class CommaSeperatorPipe implements PipeTransform {

    transform(val: string | null): string {
        if (val !== undefined && val !== null) {
            return val.toString().replace('.', ',');
        } else {
            return '';
        }
    }
}
