<!-- Deutsch Version -->
<div class="legal" *ngIf="(currentLanguage$ | async) === 'de' else englishVersion">
    <p>Nachfolgend informieren wir Sie entsprechend den gesetzlichen Vorgaben – insbesondere der EU-Datenschutzgrundverordnung (DSGVO, abrufbar unter <a target="_blank" href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU">http://eur-lex.europa.eu</a>) – über die Verarbeitung personenbezogener Daten durch unser Unternehmen.</p>
    <p>Inhaltsverzeichnis:</p>
    <ol type="I">
        <li>
            Allgemeine Informationen
            <ol>
                <li>Wichtige Begriffe</li>
                <li>Geltungsbereich</li>
                <li>Verantwortlicher</li>
                <li>Datenschutzbeauftragter</li>
            </ol>
        </li>
        <li>
            Gemeinsame Verantwortlichkeit
        </li>
        <li>
            Die Datenverarbeitungen im Einzelnen
            <ol>
                <li>Allgemeines zu den Datenverarbeitungen</li>
                <li>Aufrufen unserer Services</li>
                <li>Videosprechstunden: Behandler</li>
                <li>Videosprechstunden: Patienten</li>
                <li>Kontaktaufnahme und Feedback</li>
                <li>Videosprechstunde Siegel und Widget</li>
            </ol>
        </li>
        <li>
            Betroffenenrechte
            <ol>
                <li>Widerspruchsrecht</li>
                <li>Auskunftsrecht</li>
                <li>Berichtigungsrecht</li>
                <li>Recht auf Löschung („Recht auf Vergessenwerden“)</li>
                <li>Recht auf Einschränkung der Verarbeitung</li>
                <li>Recht auf Datenübertragbarkeit</li>
                <li>Widerrufsrecht bei Einwilligung</li>
                <li>Beschwerderecht</li>
            </ol>
        </li>
    </ol>
    <br/>
    <ol type="I">
        <li>
            <strong>Allgemeine Informationen</strong>
            <p>
                <strong>In diesem Abschnitt der Datenschutzerklärung finden Sie Informationen zum Geltungsbereich, zum Verantwortlichen für die Datenverarbeitung, dessen Datenschutzbeauftragtem und zur Datensicherheit. Außerdem erklären wir vorab die Bedeutung wichtiger Begriffe, die in der Datenschutzerklärung verwendet werden.<br/></strong>
            </p>
            <ol>
                <li>
                    <strong>Wichtige Begriffe</strong>
                    <p><strong>Browser:</strong> Computerprogramm zur Darstellung von Webseiten (z.B. Chrome, Firefox, Safari)<br/></p>
                    <p><strong>Cookies:</strong> Textdateien, die der aufgerufene Webserver mittels des verwendeten Browsers auf dem Computer des Nutzers platziert. Die gespeicherten Cookie-Informationen können sowohl eine Kennung (Cookie-ID) enthalten, die zur Wiedererkennung dient, als auch inhaltliche Angaben wie Anmeldestatus oder Informationen über besuchte Webseiten. Der Browser sendet die Cookie-Informationen bei späteren, neuen Besuchen dieser Seite mit jeder Anforderung wieder an den Webserver. Die meisten Browser akzeptieren Cookies automatisch.<br/></p>
                    <p><strong>Drittländer:</strong> Länder außerhalb der Europäischen Union (EU)<br/></p>
                    <p><strong>DSGVO:</strong> Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogenerDaten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung), abrufbar unter <a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU</a><br/></p>
                    <p><strong>Personenbezogene Daten:</strong> Alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.<br/></p>
                    <p><strong>Profiling:</strong> Jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen<br/></p>
                    <p><strong>Services:</strong> Unsere Angebote, für die diese Datenschutzerklärung gilt (siehe <a href="#geltungsbereich">Geltungsbereich</a>).<br/></p>
                    <p><strong>Tracking:</strong> Die Sammlung von Daten und deren Auswertung bzgl. des Verhaltens von Besuchern auf unseren Services.<br/></p>
                    <p><strong>Tracking-Technologien:</strong> Tracking kann sowohl über die auf unseren Webservern gespeicherten Aktivitätsprotokolle (Logfiles) erfolgen als auch mittels Datenerhebung von Ihrem Endgerät über Pixel, Cookies und ähnliche Tracking-Technologien.<br/></p>
                    <p><strong>Verarbeitung:</strong> Jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.<br/></p>
                    <p><strong>Pixel:</strong> Pixel werden auch Zählpixel, Trackingpixel, Web Beacons oder Web-Bugs genannt. Es handelt sich um kleine, nicht sichtbare Grafiken in HTML-E-Mails oder auf Webseiten. Wird ein Dokument geöffnet, dann wird dieses kleine Bild von einem Server im Internet geladen, wobei das Herunterladen dort registriert wird. So kann der Betreiber des Servers sehen, ob und wann eine E-Mail geöffnet oder eine Webseite besucht wurde. Meist wird diese Funktion durch den Aufruf eines kleinen Programms (Javascript) realisiert. So können bestimmte Arten von Informationen auf Ihrem Computersystem erkannt und weitergegeben werden, etwa der Inhalt von Cookies, Zeit und Datum des Seitenaufrufs sowie eine Beschreibung der Seite, auf der sich das Zählpixel befindet.<br/></p>
                </li>
                <li>
                    <strong><a id="geltungsbereich">Geltungsbereich</a></strong>
                    <p>Diese Datenschutzerklärung gilt für folgende Angebote:</p>
                    <ul>
                        <li>unser Onlineangebot Helios-Videosprechstunde, abrufbar insbesondere unter <a target="_blank" href="https://helios-videosprechstunde.patientus.de">https://helios-videosprechstunde.patientus.de</a></li>
                    </ul><br/>
                    <p>Alle diese Angebote werden zusammen auch als „Services“ bezeichnet.</p>
                </li>
                <li>
                    <strong>Verantwortlicher</strong>
                    <p>Verantwortlicher für die Datenverarbeitung– also derjenige, der über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet – im Zusammenhang mit den Services ist</p>
                    <p>
                        Helios Kliniken GmbH<br/>
                        Friedrichstr. 136, 10117 Berlin<br/>
                        030 52 13 21 - 0<br/>
                        info@helios-gesundheit.de<br/>
                        <a target="_blank" href="https://www.helios-gesundheit.de/impressum/">https://www.helios-gesundheit.de/impressum/</a>
                    </p>
                </li>
                <li>
                    <strong>Datenschutzbeauftragter</strong>
                    <p>Kontaktadresse: <a href="mailto:datenschutz@helios-gesundheit.de">datenschutz@helios-gesundheit.de</a></p>
                </li>
                <li>
                    <strong>Weiterer Verantwortlicher im Sinne von Art. 26 DSGVO</strong>
                    <p>jameda GmbH, Balanstraße 71a, 81541 München</p>
                </li>
                <li>
                    <strong>Datenschutzbeauftragter von Jameda</strong>
                    <p>Kontaktadresse: <a href="mailto:datenschutz@jameda.de">datenschutz@jameda.de</a></p>
                </li>
            </ol>
        </li>
        <li>
            <strong>Gemeinsame Verantwortlichkeit</strong>
            <p>
                <strong>
                    Wir (Helios Kliniken GmbH) haben mit der jameda GmbH eine Vereinbarung über eine gemeinsame
                    Verantwortlichkeit nach Art. 26 DSGVO geschlossen. Dies betrifft die Erbringung unseres
                    OnlineangebotsHelios-Videosprechstunde, für das Jameda im Wesentlichen technische Dienstleistungen
                    erbringt. Die Parteien haben gemeinsam festgelegt, für welche Datenverarbeitungsvorgänge bzw.
                    Prozessabschnitte eine gemeinsame Verantwortlichkeit besteht und welche Partei nach der internen
                    Verteilung im Einzelnen verantwortlich ist. Diese Festlegung finden Sie nachfolgend unter III.
                </strong>
            </p>
            <p>Die Parteien haben außerdem vereinbart, dass siedie Betroffenenrechte nach der DSGVO (näheres hierzu finden Sie im Abschnitt IV.) entsprechend der vereinbarten Verteilung der Verantwortlichkeit wie folgt wahrnehmen:</p>
            <p>- jameda GmbH für die Verarbeitung der personenbezogenen Daten innerhalb des VerarbeitungsschrittsAufruf des Services (siehe unter III.2) für die Datenarten/-kategorien: Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten,Profildaten sowie Zugriffsdaten und Termindaten Videosprechstunde.</p>
            <p>- jameda GmbH innerhalb des VerarbeitungsschrittsVideosprechstundenBehandler, d.h. z.B. Ärzte, Psychotherapeuten(siehe unter III. 3)für die Datenarten/-kategorien: Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten, Profildaten sowie Zugriffsdaten und Nutzungsdaten zu den einzelnen Features des Login-Bereichs und Termindaten Videosprechstunde.</p>
            <p>- jameda GmbH für die Verarbeitung der personenbezogenen Daten innerhalb des Verarbeitungsschritts VideosprechstundenPatienten (siehe unter III.4)für die Datenarten/-kategorien: Accountdaten, Personenstammdaten, Adressdaten, Anmeldedaten, Profildaten sowie Zugriffsdaten.</p>
            <p>- jameda GmbH für die Verarbeitung der personenbezogenen Daten innerhalb des VerarbeitungsschrittsKontaktaufnahme und Feedback(siehe unter III.5) für die Datenarten/-kategorien: Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten, Profildaten, Inhalte der Anfragen/Beschwerden sowie Zugriffsdaten und Termindaten Videosprechstunde.</p>
            <p>Die Parteien informieren sich unverzüglich gegenseitig über von Betroffenen geltend gemachte Rechtspositionen. Sie stellen einander sämtliche für die Beantwortung von Auskunftsersuchen notwendigen Informationen zur Verfügung.</p>
            <p>Datenschutzrechte können Sie sowohlbei Helios Kliniken GmbH als bei jameda GmbHgeltend machen. Betroffene erhalten die Auskunft grundsätzlich von der Stelle, bei der Rechte geltend gemacht wurden.</p>
        </li>
        <li>
            <strong>Die Datenverarbeitungen im Einzelnen</strong>
            <p>In diesem Abschnitt der Datenschutzerklärung informieren wir Sie im Detail über die Verarbeitung personenbezogener Daten im Rahmen unserer Services. Zur besseren Übersichtlichkeit gliedern wir diese Angaben nach bestimmten Funktionalitäten unserer Services. Bei der normalen Nutzung der Services können verschiedene Funktionalitäten und damit auch verschiedene Verarbeitungen nacheinander oder gleichzeitig zum Tragen kommen.</p>
            <ol>
                <li>
                    <strong>Allgemeines zu den Datenverarbeitungen</strong>
                    <p>Für alle nachstehend dargestellten Verarbeitungen gilt, soweit nichts anderes angegeben wird:</p>
                    <ol type="a">
                        <li>
                            <strong>Keine Verpflichtung zur Bereitstellung & Folgen der Nichtbereitstellung</strong>
                            <p>Die   Bereitstellung   der   personenbezogenen   Daten   ist   nicht   gesetzlich   oder   vertraglich vorgeschrieben und Sie sind nicht verpflichtet, Daten bereitzustellen. Wir informieren Sie im Rahmen  des  Eingabeprozesses,  wenn  die  Bereitstellung  personenbezogener  Daten für  den jeweiligen  Service  erforderlich  ist  (z.B.  durch  die  Bezeichnung  als  „Pflichtfeld“).  Bei erforderlichen Daten hat die Nichtbereitstellung zur Folge, dass der betreffende Service nicht erbracht werden kann. Ansonsten hat die Nichtbereitstellung ggf. zur Folge, dass wir unsere Dienste nicht in gleicher Form und Qualität erbringen können.</p>
                        </li>
                        <li>
                            <strong>Einwilligung</strong>
                            <p>In   verschiedenen   Fällen   haben   Sie   die   Möglichkeit,   uns   im   Zusammenhang   mit   den nachstehend dargestellten Verarbeitungen auch (ggf. für einen Teil der Daten Ihre Einwilligung in   weitergehende   Verarbeitungen   zu   erteilen.   In   diesem   Fall   informieren   wir   Sie   im Zusammenhang  mit  der  Abgabe  der  jeweiligen Einwilligungserklärunggesondert  über  alle Modalitäten  und  die  Reichweite  der  Einwilligung  und  über  die  Zwecke,  die  wir  mit  diesen Verarbeitungen verfolgen. Die auf Ihrer Einwilligung basierenden Verarbeitungen sind daher hier nicht nochmals aufgeführt (Art. 13 Abs.4 DSGVO).</p>
                        </li>
                        <li>
                            <strong>Übermittlung personenbezogener Daten an Drittländer</strong>
                            <p>Wenn wir Daten an Drittländer, d.h. Länder außerhalb der Europäischen Union, übermitteln, dann  findet  die  Übermittlung  ausschließlich  unter  Einhaltung  der  gesetzlich  geregelten Zulässigkeitsvoraussetzungen statt.</p>
                            <p>Wenn  die  Übermittlung  der  Daten  an  ein  Drittland  nicht  zur  Erfüllung  unseres  Vertrags  mit Ihnen    dient,    wir    keine    Einwilligung    Ihrerseits    haben,    die    Übermittlung    nicht    zur Geltendmachung,  Ausübung  oder  Verteidigung  von  Rechtsansprüchen  erforderlich  ist  und auch sonst keine Ausnahmeregelung nach Art. 49 DSGVO gilt, übermitteln wir Ihre Daten nur dann  an  ein  Drittland,  wenn  ein  Angemessenheitsbeschluss  nach  Art.  45  DSGVO  oder geeignete Garantien nach Art. 46 DSGVO vorliegen.</p>
                            <p>
                                Wir  erfüllen  durch  den  Abschluss  der  von  der  Europäischen  Kommission  erlassenen  EU-Standarddatenschutzklauseln    mit    der    empfangenden    Stelle    die    Anforderungen    zur Überprüfung hinsichtlich geeigneter Garantien nachArt. 46 Abs. 2 c) DSGVO, sowie hinsichtlich eines angemessenen Datenschutzniveaus imDrittland. Kopien der EU-Standarddatenschutzklauseln  erhalten  Sie  auf  der  Website  der  Europäischen  Kommission, abrufbarunter:
                                <br>
                                <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de</a>
                            </p>
                        </li>
                        <li>
                            <strong>Hosting bei externen Dienstleistern</strong>
                            <p>Unsere Datenverarbeitung erfolgt in weitem Umfang unter Einschaltung sog. Hostingdienstleister, die uns Speicherplatz und Verarbeitungskapazitäten in ihren Rechenzentren zur Verfügung stellen und nach unserer Weisung auch personenbezogene Daten in unserem Auftrag verarbeiten. Es kann bei allen nachstehend genannten Funktionalitäten geschehen, dass personenbezogene Daten an Hostingdienstleister übermittelt werden. Diese Dienstleister verarbeiten Daten entweder ausschließlich in der EU oder wir haben mithilfe der EU-Standarddatenschutzklauseln (siehe unter c.) ein angemessenes Datenschutzniveau garantiert.</p>
                        </li>
                        <li>
                            <strong>Übermittlung an staatliche Behörden</strong>
                            <p>Wir übermitteln personenbezogene Daten an staatliche Behörden (einschließlich Strafverfolgungsbehörden), wenn dies zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der wir unterliegen (Rechtsgrundlage: Art. 6 Abs. 1 c) DSGVO) oder es zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist (Rechtsgrundlage Art. 6 Abs. 1 f) DSGVO).</p>
                        </li>
                        <li>
                            <strong>Speicherdauer</strong>
                            <p>In der Rubrik „Speicherdauer“ ist jeweils angegeben, wie lange wir die Daten für den jeweiligen Verarbeitungszweck nutzen. Nach Ablauf dieser Zeit werden die Daten nicht mehr von uns verarbeitet, sondern in regelmäßigen Abständen gelöscht, sofern nicht eine fortdauernde Verarbeitung und Speicherung gesetzlich vorgesehen ist (insbes. weil sie erforderlich ist zur Erfüllung einer rechtlichen Verpflichtung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen) oder Sie uns eine darüber hinausgehende Einwilligung erteilen.</p>
                        </li>
                        <li>
                            <strong>Funktionsdauer von Cookies</strong>
                            <p>Die in den folgenden Abschnitten dargestellten Datenverarbeitungen erfolgen zum Teil mithilfe von Cookies. Auf die in einem Cookie gespeicherten Informationen kann über das Internet nur der Betreiber des Webservers zugreifen, der den Cookie ursprünglich gesetzt hat. Ein Zugriff durch Dritte auf diesem Weg ist nicht möglich. Die Cookies haben unterschiedliche Funktionsdauern. Manche Cookies sind nur während einer Browsersitzung aktiv und werden danach gelöscht, andere funktionieren für längere Zeiträume, meist jedoch kürzer als ein Jahr. Nach Ablauf der Funktionsdauer wird ein Cookie durch den Browser gelöscht. Siekönnen Cookies mithilfe der Browserfunktionen (zumeist unter „Optionen“ oder „Einstellungen“) verwalten. Dadurch kann das Speichern von Cookies deaktiviert, von Ihrer Zustimmung im Einzelfall abhängig gemacht oder anderweitig eingeschränkt werden. Sie können Cookies auch jederzeit löschen.</p>
                        </li>
                        <li>
                            <strong>Bezeichnungen von Datenkategorien</strong>
                            <p>In den nächsten Abschnitten werden für bestimmte Datenarten folgende zusammenfassenden Kategorienbezeichnungen verwendet:</p>
                            <ul>
                                <li><strong>Accountdaten:</strong> Login-/Benutzerkennung und Passwort</li>
                                <li><strong>Personenstammdaten:</strong> Titel, Anrede/Geschlecht, Vorname, Nachname, Geburtsdatum</li>
                                <li><strong>Adressdaten:</strong> Straße, Hausnummer, ggf. Adresszusätze, PLZ, Ort, Land</li>
                                <li><strong>Kontaktdaten:</strong> Telefonnummer(n), Telefaxnummer(n), E-Mail-Adresse(n)</li>
                                <li><strong>Anmeldedaten:</strong> Informationen über den Service, über den Sie sich angemeldet haben; Zeitpunkte und technische Informationen zu Anmeldung, Bestätigung und Abmeldung; bei der Anmeldung von Ihnen angegebene Daten</li>
                                <li><strong>Zahlungsdaten:</strong> Kontodaten, Kreditkartendaten, Daten zu anderen Zahlungsdiensten wie Paypal</li>
                                <li><strong>Zugriffsdaten:</strong> Datum und Uhrzeit des Besuchs unseres Service; die Seite, von der das zugreifende System auf unsere Seite gelangt ist; bei der Nutzung aufgerufene Seiten; Daten zur Sitzungsidentifizierung (Session ID); außerdem folgende Informationen des zugreifenden Computersystems: anonymisierte Internet Protokoll-Adresse (IP-Adresse), Browsertyp und -version, Gerätetyp, Betriebssystem und ähnliche technische Informationen.</li>
                                <li><strong>Profildaten:</strong> Daten, die Behandler im Profil hinterlegen</li>
                                <li><strong>Termindaten Videosprechstunde:</strong>
                                    <ul>
                                        <li><strong>Allgemein</strong> (für alle Termine, <u>außer</u> Terminen die gemäß des Einheitlichen Bewertungsmaßstabs (EBM) abgerechnet werden): Daten zu Video-Gesprächs-Terminen (Datum, Uhrzeit, Gesprächspartner, Gesprächsdauer, Terminart, Terminbeschreibung, Terminstatus, Preis, Nachrichten, die im Zuge der Terminvereinbarung versendet werden; Online-Status zur Initialisierung der Videosprechstunde, TAN)</li>
                                        <li><strong>EBM</strong> (für Termine, die gemäß des Einheitlichen Bewertungsmaßstabs (EBM) abgerechnet werden): Daten zu Video-Gesprächs-Terminen (Datum, Uhrzeit, Gesprächspartner, Gesprächsdauer, Terminart, Terminbeschreibung, Terminstatus, Preis, Nachrichten, die im Zuge der Terminvereinbarung versendet werden; Online-Status zur Initialisierung der Videosprechstunde, TAN)</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Keine Verarbeitung von Videogesprächen</strong>
                            <p>Wir verarbeiten keine Daten, die im Rahmen der Videogespräche ausgetauscht werden (Gespräche, Chat, Bilder, Dokumente). Die Videosprechstunde basiert auf einer Peer-to-Peer-Verbindung zwischen den Endgeräten der Gesprächspartner, auf die wir technisch keinen Zugriff haben. Ihre Gespräche, ihr Chatverlauf sowie ausgetauschte Bilder und Dokumente werden von uns nicht erfasst, verarbeitet oder gespeichert. Ihr Videogespräch ist vertraulich.</p>
                        </li>
                    </ol>
                </li>

                <li>
                    <strong>Aufrufen unserer Services</strong>
                    <p>Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten beim Aufrufen unserer Services
                        verarbeitet werden (z.B. Laden und Betrachten der Website, Öffnen und Navigation innerhalb der
                        Mobilgeräte-App). Besonders weisen wir darauf hin, dass die Übermittlung von Zugriffsdaten an
                        externe Inhalteanbieter (siehe unter b.) aufgrund der technischen Funktionsweise der
                        Informationsübertragung im Internet unumgänglich ist. Die Drittanbieter sind selbst für den
                        datenschutzkonformen Betrieb der von ihnen verwendeten IT-Systeme verantwortlich. Die
                        Entscheidung über die Speicherdauer der Daten obliegt den Dienstleistern. Zur Überwachung der
                        Stabilität unserer IT-Systeme setzen wir die Software Matomo der der InnoCraft Ltd., 150 Willis
                        St, 6011 Wellington, New Zealand ein. Diese, auf unserer Webseite eingebundene, Software wird
                        von der jameda GmbH ausschließlich unter Nutzung eigener IT-Systeme betrieben. Eine
                        Datenübermittlung an Dritte erfolgt nicht. Diese Website verwendet die Software Matomo mit der
                        Erweiterung „AnonymizeIP“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine direkte
                        Personenbeziehbarkeit kann damit ausgeschlossen werden. Die Daten werden anonymisiert, d.h. ohne
                        möglichen Rückschluss auf die Person, für einen unbegrenzten Zeitraum gespeichert.</p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Datenverarbeitung und Rechtsgrundlage sowie ggf. berechtigte Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>Zugriffsdaten</td>
                                    <td>Verbindungsaufbau, Darstellung der Inhalte des Service, Entdeckung von Angriffen auf unsere Seite anhand ungewöhnlicher Aktivitäten, Fehlerdiagnose</td>
                                    <td>Art. 6 Abs. 1 f) DSGVO</td>
                                    <td>ordnungsgemäße Funktion der Services, Sicherheit von Daten und Geschäftsprozessen, Verhinderung von Missbrauch, Verhütung von Schäden durch Eingriffe in Informationssysteme</td>
                                    <td>7 Tage </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Verantwortlich gemäß Art. 26 DSGVO</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Verantwortliche Stelle</th>
                                    <th>Betroffene Daten</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                </tr>
                                <tr>
                                    <td>jameda GmbH</td>
                                    <td>Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten, Zugriffsdaten, Profildaten, Termindaten Videosprechstunde Allgemein, Termindaten Videosprechstunde EBM</td>
                                    <td>(Art. 6  Abs.1 f) DSGVO</td>
                                    <td>Die Datenverarbeitung ist notwendig für den Aufruf des von Ihnen gewünschten Services und die Durchführung der Videosprechstunde</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Videosprechstunden: Behandler</strong>
                    <p>Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn sie als Behandler einen kostenpflichtigen Account für die Durchführung von Videosprechstunden bei uns erstellen und verwenden: </p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Datenverarbeitung und Rechtsgrundlage sowie ggf. berechtigte Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>E-Mail-Adresse</td>
                                    <td>Verifikation der Anmeldung (Double Opt-in-Verfahren)</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td></td>
                                    <td>Dauer des Vertragsverhältnisses</td>
                                </tr>
                                <tr>
                                    <td>Personenstammdaten, Kontaktdaten, Adressdaten</td>
                                    <td>Identifizierung, Kontrolle der Berechtigung zum Abruf des Angebots, Kontaktaufnahme, Nachvollziehbarkeit erfolgter Registrierung, Abwicklung von Zahlungen für den Service</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td></td>
                                    <td>Dauer des Vertragsverhältnisses</td>
                                </tr>
                                <tr>
                                    <td>Profildaten</td>
                                    <td>Erbringung unserer Services</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td></td>
                                    <td>Dauer des Vertragsverhältnisses</td>
                                </tr>
                                <tr>
                                    <td>Termindaten Allgemein</td>
                                    <td>Erbringung unserer Services</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td></td>
                                    <td>10 Jahre nach dem Termin</td>
                                </tr>
                                <tr>
                                    <td>Termindaten EBM </td>
                                    <td>Erbringung unserer Services</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td></td>
                                    <td>3 Monate nach dem Termin</td>
                                </tr>
                                <tr>
                                    <td>Zahlungsdaten</td>
                                    <td>Abwicklung von Bestellungen und Zahlungen zur Erbringung unserer Services</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td></td>
                                    <td>Dauer des Vertragsverhältnisses</td>
                                </tr>
                                <tr>
                                    <td>Anmeldedaten, Zugriffsdaten, Nutzungsdaten zu den einzelnen Features des Login-Bereichs</td>
                                    <td>Verbindungsaufbau, Darstellung der Inhalte des Service, Entdeckung von Angriffen auf unsere Seite anhand ungewöhnlicher Aktivitäten, Fehlerdiagnose, Erbringung unserer Services</td>
                                    <td>Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>(im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)</td>
                                    <td>ordnungsgemäße Funktion der Services, Sicherheit von Daten und Geschäftsprozessen, Verhinderung von Missbrauch, Verhütung von Schäden durch Eingriffe in Informationssysteme, Verbesserung unserer Services</td>
                                    <td>Dauer des Vertragsverhältnisses</td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Verantwortlich gemäß Art. 26 DSGVO</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Empfängerkategorie</th>
                                    <th>Betroffene Daten</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                </tr>
                                <tr>
                                    <td>jameda GmbH</td>
                                    <td>Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten, Zugriffsdaten, Profildaten, Termindaten Videosprechstunde Allgemein, Termindaten Videosprechstunde EBM</td>
                                    <td>(Art. 6 Abs. 1 f) DSGVO</td>
                                    <td>Die Datenverarbeitung ist notwendig für den Aufruf des von Ihnen gewünschten Services und die Durchführung der Videosprechstunde</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Videosprechstunden: Patienten</strong>
                    <p>Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie als Patient einen sog. einmaligen Termin, welcher über unsere Plattform von Ihrem Behandler für Sie persönlich angelegt wird, wahrnehmen oder einen sog. online buchbaren Termin in der Videosprechstunde mit einem Behandler auf unserer Plattform buchen und wahrnehmen.</p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Datenverarbeitung und Rechtsgrundlage sowie ggf. berechtigte Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>Name, Vorname, Geburtsdatum, E-Mail-Adresse</td>
                                    <td>Erbringung unserer Services für Patienten, Identifizierung, Kontrolle der Berechtigung zum Abruf des Angebots, vorgeschriebene Dokumentation durch Behandler</td>
                                    <td>Art. 6 Abs. 1 b) DSGVO</td>
                                    <td></td>
                                    <td>10 Jahre nach dem Termin</td>
                                </tr>
                                <tr>
                                    <td>Zugriffsdaten, Anmeldedaten</td>
                                    <td>Erbringung unserer Services, vorgeschriebene Dokumentation durch Behandler, Vertragserfüllung, Darstellung der Inhalte des Service, Entdeckung von Angriffen auf unsere Seite anhand ungewöhnlicher Aktivitäten, Fehlerdiagnose</td>
                                    <td>Art. 6 Abs. 1 b), f) DSGVO</td>
                                    <td>ordnungsgemäße Funktion der Services, Sicherheit von Daten und Geschäftsprozes-sen, Verhinderung von Missbrauch, Verhütung von Schäden durch Eingriffe in Informationssysteme, Ver-besserung unserer Services</td>
                                    <td>7 Tage</td>
                                </tr>
                                <tr>
                                    <td>Termindaten Allgemein</td>
                                    <td>Erbringung unserer Services, Dokumentation im Rahmen des Vertragsverhältnisses, vorgeschriebene Dokumentation durch Behandler, Vertragserfüllung</td>
                                    <td>Art. 9 Abs. 2 a) DSGVO</td>
                                    <td></td>
                                    <td>10 Jahre nach dem Termin</td>
                                </tr>
                                <tr>
                                    <td>Termindaten EBM</td>
                                    <td>Erbringung unserer Services, Dokumentation im Rahmen des Vertragsverhältnisses, vorgeschriebene Dokumentation durch Behandler, Vertragserfüllung</td>
                                    <td>Art. 9 Abs. 2 a) DSGVO</td>
                                    <td></td>
                                    <td>3 Monate nach dem Termin</td>
                                </tr>
                                <tr>
                                    <td>ggf. (nur bei online buchbarem Termin) Zahlungsdaten</td>
                                    <td>Abwicklung von Bestellungen und Zahlungen zur Erbringung unserer Services</td>
                                    <td>Art. 6 Abs. 1 b) DSGVO</td>
                                    <td></td>
                                    <td>10 Jahre nach dem Termin</td>
                                </tr>
                            </table>
                        </li>
                        <li type="none">
                            <strong>gemäßt Art. 26 DSGVO</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Empfängerkategorie</th>
                                    <th>Betroffene Daten</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                </tr>
                                <tr>
                                    <td>Behandler, bei dem Sie den Termin wahrnehmen</td>
                                    <td>Name, Vorname, Geburtsdatum, E-MailAdresse, Termindaten Allgemein, Termindaten EBM, ggf. (nur bei online buchbarem Termin), Zahlungsdaten</td>
                                    <td>Art. 6 Abs. 1 b) DSGVO; Art. 9 Abs. 2 a) DSGVO</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>jameda GmbH</td>
                                    <td>Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten, Zugriffsdaten, Profildaten, Termindaten Videosprechstunde Allgemein, Termindaten Videosprechstunde EBM</td>
                                    <td>(Art. 6Abs. 1 f und Art. 9 Abs. 2a) DSGVO)</td>
                                    <td>Die Datenverarbeitung ist notwendig für den Aufruf des von Ihnen gewünschten Services und die Durchführung der Videosprechstunde</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Kontaktaufnahme und Feedback</strong>
                    <p>Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn sie sich an unseren Kundenservice wenden (z.B. über ein Online-Kontaktformular oder via Telefon) und/oder an Umfragen zur Kunden- bzw. Nutzerzufriedenheit teilnehmen:</p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Datenverarbeitung und Rechtsgrundlage sowie ggf. berechtigte Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>Personenstammdaten, Kontaktdaten, Inhalte der Anfragen/Beschwerden, Zugriffsdaten</td>
                                    <td>Bearbeitung von Kundenanfragen und Nutzerbeschwerden, Verbesserung unseres Services</td>
                                    <td>Art. 6 Abs. 1 b), f)</td>
                                    <td>Kundenbindung, Verbesserung unseres Services</td>
                                    <td>1 Jahr nach abgeschlossener Bearbeitung der Anfrage </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Verantwortlich gemäß Art. 26 DSGVO</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Verantwortliche Stelle</th>
                                    <th>Betroffene Daten</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                </tr>
                                <tr>
                                    <td>jameda GmbH</td>
                                    <td>Accountdaten, Personenstammdaten, Adressdaten, Kontaktdaten, Anmeldedaten, Zugriffsdaten, Profildaten, Termindaten Videosprechstunde Allgemein, Termindaten Videosprechstunde EBM</td>
                                    <td>(Art. 6 Abs. 1 f) DSGVO)</td>
                                    <td>Die Datenverarbeitung ist notwendig für die Bearbeitung und Beantwortung Ihrer Anfrage/Nachfrage.</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Videosprechstunde Siegel und Widget</strong>
                    <p>In diesem Abschnitt beschreiben wir, wie wir Ihre personenbezogenen Daten verarbeiten werden, wenn Sie die Homepage eines Behandlers (z.B. eines Arztes oder Psychotherapeuten) besuchen, der eines unserer Siegel oder unserer Widgets eingebunden hat. Ein Widget ist ein kleines Fenster, das veränderliche Informationen anzeigt. Auch unser Siegel funktioniert in ähnlicher Weise, d.h. es sieht nicht immer gleich aus, sondern die Anzeige ändert sich. Dabei wird der entsprechende Inhalt zwar auf der Homepage des Behandlers dargestellt, er wird aber in diesem Moment von unseren Servern abgerufen. Nur so kann immer der aktuelle Inhalt gezeigt werden, vor allem die jeweils aktuelle Bewertung. Dafür muss eine Datenverbindung von der aufgerufenen Seite (Homepage des Behandlers) zu uns aufgebaut werden und wir erhalten gewisse technische Daten (Zugriffsdaten), die nötig sind, damit der Inhalt ausgeliefert werden kann. Diese Daten werden aber nur für die Bereitstellung des Inhalts verwendet und nicht gespeichert oder anderweitig genutzt. Beim Anklicken des Siegels werden Sie gegebenenfalls auf unsere Webseite weitergeleitet.</p>
                    <p>Der Behandler verfolgt mit der Einbindung den Zweck und das berechtigte Interesse, aktuelle und korrekte Inhalte auf seiner Homepage darzustellen. Rechtsgrundlage ist Art 6 Abs. 1 f) DSGVO. Eine Speicherung von Zugriffsdaten erfolgt durch den Verantwortlichen der Behandlerhomepage aufgrund dieser Einbindung nicht. Bezüglich der sonstigen Datenverarbeitung durch die Behandlerhomepage können Sie sich über die dort abrufbare Datenschutzerklärung informieren. Aufgrund der Einbindung erfolgt, wie oben beschrieben, eine Datenübermittlung von Zugriffsdaten an uns sowie in der Folge an unsere Hostingdiensleister (siehe oben Abschnitt II.1.d.). Die Daten werden jedoch ausschließlich zur Bereitstellung der Inhalte genutzt und danach sofort wieder gelöscht.</p>
                </li>
            </ol>
        </li>
        <li>
            <strong>Betroffenenrechte</strong>
            <ol>
                <li>
                    <strong>Widerspruchsrecht</strong>
                    <p>Sie haben außerdem das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit mit Wirkung für die Zukunft gegen die Verarbeitung Sie betreffender personenbezogener Daten, die gemäß Art. 6 Abs. 1 Buchst. e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
                    <p>Das Widerspruchsrecht können Sie kostenfrei ausüben. Um Ihr Anliegen schneller zu bearbeiten, nutzen Sie bitte bevorzugt unser Formular unter dem folgenden Link:</p><br/>
                    <p>Zur Datenschutzanfrage: <a target="_blank" href="https://datenschutzanfrage.de/?source=jameda&lang=de">https://datenschutzanfrage.de/?source=jameda&lang=de</a></p>
                    <p>Alternativ können Sie uns u.a. über die unter I.3 genannten Kontaktdaten erreichen.</p>
                </li>
                <li>
                    <strong>Auskunftsrecht</strong>
                    <p>Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden, sowie ggf. auf Auskunft über diese personenbezogenen Daten und die weiteren in Art. 15 DSGVO aufgeführten Informationen.</p>
                </li>
                <li>
                    <strong>Berichtigungsrecht</strong>
                    <p>Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen (Art. 16 DSGVO). Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.</p>
                </li>
                <li>
                    <strong>Recht auf Löschung („Recht auf Vergessenwerden“)</strong>
                    <p>Sie haben das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 Abs. 1 DSGVO genannten Gründe zutrifft und die Verarbeitung nicht für einen der in Art. 17 Abs. 3 DSGVO geregelten Zwecke erforderlich ist.</p>
                </li>
                <li>
                    <strong>Recht auf Einschränkung der Verarbeitung</strong>
                    <p>Sie sind berechtigt, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der in Art. 18 Abs. 1 Buchst. a) bis d) DSGVO geregelten Voraussetzungen gegeben ist.</p>
                </li>
                <li>
                    <strong>Recht auf Datenübertragbarkeit</strong>
                    <p>Sie haben unter den in Art. 20 Abs. 1 DSGVO genannten Voraussetzungen das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln. Bei der Ausübung des Rechts auf Datenübertragbarkeit haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns an eine andere verantwortliche Stelle übermittelt werden, soweit dies technisch machbar ist.</p>
                </li>
                <li>
                    <strong>Widerrufsrecht bei Einwilligung</strong>
                    <p>Soweit die Verarbeitung auf Ihrer Einwilligung beruht, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.</p>
                </li>
                <li>
                    <strong>Beschwerderecht</strong>
                    <p>Sie haben ein Beschwerderecht bei der für unser Unternehmen zuständigen Aufsichtsbehörde. </p>
                </li>
            </ol>
        </li>
    </ol>
</div>

<!-- English Version -->
<ng-template #englishVersion>
    <div class="legal">
        <p>Hereinafter, we will inform you in accordance with statutory provisions, including without limitation the General Data Protection Regulation (GDPR, available at <a target="_blank" href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU">http://eur-lex.europa.eu</a>) – on the processing of Personal Data by our company.</p>
        <p>Table of contents:</p>
        <ol type="I">
            <li>
                General information
                <ol>
                    <li>Important terms</li>
                    <li>Scope</li>
                    <li>Controller</li>
                    <li>Data Protection Officer</li>
                </ol>
            </li>
            <li>
                Joint Controllers
            </li>
            <li>
                Data processing activities in detail
                <ol>
                    <li>General information on data processing</li>
                    <li>Access to our services</li>
                    <li>Video consultancy Attending physician or psychotherapist</li>
                    <li>Video consultancy Patients</li>
                    <li>Contact initiation and feedback</li>
                    <li>Video consultancy seal and widget</li>
                </ol>
            </li>
            <li>
                Rights of data subjects
                <ol>
                    <li>Right to object</li>
                    <li>Right of access</li>
                    <li>Right to rectification</li>
                    <li>Right to erasure (“right to be forgotten”)</li>
                    <li>Right to restriction of processing</li>
                    <li>Right to portability</li>
                    <li>Right to withdraw your consent</li>
                    <li>Right to lodge a complaint</li>
                </ol>
            </li>
        </ol>
        <br/>
        <ol type="I">
            <li>
                <strong>General information</strong>
                <p>
                    <strong>This section of the privacy notice contains information on the scope, on the data processing controller, the data protection officer and on data security. In addition, we will define important terms used in the privacy notice.<br/></strong>
                </p>
                <ol>
                    <li>
                        <strong>Important terms</strong>
                        <p><strong>Browser:</strong> Computer program used for the presentation of websites (e.g. Chrome, Firefox, Safari).<br/></p>
                        <p><strong>Cookies:</strong> Text files which the web server you accessed places on your computer via your Browser. The stored cookies may contain a cookie ID, which enables the website to recognize you when you return to it, and contents like log-in status or information on websites you visited. The browser will re-send this information to the web server at the time you revisit the site. Most Browsers accept cookies automatically.<br/></p>
                        <p><strong>Third Countries:</strong> Countries outside the European Union (EU).<br/></p>
                        <p><strong>GDPR:</strong> Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of Personal Data, on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation), <a target="_blank" href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU">http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU</a><br/></p>
                        <p><strong>Personal Data:</strong> Means any information relating to an identified or identifiable natural person. An identifiable natural person is a person who can be identified, directly or indirectly, including without limitation by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.<br/></p>
                        <p><strong>Profiling:</strong> Means any form of automated Processing of Personal Data consisting of the use of Personal Data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location or movements.<br/></p>
                        <p><strong>Services:</strong> Our offers which are subject to this privacy notice (cf. <a href="#scope">Scope</a>).<br/></p>
                        <p><strong>Tracking:</strong> The collection and analysis of data on the behavior of visitors on our website.<br/></p>
                        <p><strong>Tracking Technologies:</strong> Tracking may be based on the activity log files stored on our web servers or on the collection of data from your end device via Pixels, Cookies and similar Tracking Technologies.<br/></p>
                        <p><strong>Processing:</strong> Any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.<br/></p>
                        <p><strong>Pixel:</strong> Pixels are also called tracking pixels, tracking bugs, web beacons or web bugs. They are small, invisible images in HTML e-mails or on websites. If a document is opened, this small image is downloaded from and the download is registered on an internet server. This way, the operator of the server can see if and when an e-mail was opened or a website was visited. In most cases, this function is realized by activating a small program (Javascript). Thus, certain types of information can be recognized on and transferred from your computer system such as the contents of Cookies, the time and date you accessed the website and a description of the site on which the tracking pixel is located.<br/></p>
                    </li>
                    <li>
                        <strong><a id="scope">Scope</a></strong>
                        <p>This privacy notice applies to the following services:</p>
                        <ul>
                            <li>our online service Helios video consultancy, accessible at <a target="_blank" href="https://helios-videosprechstunde.patientus.de">https://helios-videosprechstunde.patientus.de</a></li>
                        </ul><br/>
                        <p>All these services are hereinafter together referred to as “Services”.</p>
                    </li>
                    <li>
                        <strong>Controller</strong>
                        <p>The Controller for the data processing activities, this means the person that makes decisions on the purposes and means of Processing of Personal Data, in relation to the services is:</p>
                        <p>
                            Helios Kliniken GmbH<br/>
                            Friedrichstr. 136, 10117 Berlin<br/>
                            030 52 13 21 - 0<br/>
                            info@helios-gesundheit.de<br/>
                            <a target="_blank" href="https://www.helios-gesundheit.de/impressum/">https://www.helios-gesundheit.de/impressum/</a>
                        </p>
                    </li>
                    <li>
                        <strong>Data Protection Officer</strong>
                        <p>Contact: <a href="mailto:datenschutz@helios-gesundheit.de">datenschutz@helios-gesundheit.de</a></p>
                    </li>
                    <li>
                        <strong>Joint Controller as stipulated in Art. 26 of the GDPR</strong>
                        <p>jameda GmbH, Balanstraße 71a, 81541 München, Germany</p>
                    </li>
                    <li>
                        <strong>Jameda’s Data Protection Officer</strong>
                        <p>Contact: <a href="mailto:datenschutz@jameda.de">datenschutz@jameda.de</a></p>
                    </li>
                </ol>
            </li>
            <li>
                <strong>Joint Controllers</strong>
                <p>
                    <strong>We, Helios Kliniken GmbH, made a joint controllers arrangement as stipulated in Art 26 of the GDPR. This arrangement refers to our online service Helios video consultancy for which Jameda provides mostly technical services. The parties jointly agreed on the data processing activities and process steps that are subject to joint controlling and allocated the individual activities and responsibilities to the parties. This arrangement is described in III below.</strong><br/>
                    In addition, the parties agreed to protect the rights of data subjects as described in the GDPR (for more information, please refer to no. IV) in accordance with the agreed allocation of responsibilities as follows:
                </p>
                <ul>
                    <li>jameda GmbH for the Processing of Personal Data within the processing step “access to the service” (cf. III:2) for the following types/categories of data: account data, personal master data, address data, contact data, log-in data, profile data, access data and appointment data for the video consultancy.</li>
                    <li>jameda GmbH for the processing step “video consultancy attending physician, psychotherapist, etc.” (cf. III.3) for the following types/categories of data: account data, personal master data, address data, contact data, log-in data, profile data, access data and user data relating to the individual features of the log-in area and appointment data for the video consultancy.</li>
                    <li>jameda GmbH for the Processing of Personal Data within the processing step “video consultancy patients” (cf. III.4) for the following types/categories of data: account data, personal master data, address data, log-in data, profile data and access data.</li>
                    <li>jameda GmbH for the Processing of Personal Data within the processing step “initiation of contact and feedback” (cf. III.5) for the following types/categories of data: account data, personal master data, address data, contact data, log-in data, profile data, contents of the requests/complaints, access data and appointment data for the video consultancy.</li>
                </ul>
                <p>
                    The parties will inform each other without delay of any legal positions asserted by data subjects. They will provide each other with any and all information necessary for answering these requests.<br/>
                    You may assert data protection rights vis-à-vis Helios Kliniken GmbH as well as vis-à-vis jameda GmbH. Data subjects obtain information from the party vis-à-vis which they asserted their rights.
                </p>
            </li>
            <li>
                <strong>Data processing activities in detail:</strong>
                <p>
                    <strong>In this section of the privacy notice, we provide you with detailed information on the Processing of your Personal Data with regard to our services. We sort this information in accordance with certain functionalities of our services in order to provide you with a better overview. For the ordinary use of our services, various functionalities and thus various forms of Processing may occur concurrently or successively.</strong>
                </p>
                <ol>
                    <li>
                        <strong>General information on data processing</strong>
                        <p>Unless otherwise provided, the following shall apply for all presented data processing activities:</p>
                        <ol type="a">
                            <li>
                                <strong>No obligation to disclose Personal Data & consequences of a failure to disclose Personal Data</strong>
                                <p>The disclosure of Personal Data is not provided for by law or contract and you are under no obligation to disclose your data. In the course of the entry procedure, we will inform you when the disclosure of your Personal Data is required for the relevant service (e.g. by designating a field as “mandatory”). If you fail to disclose mandatory data, we will not be able to render the relevant service. Another consequence of the failure to disclose mandatory data is that we will be unable to provide our services in a consistent form and quality.</p>
                            </li>
                            <li>
                                <strong>Consent</strong>
                                <p>In some cases, you may give your consent to additional Processing of all or part of your data in relation to the processing activities stipulated hereinafter. In this case, at the time you give your consent, we will inform you separately of all modalities and the scope of your consent and of the purposes for which we process these data. Therefore, we will not list the processing activities based on your consent again (Art. 13(4) of the GDPR).</p>
                            </li>
                            <li>
                                <strong>Transfer of Personal Data to Third Countries</strong>
                                <p>If and when we transfer data to Third Countries, i.e. countries outside the European Union, this transfer will only be carried out in full compliance with statutory regulations.</p>
                                <p>If data is transferred to a Third Country other than for the fulfillment of a contract between you and us, if we do not have your consent, if the transfer is not for the establishment, exercise or defense of legal claims and if there is no other derogation for special situations in accordance with Art. 49 of the GDPR, we will not transfer your data to a Third Country, unless the transfer is based on an adequacy decision in accordance with Art. 45 of the GDPR or on appropriate safeguards in accordance with Art. 46 of the GDPR.</p>
                                <p>By agreeing on the applicability of the EU standard protection clauses adopted by the European Commission between us and the recipient, we fulfill the requirements for examination procedures regarding appropriate safeguards in accordance with Art. 46(2)(c) of the GDPR and regarding an adequate level of data protection in this Third Country. For copies of the EU standard protection clauses, please go to the website of the European Commission at: <a target="_blank" href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de</a>.</p>
                            </li>
                            <li>
                                <strong>Hosting with third-party service providers </strong>
                                <p>We process data mostly using so-called hosting service providers that provide us with storage space and processing capacities in their data centers and that also process Personal Data in our name in accordance with our instructions. In connection with any of the functionalities listed hereinafter, Personal Data may be transmitted to hosting service providers. These service providers will process Personal Data either exclusively within the EU or we guaranteed an adequate level of protection by employing EU standard protection clauses (cf. c.).</p>
                            </li>
                            <li>
                                <strong>Transfer to public authorities</strong>
                                <p>We transfer Personal Data to public authorities (including law enforcement agencies) if this is required for compliance with a legal obligation to which we are subject (legal basis: Art. 6(1)(c) of the GDPR) or if this is necessary for the establishment, exercise or defense of legal claims (legal basis: Art. 6(1)(f) of the GDPR).</p>
                            </li>
                            <li>
                                <strong>Storage period</strong>
                                <p>The storage period indicates how long we will store Personal Data for the relevant purpose. After this period of time, we will not process your data anymore, but erase them in regular intervals, unless Processing and storage is provided for by law (including without limitation if Processing and storage is required to fulfill a legal obligation for the establishment, exercise or defense of legal claims) or if you give your consent to such Processing and storage.</p>
                            </li>
                            <li>
                                <strong>Functional life of Cookies</strong>
                                <p>Some of the data processing activities stated hereinafter are effected with the help of Cookies. Only the operator of the web server that placed the Cookie in the first place can access the information stored on this Cookie. No third party has access to this information. The Cookies have individual functional lives. Some Cookies are only active during the Browser session and will be deleted afterwards, others remain active for longer periods of time, however, most of them for up to one year. After expiry of its functional life, a Cookie will be deleted by the Browser. You may manage Cookies via your Browser settings (in most cases under “Options” or “Settings”). This way, you may deactivate, subject to your consent or otherwise restrict the storage of Cookies. You can delete Cookies at any time.</p>
                            </li>
                            <li>
                                <strong>Stipulation of data categories</strong>
                                <p>In the next paragraphs, the following comprehensive categories are used to describe certain types of data:</p>
                                <ul>
                                    <li><strong>Account data:</strong> Log-in, user name and password.</li>
                                    <li><strong>Personal master data:</strong> Title, form of address/sex, first name, last name, date of birth.</li>
                                    <li><strong>Address data:</strong> Street, number, additional address information, if any, ZIP code, city or town, country.</li>
                                    <li><strong>Contact data:</strong> Telephone number(s), fax number(s), e-mail address(es).</li>
                                    <li><strong>Log-in data:</strong> Information on the service which you used for log-in; time and technical information on log-in, confirmation and log-out; data you disclosed upon log-in.</li>
                                    <li><strong>Payment data:</strong> Bank data, credit card data, data relating to other payment services, e.g. Paypal.</li>
                                    <li><strong>Access data:</strong> Date and time of access to our service; the website from which the accessing system was forwarded to our site, pages accessed during use; session ID, and the following information on the accessing computer system: anonymised internet protocol address (IP address), Browser type and version, type of computer, operating system and similar technical information.</li>
                                    <li><strong>Profile data:</strong> Data which the attending physician or psychotherapist store in the profile.</li>
                                    <li><strong>Appointment data for the video consultancy:</strong>
                                        <ul>
                                            <li><strong>General</strong> (for all appointments <strong>except</strong> for appointments settled in accordance with the uniform standard of assessment (EBM)): data on video appointments (date, time, person you are talking to, length of the appointment, type of appointment, description of appointment, status of appointment, price, messages sent with regard to the agreement of the appointment, online status for the initialization of the video consultancy, TAN).</li>
                                            <li><strong>EBM</strong> (for appointments settled in accordance with the uniform standard of assessment (EBM)): data on video appointments (date, time, person you are talking to, length of the appointment, type of appointment, description of appointment, status of appointment, price, messages sent with regard to the agreement of the appointment, online status for the initialization of the video consultancy, TAN).</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>No processing of video calls</strong>
                                <p>We do not process any data exchanged during video calls (conversations, chats, pictures, documents). The video consultancy is based on a peer-to-peer connection between the end devices of the conversation partners to which we have no access. We will not collect, process or store your conversations, chat history and any pictures or documents you exchanged. Your video call is confidential.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Access to our services</strong>
                        <p>Hereinafter, we describe how we process your Personal Data when you access our services (e.g.
                            loading and visiting the website, opening and navigating within the mobile app). We
                            explicitly point out that the transfer of access data to third-party content providers (cf.
                            b.) is inevitable based on the technical functionality of the transfer of information on the
                            internet. The service providers are obliged to ensure that the IT systems they use are
                            operated in compliance with data protection regulations. The service providers decide on the
                            storage period of the data. To monitor the stability of our IT systems, we use the Matomo
                            software from InnoCraft Ltd, 150 Willis St, 6011 Wellington, New Zealand. This software,
                            which is integrated into our website, is operated by jameda GmbH exclusively using its own
                            IT systems. No data is transmitted to third parties. This website uses the software Matomo
                            with the extension "AnonymizeIP". This means that IP addresses are processed in abbreviated
                            form, thus excluding the possibility of direct personal references. The data is stored
                            anonymously, i.e. without any possible inference to the person, for an unlimited period of
                            time.</p>
                        <ol type="a">
                            <li>
                                <strong>Stipulation of purposes of data processing and legal bases and/or legitimate interests, storage period</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Data category</th>
                                        <th>Purpose of processing</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                        <th>Storage period</th>
                                    </tr>
                                    <tr>
                                        <td>Access data</td>
                                        <td>Dial-up, presentation of the contents of the service, detection of attacks on our website based on unusual activities, error diagnosis</td>
                                        <td>Article 6(1)(f) of the GDPR.</td>
                                        <td>Proper functioning of the services, security of data and business processes, prevention of misuse, prevention of damage caused by attacks on information systems</td>
                                        <td>7 days</td>
                                    </tr>
                                </table>
                            </li>
                            <li>
                                <strong>Responsible in accordance with Art. 26 of the GDPR</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Recipient category</th>
                                        <th>Affected data</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                    </tr>
                                    <tr>
                                        <td>jameda GmbH</td>
                                        <td>Account data, personal master data, address data, contact data, log-in data, access data, profile data, general data for the video consultancy, EBM data for the video consultancy</td>
                                        <td>Article 6(1)(f) of the GDPR</td>
                                        <td>Data processing is necessary to access the service you desire and for the realization of the video consultancy</td>
                                    </tr>
                                </table>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Video consultancy Attending physician or psychotherapist</strong>
                        <p>Hereinafter, we describe how we process your Personal Data if you set up and use an account subject to costs for the realization of video consultancies as an attending physician or psychotherapist:</p>
                        <ol type="a">
                            <li>
                                <strong>Stipulation of purposes of data processing and legal bases and/or legitimate interests, storage period</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Data category</th>
                                        <th>Purpose of processing</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                        <th>Storage period</th>
                                    </tr>
                                    <tr>
                                        <td>E-mail address</td>
                                        <td>Verification of log-in (double opt-in) </td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>Term of contract</td>
                                    </tr>
                                    <tr>
                                        <td>Personal master data, contact data, address data</td>
                                        <td>Identification, control of authorization to access the service, contact initiation, verifiability of registration, settlement of payments for the service</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>Term of contract </td>
                                    </tr>
                                    <tr>
                                        <td>Profile data</td>
                                        <td>Our services</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>Term of contract </td>
                                    </tr>
                                    <tr>
                                        <td>General appointment data</td>
                                        <td>Our services</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>10 years after the appointment</td>
                                    </tr>
                                    <tr>
                                        <td>EBM appointment data</td>
                                        <td>Our services</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>3 months after the appointment </td>
                                    </tr>
                                    <tr>
                                        <td>Payment data</td>
                                        <td>Processing of orders and payments for our services</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>Term of contract</td>
                                    </tr>
                                    <tr>
                                        <td>Log-in data, access data, user data regarding the individual features of the log-in area</td>
                                        <td>Dial-up, presentation of the contents of the service, detection of attacks on our website based on unusual activities, error diagnosis, rendering our services</td>
                                        <td>Article 6(1)(b) and (f) of the GDPR </td>
                                        <td>Proper functioning of the services, security of data and health processes, prevention of misuse, prevention of damage caused by attacks on our information systems, improvement of our services</td>
                                        <td>Term of contract</td>
                                    </tr>
                                </table>
                            </li>
                            <li>
                                <strong>Responsible in accordance with Art. 26 of the GDPR</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Recipient category</th>
                                        <th>Affected data</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                    </tr>
                                    <tr>
                                        <td>jameda GmbH</td>
                                        <td>Account data, personal master data, address data, contact data, log-in data, access data, profile data, general data for the video consultation, EBM data for the video consultancy</td>
                                        <td>Article 6(1)(f) of the GDPR</td>
                                        <td>Data processing is necessary to access the service you desire and for the realization of the video consultancy</td>
                                    </tr>
                                </table>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Video consultancy Patients</strong>
                        <p>Hereinafter, we describe how your Personal Data will be processed if and when you, as a patient, use a so-called one-time appointment that your attending physician or psychotherapist made for you personally on our platform, or if and when you book and use a so-called online-booked appointment for a video consultancy with your attending physician or psychotherapist on our platform.</p>
                        <ol type="a">
                            <li>
                                <strong>Stipulation of purposes of data processing and legal bases and/or legitimate interests, storage period</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Data category</th>
                                        <th>Purpose of processing</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                        <th>Storage period</th>
                                    </tr>
                                    <tr>
                                        <td>Last name, first name, date of birth, e-mail address</td>
                                        <td>Rendering our services to patients, identification, control of authorization to access the services, mandatory documentation to be made by attending physician or psychotherapist</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>10 years after the appointment</td>
                                    </tr>
                                    <tr>
                                        <td>Access data, log-in data</td>
                                        <td>Rendering our services, mandatory documentation to be made by attending physician or psychotherapist, contract fulfillment, presentation of the contents of the service, detection of attacks on our website based on unusual activities, error diagnosis</td>
                                        <td>Article 6(1)(b) and (f) of the GDPR</td>
                                        <td>Proper functioning of the services, security of data and business processes, prevention of misuse, prevention of damage caused by attacks on our information systems, improvement of our services</td>
                                        <td>7 days</td>
                                    </tr>
                                    <tr>
                                        <td>General appointment data</td>
                                        <td>Rendering our services, documentation of the contractual relationship, mandatory documentation to be made by attending physician or psychotherapist, contract fulfillment</td>
                                        <td>Article 9(2)(a) of the GDPR</td>
                                        <td></td>
                                        <td>10 years after the appointment</td>
                                    </tr>
                                    <tr>
                                        <td>EBM appointment data</td>
                                        <td>Rendering our services, documentation of the contractual relationship, mandatory documentation to be made by attending physician or psychotherapist, contract fulfillment</td>
                                        <td>Article 9(2)(a) of the GDPR</td>
                                        <td></td>
                                        <td>3 months after the appointment</td>
                                    </tr>
                                    <tr>
                                        <td>If applicable (only for appointments that can be booked online), payment data</td>
                                        <td>Processing of orders and payments for our services</td>
                                        <td>Article 6(1)(b) of the GDPR</td>
                                        <td></td>
                                        <td>10 years after the appointment</td>
                                    </tr>
                                </table>
                            </li>
                            <li type="none">
                                <strong>Responsible in accordance with Art. 26 of the GDPR</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Recipient category</th>
                                        <th>Affected data</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                    </tr>
                                    <tr>
                                        <td>Attending physician or psychotherapist with whom you have your appointment</td>
                                        <td>Last name, first name, date of birth, e-mail address, general appointment data, EBM appointment data, and if applicable (only for appointments that can be booked online), payment data</td>
                                        <td>Art. 6(1)(b) of the GDPR, Art. 9(2)(a) of the GDPR</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>jameda GmbH</td>
                                        <td>Account data, personal master data, address data, contact data, log-in data, access data, profile data, general data for the video consultancy, EBM data for the video consultancy</td>
                                        <td>Art. 6(1)(f)of the GDPR, Art. 9(2)(a) of the GDPR</td>
                                        <td>Data processing is necessary to access the service you desire and for the realization of the video consultancy</td>
                                    </tr>
                                </table>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Contact initiation and feedback</strong>
                        <p>Hereinafter, we describe how we will process your Personal Data if and when you contact our customer service (e.g. via an online contact form or via telephone) and/or if and when you participate in surveys on customer or user satisfaction:</p>
                        <ol type="a">
                            <li>
                                <strong>Stipulation of purposes of data processing and legal bases and/or legitimate interests, storage period</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Data category</th>
                                        <th>Purpose of processing</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                        <th>Storage period</th>
                                    </tr>
                                    <tr>
                                        <td>Personal master data, contact data, contents of the requests/complaints, access data</td>
                                        <td>Processing of customer requests and user complaints, improvement of our services</td>
                                        <td>Article 6(1)(b) and (f) of the GDPR</td>
                                        <td>Customer loyalty, improvement of our services</td>
                                        <td>1 year after processing of the request is completed</td>
                                    </tr>
                                </table>
                            </li>
                            <li>
                                <strong>Responsible in accordance with Art. 26 of the GDPR</strong>
                                <table style="width:100%">
                                    <tr>
                                        <th>Recipient category</th>
                                        <th>Affected data</th>
                                        <th>Legal basis</th>
                                        <th>Legitimate interests, if any</th>
                                    </tr>
                                    <tr>
                                        <td>jameda GmbH</td>
                                        <td>Account data, personal master data, address data, contact data, log-in data, access data, profile data, general data for the video consultancy, EBM data for the video consultancy</td>
                                        <td>Article 6(1)(f) of the GDPR</td>
                                        <td>Data processing is required for the processing of and reply to your request.</td>
                                    </tr>
                                </table>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Video consultancy seal and widget</strong>
                        <p>In this section, we describe how we process your Personal Data when you visit the website of a physician or psychotherapist who embedded one of our seals or widgets. A widget is a small window showing changing information. Our seal works in a similar way, i.e. it does not always look the same but the display changes. The content is retrieved from our website the moment it is displayed on the website of the attending physician or psychotherapist. This way we can make sure that the content shown is always up to date, including the current rating. For this purpose, a data connection must be established between the website of the attending physician or psychotherapist and us, and we receive certain technical data (access data) which are required to deliver the content. However, these data will only be used for the provision of the content and will not be stored or used for any other purpose. When you click on the seal you will be directed to our website.</p>
                        <p>By embedding one of our seals or widgets, the attending physician or psychotherapist aims at presenting current and correct contents on their website, which is in their legitimate interest. The legal basis is Art. 6(1)(f) of the GDPR. The Controller of the website of the attending physician or psychotherapist will not store any access data based on this embedding. For information on the other processing of data by the website of the attending physician or psychotherapist, please read the privacy notice available on this website. Based on this embedding, the access data will be transmitted to us and, consequently, to our hosting service providers as described above (cf. section II.1.d). However, these data will be exclusively used for the provision of the contents and will be deleted immediately thereafter.</p>
                    </li>
                </ol>
            </li>
            <li>
                <strong>Rights of data subjects</strong>
                <ol>
                    <li>
                        <strong>Right to object</strong>
                        <p>In addition, you have the right to object at any time, on grounds relating to your particular situation and with future effect, to the processing of your Personal Data which is based on Art. 6(1)(e) or (f) of the GDPR, including Profiling based on those provisions.</p>
                        <p>You may exercise this right to object without any costs at any time. Please use the form under the following link, this enables us to process your request more expeditiously:</p><br/>
                        <p>Request regarding data protection: <a target="_blank" href="https://datenschutzanfrage.de/?source=jameda&lang=de">https://datenschutzanfrage.de/?source=jameda&lang=de</a></p>
                        <p>You can also contact us via the contact data stipulated below in I.3.</p>
                    </li>
                    <li>
                        <strong>Right of access</strong>
                        <p>You may request from us a confirmation of whether or not we process any Personal Data relating to you and to access these Personal Data and the other information stated in Art. 15 of the GDPR.</p>
                    </li>
                    <li>
                        <strong>Right to rectification</strong>
                        <p>You are entitled to demand that we immediately rectify your Personal Data to the extent they are inaccurate (Art. 16 DSGVO). Taking into account the purposes of the processing, you have the right to have incomplete Personal Data completed, including by means of providing a supplementary statement.</p>
                    </li>
                    <li>
                        <strong>Right to erasure (“right to be forgotten”)</strong>
                        <p>You are entitled to obtain from us the erasure of your Personal Data without undue delay where one of the grounds stipulated in Art. 17(1) of the GDPR applies and processing thereof is not necessary for one of the reasons stated in Art. 17(3) of the GDPR.</p>
                    </li>
                    <li>
                        <strong>Right to restriction of processing</strong>
                        <p>You are entitled to demand the restriction of processing of your Personal Data if one of the prerequisites stipulated in Art. 18(1)(a)-(d) of the GDPR.</p>
                    </li>
                    <li>
                        <strong>Right to portability</strong>
                        <p>In the cases stipulated in Art. 20(1) of the GDPR, you are entitled to receive your Personal Data, which you have provided to us, in a structured, commonly used and machine-readable format and you have the right to transmit those data to another Controller without hindrance from us. In exercising your right to data portability, you have the right to have the Personal Data transmitted directly from one Controller to another, where technically feasible.</p>
                    </li>
                    <li>
                        <strong>Right to withdraw your consent</strong>
                        <p>To the extent the processing of Personal Data is based on your consent, you are entitled, at any time, to withdraw such consent. The withdrawal of your consent shall not affect the lawfulness of processing based on consent before its withdrawal.</p>
                    </li>
                    <li>
                        <strong>Right to lodge a complaint</strong>
                        <p>You have the right to lodge a complaint with the supervisory authority that is competent for our company. </p>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
</ng-template>

