import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentAccountService } from '../../../../../entities/payment-account/provider/payment-account.service';
import { PaymentAccountStoreService } from '../../../../../entities/payment-account/provider/payment-account-store.service';
import { PaymentAccountSum } from '../../../../../entities/payment-account/store/payment-account.model';
import { TimeHelperService } from '../../../../utils/time-helpers/time-helper.service';
import { PaymentBankAccountService } from '../../../../../entities/payment-bank-account/provider/payment-bank-account.service';
import {
    MangoPayBankAccountStatus,
    STATUS_TYPES
} from '../../../../../entities/payment-bank-account/provider/dto/payment-bank-account-dto.model';
import { PayOutService } from '../../../../../entities/payment-pay-out/provider/pay-out.service';
import { MangoPayPayOutStatus } from '../../../../../entities/payment-pay-out/dto/pay-out-dto.model';
import { Observable, of } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-payout-info',
    templateUrl: './payout-info.component.html',
    styleUrls: ['./payout-info.component.scss']
})
export class PayoutInfoComponent implements OnInit, OnDestroy {

    payoutObj$: Observable<PaymentAccountSum>;
    requestTimestamp: number;
    isLoading = false;
    bankAccountStatus: MangoPayBankAccountStatus;
    payOutStatus: MangoPayPayOutStatus;
    accountSum: PaymentAccountSum;
    STATUS_EMPTY = STATUS_TYPES.EMPTY;
    STATUS_PROCESSING = STATUS_TYPES.PROCESSING;
    STATUS_FINISHED = STATUS_TYPES.FINISHED;
    STATUS_FAILED = STATUS_TYPES.FAILED;
    buttonClicked = false;

    dateTimeFormat = 'dd.MM.yyyy, hh:mm';

    private storeServiceSubscription: Subscription = null;
    private bankAccountSubscription: Subscription = null;
    private payoutServiceSubscription: Subscription = null;
    private payoutObjSubscription: Subscription = null;

    constructor(private paymentAccountService: PaymentAccountService,
                private storeService: PaymentAccountStoreService,
                private payOutService: PayOutService,
                private bankAccountService: PaymentBankAccountService) {
    }

    ngOnInit(): void {
        this.buttonClicked = false;
        this.triggerRefreshPaymentAccountStates();
        this.storeServiceSubscription = this.storeService.getPaymentRequestTimestamp().subscribe(
            s => this.requestTimestamp = TimeHelperService.secondsToMiliseconds(s)
        );
        this.payoutObj$ = this.storeService.getPaymentAccountSumFromStore();
        this.bankAccountSubscription = this.bankAccountService.readMangopayBankAccountStatus().subscribe(v => this.bankAccountStatus = v);
        this.payoutServiceSubscription = this.payOutService.readPayOutStatus().subscribe(v => this.payOutStatus = v);
        this.payoutObjSubscription = this.payoutObj$.subscribe(v => this.accountSum = v);
    }

    public isPayoutPossible(): Observable<boolean> {
        if (!this.bankAccountStatus || !this.payOutStatus || !this.accountSum) {
            return of(false);
        }

        const isPossible = this.bankAccountStatus.status === STATUS_TYPES.FINISHED
            && (this.payOutStatus.status === STATUS_TYPES.EMPTY || this.payOutStatus.status === STATUS_TYPES.FINISHED)
            && Number(this.accountSum.paidSum) > 0;

        return of(isPossible);
    }

    public doPayOut(): void {
        this.buttonClicked = true;
        const timestampInSeconds = this.requestTimestamp / 1000;
        this.payOutService.createPayOut(timestampInSeconds).subscribe(v => this.payOutStatus = v);
    }

    public reload(): void {
        this.triggerRefreshPaymentAccountStates();
    }

    private triggerRefreshPaymentAccountStates(): void {
        this.isLoading = true;
        const ts = TimeHelperService.getCurrentTimestamp();
        this.paymentAccountService.refreshAllPaymentAccountStates(ts, function() {
            this.isLoading = false;
        }.bind(this));
    }

    ngOnDestroy(): void {
        if (this.storeServiceSubscription !== null) {
            this.storeServiceSubscription.unsubscribe();
        }

        if (this.bankAccountSubscription !== null) {
            this.bankAccountSubscription.unsubscribe();
        }

        if (this.payoutServiceSubscription !== null) {
            this.payoutServiceSubscription.unsubscribe();
        }

        if (this.payoutObjSubscription !== null) {
            this.payoutObjSubscription.unsubscribe();
        }
    }
}
