<div id="dashboard-wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div id="main-board" [ngClass]="{'patient': (roles$ | async).hasOwnProperty(PATIENT)}">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
    <app-file-transfer-pop-up></app-file-transfer-pop-up>
    <app-survey-overlay></app-survey-overlay>
</div>
