
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../profile/providers/profile.service';
import { Observable } from 'rxjs';
import { Profile } from '../../profile/store/profile.entity';
import { BrandingService } from '../../branding/providers/branding.service';
import { BrandingState } from '../../branding/store/branding.state';

@Component({
    selector: 'app-rtc-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['../rtc-detection.component.scss', './footer.component.scss']
})
export class FooterComponent implements OnInit {

    public profileData$: Observable<Profile>;
    public brandingData$: Observable<BrandingState>;

    constructor(private profileService: ProfileService, private brandingService: BrandingService) {
        this.profileData$ = this.profileService.getCurrentProfileDataObserver();
    }

    ngOnInit() {
        this.brandingData$ = this.brandingService.getBrandingSettingsFromStore().pipe(filter(e => e !== null && e !== undefined));
    }
}
