import { Injectable, OnDestroy } from '@angular/core';
import { createAction, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { UserAgentService } from '../user-agent/user-agent.service';
import { tap } from 'rxjs/operators';
import { UserAgentState } from '../../store/user-agent.reducer';
import { USER_AGENT_MESSAGE, UserAgentMessage } from '../../xmpp/user-agent.message';
import { upsertUserAgent } from '../../store/user-agent.actions';
import { XmppMessage, XmppMessageService } from '../../../user-presence/provider/xmpp-message.service';

@Injectable()
export class UserAgentSyncService implements OnDestroy {
    private readonly subscription: Subscription;

    constructor(
        private xmppMessageService: XmppMessageService,
        private userAgentService: UserAgentService,
        private store: Store<UserAgentState>
    ) {
        this.subscription = this.bindEvents().subscribe();
    }

    public sendUserAgentSyncMessage(profileId: number, participantId: number): void {
        const browserName: string = this.userAgentService.getBrowserName();
        const userAgent: UserAgentMessage = this.getUserAgentSyncMessage(profileId, browserName);

        this.xmppMessageService.send(userAgent, participantId);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private bindEvents(): Observable<UserAgentMessage> {
        return this.xmppMessageService.listen(USER_AGENT_MESSAGE)
            .pipe(
                tap((userAgentMessage: UserAgentMessage) => this.store.dispatch(upsertUserAgent({userAgent: userAgentMessage.userAgent})))
            );
    }

    private getUserAgentSyncMessage(id: number, browserName: string): UserAgentMessage {
        return {
            type: USER_AGENT_MESSAGE,
            userAgent: {
                id,
                browserName
            }
        };
    }
}
