import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {PaymentBankAccountService} from './provider/payment-bank-account.service';
import {PaymentBankAccountMapperService} from './provider/dto/payment-bank-account-mapper';
import * as bankAccountInfo from './store/payment-bank-account.reducer';
import {PaymentBankAccountStoreService} from './provider/payment-bank-account-store.service';
import {EffectsModule} from '@ngrx/effects';
import {PaymentBankAccountEffects} from './store/payment-bank-account.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('payment-bank-account-info', bankAccountInfo.reducer),
        EffectsModule.forFeature([PaymentBankAccountEffects]),
    ],
    declarations: [],
    providers: [
        PaymentBankAccountService,
        PaymentBankAccountMapperService,
        PaymentBankAccountStoreService
    ]
})
export class PaymentBankAccountModule {
}
