import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CountdownService } from '../providers/countdown.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent implements OnInit {
    public countdownValue$: Observable<number>;
    public isCountdownRunning$: Observable<boolean>;

    constructor(private countdownService: CountdownService) {
    }

    ngOnInit(): void {
        this.countdownValue$ = this.countdownService.getCountdown();
        this.isCountdownRunning$ = this.countdownService.getCountdownState();
    }
}
