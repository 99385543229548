<div id="small-video-box" [ngClass]="{'no-display' : !(isLocalStreamActive$ | async), 'collapsed': collapsed}">
    <div id="eye-close">
        <i class="fa fa-eye-slash" aria-hidden="true"></i>
    </div>
    <div class="video-tag-wrapper">
        <div *ngIf="!((localScreenStreamActive$ | async) || (remoteScreenStreamActive$ | async));else showRemoteCam"
             #showLocalCam>
            <app-local-cam-video-tag [fullsized]="true"></app-local-cam-video-tag>
        </div>
        <ng-template #showRemoteCam>
            <div *ngIf="(localScreenStreamActive$ | async)">
                <app-local-screen-videotag></app-local-screen-videotag>
            </div>
            <ng-template [ngIf]="(remoteScreenStreamActive$ | async)">
                <div class="flex-side">
                    <app-local-cam-video-tag [fullsized]="true"></app-local-cam-video-tag>
                </div>
                <div class="flex-side">
                    <app-remote-cam-video-tag [fullsized]="true"></app-remote-cam-video-tag>
                </div>
            </ng-template>
        </ng-template>
    </div>
    <div id="collapse-local-video" (click)="collapseLocalVideoBox()" class="not-collapsed">
        <i class="fa fa-arrow-down" *ngIf="!collapsed" aria-hidden="true"></i>
        <i class="fas fa-user" *ngIf="collapsed" aria-hidden="true"></i>
    </div>
</div>
