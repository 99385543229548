import { AppState as RootAppState } from '../../../providers/store/app.state';
import { StepType } from '../rtc-detection.entities';


export interface RtcDetectionState {
    aboveModalOverlayIsOpen: boolean;
    currentStep: StepType;
    isFinished: boolean;
}


export interface AppState extends RootAppState {
    rtcDetection: RtcDetectionState;
}


export class RtcDetectionInitialState implements RtcDetectionState {
    public aboveModalOverlayIsOpen = false;
    public currentStep = StepType.BROWSER_CHECK;
    public isFinished = false;
}
