import { XmppUtil } from './xmpp.util';
import { Profile, ProfileType } from '../../profile/store/profile.entity';

export class XmppUser {
    public readonly profileId: number;
    public readonly jId: string;
    public readonly role: ProfileType;
    public readonly user: string;
    public readonly pass: string;
    public fullJId: string;

    constructor(profile: Profile) {

        this.profileId = profile.id;
        this.jId = XmppUtil.createJid(profile.id);
        this.role = profile.type;
        this.user = XmppUtil.getUserFromJid(this.jId);
        this.pass = 'test';
    }
}
