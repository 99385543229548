import { AppState as RootAppState } from '../../../providers/store/app.state';


export interface AppState extends RootAppState {
    countdown: CountdownState;
}

export interface CountdownState {
    running: boolean;
    value: number;
    isStopped: boolean;
    participantId: number;
}

export class CountdownInitialState implements CountdownState {
    public running = false;
    public isStopped = false;
    public value = 10;
    public participantId = null;
}
