import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Profile } from '../../../../profile/store/profile.entity';
import { ProfileSettingState } from '../profile-tab/profile-tab.component';

@Component({
    selector: 'app-profile-view',
    templateUrl: './profile-view.component.html',
    styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent {
    @Input() profile: Profile;
    @Output() profileSettingsStateEmitter = new EventEmitter<ProfileSettingState>();

    constructor() {
    }

    public getListFromSpecialitiesMap(specialities): string[] {
        if (!specialities) {
            return [];
        }
        const specArray = [];
        specialities.forEach(element => {
            specArray.push(element['itemName']);
        });

        return specArray;
    }

    public onEdit(): void {
        this.profileSettingsStateEmitter.emit(ProfileSettingState.PROFILE_SETTINGS_UPDATE);
    }
}
