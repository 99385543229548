import { PaymentInfo, PaymentInfoStatuses } from '../../store/payment-info.model';
import { PaymentInfoDto, PaymentInfoDtoStatuses } from './payment-info-dto';
import deleteProperty = Reflect.deleteProperty;


export class PaymentInfoMapper {

    public static mapDtoToEntity(dto: PaymentInfoDto): PaymentInfo {
        if (!dto) {
            return null;
        }
        const entity = {
            ...dto,
            id: (dto.hasOwnProperty('paymentInfoId')) ? dto.paymentInfoId : null,
            status: PaymentInfoStatuses[dto.status]
        };
        if (entity.hasOwnProperty('paymentInfoId')) {
            deleteProperty(entity, 'paymentInfoId');
        }
        return entity;
    }

    public static mapEntityToDto(entity: PaymentInfo): PaymentInfoDto {
        const dto = {
            ...entity,
            paymentInfoId: entity.id,
            status: PaymentInfoDtoStatuses[entity.status]
        };
        deleteProperty(dto, 'id');
        if (dto.paymentInfoId === null) {
            deleteProperty(dto, 'paymentInfoId');
        }
        return dto;
    }
}

