export interface PaymentInfo {
    id?: number;
    type: PaymentInfoTypes;
    firstName: string;
    lastName: string;
    birthday: string;
    email: string;
    country: {id: number; name: string};
    nationality: {id: number; name: string};
    status: PaymentInfoStatuses;
    companyName: string;
    companyType: string;
}
export enum PaymentInfoTypes {
    'LEGAL' = 'LEGAL',
    'NATURAL' = 'NATURAL',
}
export enum PaymentInfoStatuses {
    'PROCESSING' = 'PROCESSING',
    'FINISHED' = 'FINISHED',
    'FAILED' = 'FAILED',
}
