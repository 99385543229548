import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export interface LoginActionInterface extends Action {
    type: string;
    jwt?: string;
    credential?: Credential;
    tan?: string;
    error?: HttpErrorResponse;
}

export class DoLogoutAction implements LoginActionInterface {
    type = LOGIN_DO_LOGOUT;
}

export class DoForceLogoutAction implements LoginActionInterface {
    type = LOGIN_DO_FORCE_LOGOUT;
}


export class DoLoginAction implements LoginActionInterface {
    type = LOGIN_DO_LOGIN;

    constructor(public credentials: Credential) {
    }
}

export class DoTanLoginAction implements LoginActionInterface {
    type = LOGIN_DO_TAN_LOGIN;

    constructor(public tan: string) {
    }
}

export class LoginSuccessAction implements LoginActionInterface {
    type = LOGIN_SUCCESS;

    constructor(public jwt: string) {
    }
}

export class LogoutSuccessAction implements LoginActionInterface {
    type = LOGOUT_SUCCESS;

    constructor(public payload: string) {
    }
}

export class LoginFailedAction implements LoginActionInterface {
    type = LOGIN_FAILED;

    constructor(public error: HttpErrorResponse) {
    }
}

export class LoginRefreshJWTAction implements LoginActionInterface {
    type = LOGIN_REFRESH_JWT;

    constructor(public jwt: string) {
    }
}

export interface Credential {
    email: string;
    password: string;
}

export const LOGIN_DO_LOGIN = 'DO_LOGIN';
export const LOGIN_DO_LOGOUT = 'DO_LOGOUT';
export const LOGIN_DO_FORCE_LOGOUT = 'LOGIN_DO_FORCE_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_DO_TAN_LOGIN = 'DO_TAN_LOGIN';
export const LOGIN_REFRESH_JWT = 'REFRESH_LOGIN';

