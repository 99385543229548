import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../forms/validators/custom-validator';

export class StepInvoiceFormBuilder {
    public static build(fb: FormBuilder): FormGroup {
        return fb.group({
            owner: fb.control('', Validators.compose([Validators.required])),
            iban: fb.control('', Validators.compose([Validators.required, CustomValidators.iban()])),
            bic: fb.control('', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(11)])),
            bank: fb.control('', Validators.compose([Validators.required])),
            terms: fb.control(null, Validators.compose([Validators.requiredTrue]))
        });
    }
}
