<div class="card-header-beam">
    <div class="content-wrapper row">
        <div class="col-md-4 col-lg-3"></div>
        <div class="col-md-8">
            <div class='content-container '>
                <p>{{ 'SETTING.PROFILE-TAB.PERSONAL-DESC' | translate }}</p>
            </div>
        </div>
    </div>
</div>
<div class='content-wrapper card move-up'>
    <div class='tan-form-container card-content'>
        <div class="row">
            <div class="col-md-4 col-lg-3">
            </div>
            <div class="col-md-8 dr-info">
                <h1>{{profile?.fullName}}</h1>
                <span *ngIf="profile?.details?.about">{{profile?.details?.about}}</span>
                <span (click)="onEdit()" class="color-gray cursor-pointer"
                      *ngIf="!profile?.details?.about">{{ 'SETTING.PROFILE-TAB.PROFILE-VIEW.EMPTY-DESCRIPTION' | translate }}</span>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4 col-lg-3 bold">
                {{ 'SETTING.PROFILE-TAB.QUALIFICATION' | translate }}
            </div>
            <div class="col-md-8 dr-info">
                <div class="qualification"
                     *ngFor="let speciality of getListFromSpecialitiesMap(profile?.specialities)">
                    <span class="bold">{{ speciality }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
