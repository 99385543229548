
export enum XmppPresenceType {
    ONLINE = 'online',
    UNAVAILABLE = 'unavailable', // Signals that the entity is no longer available for communication.
    SUBSCRIBE = 'subscribe', //  The sender wishes to subscribe to the recipient's presence.
    SUBSCRIBED = 'subscribed', //  The sender has allowed the recipient to receive their presence.
    UNSUBSCRIBE = 'unsubscribe', // The sender is unsubscribing from another entity's presence.
    UNSUBSCRIBED = 'unsubscribed' // The subscription request has been denied or a previously-granted subscription has been cancelled. etc..
}

export enum XmppPresenceStatus {
    AWAY = 'away', // The entity or resource is temporarily away.
    CHAT = 'chat', // The entity or resource is actively interested in chatting.
    DND = 'dnd', // The entity or resource is busy (dnd = "Do Not Disturb").
    XA = 'xa'// The entity or resource is away for an extended period (xa = "eXtended Away").
}

export interface XmppPresence {
    id: number;
    type: XmppPresenceType;
    status: XmppPresenceStatus;
    resource: string;
}
