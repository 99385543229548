import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProfileService } from '../../profile/providers/profile.service';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';
import { Appointment } from '../store/one-time-appointment/appointment.entity';
import { Observable } from 'rxjs';
import { Profile } from '../../profile/store/profile.entity';
import { UserPresenceService } from '../../user-presence/provider/user-presence.service';
import { UserPresenceStatus } from '../../user-presence/user-presence-status';
import { ConferenceService } from '../../conference-v2/providers/conference.service';
import { Conference } from '../../conference-v2/store/conference.model';
import { MultiUserAppointment } from '../store/appointment/multi-user-appointment.entity';

@Injectable()
export class AppointmentInfoService {

    constructor(private profileSelectService: ProfileSelectService,
                private profileService: ProfileService,
                private userPresenceService: UserPresenceService,
                private conferenceService: ConferenceService) {
    }

    public getCurrentProfileId(): number {
        return this.profileSelectService.getCurrentProfileId();
    }

    public loadParticipants(appointment: Appointment, currentProfileId: number): Observable<Profile[]> {
        return this.profileService.getProfilesList()
            .pipe(
                map((profileMap: { [id: number]: Profile }) => this.getProfileListById(profileMap, appointment, currentProfileId)),
                filter((profileList: Profile[]): boolean => (profileList?.length > 0))
            );
    }

    public loadMUCParticipants(appointment: MultiUserAppointment, currentProfileId: number): Observable<Profile[]> {
        return this.profileService.getProfilesList()
            .pipe(
                map((profileMap: { [id: number]: Profile }) => this.getMUCProfileListById(profileMap, appointment, currentProfileId)),
                filter((profileList: Profile[]): boolean => (profileList?.length > 0))
            );
    }

    public loadPatientStatus(profileId: number): Observable<'online' | 'offline' | 'busy'> {
        return <Observable<'online' | 'offline' | 'busy'>>this.userPresenceService.getUserPresenceStatus(profileId).pipe(
            map((status) => {
                let returnStatus;
                switch (status) {
                    case UserPresenceStatus.AWAY:
                        returnStatus = 'busy';
                        break;
                    case UserPresenceStatus.ONLINE:
                        returnStatus = 'online';
                        break;
                    default:
                        returnStatus = 'offline';
                }
                return returnStatus;
            }),
            distinctUntilChanged()
        );
    }

    public loadConference(conferenceId: number): Observable<Conference> {
        return this.conferenceService.getConferenceById(conferenceId);
    }

    private getProfileListById(profileMap: { [id: number]: Profile }, appointment: Appointment, currentProfileId: number): Profile[] {
        const filteredProfileMap: string[] = Object.keys(profileMap)
            .filter(key => appointment.profileIds.includes(+key))
            .filter(key => Number(key) !== currentProfileId);
        return filteredProfileMap.map(id => profileMap[id]);
    }

    private getMUCProfileListById(profileMap: { [id: number]: Profile }, appointment: MultiUserAppointment, currentProfileId: number): Profile[] {
        const filteredProfileMap: string[] = Object.keys(profileMap)
            .filter(key => appointment.profileIds.includes(+key))
            .filter(key => Number(key) !== currentProfileId);
        return filteredProfileMap.map(id => profileMap[id]);
    }
}
