import { filter } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataChannelProtocol, DataChannelProtocolService, DataChannelSendFile } from './data-channel-protocol';
import {
    FileTransferCancelledEvent,
    FileTransferChunkEvent,
    FileTransferCompleteEvent,
    FileTransferErrorEvent,
    FileTransferInitEvent,
    FileTransferStartEvent
} from './file-transfer-event.model';
import { AbstractFileTransmitter } from './file-transmitter';
import { FileUtil } from '../../../utils/file/file-util';

export class FileReceiver extends AbstractFileTransmitter {
    private chunkAmount: number;
    private receiveBuffer = [];
    private messageSubscription: Subscription = null;

    constructor(protected id, private channel, messages: BehaviorSubject<ArrayBuffer>) {
        super();
        this.transferEvents.add(new FileTransferInitEvent());
        this.messageSubscription = messages.pipe(filter(msg => msg !== null)).subscribe(msg => {
            const protocol = DataChannelProtocolService.readProtocol(msg);
            if (protocol.protocol === 'file' && protocol.id === id) {
                this.handleMessage(protocol, msg);
            }
        });
    }

    public cancel() {
        this.channel.send(DataChannelProtocolService.createCancelFileProtocol(this.id));
    }

    private handleMessage(protocol: DataChannelProtocol, msg: ArrayBuffer) {
        if (protocol.action === 'start') {
            const data = DataChannelProtocolService.readFileMetaData(msg);
            this.fileData = data.file;
            this.chunkAmount = data.chunkAmount;

            this.transferEvents.add(new FileTransferStartEvent(this.chunkAmount, this.fileData));
        }

        if (protocol.action === 'send') {
            const chunkProtocol = <DataChannelSendFile>protocol;
            const chunk = DataChannelProtocolService.readFileChunk(msg);
            this.receiveBuffer[chunkProtocol.chunk] = chunk;
            this.transferEvents.add(new FileTransferChunkEvent(chunkProtocol.chunk, chunk.byteLength, chunk));
        }

        if (protocol.action === 'complete') {
            const arrayBuffer = [];
            this.receiveBuffer.forEach(bytes => arrayBuffer.push(bytes));

            if (arrayBuffer.length === 0) {
                this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.FILE_IS_EMPTY));
            } else if (!FileUtil.isSupportedFileType(this.fileData)) {
                this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.UNSUPPORTED_FILE_EXTENSION));
            } else if (!FileUtil.matchesMimeTypeTheExtension(this.fileData)) {
                this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.WRONG_FILE_EXTENSION));
            } else {
                this.fileData.blob = new Blob(arrayBuffer, {type: this.fileData.type});
                this.transferEvents.add(new FileTransferCompleteEvent(this.fileData.blob));
            }

            if (this.messageSubscription !== null) {
                this.messageSubscription.unsubscribe();
            }
        }

        if (protocol.action === 'cancel') {
            this.transferEvents.add(new FileTransferCancelledEvent());

            if (this.messageSubscription !== null) {
                this.messageSubscription.unsubscribe();
            }
        }
    }
}
