import { Injectable } from '@angular/core';

@Injectable()
export class BrowserService {

    public isChrome(): boolean {
        return (<any>window).adapter.browserDetails.browser === 'chrome';
    }

    public isFirefox(): boolean {
        return (<any>window).adapter.browserDetails.browser === 'firefox';
    }

    public isMobile(): boolean {
        return window.matchMedia('only screen and (max-width: 760px)').matches;
    }

    public isSafari(): boolean {
        return (<any>window).adapter.browserDetails.browser === 'safari';
    }
}
