<div class="card-header-beam">
    <div class="content-wrapper row">
        <div class="col-md-4 col-lg-3"></div>
        <div class="col-md-8">
            <div class='content-container '>
                <p>{{ 'SETTING.PROFILE-TAB.PERSONAL-DESC' | translate }}</p>
            </div>
        </div>
    </div>
</div>
<div class='content-wrapper card move-up'>
    <form [formGroup]="profileUpdateForm" role="form">
        <div class='tan-form-container card-content'>
            <div class="form-group-header">
                <h3>{{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.NAME' | translate }}</h3>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="gender">
                        {{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.GENDER' | translate}}
                    </label>
                    <select id="gender"
                            formControlName="gender"
                            class="form-rounded form-control input-select ">
                        <option value="" disabled>
                            {{'SETTING.PROFILE-TAB.PROFILE-EDIT.PLACEHOLDER.GENDER' | translate}}
                        </option>
                        <option *ngFor="let gender of genders"
                                [selected]="isSelected(gender.name)"
                                [ngValue]="gender"
                        >{{gender.name}}</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="title">
                        {{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.TITLE' | translate}}
                    </label>
                    <input id="title"
                           formControlName="title"
                           type="text"
                           class="form-rounded form-control"
                           value="{{profile?.title}}"
                           placeholder="{{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.PLACEHOLDER.TITLE' | translate }}">
                </div>
                <div class="form-group col-md-6">
                    <div class="name_holder cursor-allowed">
                        <span class="name">{{profile?.firstName}} {{profile?.lastName}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="about">
                        {{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.ABOUT_ME' | translate}}
                    </label>
                    <textarea class="form-rounded form-control textarea" rows="50" id="about" formControlName="about"
                              value="{{profile?.details?.about}}"
                              placeholder="{{'SETTING.PROFILE-TAB.PROFILE-EDIT.PLACEHOLDER.ABOUT_ME' | translate}}"></textarea>
                </div>
            </div>
            <div class="form-group-header">
                <h3>{{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.SPECIALITIES_TITLE' | translate }}</h3>
                <span>{{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.SPECIALITIES_SUBTITLE' | translate }}</span>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="specialities">
                        {{ 'SETTING.PROFILE-TAB.PROFILE-EDIT.SPECIALITIES' | translate}}
                    </label>
                    <div class="chosen-container chosen-container-multi"
                         title=""
                         *ngIf="!this.specialities"
                         id="qualificationlist_loading">
                        {{ 'GENERAL.LOADING' | translate}}
                    </div>
                    <div class="chosen-container chosen-container-multi"
                         title="" *ngIf="!!this.specialities"
                         id="qualificationlist_chosen">
                        <angular2-multiselect
                            id="specialities"
                            formControlName="specialities"
                            [data]="specialities"
                            (change)="onChangeSpecialities()"
                            [settings]="specialityDropDownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer clearfix">
            <div class="footer-left">
                <span class='required'>*</span>{{ 'GENERAL.REQUIRED' | translate }}
            </div>
            <div class="footer-right">
                <button id="cancelButton" class="btn btn-link color-white" (click)="changeView()">
                    {{ 'GENERAL.CANCEL' | translate }}
                </button>
                <button type="button" id='submitButton' class="btn btn-primary" (click)="onSubmit()"
                        [disabled]="isSubmitting === true">
                    {{ 'GENERAL.SUBMIT' | translate }}
                    <span class='fal fa-check-circle' aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </form>
</div>
