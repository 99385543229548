<div @fastFadeInOut *ngIf="mouseMoved$" class="logo-space">
    <img *ngIf="(brandingSettings$ | async).logoUrl"
         [src]="(brandingSettings$ | async).logoUrl"
         class="logo"
         alt="Logo">
    <img *ngIf="!(brandingSettings$ | async).logoUrl"
         src="/assets/img/general/logo_new.svg"
         class="logo"
         alt="Logo">
</div>
