import { catchError, filter, map, retry, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MangoPayBankAccountStatus, PaymentBankAccountDto } from './dto/payment-bank-account-dto.model';
import { PaymentBankAccountMapperService } from './dto/payment-bank-account-mapper';
import { PaymentBankAccountStoreService } from './payment-bank-account-store.service';
import { PaymentBankAccount } from '../store/payment-bank-account.model';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class PaymentBankAccountService {

    constructor(private http: HttpClient,
                private storeService: PaymentBankAccountStoreService) {
    }

    paymentBankAccountEndpoint = environment.paymentBankAccountEndpoint;
    mangopayBankAccountStatusEndpoint = environment.mangopayBankAccountStatusEndpoint;

    public readPaymentBankAccount(): Observable<PaymentBankAccount> {
        return this.http
            .get(this.paymentBankAccountEndpoint, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                catchError(this.handleError),
                map((paymentBankAccountDto: PaymentBankAccountDto) =>
                    PaymentBankAccountMapperService.mapDtoToEntity(paymentBankAccountDto)
                ),
                tap((bankAccount: PaymentBankAccount) => {
                    if (bankAccount !== null) {
                        this.storeService.addPaymentBankAccountInfoInStore(bankAccount);
                    }
                })
            );
    }

    public setPaymentBankAccount(paymentBankAccount: PaymentBankAccount): Observable<PaymentBankAccount> {
        const dto = PaymentBankAccountMapperService.mapEntityToDto(paymentBankAccount);

        return this.http
            .post(this.paymentBankAccountEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                catchError(this.handleError),
                filter(e => !(e === null)),
                map((newDto: PaymentBankAccountDto) => PaymentBankAccountMapperService.mapDtoToEntity(newDto)),
                tap((bankAccount: PaymentBankAccount) => {
                    if (bankAccount !== null) {
                        this.storeService.addPaymentBankAccountInfoInStore(bankAccount);
                    }
                })
            );
    }

    public updatePaymentBankAccount(paymentBankAccount: PaymentBankAccount): Observable<PaymentBankAccount> {
        const dto = PaymentBankAccountMapperService.mapEntityToDto(paymentBankAccount);

        return this.http
            .put(this.paymentBankAccountEndpoint, dto,
                {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                catchError(this.handleError),
                filter(e => !(e === null)),
                map((paymentBankInfoAccountDto: PaymentBankAccountDto) =>
                    PaymentBankAccountMapperService.mapDtoToEntity(paymentBankInfoAccountDto)
                ),
                tap((updatedPaymentBankInfoAccount) => {
                    if (updatedPaymentBankInfoAccount !== null) {
                        this.storeService.updatePaymentBankAccountInfoInStore(updatedPaymentBankInfoAccount);
                    }
                })
            );
    }

    public readMangopayBankAccountStatus(): Observable<MangoPayBankAccountStatus> {
        return this.http
            .get<MangoPayBankAccountStatus>(this.mangopayBankAccountStatusEndpoint, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                retry(3),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred in PaymentBankAccountService:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError('Something bad happened; please try again later.');
    }
}
