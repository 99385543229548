import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Order, PaymentAccountSum } from './payment-account.model';
import { PaymentAccountActions, PaymentAccountActionTypes } from './payment-account.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity/src/models';

export interface PaymentAccountState extends EntityState<Order> {
    // additional entities state properties
    statusSums: PaymentAccountSum;
    timestamp: number;
}

export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>();

export const initialState: PaymentAccountState = adapter.getInitialState({
    // additional entity state properties
    statusSums: {
        forPayOutAmount: '0.00',
        frozenAmount: '0.00',
        paidOutSum: '0.00'
    },
    timestamp: null

});

export function reducer(state = initialState,
                        action: PaymentAccountActions): PaymentAccountState {
    switch (action.type) {
        case PaymentAccountActionTypes.AddPaymentAccount: {
            return adapter.addOne(action.payload.paymentAccount, state);
        }

        case PaymentAccountActionTypes.AddPaymentAccounts: {
            return adapter.addMany(action.payload.paymentAccounts, state);
        }

        case PaymentAccountActionTypes.UpdatePaymentAccount: {
            return adapter.updateOne(action.payload.paymentAccount, state);
        }

        case PaymentAccountActionTypes.UpdatePaymentAccounts: {
            return adapter.updateMany(action.payload.paymentAccounts, state);
        }

        case PaymentAccountActionTypes.DeletePaymentAccount: {
            return adapter.removeOne(action.payload.id, state);
        }

        case PaymentAccountActionTypes.DeletePaymentAccounts: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case PaymentAccountActionTypes.LoadPaymentAccounts: {
            return adapter.setAll(action.payload.paymentAccounts, state);
        }

        case PaymentAccountActionTypes.ClearPaymentAccounts: {
            return adapter.removeAll(state);
        }

        case PaymentAccountActionTypes.SetPaymentAccountPayInSum: {
            return {...state, statusSums: {...state.statusSums, payInSum: action.payload}};
        }

        case PaymentAccountActionTypes.SetPaymentAccountPaidSum: {
            return {...state, statusSums: {...state.statusSums, paidSum: action.payload}};
        }

        case PaymentAccountActionTypes.SetPaymentAccountTimestamp: {
            return {...state, timestamp: action.payload};
        }

        default: {
            return state;
        }
    }
}


export const selectPaymentAccountState = createFeatureSelector<PaymentAccountState>('payment-account');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors(selectPaymentAccountState);

export const selectPaymentAccount = createSelector(selectEntities, (allEntities: Dictionary<Order>) => {
        if (Object.keys(allEntities).length < 1) {
            return null;
        }
        const key = Object.keys(allEntities)[0];

        return allEntities[key];
    }
);

export const selectStatusSums = createSelector(selectPaymentAccountState, (state) => state.statusSums);
export const selectTimestamp = createSelector(selectPaymentAccountState, (state) => state.timestamp);

