import { Injectable } from '@angular/core';
import { SchedulerFactory, SchedulerService } from '../scheduler/scheduler.service';
import { HeartbeatService } from '../heartbeat/heartbeat.service';
import { StatsService } from '../../../../entities/webrtc-connection-stats';

@Injectable()
export class MonitorService {
    private heartbeatScheduler: SchedulerService;
    private statsScheduler: SchedulerService;

    constructor(
        private factory: SchedulerFactory,
        private heartbeat: HeartbeatService,
        private stats: StatsService
    ) {
        this.heartbeatScheduler = this.factory.create();
        this.heartbeatScheduler.register('heartbeat', heartbeat);
        this.statsScheduler = this.factory.create();
        this.statsScheduler.register('stats', stats);
    }

    public start(connection: RTCPeerConnection): void {
        this.heartbeatScheduler.start(connection);
        this.statsScheduler.start(connection, 1000);
    }

    public stop() {
        this.heartbeatScheduler.stop();
        this.statsScheduler.stop();
    }
}
