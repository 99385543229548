import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UserAgent } from './user-agent.model';

export const loadUserAgents = createAction(
  '[UserAgent/API] Load UserAgents',
  props<{ userAgents: UserAgent[] }>()
);

export const addUserAgent = createAction(
  '[UserAgent/API] Add UserAgent',
  props<{ userAgent: UserAgent }>()
);

export const upsertUserAgent = createAction(
  '[UserAgent/API] Upsert UserAgent',
  props<{ userAgent: UserAgent }>()
);

export const addUserAgents = createAction(
  '[UserAgent/API] Add UserAgents',
  props<{ userAgents: UserAgent[] }>()
);

export const upsertUserAgents = createAction(
  '[UserAgent/API] Upsert UserAgents',
  props<{ userAgents: UserAgent[] }>()
);

export const updateUserAgent = createAction(
  '[UserAgent/API] Update UserAgent',
  props<{ userAgent: Update<UserAgent> }>()
);

export const updateUserAgents = createAction(
  '[UserAgent/API] Update UserAgents',
  props<{ userAgents: Update<UserAgent>[] }>()
);

export const deleteUserAgent = createAction(
  '[UserAgent/API] Delete UserAgent',
  props<{ id: string }>()
);

export const deleteUserAgents = createAction(
  '[UserAgent/API] Delete UserAgents',
  props<{ ids: string[] }>()
);

export const clearUserAgents = createAction(
  '[UserAgent/API] Clear UserAgents'
);
