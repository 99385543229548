import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityStateProfile, Profile, profileAdapter } from './profile.entity';

export const selectProfileState = createFeatureSelector<EntityStateProfile>('profile');

export const {
    selectIds: idList,
    selectEntities: entityList,
} = profileAdapter.getSelectors(selectProfileState);

export const getCurrentIdSelector = (state: EntityStateProfile) => state.currentId;

export const selectCurrentProfileId = createSelector(selectProfileState, getCurrentIdSelector);

export const getEntetiesSelector = (state: EntityStateProfile) => state.entities;

export const isInitSelector = (state: EntityStateProfile) => state.isInit;

export const selectEntitites = createSelector(selectProfileState, getEntetiesSelector);

export const selectCurrentProfile = createSelector(
    entityList,
    selectCurrentProfileId,
    (profileEntities, currentId) => profileEntities[currentId]
);

export const selectCurrentProfileType = createSelector(
    selectCurrentProfile,
    (profile: Profile) => {
        if (profile) {
            return profile.type;
        }
    }
);

export const getIsInitSelector = createSelector(selectProfileState, isInitSelector);


