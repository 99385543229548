import { Injectable, OnDestroy } from '@angular/core';
import { ProfileService } from '../../profile/providers/profile.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JitsiRefreshTokenService implements OnDestroy {
    private timerId: ReturnType<typeof setTimeout>;

    // FIXME ProfileService is used here temporarily until we create the appropriate endpoint to perform this task.
    constructor(private profileService: ProfileService) {
    }

    public startRefresh(): void {
        this.stopRefresh();
        let refreshTokenService$: Subscription = new Subscription();

        this.timerId = setInterval(() => {
            refreshTokenService$ = this.profileService.getCurrentProfileData()
                .pipe(first())
                .subscribe(() => {
                    refreshTokenService$.unsubscribe();
                });
        }, 900000);
    }

    public stopRefresh(): void {
        clearInterval(this.timerId);
    }

    public ngOnDestroy(): void {
        this.stopRefresh();
    }
}
