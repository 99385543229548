import {catchError, map, mapTo, mergeMap, switchMap, tap} from 'rxjs/operators';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS,
    MEDIA_START_CLOSING_LOCAL_SCREEN_STREAM,
    MEDIA_START_CLOSING_LOCAL_STREAM,
    MEDIA_START_REQUESTING_LOCAL_SCREEN_STREAM,
    MEDIA_START_REQUESTING_LOCAL_STREAM,
    MediaCallEndAction,
    MediaCallStartSuccessAction,
    MediaCanNotAccessLocalStreamAction,
    MediaClosedLocalScreenStreamSuccessAction,
    MediaClosedLocalStreamSuccessAction,
    MediaFailure,
    MediaReceivedLocalScreenStreamSuccessAction,
    MediaReceivedLocalStreamSuccessAction,
    MediaStartClosingLocalScreenStreamAction,
    MediaStartClosingLocalStreamAction,
    MediaStartClosingRemoteScreenStreamAction
} from './media.action';
import {MediaStreamProvider} from '../provider/media-stream.provider';
import {MediaVideoModel} from '../model/media-video.model';
import {
    WEBRTC_CLOSE_MAIN_CONNECTION,
    WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS,
    WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION
} from '../../webrtc/store/webrtc.action';
import {APP_CHANGE_TO_ANONYMOUS_STATE} from '../../../providers/store/app.action';
import {ChromeExtensionService} from '../provider/chrome-extension.service';
import {BrowserService} from '../provider/browser.service';
import {COUNTDOWN_FINISHED} from '../../countdown/store/countdown.action';
import {empty, iif, Observable, of} from 'rxjs';

@Injectable()
export class MediaEffects {
    constructor(private mediaStreamProvider: MediaStreamProvider,
                private actions$: Actions,
                private chromeExtensionService: ChromeExtensionService,
                private browserService: BrowserService) {
    }


     startLocalVideoCamera$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_START_REQUESTING_LOCAL_STREAM),
        switchMap(() => this.mediaStreamProvider
                .getLocalVideoStream().pipe(
                    map((stream: MediaStream) => new MediaReceivedLocalStreamSuccessAction(new MediaVideoModel(stream))),
                    catchError((err) => {
                        console.log(err);
                        return of(new MediaCanNotAccessLocalStreamAction());
                    })
                ))));

     startRequestLocalScreenShareStream$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_START_REQUESTING_LOCAL_SCREEN_STREAM),
        mergeMap(() => iif(
                this.browserService.isChrome,
                this.chromeExtensionService.queryChromeExtensionForScreenId(),
                of(null)
            ).pipe(catchError(error =>
                // because we want to go on when screen selection was dismissed
                 empty()
            ))),
        mergeMap((sourceId: string) => this.mediaStreamProvider
                .getLocalScreenStream(sourceId).pipe(
                    map((stream: MediaStream) => new MediaReceivedLocalScreenStreamSuccessAction(new MediaVideoModel(stream))),
                    catchError((err) => of(new MediaFailure([err.message])))
                ))
    ));

     setStreamEventHandler$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS),
        tap((action: MediaReceivedLocalScreenStreamSuccessAction) => {
            this.mediaStreamProvider.initializeStreamEventHandler(action.payload.stream);
        })), {dispatch: false});

     endScreenShareWhenMainConnectionClosed$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CLOSE_MAIN_CONNECTION),
        map(() => new MediaStartClosingLocalScreenStreamAction())));

     closeLocalVideoCamera$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_START_CLOSING_LOCAL_STREAM),
        map(() => {
            // @todo: refactor this please this.mediaStreamProvider.closeLocalVideoStream() should return a observable
            try {
                this.mediaStreamProvider.closeLocalVideoStream();
                return new MediaClosedLocalStreamSuccessAction();
            } catch (error) {
                return new MediaFailure([error.message]);
            }
        })));

     closeLocalScreenShareStream$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_START_CLOSING_LOCAL_SCREEN_STREAM),
        map(() => {
            try {
                this.mediaStreamProvider.closeLocalScreenStream();
                return new MediaClosedLocalScreenStreamSuccessAction();
            } catch (error) {
                return new MediaFailure([error.message]);
            }
        })));

     closeRemoteScreenSHareStream$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION),
        map(() => new MediaStartClosingRemoteScreenStreamAction())));

     endCall$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS),
        mapTo(new MediaCallEndAction())));

     doResetVideoCamera$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new MediaStartClosingLocalStreamAction())));

     startVideoConferenceAfterCountdown$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(COUNTDOWN_FINISHED),
        mapTo(new MediaCallStartSuccessAction())));
}
