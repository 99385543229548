export interface AudioModel {
    play(): void;
    stop(): void;
    getSound(): HTMLAudioElement;
    onEnded(callback: () => void): void;
    onPause(callback: () => void): void;
}

export class AudioTestModel implements AudioModel {
    private sound: HTMLAudioElement = new Audio();

    constructor() {
        this.sound.src = '/assets/sounds/din_din.mp3';
        this.sound.load();
    }

    public play(): void {
        this.sound.play();
    }

    public stop(): void {
        this.sound.pause();
    }

    public getSound(): HTMLAudioElement {
        return this.sound;
    }

    public onEnded(callback: () => void) {
        this.sound.onended = callback;
    }

    public onPause(callback: () => void) {
        this.sound.onpause = callback;
    }
}
