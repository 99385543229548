import { Injectable } from '@angular/core';
import { AppState } from '../store/signal.state';
import { Store } from '@ngrx/store';
import { SignalActivateSessionAction, SignalSetTokensAction } from '../store/signal.action';


@Injectable()
export class OnlineStateService {

    constructor(private store: Store<AppState>) {
    }

    public setTokens(myTokens: string[], userTokens: string[]): void {
        this.store.dispatch(new SignalSetTokensAction(myTokens, userTokens));
    }

    public loadSession(userToken: string): void {
        this.store.dispatch(new SignalActivateSessionAction(userToken));
    }
}

