<div class="modal-header">
    <i class="far fa-list"></i>
    <h4 class="modal-title">
        {{ 'RTC_DETECTION.TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelStep()"><span
            aria-hidden="true"><i class="far fa-times"></i></span></button>
</div>
<div class="modal-body">
    <div class="body-headline">
        <i class="fas fa-video"></i>
        <i class="fas fa-microphone"></i>
        <h5>{{'RTC_DETECTION.HARDWARE.HEADLINE' | translate }}</h5>
    </div>

    <div class="body-content">
        <span>{{ 'RTC_DETECTION.HARDWARE.TEXT' | translate }}</span>
    </div>
</div>
<div class="modal-footer">
    <div class="button-line">
        <button type="button" class="btn btn-outline-dark" (click)="cancelStep()">
            {{'RTC_DETECTION.CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="startCamera()">
            <span>
                {{'RTC_DETECTION.START' | translate }}
                <i class="fas fa-arrow-right"></i>
            </span>
        </button>
    </div>

    <app-rtc-footer></app-rtc-footer>
</div>

