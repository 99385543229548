export interface PaymentBankAccountDto {
    bankAccountId?: number;
    iban: string;
    bic: string;
    firstName: string;
    lastName: string;
    country: {id: number; name: string};
    city: string;
    zipCode: string;
    addressLine: string;
}

export enum STATUS_TYPES {
    EMPTY = 'EMPTY',
    PROCESSING = 'PROCESSING',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
}
export interface MangoPayBankAccountStatus {
    status: STATUS_TYPES;
}
