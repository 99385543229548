export interface PaymentBankAccount {
    id?: number;
    iban: string;
    bic: string;
    firstName: string;
    lastName: string;
    country: {id: number; name: string};
    city: string;
    plz: string;
    status?: BankAccountStatus;
    addressLine: string;
}
export enum BankAccountStatus {
    'PROCESSING' = 'PROCESSING',
    'FINISHED' = 'FINISHED',
    'FAILED' = 'FAILED',
}
