import { CompanyType, CompanyTypes } from '../../../../utils/mangopay/company-types';
import { CountryService, CountryType } from '../../../../../entities/payment-country/provider/country.service';
import { PaymentInfo, PaymentInfoStatuses, PaymentInfoTypes } from '../../../../../entities/payment-info/store/payment-info.model';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreateLegalUserFormBuilder } from './legal-user.form-builder';
import { InvalidDirective } from '../../../../../forms/directives/invalid.directive';
import { Child, ChildResponseType, ResponseType } from '../payment-info-tab/payment-info-tab.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-legal-user',
    templateUrl: './legal-user.component.html',
    styleUrls: ['./legal-user.component.scss']
})
export class LegalUserComponent implements OnInit {
    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;
    @Output() editFinishedEmitter = new EventEmitter<ChildResponseType>();
    @Input() paymentInfo: PaymentInfo;
    @Input() isEditing: boolean;

    public createLegalUserForm: FormGroup;
    public countries: CountryType[];
    public selectedCompanyType: CompanyType = {id: 'SOLETRADER', name: 'Sole-trader'};
    public companyTypes: CompanyType[];
    public birthYear: string;
    public birthMonth: string;
    public birthDay: string;

    constructor(
        private formBuilder: FormBuilder,
        private countryService: CountryService,
        public cdRef: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.createLegalUserForm = CreateLegalUserFormBuilder.build(this.formBuilder);
        this.countryService.getCountries().pipe(take(1)).subscribe(countries => this.countries = countries);
        this.companyTypes = CompanyTypes;

        if (!!this.paymentInfo) {
            this.separateBirthdayString();
            this.updateInputFieldsOnUpdate();
        }
    }

    private updateInputFieldsOnUpdate() {
        this.createLegalUserForm.setValue({
            companyName: this.paymentInfo.companyName,
            firstName: this.paymentInfo.firstName,
            lastName: this.paymentInfo.lastName,
            birthday: {
                birthDay: this.birthDay,
                birthMonth: this.birthMonth,
                birthYear: this.birthYear,
            },
            email: this.paymentInfo.email,
            country: {
                id: this.paymentInfo.country.id,
                name: this.paymentInfo.country.name
            },
            nationality: {
                id: this.paymentInfo.nationality.id,
                name: this.paymentInfo.nationality.name
            }
        });
    }

    private validateForm() {
        Object.keys(this.createLegalUserForm.controls).map(key => {
            this.createLegalUserForm.get(key).markAsDirty();
        });
        this.separateBirthdayString();
        this.invalidDirectives.map((directive) => {
            directive.triggerTooltip();
        });
    }

    public submit(): void {
        this.createCompanyName();

        this.validateForm();
        if (this.createLegalUserForm.valid) {
            if (!this.paymentInfo) {
                this.editFinishedEmitter.emit({Child: Child.USERINFO, ResponseType: ResponseType.CREATED, response: this.getPayload()});
            } else {
                this.editFinishedEmitter.emit({Child: Child.USERINFO, ResponseType: ResponseType.UPDATED, response: this.getPayload()});
            }
        }
    }

    public cancel(): void {
        this.editFinishedEmitter.emit({Child: Child.USERINFO, ResponseType: ResponseType.CANCELED});
    }

    public getPayload(): PaymentInfo {
        const form = this.createLegalUserForm.controls;

        return <PaymentInfo>{
            type: PaymentInfoTypes.LEGAL,
            firstName: form['firstName'].value,
            lastName: form['lastName'].value,
            birthday: this.getBirthday(),
            email: form['email'].value,
            country: form['country'].value,
            nationality: form['nationality'].value,
            status: PaymentInfoStatuses.PROCESSING,
            companyName: form['companyName'].value,
            companyType: this.selectedCompanyType.id,
        };
    }

    public goToNextInput(event) {
        const target = event.srcElement || event.target;
        if (target.nextElementSibling && target.value.length === 2) {
            target.nextElementSibling.focus();
        }
    }

    private getBirthday(): string {
        const birthday = this.createLegalUserForm.controls['birthday'].value;
        const formattedBirthday = `${birthday.birthYear}-${birthday.birthMonth}-${birthday.birthDay}`;
        return formattedBirthday;
    }

    public separateBirthdayString() {
        if (!!this.paymentInfo && this.paymentInfo.birthday !== undefined) {
            const birthDate = this.paymentInfo.birthday;
            this.birthYear = birthDate.substring(0, 4).toString();
            this.birthMonth = birthDate.substring(5, 7).toString();
            this.birthDay = birthDate.substring(8, 10).toString();
        } else {
            const getDateInput = this.createLegalUserForm.controls['birthday'].value;
            this.birthYear = getDateInput.birthYear;
            this.birthMonth = getDateInput.birthMonth;
            this.birthDay = getDateInput.birthDay;
        }
    }

    public isBirthdayElementDirty(): boolean {
        return this.createLegalUserForm.controls['birthday'].dirty;
    }

    public isBirthdayElementTouched(): boolean {
        return this.createLegalUserForm.controls['birthday'].touched;
    }

    public isBirthdayValid(): boolean {
        return this.createLegalUserForm.controls['birthday'].valid;
    }

    public compareByCountry(item1, item2): boolean {
        return item1.name === item2.name;
    }

    private createCompanyName() {
        const firstName = this.createLegalUserForm.controls.firstName.value;
        const lastName = this.createLegalUserForm.controls.lastName.value;
        const companyName = firstName + ' ' + lastName;
        this.createLegalUserForm.controls.companyName.setValue(companyName);
    }
}
