<div class="overflow-wrapper">
    <div class="payment-info-tab payment-info">
        <div class="banner-wrapper">
            <h2>{{ 'PAYMENT.INFO.HEADER' | translate}}</h2>
            <p>{{ 'PAYMENT.INFO.DESCRIPTION' | translate}}</p>
        </div>
        <img class="header-illustration" src="/assets/img/illustrations/konto-info-bg.png" alt="">
    </div>
</div>
<div class="content-wrapper">
    <h3>
        <i class="fal fa-user-md"></i> {{ 'PAYMENT.INFO.PERSONAL-PAYMENT-INFO' | translate}}
        <span class="fas fa-check-circle color-green payment-activated" *ngIf="paymentInfoActivated === true"></span>
        <span class="fas fa-exclamation-circle color-alert payment-not-activated" *ngIf="paymentInfoActivated === false"></span>
        <span *ngIf="!!paymentInfo && isUserInfoEditing === false" class="modify-badge" (click)="editUserInfo()">
            <i class="fas fa-pen-alt"></i> {{ 'PAYMENT.INFO.MODIFY' | translate }}
        </span>
    </h3>
    <p class="color-alert" *ngIf="dataUnderReview === true && paymentInfoActivated === false">
        {{ 'PAYMENT.INFO.PAGE_RELOAD' | translate }}
    </p>
</div>
<div class="card-header-beam">
    <div class="content-wrapper row">
        <div class="content-container">
            <p>{{ 'PAYMENT.INFO.INFO' | translate }}</p>
        </div>
    </div>
</div>
<div class="loading" *ngIf="paymentInfoLoading === true">
    Loading..
</div>
<div *ngIf="paymentInfoLoading === false">

    <app-legal-user [paymentInfo]="paymentInfo" [isEditing]="isUserInfoEditing"
        (editFinishedEmitter)="userInfoEditFinished($event)">
    </app-legal-user>

</div>
<div class="content-wrapper" *ngIf="paymentInfoActivated === true && !!childResponse && childResponse.Child === 'USERINFO'">
    <h3>{{ 'PAYMENT.INFO.TITLE' | translate }}</h3>
    <div class="content-container" *ngIf="childResponse.ResponseType === 'CREATED'">
        <p>
            <span class="fas fa-bullhorn color-green"></span>
            {{ 'PAYMENT.INFO.CREATE-USER-INFO' | translate }}
            <strong>{{ 'PAYMENT.INFO.FILL-IN-BANK-DETAILS' | translate }}</strong>
        </p>
    </div>
    <div class="content-container" *ngIf="childResponse.ResponseType === 'UPDATED'">
        <p>
            <span class="fas fa-bullhorn color-green"></span>
            {{ 'PAYMENT.INFO.UPDATE-USER-INFO' | translate }}
        </p>
    </div>
</div>
<ng-template [ngIf]="hasActivePaymentInfo()">
    <div class="content-wrapper">
        <h3>
            <i class="fal fa-university"></i> {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLE' | translate }}
            <span class="bank-completed fas fa-check-circle color-green"
                  *ngIf="bankAccountInfoActivated === true"></span>
            <span class="bank-not-completed  fas fa-exclamation-circle color-alert"
                  *ngIf="bankAccountInfoActivated === false"></span>
            <span *ngIf="!!paymentBankAccount && isBankInfoEditing === false"
                  class="modify-badge" (click)="editBankInfo()">
                <i class="fas fa-pen-alt"></i> {{ 'PAYMENT.INFO.MODIFY' | translate }}
            </span>
        </h3>
        <div class="payment-info-updates row">
        </div>
    </div>
    <div class="card-header-beam">
        <div class="content-wrapper row">
            <div class="content-container">
                <p>{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.SUB-TITLE' | translate }}</p>
            </div>
        </div>
    </div>

    <app-bank-account [bankAccount]="paymentBankAccount" [isEditing]="isBankInfoEditing"
        (editFinishedEmitter)="bankInfoEditFinished($event)">
    </app-bank-account>

    <div class="content-wrapper"
         *ngIf="!!childResponse && childResponse.Child === 'BANKINFO'">
        <h3>{{ 'PAYMENT.INFO.TITLE' | translate }}</h3>
        <div class="content-container" *ngIf="childResponse.ResponseType === 'CREATED'">
            <p>
                <span class="fas fa-bullhorn color-green"></span>
                {{ 'PAYMENT.INFO.CREATE-PAYMENT-INFO' | translate }}
            </p>
        </div>
        <div class="content-container" *ngIf="childResponse.ResponseType === 'UPDATED'">
            <p>
                <span class="fas fa-bullhorn color-green"></span>
                {{ 'PAYMENT.INFO.UPDATE-PAYMENT-INFO' | translate }}
            </p>
        </div>
    </div>
</ng-template>
