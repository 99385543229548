import { filter } from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-remote-screen-videotag',
    templateUrl: './remote-screen-video-tag.component.html',
    styleUrls: ['./remote-screen-video-tag.component.scss']
})
export class RemoteScreenVideoTagComponent implements OnInit, OnDestroy {

    @Input() fullsized: boolean;

    remoteScreenStreamElement$: Observable<MediaStreamElement>;
    private remoteStreamSubscription: Subscription = null;

    constructor(private elRef: ElementRef, private store: Store<AppState>) {
        this.remoteScreenStreamElement$ = this.store.select((state: AppState) => state.media.remoteScreenStream).pipe(filter(e => !!e));
    }

    ngOnInit() {
        this.remoteStreamSubscription = this.remoteScreenStreamElement$.subscribe(mediaElement => {
            if (mediaElement.active) {
                const video = this.elRef.nativeElement.querySelector('#remote-screen-video-tag');
                video.srcObject = mediaElement.media.stream;
                video.muted = true;
            }
            if (!mediaElement.active) {
                const video = this.elRef.nativeElement.querySelector('#remote-screen-video-tag');
                video.srcObject = null;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.remoteStreamSubscription !== null) {
            this.remoteStreamSubscription.unsubscribe();
        }
    }
}
