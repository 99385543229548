import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity/src/models';
import {PaymentBankAccount} from './payment-bank-account.model';

export enum PaymentBankAccountActionTypes {
    LoadPaymentBankAccountInfos = '[PaymentBankAccount] Load PaymentBankAccounts',
    AddPaymentBankAccountInfo = '[PaymentBankAccount] Add PaymentBankAccount',
    AddPaymentBankAccountInfos = '[PaymentBankAccount] Add PaymentBankAccounts',
    UpdatePaymentBankAccountInfo = '[PaymentBankAccount] Update PaymentBankAccount',
    UpdatePaymentBankAccountInfos = '[PaymentBankAccount] Update PaymentBankAccounts',
    DeletePaymentBankAccountInfo = '[PaymentBankAccount] Delete PaymentBankAccount',
    DeletePaymentBankAccountInfos = '[PaymentBankAccount] Delete PaymentBankAccounts',
    ClearPaymentBankAccountInfos = '[PaymentBankAccount] Clear PaymentBankAccounts'
}

export class LoadPaymentBankAccountInfos implements Action {
    readonly type = PaymentBankAccountActionTypes.LoadPaymentBankAccountInfos;

    constructor(public payload: { paymentBankAccountInfos: PaymentBankAccount[] }) {
    }
}

export class AddPaymentBankAccountInfo implements Action {
    readonly type = PaymentBankAccountActionTypes.AddPaymentBankAccountInfo;

    constructor(public payload: { paymentBankAccountInfo: PaymentBankAccount }) {
    }
}

export class AddPaymentBankAccountInfos implements Action {
    readonly type = PaymentBankAccountActionTypes.AddPaymentBankAccountInfos;

    constructor(public payload: { paymentBankAccountInfos: PaymentBankAccount[] }) {
    }
}

export class UpdatePaymentBankAccountInfo implements Action {
    readonly type = PaymentBankAccountActionTypes.UpdatePaymentBankAccountInfo;

    constructor(public payload: { paymentBankAccountInfo: Update<PaymentBankAccount> }) {
    }
}

export class UpdatePaymentBankAccountInfos implements Action {
    readonly type = PaymentBankAccountActionTypes.UpdatePaymentBankAccountInfos;

    constructor(public payload: { paymentBankAccountInfos: Update<PaymentBankAccount>[] }) {
    }
}

export class DeletePaymentBankAccountInfo implements Action {
    readonly type = PaymentBankAccountActionTypes.DeletePaymentBankAccountInfo;

    constructor(public payload: { id: string }) {
    }
}

export class DeletePaymentBankAccountInfos implements Action {
    readonly type = PaymentBankAccountActionTypes.DeletePaymentBankAccountInfos;

    constructor(public payload: { ids: string[] }) {
    }
}

export class ClearPaymentBankAccountInfos implements Action {
    readonly type = PaymentBankAccountActionTypes.ClearPaymentBankAccountInfos;
}

export type PaymentBankAccountActions =
    LoadPaymentBankAccountInfos
    | AddPaymentBankAccountInfo
    | AddPaymentBankAccountInfos
    | UpdatePaymentBankAccountInfo
    | UpdatePaymentBankAccountInfos
    | DeletePaymentBankAccountInfo
    | DeletePaymentBankAccountInfos
    | ClearPaymentBankAccountInfos;
