import {
    SIGNAL_ACTIVATE_SESSION_SUCCESS,
    SIGNAL_CLEAN_SESSION,
    SIGNAL_SET_TOKENS,
    SIGNAL_LOCAL_CONNECTION_READY_CHANGED,
    SIGNAL_REMOTE_CONNECTION_READY_CHANGED,
    SIGNAL_START_OBSERVE_USER_SUCCESS,
    SIGNAL_STOP_OBSERVE_USER_SUCCESS,
    SignalAction,
} from './signal.action';
import { SignalInitialState, SignalState } from './signal.state';

export function signalReducer(state: SignalState = new SignalInitialState(), action: SignalAction): SignalState {
    switch (action.type) {
        case SIGNAL_SET_TOKENS: {
            return {...state, userTokens: action.payload.userTokens, myTokens: action.payload.myTokens};
        }
        case SIGNAL_CLEAN_SESSION: {
            return new SignalInitialState();
        }
        case SIGNAL_ACTIVATE_SESSION_SUCCESS: {
            return {...state, activeUserToken: action.payload.activeUserToken};
        }
        case SIGNAL_START_OBSERVE_USER_SUCCESS: {
            return {...state, activePollingTimer: action.payload.activePollingTimer};
        }
        case SIGNAL_STOP_OBSERVE_USER_SUCCESS: {
            return {...state, activePollingTimer: null};
        }
        case SIGNAL_LOCAL_CONNECTION_READY_CHANGED:
            return {...state, ready: {...state.ready, isLocalConnectionReady: action.payload.ready.isLocalConnectionReady}};
        case SIGNAL_REMOTE_CONNECTION_READY_CHANGED:
            return {...state, ready: {...state.ready, isRemoteConnectionReady: action.payload.ready.isRemoteConnectionReady}};
        default: {
            return state;
        }
    }
}
