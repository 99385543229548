<ng-template #content let-modal>
    <div class="modal-header header-modal">
        <i class="far fa-edit icon-header"></i>
        <h5 class="modal-title weight-600">{{'NOTICE.OVERLAY.TITLE' | translate}}</h5>
        <i class="fa fa-window-minimize close minimizeIcon-size" (click)="close('Cross click')"></i>
    </div>
    <div class="modal-body padding-body">
        <form>
            <div class="form-group form-margin">
                <textarea [ngModelOptions]="{standalone: true}" [(ngModel)]="text" class="form-control textarea-border"
                          placeholder="{{'NOTICE.PLACEHOLDER.TEXT' | translate}}"></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer padding-footer" *ngIf="(text)">
        <button type="button" class="btn btn-primary btn-sm weight-600 btn-radius"
                [ngClass]="{'btn-copy-clickStyle':copyBtnStyle === true}"
                (click)="adjustBtn()" ngxClipboard [cbContent]="text">
            <i [ngClass]="copyIconClass ? 'fa fa-check' : 'fa fa-files-o'"></i>
            {{'NOTICE.BUTTONS.COPY' | translate}}
        </button>
        <button type="button" class="btn btn-primary btn-sm weight-600 btn-radius" (click)="downloadFile()"><i
                class="fa fa-download"></i>
            {{'NOTICE.BUTTONS.DOWNLOAD' | translate}}
        </button>
    </div>
</ng-template>
<div class="control-item-icon on-hover" (click)="open(content)">
    <i class="far fa-edit icon-position"><span class="badge badge-light customize-badge" *ngIf="(text)"> </span></i>
</div>
