import { RtcDetectionStepComponent } from '../rtc-detection-step-component.interface';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from '../rtc-detection.entities';

@Component({
    templateUrl: './step-chrome-extension.component.html',
    styleUrls: ['../rtc-detection.component.scss', './step-chrome-extension.component.scss']
})
export class StepChromeExtensionComponent implements RtcDetectionStepComponent {
    nextStep: (arg) => void;
    dismissModal: () => void;
    closeModal: () => void;
    cancelStep: () => void;

    constructor(private translate: TranslateService) {
    }

    public continue() {
        this.nextStep(StepType.SUCCESS);
    }

    public install() {
        window.open('https://chrome.google.com/webstore/detail/patientus/onlgpihoakknioeppcehlhppfflcbieo', '_blank');
    }
}
