export interface GenderType {
    id: string;
    name: string;
}

export const Genders = [
    { id: 'm', name: 'Herr' },
    { id: 'f', name: 'Frau' },
    { id: 'u', name: '' }
];

export function getGenderIdByName(name: string): string {
    const item = Genders.find(element => element.name === name , name);

    if (!item) {
        return name;
    }

    return item.id;
}

export function getGenderNameById(id: string): string {
    const item = Genders.find(element => element.id === id , id);

    if (!item) {
        return;
    }

    return item.name;
}

export function getGenderByName(name: string): GenderType {
    const item = Genders.find(element => element.name === name , name);

    if (!item) {
        return;
    }

    return item;
}
