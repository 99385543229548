import { Action } from '@ngrx/store';
import { Media, MediaVideoModel } from '../model/media-video.model';

export interface MediaAction extends Action {
    readonly type: string;
}

export const MEDIA_FAIL = 'MEDIA_FAIL';
export class MediaFailure implements MediaAction{
    readonly type = MEDIA_FAIL;
    constructor(errors: string[]) {
    }
}

// #####################################################################################################################
// Local Stream Actions
export const MEDIA_START_REQUESTING_LOCAL_STREAM = 'MEDIA_START_REQUESTING_LOCAL_STREAM';
export class MediaStartRequestingLocalStreamAction implements MediaAction {
    readonly type = MEDIA_START_REQUESTING_LOCAL_STREAM;
}

export const MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS = 'MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS';
export class MediaReceivedLocalStreamSuccessAction implements MediaAction {
    readonly type = MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS;
    constructor(public payload: Media) {
    }
}

export const MEDIA_CAN_NOT_ACCESS_LOCAL_STREAM = 'MEDIA_CAN_NOT_ACCESS_LOCAL_STREAM';
export class MediaCanNotAccessLocalStreamAction implements MediaAction {
    readonly type = MEDIA_CAN_NOT_ACCESS_LOCAL_STREAM;
}

export const MEDIA_START_CLOSING_LOCAL_STREAM = 'MEDIA_START_CLOSING_LOCAL_STREAM';
export class MediaStartClosingLocalStreamAction implements MediaAction {
    readonly type = MEDIA_START_CLOSING_LOCAL_STREAM;
}

export const MEDIA_CLOSED_LOCAL_STREAM_SUCCESS = 'MEDIA_CLOSED_LOCAL_STREAM_SUCCESS';
export class MediaClosedLocalStreamSuccessAction implements MediaAction {
    readonly type = MEDIA_CLOSED_LOCAL_STREAM_SUCCESS;
}

// #####################################################################################################################
// Local Screen Stream Actions
export const MEDIA_START_REQUESTING_LOCAL_SCREEN_STREAM = 'MEDIA_START_REQUESTING_LOCAL_SCREEN_STREAM';
export class MediaStartRequestingLocalScreenStreamAction implements MediaAction {
    readonly type = MEDIA_START_REQUESTING_LOCAL_SCREEN_STREAM;
}

export const MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS = 'MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS';
export class MediaReceivedLocalScreenStreamSuccessAction implements MediaAction {
    readonly type = MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS;
    constructor(public payload: Media) {}
}

export const MEDIA_START_CLOSING_LOCAL_SCREEN_STREAM = 'MEDIA_START_CLOSING_LOCAL_SCREEN_STREAM';
export class MediaStartClosingLocalScreenStreamAction implements MediaAction {
    readonly type = MEDIA_START_CLOSING_LOCAL_SCREEN_STREAM;
}

export const MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS = 'MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS';
export class MediaClosedLocalScreenStreamSuccessAction implements MediaAction {
    readonly type = MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS;
}

// #####################################################################################################################
// Remote Stream
export const MEDIA_SAVE_REMOTE_STREAM = 'MEDIA_SAVE_REMOTE_STREAM';
export class MediaSaveRemoteStreamAction implements MediaAction {
    readonly type = MEDIA_SAVE_REMOTE_STREAM;
    payload: Media;
    constructor(media: MediaStream) {
        this.payload = new MediaVideoModel(media);
    }
}

export const MEDIA_START_CLOSING_REMOTE_SCREEN_STREAM = 'MEDIA_START_CLOSING_REMOTE_SCREEN_STREAM';
export class MediaStartClosingRemoteScreenStreamAction implements MediaAction {
    readonly type = MEDIA_START_CLOSING_REMOTE_SCREEN_STREAM;
}

export const MEDIA_CLOSED_REMOTE_SCREEN_STREAM_SUCCESS = 'MEDIA_CLOSED_REMOTE_SCREEN_STREAM_SUCCESS';
export class MediaClosedRemoteScreenStreamSuccessAction implements MediaAction {
    readonly type = MEDIA_CLOSED_REMOTE_SCREEN_STREAM_SUCCESS;
}


// #####################################################################################################################
// Remote Screen Stream
export const MEDIA_SAVE_REMOTE_SCREEN_STREAM = 'MEDIA_SAVE_REMOTE_SCREEN_STREAM';
export class MediaSaveRemoteScreenStreamAction implements MediaAction {
    readonly type = MEDIA_SAVE_REMOTE_SCREEN_STREAM;
    payload: Media;
    constructor(media: MediaStream) {
        this.payload = new MediaVideoModel(media);
    }
}

// #####################################################################################################################
// Main Video
export const MEDIA_TOGGLE_FULLSIZED_MAIN_VIDEO = 'MEDIA_TOGGLE_FULLSIZED_MAIN_VIDEO';
export class MediaToggleFullsizedMainVideo implements MediaAction {
    readonly type = MEDIA_TOGGLE_FULLSIZED_MAIN_VIDEO;
}

// #####################################################################################################################
// Camera Control Actions
export const MEDIA_VIDEOCAM_ON = 'MEDIA_VIDEOCAM_ON';
export class MediaVideocamOnAction implements MediaAction {
    readonly type = MEDIA_VIDEOCAM_ON;
}

export const MEDIA_VIDEOTRACK_OFF = 'MEDIA_VIDEOTRACK_OFF';
export class MediaVideoTrackOffAction implements MediaAction {
    readonly type = MEDIA_VIDEOTRACK_OFF;
}

export const MEDIA_MIC_MUTE = 'MEDIA_MIC_MUTE';
export class MediaMicMuteAction implements MediaAction {
    readonly type = MEDIA_MIC_MUTE;
}

export const MEDIA_MIC_UNMUTE = 'MEDIA_MIC_UNMUTE';
export class MediaMicUnmuteAction implements MediaAction {
    readonly type = MEDIA_MIC_UNMUTE;
}
// #####################################################################################################################
// Video Call Actions
export const MEDIA_CALL_START = 'MEDIA_CALL_START';
export class MediaCallStartAction implements MediaAction {
    readonly type = MEDIA_CALL_START;
}

export const MEDIA_CALL_START_SUCCESS = 'MEDIA_CALL_START_SUCCESS';
export class MediaCallStartSuccessAction implements MediaAction {
    readonly type = MEDIA_CALL_START_SUCCESS;
}

export const MEDIA_CALL_END = 'MEDIA_CALL_END';
export class MediaCallEndAction implements MediaAction {
    readonly type = MEDIA_CALL_END;
}

export const MEDIA_CALL_END_SUCCESS = 'MEDIA_CALL_END_SUCCESS';
export class MediaCallEndSuccessAction implements MediaAction {
    readonly type = MEDIA_CALL_END_SUCCESS;
}


export const MEDIA_MICROPHONE_STATE = 'MEDIA_MICROPHONE_STATE';
export class MediaMicrophoneStateAction implements MediaAction {
    readonly type = MEDIA_MICROPHONE_STATE;
    constructor(public payload: boolean) {}
}
