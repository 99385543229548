import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { BrowserService } from './browser.service';

@Injectable()
export class ChromeExtensionService {

    public chromeExtensionInstationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private browserService: BrowserService) {
    }

    public installChromeExtension(): void {
        if (this.browserService.isChrome()) {
            const chromeWindow = (<any>window).chrome;
            const chromeExtensionString = 'https://chrome.google.com/webstore/detail/' + environment.chromeExtensionId;
            this.chromeExtensionInstationStatus$.next(false);
            if (typeof chromeWindow.webstore !== 'undefined' ) {
                (<any>window).chrome.webstore.install(chromeExtensionString
                    , (e) => {
                        this.chromeExtensionInstationStatus$.next(true);
                        console.log('success', e);
                    }, (e) => {
                        console.log('failure', e);
                    });
            }
        }
    }

    public isChromeExtensionInstalled(): Observable<boolean> {
        if (this.browserService.isChrome()) {
            const chromeWindow = (<any>window).chrome;
            this.chromeExtensionInstationStatus$.next(false);
            if (typeof chromeWindow.runtime !== 'undefined' ) {
                chromeWindow.runtime.sendMessage(environment.chromeExtensionId, {type: 'isInstalled'},
                    (response) => {
                        if (response) {
 this.chromeExtensionInstationStatus$.next(true);
}
                    });
            }
        }
        return this.chromeExtensionInstationStatus$;
    }

    public queryChromeExtensionForScreenId(): Observable<string> {
        if (this.browserService.isChrome()) {
            return Observable.create(observer => {
                (<any>window).chrome.runtime.sendMessage(environment.chromeExtensionId, {type: 'getSourceId'},
                    (response) => {
                        if (response) {
                            if (response.error) {
                                observer.error(new Error(response.error));
                            }
                            observer.next(response.sourceId);
                        } else {
                            observer.error(new Error('No Response from Extension' + response));
                        }
                    });
            });
        }
    }

}
