
import {filter,  map } from 'rxjs/operators';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, MediaStreamElement } from '../../store/media.state';
import { AuthPermissionService } from '../../../auth/auth-permission.service';
import { PatientusRole } from '../../../auth/auth.roles';

@Component({
    selector: 'app-main-video',
    templateUrl: './main-video.component.html',
    styleUrls: ['./main-video.component.scss']
})
export class MainVideoComponent implements OnInit {

    localScreenStreamActive$: Observable<MediaStreamElement>;
    remoteScreenStreamActive$: Observable<MediaStreamElement>;
    remoteStream$: Observable<MediaStreamElement>;
    isDoctor$: Observable<boolean>;

    mainVideoFullScreen$: Observable<boolean>;

    constructor(private elRef: ElementRef, private store: Store<AppState>, private permission: AuthPermissionService) {
        this.remoteStream$ = store.select(state => state.media.remoteStream).pipe(filter(e => !!e));
        this.localScreenStreamActive$ = this.store.select((state: AppState) => state.media.localScreenStream).pipe(filter(e => !!e));
        this.remoteScreenStreamActive$ = this.store.select((state: AppState) => state.media.remoteScreenStream).pipe(filter(e => !!e));
        this.mainVideoFullScreen$ = this.store.select(state => state.media.mainVideoFullsized );
    }

    ngOnInit(): void {
        this.isDoctor$ = this.permission.getCurrentRole().pipe(map( (r) => r === PatientusRole.DOCTOR));
    }


}
