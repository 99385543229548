import { Action } from '@ngrx/store';
import { StepType } from '../rtc-detection.entities';

export const RTC_DETECTION_START_CAM_ACTION = 'RTC_DETECTION_START_CAM_ACTION';
export const RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION = 'RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION';
export const RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION = 'RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION';
export const RTC_DETECTION_NEXT_STEP = 'RTC_DETECTION_NEXT_STEP';
export const RTC_DETECTION_MODAL_MANUALLY_CLOSED = 'RTC_DETECTION_MODAL_MANUALLY_CLOSED';
export const RTC_DETECTION_FINISHED = 'RTC_DETECTION_FINISHED';
export const RTC_DETECTION_CLEANUP = 'RTC_DETECTION_CLEANUP';

export interface RtcDetectionAction extends Action {
    type: string;
    payload?: { next?: StepType };
}

export class RtcDetectionStartCamAction implements RtcDetectionAction {
    type = RTC_DETECTION_START_CAM_ACTION;
}

export class RtcDetectionNextStepAction implements RtcDetectionAction {
    type = RTC_DETECTION_NEXT_STEP;
    payload = {next: null};

    constructor(next: StepType) {
        this.payload.next = next;
    }
}

export class RtcDetectionReceiveCamStreamAction implements RtcDetectionAction {
    type = RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION;
}

export class RtcDetectionNoAccessAllowedToStreamAction implements RtcDetectionAction {
    type = RTC_DETECTION_NO_ACCESS_ALLOWED_TO_STREAM_ACTION;
}
export class RtcDetectionModalManuallyClosed implements RtcDetectionAction {
    type = RTC_DETECTION_MODAL_MANUALLY_CLOSED;
}


export class RtcDetectionFinishedAction implements RtcDetectionAction {
    type = RTC_DETECTION_FINISHED;
}


export class RtcDetectionCleanupAction implements RtcDetectionAction {
    type = RTC_DETECTION_CLEANUP;
}
