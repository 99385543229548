<div class='content-wrapper card move-up'>
    <div *ngIf="!!bankAccount && isEditing === false" class='card-content'>
        <div class='row'>
            <div class='col-md-4'>
                <span>{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.BANK-ACCOUNT' | translate }}</span>
            </div>
            <div class='col-md-8'>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.IBAN' | translate }}
                        :</span>
                    <span class="item-content">{{ bankAccount?.iban }}</span>
                </div>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.BIC' | translate }}
                        :</span>
                    <span class="item-content">{{ bankAccount?.bic }}</span>
                </div>
            </div>
        </div>
        <hr>
        <div class='row'>
            <div class='col-md-4'>
                <span>{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.OWNER-INFORMATION' | translate }}</span>
            </div>
            <div class='col-md-8'>
                <div class="item">
                    <span class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.NAME' | translate }}
                        :</span>
                    <span class="item-content">{{ bankAccount?.firstName }} {{ bankAccount?.lastName }}</span>
                </div>
                <div class="item">
                    <span
                        class="item-header bold">{{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.ADDRESS-LINE' | translate }}
                        :</span>
                    <span class="item-content" *ngIf="bankAccount">
                        {{ getWholeAddressLine() }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!bankAccount || isEditing">
        <form [formGroup]="bankAccountForm" role="form">
            <div class='card-content'>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="iban">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.IBAN' | translate }}
                        </label>
                        <input type="text"
                               formControlName="iban"
                               class="form-rounded form-control"
                               id="iban"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.IBAN' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.IBAN' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="bic">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.BIC' | translate }}
                        </label>
                        <input type="text"
                               formControlName="bic"
                               class="form-rounded form-control"
                               id="bic"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.BIC' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.BIC' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                </div>
                <hr>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="firstName">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.FIRSTNAME' | translate }}
                        </label>
                        <input type="text"
                               formControlName="firstName"
                               class="form-rounded form-control"
                               id="firstName"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.FIRSTNAME' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.FIRSTNAME' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="lastName">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.SURNAME' | translate }}
                        </label>
                        <input type="text"
                               formControlName="lastName"
                               class="form-rounded form-control"
                               id="lastName"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.SURNAME' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.SURNAME' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="country">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.COUNTRY' | translate }}
                            <span class='fal fa-question-circle'
                                  aria-hidden="true"
                                  placement="bottom"
                                  [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TOOLTIPS.COUNTRY' | translate"></span>
                        </label>
                        <select formControlName="country"
                                id="country"
                                class="form-control input-select"
                                [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.COUNTRY' | translate"
                                placement="bottom"
                                triggers="manual"
                                [compareWith]="compareByCountry"
                                appInvalidDirective>
                            <option *ngFor="let country of countries"
                                    [ngValue]="country">{{country.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="city">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.CITY' | translate }}
                        </label>
                        <input type="text"
                               formControlName="city"
                               class="form-rounded form-control"
                               id="city"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.CITY' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.CITY' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="plz">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.POSTCODE' | translate }}
                        </label>
                        <input type="text"
                               formControlName="plz"
                               class="form-rounded form-control"
                               id="plz"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.POSTCODE' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.POSTCODE' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="addressLine">
                            <span class='required'>*</span>
                            {{ 'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.TITLES.ADDRESS' | translate }}
                        </label>
                        <input type="text"
                               formControlName="addressLine"
                               class="form-rounded form-control"
                               id="addressLine"
                               [placeholder]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.PLACEHOLDERS.ADDRESS' | translate"
                               [ngbTooltip]="'PAYMENT.PAYMENT-INFO.BANK-ACCOUNT.ERRORS.ADDRESS' | translate"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                    </div>
                </div>
            </div>
            <div class="card-footer clearfix">
                <div class="footer-left">
                    <span class='required'>*</span>{{ 'GENERAL.REQUIRED' | translate }}
                </div>
                <div class="footer-right">
                    <button id="cancelButton"
                            class="btn btn-link color-white"
                            [disabled]="isSubmitting === true"
                            (click)="cancelHandler()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                    <button type="submit"
                            id="submitButton"
                            class="btn btn-primary"
                            [disabled]="isSubmitting === true"
                            (click)="submit()">
                        {{ 'GENERAL.SUBMIT' | translate }}
                        <span class='fal fa-check-circle' aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
