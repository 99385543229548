import { AuthLogoutInterceptor } from './auth-logout.interceptor';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { loginReducer } from './store/login/login.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './store/login/login.effect';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import { LoginComponent } from './pages/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TanLoginComponent } from './pages/tan-login/tan-login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthStateProvider } from './auth-state.provider';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ExternalLoginModule } from './external-login-module/external-login.module';
import { AuthGuardExternal } from './auth-guard-external.service';
import { AuthRefreshJWTInterceptor } from './auth-refresh-jwt.interceptor';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UtilsModule } from '../utils/utils.module';
import { BrowserInfoComponent } from './components/browser-info/browser-info.component';
import { BrandingModule } from '../branding/branding.module';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { DoctorQuestionsComponent } from './components/doctor-questions/doctor-questions.component';
import { PatientsQuestionsComponent } from './components/patients-questions/patients-questions.component';
import { ToggleSideBarComponent } from './components/toggle-side-bar/toggle-side-bar.component';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { AuthPermissionService } from './auth-permission.service';
import { InstitutionAuthGuard } from './institution-auth-guard.service';
import { BrandingService } from '../branding/providers/branding.service';
import { JitsiModule } from '../jitsi/jitsi.module';

const authRoutes: Routes = [
    {path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
];

export function loginStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: [{login: ['jwt', 'loggedIn']}], rehydrate: true})(reducer);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(authRoutes),
        TranslateModule.forChild(),
        StoreModule.forFeature('login', loginReducer),
        EffectsModule.forFeature([LoginEffects]),
        ExternalLoginModule,
        NgxPermissionsModule,
        UtilsModule,
        BrandingModule,
        HeaderModule,
        FooterModule,
        JitsiModule
    ],
    declarations: [
        LoginComponent,
        TanLoginComponent,
        LogoutComponent,
        BrowserInfoComponent,
        LoginLayoutComponent,
        DoctorQuestionsComponent,
        PatientsQuestionsComponent,
        ToggleSideBarComponent
    ],
    providers: [
        AuthService,
        AuthGuard,
        InstitutionAuthGuard,
        AuthGuardExternal,
        AuthPermissionService,
        AuthStateProvider,
        BrandingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthLogoutInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthRefreshJWTInterceptor,
            multi: true,
        }
    ],
    exports: [
        RouterModule,
        TanLoginComponent,
        LoginComponent
    ]
})
export class AuthModule {
}
