import { Injectable } from '@angular/core';
import { AppState } from '../../store/webrtc.state';
import { Store } from '@ngrx/store';
import { Conference } from '../../../conference/store/conference.entity';
import { cfgAok } from './configurations';
import { WebrtcUpdateConnectionConfigurationAction } from '../../store/webrtc.action';
import { CFG_USERS } from '../../../jitsi/configurations/cfg-users.config';
import { TurnConfigService } from '../../../../providers/turn-config/turn-config.service';

@Injectable()
export class ConnectionConfigurationService {
    public cfgUsers = CFG_USERS;

    constructor(private store: Store<AppState>,
                private turnConfig: TurnConfigService) {
    }

    public updateConnectionConfigurationByConference(conference: Conference) {
        const ids = this.getUserIdsFromConference(conference);
        const config = this.getConfigurationForProfileIds(ids);

        this.store.dispatch(new WebrtcUpdateConnectionConfigurationAction(config));
    }

    getConfigurationForProfileIds(ids: number[]): RTCConfiguration {
        // CFG Users
        if (this.haveAtLeastOneInCommon(this.cfgUsers, ids)) {
            return <RTCConfiguration>cfgAok;
        }

        // Default Config
        return <RTCConfiguration>this.turnConfig.defaultTurnServers;
    }

    getUserIdsFromConference(conference: Conference): number[] {
        const ids: number[] = [];
        ids.push(conference.myToken.profileId);
        const participants: number[] = Object.keys(conference.userTokens).map(token => Number(token));
        participants.forEach(p => ids.push(p));
        return ids;
    }

    haveAtLeastOneInCommon(a: number[], b: number[]): boolean {
        return a.reduce((prev, curr) => (prev || b.indexOf(curr) > -1), false);
    }
}
