import { RegisterAddPersonalDetailsAction } from '../store/register.action';
import { AppState, PersonalDetails, RegisterPersonalState } from '../store/register.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { Countries, Country } from './countries';
import { Genders } from './genders';
import { ScrollService } from '../providers/scroll.service';
import { AnalyticsService } from '../../../providers/analytics/analytics.service';
import { StepPersonalFormBuilder } from './step-personal-form.builder';
import { SpecialityService } from '../../../entities/speciality/provider/speciality.service';
import { QualificationDropDownSettings } from '../../utils/qualifications';
import { NextStepFrom } from '../navigation/navigation.types';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-step-personal',
    templateUrl: './step-personal.component.html',
    styleUrls: ['./step-personal.component.scss']
})
export class StepPersonalComponent implements OnInit, AfterViewChecked {

    personalDetailsForm: FormGroup;

    public countries: Country[] = [];
    public genders: any = [];
    public qualifications = [];
    public qualificationDropDownSettings: any;

    constructor(private store: Store<AppState>,
                private scroll: ScrollService,
                private analytics: AnalyticsService,
                private specialityService: SpecialityService,
                private cdRef: ChangeDetectorRef,
                formBuilder: FormBuilder) {
        this.personalDetailsForm = StepPersonalFormBuilder.build(formBuilder);
        this.personalDetailsForm.valueChanges.subscribe(this.dispatch.bind(this));
    }

    ngOnInit() {
        this.countries = Countries;
        this.genders = Genders;
        this.specialityService.getSpecialties().pipe(take(1)).subscribe(sp => this.qualifications = sp);

        this.qualificationDropDownSettings = {...QualificationDropDownSettings};
    }

    // TODO Remove when angular2-multiselect-dropdown fixes the bug in the new release
    // https://github.com/CuppaLabs/angular2-multiselect-dropdown/issues/544
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    onSubmit() {
        if (this.personalDetailsForm.valid) {
            this.scroll.scrollToStep(NextStepFrom.PERSONAL);
            this.analytics.trackNewPageView('/enterPaymentData.html', 'Enter Payment Data');
        } else {
            Object.keys(this.personalDetailsForm.controls).forEach(key => {
                this.personalDetailsForm.get(key).markAsTouched();
            });
            this.analytics.trackWrongFormInputEvent(this.personalDetailsForm);
        }
    }

    dispatch(formValues) {
        if (formValues.gender === '' || formValues.gender === null || formValues.gender === undefined) {
            formValues.gender = null;
        }
        const personalDetails: PersonalDetails = {
            firstName: formValues.firstName,
            surname: formValues.surname,
            title: formValues.title,
            gender: formValues.gender,
            subjects: formValues.subjects,
            practiceName: formValues.practiceName,
            street: formValues.street,
            houseNumber: formValues.houseNumber,
            additionalAddress: formValues.additionalAddress,
            zipCode: formValues.zipCode,
            location: formValues.location,
            country: formValues.country,
            telephone: formValues.telephone,
        };
        const state = new RegisterPersonalState(this.personalDetailsForm.valid, personalDetails);
        this.store.dispatch(new RegisterAddPersonalDetailsAction(state));
        return formValues;
    }

    compareByCountry(item1, item2) {
        return item1.name === item2.name;
    }

    isInvalidAndTouched(name): boolean {
        const input = this.personalDetailsForm.get(name);
        if (input) {
            return input.hasError('required') && input.touched;
        }
        return false;
    }
}
