import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export interface CountryType {
    id: number;
    alpha_2?: string;
    name: string;
}

export const COUNTRY_GERMANY = {id: 83, name: 'Germany'};

@Injectable()
export class CountryService {
    private countryEndpoint = environment.paymentGetAllCountriesEndpoint;

    constructor(private http: HttpClient) {
    }

    public getCountries(): Observable<CountryType[]> {
        return this.http
            .get<CountryType[]>(this.countryEndpoint, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError) // then handle the error
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in PaymentInfoService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
