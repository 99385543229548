export const cfgAok = {
    iceServers: [
        {
            url: 'turn:turn.gkvi.de',
            urls: 'turn:turn.gkvi.de',
            username: 'patientus',
            credential: 'patientus123'
        },
        {
            url: 'turns:turn.gkvi.de',
            urls: 'turns:turn.gkvi.de',
            username: 'patientus',
            credential: 'patientus123'
        }
    ]
};
