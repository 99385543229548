import { Action } from '@ngrx/store';

export const JITSI_DEVICE_DETECTION_FINISHED = 'JITSI_DEVICE_DETECTION_FINISHED';
export const JITSI_OPEN = 'JITSI_OPEN';
export const JITSI_CLOSE = 'JITSI_CLOSE';
export const JITSI_CLEAN = 'JITSI_CLEAN';

export interface JitsiAction extends Action {
    type: string;
}

export class JitsiDeviceDetectionFinishedAction implements JitsiAction {
    type = JITSI_DEVICE_DETECTION_FINISHED;
}

export class JitsiOpenAction implements JitsiAction {
    type = JITSI_OPEN;
}

export class JitsiCloseAction implements JitsiAction {
    type = JITSI_CLOSE;
}

export class JitsiCleanAction implements JitsiAction {
    type = JITSI_CLEAN;
}
