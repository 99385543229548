import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export class AppointmentRequestFormBuilder {
    public static buildAcceptOrReject(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            acceptOrDecline: ['', Validators.compose([Validators.required])]
        });
    }

    public static buildAcceptingForm(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            confirmation_comment: ['', Validators.compose([Validators.required])]
        });
    }

    public static buildRejectingForm(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            confirmation_comment: ['', Validators.compose([Validators.required])]
        });
    }
}
