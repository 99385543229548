import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile } from '../../../../profile/store/profile.entity';
import { ProfileService } from '../../../../profile/providers/profile.service';

@Component({
    selector: 'app-account-tab',
    templateUrl: './account-tab.component.html',
    styleUrls: ['./account-tab.component.scss', '../../../tabs.scss']
})
export class AccountTabComponent {

    profileData$: Observable<Profile>;

    constructor(private profileService: ProfileService) {
        this.profileData$ = this.profileService.getCurrentProfileDataObserver();
    }
}
