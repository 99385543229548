<div class="countdown-page-overlay" *ngIf="(isCountdownRunning$ | async)">
    <div class="center-wrapper">
        <span class="starting-information">{{ 'COUNTDOWN.STARTIN-IN' | translate }}</span>
        <div class="countdown-wrapper">
            <div class="spinner">
                <div class="spinner-ring">
                    <div></div>
                    <div></div>
                </div>
                <span class="countdown-value numbers-bold">{{ (countdownValue$ | async)}}</span>
            </div>
        </div>
        <span class="starting-information start-information-timeframe">{{ 'COUNTDOWN.TIME-UNIT' | translate }}</span>
        <span class="starting-information start-information-timeframe" *ngIf="(countdownValue$ | async) === 0">{{ 'COUNTDOWN.WAIT' | translate }}</span>
    </div>
</div>
