import { filter, map } from 'rxjs/operators';
// FIXME: PAT-2320
import {
    COMMON_ROUTES,
    RestrictedFeatureRouteMap
} from '../../entities/institution/institution.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '../profile/providers/profile.service';
import { RouterHelperService } from '../utils/router-helper/router-helper.service';
import { Profile, ProfileType } from '../profile/store/profile.entity';
import { APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE, WAITINGROOM_MULTIUSER_ROUTE } from '../../routes';

@Injectable()
export class InstitutionAuthGuard implements CanActivate {
    private institution: string;

    constructor(private profileService: ProfileService,
                private router: RouterHelperService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        return this.profileService.getCurrentProfileDataObserver().pipe(filter(profile => !!profile), map((profile: Profile) => {
                this.institution = profile.institution;
                let isAllow = false;
                let routes: string[];

                const isRestrictedInstitute = RestrictedFeatureRouteMap.hasOwnProperty(this.institution);
                /*
                    if institution is not a restricted one it will get COMMON_ROUTES
                */
                if (isRestrictedInstitute === true) {
                    isAllow = RestrictedFeatureRouteMap[this.institution].includes(state.url);
                    if (isAllow === true) {
                        return true;
                    }
                    routes = RestrictedFeatureRouteMap[this.institution];
                } else {
                    routes = COMMON_ROUTES;
                }

                /*
                    Here checks requested route coming from a parent route
                    /payment => parent, /payment/account => is a child route
                    /appointment => parent, /appointment/tan/successful => is a child route
                    If requested has a parent route that route will be allow to access
                */
                const getParentRoute = Object.values(routes).find(value => (new RegExp(value)).test(state.url));

                if (!!getParentRoute) {
                    return true;
                }

                const route = profile.type === ProfileType.DOCTOR ? APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE : WAITINGROOM_MULTIUSER_ROUTE;
                this.router.navigate([route]);

                return false;
            })
        );
    }
}

