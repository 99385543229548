import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';


@Injectable()
export class DatepickerTranslationService extends NgbDatepickerI18n {

    weekdays = [];
    months = [];

    constructor(private translate: TranslateService) {
        super();
        this.setDatepickerTranslations();
        this.translate.onLangChange.pipe(
            tap(() => this.setDatepickerTranslations())
        ).subscribe();
    }

    public getWeekdayLabel(weekday: number): string {
        return this.weekdays[weekday - 1];
    }

    public getMonthShortName(month: number): string {
        return this.months[month - 1];
    }

    public getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        // TODO: return the day labels
        return '';
    }

    private setDatepickerTranslations(): void {
        this.translate.get('UTIL.DATEPICKER.WEEKDAYS').pipe(
            take(1),
            tap((translation) => this.weekdays = translation)
        ).subscribe();
        this.translate.get('UTIL.DATEPICKER.MONTHS').pipe(
            take(1),
            tap((translation) => this.months = translation)
        ).subscribe();
    }
}
