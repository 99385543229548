import { filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    CONFERENCE_LIST_UPDATE_SUCCESS,
    ConferenceActivateAction,
    ConferenceListUpdateSuccessAction
} from '../../conference/store/conference.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Conference } from '../../conference/store/conference.entity';
import {
    WAITINGROOM_CLOSE_VIDEO_CONFERENCE,
    WAITINGROOM_IS_ONLINE,
    WAITINGROOM_START_VIDEO_CONFERENCE,
    WAITINGROOM_SWITCH_CONFERENCE,
    WaitingroomAction,
    WaitingroomCloseVideoConference,
    WaitingroomIsOnlineAction,
    WaitingroomRtcDetectionModalManuallyClosedAction,
    waitingRoomStartMUCVideoConference,
    WaitingroomStartVideoConferenceRemotely,
} from './waitingroom.action';
import { COUNTDOWN_REQUEST_INIT } from '../../countdown/store/countdown.action';
import { RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION } from '../../rtc-detection/store/rtc-detection.action';
import { CommunicationService } from '../../appointment/providers/communication.service';
import { WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS } from '../../webrtc/store/webrtc.action';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';
import { JitsiConferenceService } from '../../jitsi/provider/jitsi-conference.service';
import { AppointmentService } from '../../appointment/providers/appointment.service';
import { Appointment } from '../../appointment/store/one-time-appointment/appointment.entity';
import { TokenService } from '../../conference-v2/providers/token.service';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';
import { UserAgentService } from '../../user-agent/providers/user-agent/user-agent.service';
import { ConferenceService } from '../../conference-v2/providers/conference.service';
import { FileTransferService } from '../../file-transfer/provider/file-transfer.service';
import { BrandingService } from '../../branding/providers/branding.service';
import { BrandingState } from '../../branding/store/branding.state';
import { Institution } from '../../../entities/institution/institution.model';
import { Callee, SingleConferenceToken } from '../../conference-v2/store/conference.model';
import { Profile } from '../../profile/store/profile.entity';
import { ProfileService } from '../../profile/providers/profile.service';
import { MultiUserAppointment } from '../../appointment/store/appointment/multi-user-appointment.entity';
import { MultiUserAppointmentService } from '../../appointment/providers/multi-user-appointment.service';

export interface InstitutionSettings {
    name: Institution | null;
    brandLogoPath: string | null;
}

export interface StartConferencePayload {
    conferenceId: number;
    token: string;
    peerProfileId: number;
    peerBrowserName: string;
    inst: InstitutionSettings;
}

@Injectable()
export class WaitingroomEffects {
    constructor(private actions$: Actions,
                private communicationService: CommunicationService,
                private jitsiConferenceService: JitsiConferenceService,
                private tokenService: TokenService,
                private appointmentService: AppointmentService,
                private multiUserAppointmentService: MultiUserAppointmentService,
                private conferenceService: ConferenceService,
                private profileSelectService: ProfileSelectService,
                private profileService: ProfileService,
                private userAgentService: UserAgentService,
                private fileTransferService: FileTransferService,
                private tracer: LogAppointmentService,
                private brandingService: BrandingService) {
    }

     goOnline$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CONFERENCE_LIST_UPDATE_SUCCESS),
        map((action: ConferenceListUpdateSuccessAction) => action.payload),
        mergeMap((conferences: Conference[]) => this.appointmentService.getOnlyUpcomingAppointmentConferences(conferences)),
        map((conferences: Conference[]) => this.communicationService.updateTokens(conferences)),
        map(conference => new WaitingroomIsOnlineAction(conference))));

     activateConference$: Observable<Action> = createEffect(() => this.actions$.pipe(
        filter((action) => action.type === WAITINGROOM_IS_ONLINE || action.type === WAITINGROOM_SWITCH_CONFERENCE),
        filter((action: WaitingroomAction) => !!action.payload),
        map((action: WaitingroomAction) => new ConferenceActivateAction(<Conference>action.payload))));

    startMUCVideoConferenceForDoctor$ = createEffect(() => this.actions$.pipe(
        ofType(waitingRoomStartMUCVideoConference),
        switchMap((action) =>  this.getConferenceId(action.payload)),
        tap((conferenceId: number) => this.conferenceService.activateConference(conferenceId)),
        mergeMap((conferenceId: number) => this.getSingleTokenMultiUserConferencePayload(conferenceId)),
        mergeMap((payload: SingleConferenceToken) => this.createMultiUserConferencePayload(payload)),
        mergeMap((payload: StartConferencePayload) => this.readPeerUserAgent(payload)),
        tap((payload: StartConferencePayload) => {
            this.jitsiConferenceService.startConference(
                payload.conferenceId,
                payload.token,
                payload.inst,
                payload.peerBrowserName)
            this.tracer.logAppointmentStarted();
            this.fileTransferService.start(payload.peerProfileId, this.profileSelectService.getCurrentProfile());
        }),
        mapTo(new WaitingroomStartVideoConferenceRemotely())
        )
    );

     closeVideoConference$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CLOSE_MAIN_CONNECTION_SUCCESS),
        mapTo(new WaitingroomCloseVideoConference())));

     stopConference$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WAITINGROOM_CLOSE_VIDEO_CONFERENCE),
        tap(() => this.fileTransferService.closePeerConnection())), {dispatch: false});

     logStartVideoConferenceByDoctor$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WAITINGROOM_START_VIDEO_CONFERENCE),
        tap(() => this.tracer.logAppointmentStarted())), {dispatch: false});

     closeModal: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(RTC_DETECTION_RECEIVE_CAM_STREAM_ACTION),
        mapTo(new WaitingroomRtcDetectionModalManuallyClosedAction())));

    private getConferenceId(conference: Conference): Observable<number> {
        if (!conference.hasOwnProperty('id')) {
            return this.getConferenceIdFromPatient();
        }

        return of(conference.id);
    }

    private getConferenceIdFromPatient(): Observable<number> {
        return this.appointmentService.getAllAppointmentsObserver().pipe(
            take(1),
            map((appointments: Appointment[]) => appointments[0].conferenceId)
        );
    }

    private getSingleTokenConferencePayload(conferenceId: number): Observable<SingleConferenceToken> {
        return this.appointmentService.getAppointmentByConferenceId(conferenceId).pipe(
            take(1),
            mergeMap((appointment: Appointment) => this.profileService.getProfilesList().pipe(
                map((profileMap: { [id: number]: Profile }) => {
                    const filtered: string[] = Object.keys(profileMap)
                        .filter(key => appointment.profileIds.includes(+key))
                        .filter(key => Number(key) !== this.profileSelectService.getCurrentProfileId());
                    return filtered.map(id => profileMap[id]);
                }),
            )),
            take(1),
            map((calleeProfile: Profile[]) => Object.keys(calleeProfile)
                .map((profileKey) => ({
                    id: calleeProfile[profileKey].id,
                    name: this.getNameDisplayedInOVS(calleeProfile[profileKey]),
                    email: calleeProfile[profileKey].email,
                }))),
            map((callees: Callee[]) => ({
                conferenceId,
                userName: this.getNameDisplayedInOVS(this.profileSelectService.getCurrentProfile()),
                callee: callees
            }))
        );
    }

    private getSingleTokenMultiUserConferencePayload(conferenceId: number): Observable<SingleConferenceToken> {
        return this.multiUserAppointmentService.getMultiUserAppointmentByConferenceId(conferenceId).pipe(
            take(1),
            mergeMap((appointment: MultiUserAppointment) => this.profileService.getProfilesList().pipe(
                map((profileMap: { [id: number]: Profile }) => {
                    const filtered: string[] = Object.keys(profileMap)
                        .filter(key => appointment.profileIds.includes(+key))
                        .filter(key => Number(key) !== this.profileSelectService.getCurrentProfileId());
                    return filtered.map(id => profileMap[id]);
                }),
            )),
            take(1),
            map((calleeProfile: Profile[]) => Object.keys(calleeProfile)
                .map((profileKey) => ({
                    id: calleeProfile[profileKey].id,
                    name: this.getNameDisplayedInOVS(calleeProfile[profileKey]),
                    email: calleeProfile[profileKey].email,
                }))),
            map((callees: Callee[]) => ({
                conferenceId,
                userName: this.getNameDisplayedInOVS(this.profileSelectService.getCurrentProfile()),
                callee: callees
            }))
        );
    }

    private createConferencePayload(payload: SingleConferenceToken): Observable<StartConferencePayload> {
        return this.appointmentService.getAppointmentByConferenceId(payload.conferenceId).pipe(
            take(1),
            mergeMap((appointment: Appointment) => this.tokenService.getSingleConferenceToken(payload).pipe(
                switchMap((token: string) => this.brandingService.getBrandingSettingsFromStore().pipe(
                    take(1),
                    map((brandingState: BrandingState) => {
                        const logoUrl = brandingState.ovsLogoUrl || brandingState.logoUrl;
                        return {
                            token,
                            conferenceId: payload.conferenceId,
                            peerProfileId: this.getPeerProfileId(appointment.profileIds),
                            peerBrowserName: '',
                            inst: {
                                name: this.profileSelectService.getCurrentProfile() ?
                                    this.profileSelectService.getCurrentProfile().institution : null,
                                brandLogoPath: logoUrl ?
                                    window.location.origin + logoUrl : window.location.origin + '/assets/img/general/patientus_logo.png'
                            }
                        }
                    })
                )),
            ))
        );
    }

    private createMultiUserConferencePayload(payload: SingleConferenceToken): Observable<StartConferencePayload> {
        return this.multiUserAppointmentService.getMultiUserAppointmentByConferenceId(payload.conferenceId).pipe(
            take(1),
            mergeMap((appointment: MultiUserAppointment) => this.tokenService.getSingleConferenceToken(payload).pipe(
                switchMap((token: string) => this.brandingService.getBrandingSettingsFromStore().pipe(
                    take(1),
                    map((brandingState: BrandingState) => {
                        const logoUrl = brandingState.ovsLogoUrl || brandingState.logoUrl;
                        return {
                            token,
                            conferenceId: payload.conferenceId,
                            peerProfileId: this.getPeerProfileId(appointment.profileIds),
                            peerBrowserName: '',
                            inst: {
                                name: this.profileSelectService.getCurrentProfile() ?
                                    this.profileSelectService.getCurrentProfile().institution : null,
                                brandLogoPath: logoUrl ?
                                    window.location.origin + logoUrl : window.location.origin + '/assets/img/general/patientus_logo.png'
                            }
                        }
                    })
                )),
            ))
        );
    }

    private getPeerProfileId(profileIds: number[]): number {
        const currentProfileId = this.profileSelectService.getCurrentProfileId();
        return profileIds
            .filter((id: number) => currentProfileId !== id)
            .reduce((previousValue, currentValue) => currentValue, 0);
    }

    private readPeerUserAgent(payload: StartConferencePayload): Observable<StartConferencePayload> {
        return this.userAgentService.getBrowserNameByProfileId(payload.peerProfileId).pipe(
            take(1),
            map(peerBrowserName => ({...payload, peerBrowserName}))
        );
    }

    private getNameDisplayedInOVS(profile: Profile): string {
        return profile.firstName + ' ' + profile.lastName;
    }
}
