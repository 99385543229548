import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentAccountTabComponent } from './components/payment-account-tab/payment-account-tab.component';
import { TranslateModule } from '@ngx-translate/core';
import { UtilsModule } from '../../utils/utils.module';
import { DisposedTableComponent } from './components/disposed-table/disposed-table.component';
import { OutstandingTableComponent } from './components/outstanding-table/outstanding-table.component';
import { CanceledTableComponent } from './components/canceled-table/canceled-table.component';
import { PaidTableComponent } from './components/paid-table/paid-table.component';
import { RouterModule } from '@angular/router';
import { PayoutInfoComponent } from './components/payout-info/payout-info.component';
import { PaymentAccountModule as PaymentAccountModuleEntityModule } from '../../../entities/payment-account/payment-account.module';

export const ROUTE = '/account';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        UtilsModule,
        PaymentAccountModuleEntityModule,
        RouterModule
    ],
    declarations: [
        PaymentAccountTabComponent,
        DisposedTableComponent,
        OutstandingTableComponent,
        CanceledTableComponent,
        PaidTableComponent,
        PayoutInfoComponent
    ],
    exports: [
        PaymentAccountTabComponent,
        DisposedTableComponent,
        OutstandingTableComponent,
        CanceledTableComponent,
        PaidTableComponent
    ]
})
export class PaymentAccountModule {
}
