import { animate, style, transition, trigger } from '@angular/animations';

export const fastFadeInOut = trigger('fastFadeInOut', [
        transition(':enter', [
            style({opacity: 0}),
            animate(300, style({opacity: 1}))
        ]),
        transition(':leave', [
            animate(1000, style({opacity: 0}))
        ])
    ]
);
