import { filter } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChromeExtensionService } from '../../provider/chrome-extension.service';
import { MediaStreamProvider } from '../../provider/media-stream.provider';
import {
    MediaStartClosingLocalScreenStreamAction,
    MediaStartRequestingLocalScreenStreamAction
} from '../../store/media.action';
import { AppState } from '../../store/media.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { BrowserService } from '../../provider/browser.service';

@Component({
    selector: 'app-big-control-bar',
    templateUrl: './big-control-bar.component.html',
    styleUrls: ['./big-control-bar.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    style({opacity: 0}),
                    animate('200ms 400ms', style({opacity: 1}))
                ]),
                transition(':leave', [
                    style({opacity: 1}),
                    animate('200ms', style({opacity: 0}))
                ])
            ]
        )
    ],
})

export class BigControlBarComponent implements OnDestroy {

    isScreenShareButtonHovered = false;
    isNoticeButtonOnHover = false;
    isFileTransferButtonHovered = false;
    isLocalScreenShareActive$: Observable<boolean>;
    isRemoteScreenShareActive$: Observable<boolean>;
    changeToRemoteSubscription: Subscription = null;

    constructor(private chromeService: ChromeExtensionService,
                private mediaStreamProvider: MediaStreamProvider,
                private store: Store<AppState>,
                private translate: TranslateService,
                public browser: BrowserService) {
        this.isLocalScreenShareActive$ = this.store.select(s => s.media.localScreenStream.active);
        this.isRemoteScreenShareActive$ = this.store.select(s => s.media.remoteScreenStream.active);
        this.changeToRemoteSubscription = this.isRemoteScreenShareActive$.pipe(filter((a) => a === true)).subscribe(() => {
            this.isScreenShareButtonHovered = false;
            this.isNoticeButtonOnHover = false;
            this.isFileTransferButtonHovered = false;
        });
    }

    public startScreenShare() {
        this.store.dispatch(new MediaStartRequestingLocalScreenStreamAction());
        this.changeScreenShareOverlayVisibility(false);
    }

    public closeScreenShare() {
        this.store.dispatch(new MediaStartClosingLocalScreenStreamAction());
    }

    public changeScreenShareOverlayVisibility(visibility: boolean) {
        this.isScreenShareButtonHovered = visibility;
    }

    public changeNoticeOverlayVisibility(visibility: boolean) {
        this.isNoticeButtonOnHover = visibility;
    }

    public changeFileTransferOverlayVisibility(visibility: boolean) {
        this.isFileTransferButtonHovered = visibility;
    }

    ngOnDestroy(): void {
        this.isScreenShareButtonHovered = false;
        this.isNoticeButtonOnHover = false;
        this.isFileTransferButtonHovered = false;

        if (this.changeToRemoteSubscription !== null) {
            this.changeToRemoteSubscription.unsubscribe();
        }
    }
}
