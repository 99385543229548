import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity/src/models';
import { PaymentInfo } from './payment-info.model';

export enum PaymentInfoActionTypes {
    LoadPaymentInfos = '[PaymentInfo] Load PaymentInfos',
    AddPaymentInfo = '[PaymentInfo] Add PaymentInfo',
    AddPaymentInfos = '[PaymentInfo] Add PaymentInfos',
    UpdatePaymentInfo = '[PaymentInfo] Update PaymentInfo',
    UpdatePaymentInfos = '[PaymentInfo] Update PaymentInfos',
    DeletePaymentInfo = '[PaymentInfo] Delete PaymentInfo',
    DeletePaymentInfos = '[PaymentInfo] Delete PaymentInfos',
    ClearPaymentInfos = '[PaymentInfo] Clear PaymentInfos'
}

export class LoadPaymentInfos implements Action {
    readonly type = PaymentInfoActionTypes.LoadPaymentInfos;

    constructor(public payload: { paymentInfos: PaymentInfo[] }) {
    }
}

export class AddPaymentInfo implements Action {
    readonly type = PaymentInfoActionTypes.AddPaymentInfo;

    constructor(public payload: { paymentInfo: PaymentInfo }) {
    }
}

export class AddPaymentInfos implements Action {
    readonly type = PaymentInfoActionTypes.AddPaymentInfos;

    constructor(public payload: { paymentInfos: PaymentInfo[] }) {
    }
}

export class UpdatePaymentInfo implements Action {
    readonly type = PaymentInfoActionTypes.UpdatePaymentInfo;

    constructor(public payload: { paymentInfo: Update<PaymentInfo> }) {
    }
}

export class UpdatePaymentInfos implements Action {
    readonly type = PaymentInfoActionTypes.UpdatePaymentInfos;

    constructor(public payload: { paymentInfos: Update<PaymentInfo>[] }) {
    }
}

export class DeletePaymentInfo implements Action {
    readonly type = PaymentInfoActionTypes.DeletePaymentInfo;

    constructor(public payload: { id: string }) {
    }
}

export class DeletePaymentInfos implements Action {
    readonly type = PaymentInfoActionTypes.DeletePaymentInfos;

    constructor(public payload: { ids: string[] }) {
    }
}

export class ClearPaymentInfos implements Action {
    readonly type = PaymentInfoActionTypes.ClearPaymentInfos;
}

export type PaymentInfoActions =
    LoadPaymentInfos
    | AddPaymentInfo
    | AddPaymentInfos
    | UpdatePaymentInfo
    | UpdatePaymentInfos
    | DeletePaymentInfo
    | DeletePaymentInfos
    | ClearPaymentInfos;
