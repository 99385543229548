<div id="statistics-block"></div>
<div id="main-video-box" class="bg-black" [ngClass]="{'no-display' : !(remoteStream$ | async).active}">
    <ng-template [ngIf]="!((remoteScreenStreamActive$ | async).active) ">
        <app-remote-cam-video-tag [fullsized]="mainVideoFullScreen$ | async"></app-remote-cam-video-tag>
        <app-internet-icon [type]="'remote'" *ngIf="(isDoctor$ | async)"></app-internet-icon>
        <app-logo></app-logo>
    </ng-template>
    <ng-template [ngIf]="(remoteScreenStreamActive$ | async).active">
        <app-remote-screen-videotag [fullsized]="mainVideoFullScreen$ | async"></app-remote-screen-videotag>
    </ng-template>
    <app-big-control-bar></app-big-control-bar>
</div>
