import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PollingClientService } from './polling-client.service';

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        PollingClientService
    ]
})
export class HttpModule {
}
