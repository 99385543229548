import { Injectable } from '@angular/core';
import { StepHardwareTestComponent } from '../step-hardware-test/step-hardware-test.component';
import { RtcDetectionStepComponent } from '../rtc-detection-step-component.interface';
import { StepWarningComponent } from '../step-warning/step-warning.component';
import { RtcDetectionStep, StepType } from '../rtc-detection.entities';
import { StepHardwareVerifyComponent } from '../step-hardware-verify/step-hardware-verify.component';
import { StepSuccessComponent } from '../step-success/step-success.component';
import { StepChromeExtensionComponent } from '../step-chrom-extension/step-chrome-extension.component';
import { StepNoCameraAccessComponent } from '../step-no-camera-access/step-no-camera-access.component';
import { StepBrowserCheckComponent } from '../step-browser-check/step-browser-check.component';

@Injectable()
export class NextStepService {
    public getSteps(): RtcDetectionStep<RtcDetectionStepComponent>[] {
        return [
            new RtcDetectionStep(StepHardwareTestComponent)
        ];
    }

    public getStepByType(type: StepType): RtcDetectionStep<RtcDetectionStepComponent> {
        switch (type) {
            case StepType.CAM_TEST: {
                return new RtcDetectionStep(StepHardwareTestComponent);
            }
            case StepType.WARNING: {
                return new RtcDetectionStep(StepWarningComponent);
            }
            case StepType.CAM_VERIFY: {
                return new RtcDetectionStep(StepHardwareVerifyComponent);
            }
            case StepType.CHROME_EXTENSION: {
                return new RtcDetectionStep(StepChromeExtensionComponent);
            }
            case StepType.SUCCESS: {
                return new RtcDetectionStep(StepSuccessComponent);
            }
            case StepType.NO_CAMERA_ACCESS: {
                return new RtcDetectionStep(StepNoCameraAccessComponent);
            }
            case StepType.BROWSER_CHECK: {
                return new RtcDetectionStep(StepBrowserCheckComponent);
            }
            default: {
                return null;
            }
        }
    }
}
