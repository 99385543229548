import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppState } from '../store/countdown.state';
import { COUNTDOWN_SYNC_MESSAGE_TYPE, CountdownSyncMessage } from '../models';
import { CountdownRequestInitAction, CountdownStopAction, } from '../store/countdown.action';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { XmppMessageService } from '../../user-presence/provider/xmpp-message.service';

@Injectable()
export class CountdownSyncService implements OnDestroy {
    private readonly subscription: Subscription;

    constructor(private xmppMessageService: XmppMessageService,
                private store: Store<AppState>) {
        this.subscription = this.bindEvents().subscribe();
    }

    public sendCountdownSyncMessage(isStarted: boolean, calleeId: number, running: boolean, participantId) {
        const message: CountdownSyncMessage = this.getCountdownSyncMessage(isStarted, running, participantId);
        this.xmppMessageService.send(message, calleeId);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private bindEvents(): Observable<CountdownSyncMessage> {
        return this.xmppMessageService.listen(COUNTDOWN_SYNC_MESSAGE_TYPE)
            .pipe(
                tap((data: CountdownSyncMessage) => this.handleCountdownSyncMessage(data))
            );
    }

    private handleCountdownSyncMessage(message: CountdownSyncMessage): void {
        if (message.isStarted) {
            this.store.dispatch(new CountdownRequestInitAction(message.participantId));
        }

        if (!message.isStarted && !message.running) {
            this.store.dispatch(new CountdownStopAction());
        }
    }

    private getCountdownSyncMessage(isStarted: boolean, running: boolean, participantId: number): CountdownSyncMessage {
        return new CountdownSyncMessage(isStarted, running, participantId);
    }
}
