import { Action } from '@ngrx/store';
import {
    JITSI_CLEAN,
    JITSI_CLOSE,
    JITSI_DEVICE_DETECTION_FINISHED,
    JITSI_OPEN
} from './jitsi.action';
import { AppState as RootAppState } from '../../../providers/store/app.state';

export const jitsiFeatureKey = 'jitsi';

export interface JitsiState {
    isFinished: boolean;
    isOpen: boolean;
}

export interface AppState extends RootAppState {
    jitsi: JitsiState;
}

export class InitialState implements JitsiState {
    isFinished = false;
    isOpen = false;
}

export function reducer(state = new InitialState(), action: Action): JitsiState {
    switch (action.type) {
        case JITSI_DEVICE_DETECTION_FINISHED: {
            return { ...state, isFinished: true};
        }
        case JITSI_OPEN: {
            return { ...state, isOpen: true};
        }
        case JITSI_CLOSE: {
            return { ...state, isOpen: false};
        }
        case JITSI_CLEAN: {
            return new InitialState();
        }
        default:
            return state;
    }
}
