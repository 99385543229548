<app-login-layout [questions]="'doctor'">
    <div class="page-wrapper" [ngStyle]="{backgroundImage: 'url(' + (backgroundImg$ | async).backgroundImage + ')'}">
        <div class="content-wrapper">
            <div class="option-card">
                <form class="form-signin"
                      [formGroup]="loginForm"
                      (ngSubmit)="onSubmit()"
                      role="form">
                    <div class="option-card-body">
                        <div class="option-card-header">
                            <h3 class="form-signin-heading option-card-heading">{{ 'LOGIN.TITLE' | translate }}
                                <span class="heading-note">{{ 'LOGIN.TITLE-NOTE' | translate }}</span>
                            </h3>
                            <span class="option-card-subtitle weight-300">{{ 'LOGIN.SUBTITLE' | translate }}</span>
                        </div>
                        <div class="flex">
                            <label for="email" class="bold">{{ 'LOGIN.EMAIL' | translate }}</label>
                            <input type="email"
                                   id="email"
                                   formControlName="email"
                                   class="form-control"
                                   placeholder="{{ 'LOGIN.EMAIL-PLACEHOLDER' | translate }}"
                                   autofocus>
                        </div>
                        <div class="flex">
                            <label for="password" class="bold">{{ 'LOGIN.PASS' | translate }}</label>
                            <input type="password"
                                   id="password"
                                   formControlName="password"
                                   class="form-control"
                                   placeholder="{{ 'LOGIN.PASSWORD-PLACEHOLDER' | translate }}">
                        </div>
                    </div>
                    <div *ngIf="loginForm.hasError('notValid')" class="error-notice">
                        <i class="fas fa-exclamation-triangle"></i> {{ 'LOGIN.ERROR' | translate }}
                    </div>
                    <div *ngIf="loginForm.hasError('rateLimitReached')" class="error-notice">
                        <i class="fas fa-exclamation-triangle"></i> {{ 'LOGIN.ERROR-RATE-LIMIT' | translate }}
                    </div>
                    <app-browser-info></app-browser-info>
                    <div class="option-card-footer flex-to-side">
                        <a routerLink="{{passwordRecoveryRoute}}" class="link-white">{{
                            'LOGIN.PASSWORD-RECOVERY' | translate }}
                        </a>
                        <button class="button dark-blue" type="submit">
                            {{ 'LOGIN.BUTTON' | translate }}
                            <i class="fal fa-check-circle"></i>
                        </button>
                    </div>
                </form>
            </div>
            <div class="further-links-section">
            <span>
                {{ 'LOGIN.YOU-ARE-PATIENT' | translate }}
                <a routerLink="{{patientLoginRoute}}"
                   class="link-dark">{{ 'LOGIN.PATIENT-LOGIN-LINK' | translate }}</a>
            </span>
            </div>
            <div class="data-security-seal" *ngIf="showDataSecuritySeal">
                <img class="img" src="/assets/img/general/siegel_ips.png" alt="data-security-seal">
            </div>
        </div>
    </div>
</app-login-layout>
