import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { TokenService } from '../../conference-v2/providers/token.service';
import { JitsiConferenceService } from '../provider/jitsi-conference.service';
import { COUNTDOWN_FINISHED, COUNTDOWN_REQUEST_INIT } from '../../countdown/store/countdown.action';
import {
    WAITINGROOM_CLOSE_VIDEO_CONFERENCE,
    WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY
} from '../../waitingroom/store/waitingroom.action';
import { JitsiRefreshTokenService } from '../provider/jitsi-refresh-token.service';
import { AuthPermissionService } from '../../auth/auth-permission.service';
import { JITSI_DEVICE_DETECTION_FINISHED } from './jitsi.action';
import { PatientusRole } from '../../auth/auth.roles';
import { JitsiStateService } from '../provider/jitsi-state.service';
import { UserPresenceService } from '../../user-presence/provider/user-presence.service';

@Injectable()
export class JitsiEffects {

    constructor(
        private actions$: Actions,
        private tokenService: TokenService,
        private jitsiConferenceService: JitsiConferenceService,
        private jitsiRefreshTokenService: JitsiRefreshTokenService,
        private authPermissionService: AuthPermissionService,
        private userPresenceService: UserPresenceService
    ) {
    }

    // @Effect({dispatch: false}) doConnect$: Observable<void> = this.actions$.pipe(
    //     ofType(APP_CHANGE_TO_AUTHENTICATED_STATE),
    //     mergeMap(() => this.tokenService.getConferenceOnlineToken()),
    //     mergeMap((token) => this.jitsiConnectionService.connect(token)),
    //     //map((connected: boolean) => this.xmppEventBusService.init())
    // );

     toggleAudioVideo$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(COUNTDOWN_FINISHED),
        tap(() => {
            this.jitsiConferenceService.toggleAudio();
            this.jitsiConferenceService.toggleVideo();
        })
    ), {dispatch: false});

     startRefreshToken$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY),
        tap(() => this.jitsiRefreshTokenService.startRefresh())
    ), {dispatch: false});

     stopRefreshToken$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WAITINGROOM_CLOSE_VIDEO_CONFERENCE),
        tap(() => this.jitsiRefreshTokenService.stopRefresh())
    ), {dispatch: false});

     setOnlineAfterDeviceTest$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(JITSI_DEVICE_DETECTION_FINISHED),
        mergeMap(() => this.authPermissionService.getCurrentRole()),
        filter((role) => role === PatientusRole.PATIENT),
        tap((role) => this.userPresenceService.setReady())
    ), {dispatch: false});
}
