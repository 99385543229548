import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { LanguageMapper } from './language-mapper';
import { StaticUtils } from '../static-utils';

export class DatepickerFormatParser extends NgbDateParserFormatter {

    constructor(private momentFormat: string, private translate: TranslateService) {
        super();
        this.setMomentLocale();
    }

    public format(date: NgbDateStruct): string {
        if (date === null) {
            return '';
        }
        const d = moment({
            year: date.year,
            month: date.month - 1,
            date: date.day
        });
        return d.isValid() ? d.format(this.momentFormat) : '';
    }

    public parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        const d = this.parseWithMoment(value);
        return d.isValid() ? {
            year: d.year(),
            month: d.month() + 1,
            day: d.date()
        } : null;
    }

    private parseWithMoment(value: string): moment.Moment {
        if (moment(value, this.momentFormat, true).isValid()) {
            return moment(value, this.momentFormat);
        }
        if (moment(value, 'D.M.YY', true).isValid()) {
            return moment(value, 'D.M.YY');
        }
        return moment(value, 'DD.MM.YYYY');
    }

    private setMomentLocale() {
        const localLang = LanguageMapper.mapTranslateToMomentLang(this.translate.getDefaultLang());
        moment.locale(localLang);
    }
}
