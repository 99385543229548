import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { APP_CHANGE_TO_ANONYMOUS_STATE, APP_CHANGE_TO_AUTHENTICATED_STATE } from '../../../providers/store/app.action';
import { filter, map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import {
    APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED,
    CreateTanAppointmentCompletedAction
} from '../../appointment/store/one-time-appointment/appointment.action';
import { CreateTanAppointmentDTO } from '../../appointment/providers/create-tan.types';
import { ClearUserPresences } from './user-presence.actions';
import { XmppStropheService } from '../provider/xmpp-strophe.service';
import { PatientusRole } from '../../auth/auth.roles';
import { AuthPermissionService } from '../../auth/auth-permission.service';
import { ProfileService } from '../../profile/providers/profile.service';
import { Profile } from '../../profile/store/profile.entity';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';

@Injectable()
export class UserPresenceEffects {

    constructor(
        private actions$: Actions,
        private profileService: ProfileSelectService,
        private xmppStrophe: XmppStropheService
    ) {
    }

     doConnect$: Observable<any> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_AUTHENTICATED_STATE),
        map(() => this.profileService.getCurrentProfile()),
        tap((profile: Profile) => this.xmppStrophe.connect(profile))
    ), {dispatch: false});

     doReset$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        tap(() => this.xmppStrophe.disconnect()),
        map(() => new ClearUserPresences())
    ));

    // @Effect({dispatch: false}) onNewTanAppointment$: Observable<any> = this.actions$.pipe(
    //     ofType(APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED),
    //     map((action: CreateTanAppointmentCompletedAction) => action.payload),
    //     tap((dto: CreateTanAppointmentDTO) => this.presenceService.initListenForUserPresence(dto.getProfileId()))
    // );
}
