import { filter } from 'rxjs/operators';
import { DataChannelProtocol, DataChannelProtocolService } from './data-channel-protocol';
import {
    FileTransferCancelledEvent,
    FileTransferChunkEvent,
    FileTransferCompleteEvent,
    FileTransferErrorEvent,
    FileTransferInitEvent,
    FileTransferStartEvent
} from './file-transfer-event.model';
import { AbstractFileTransmitter } from './file-transmitter';
import { FileUtil } from '../../../utils/file/file-util';
import { Subscription, BehaviorSubject } from 'rxjs';

export class FileSender extends AbstractFileTransmitter {

    private fileReader: FileReader;
    private messageSubscription: Subscription = null;

    constructor(protected id, private channel, messages: BehaviorSubject<ArrayBuffer>, private file: File) {
        super();
        this.transferEvents.add(new FileTransferInitEvent());
        this.messageSubscription = messages.pipe(filter(msg => msg !== null)).subscribe(msg => {
            const protocol = DataChannelProtocolService.readProtocol(msg);
            if (protocol.protocol === 'file' && protocol.id === id) {
                this.handleMessage(protocol, msg);
            }
        });
    }

    private handleMessage(protocol: DataChannelProtocol, msg: ArrayBuffer) {
        if (protocol.action === 'cancel') {
            this.cancel();
            this.transferEvents.add(new FileTransferCancelledEvent());
        }
    }

    doStart() {
        if (this.file.size === 0) {
            this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.FILE_IS_EMPTY));
            return;
        }

        if (!FileUtil.isSupportedFileType(this.file)) {
            this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.UNSUPPORTED_FILE_EXTENSION));
            return;
        }

        if (!FileUtil.matchesMimeTypeTheExtension(this.file)) {
            this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.WRONG_FILE_EXTENSION));
            return;
        }

        this.fileReader = new FileReader();
        const maxSize = this.file.size;
        let offset = 0;
        let chunkNumber = 1;

        const fileMetaData = DataChannelProtocolService.createFileMetaData(this.file);
        const start = DataChannelProtocolService.createStartFileProtocol(this.id, fileMetaData);

        this.channel.send(start);
        this.transferEvents.add(new FileTransferStartEvent(fileMetaData.chunkAmount, fileMetaData.file));

        this.fileData = fileMetaData.file;

        this.fileReader.addEventListener('error', this.handleError.bind(this));
        this.fileReader.addEventListener('abort', () => {
        });
        this.fileReader.addEventListener('load', (e: any) => {
            const chunk = DataChannelProtocolService.createSendFileProtocol(this.id, chunkNumber, e.target.result);
            this.channel.send(chunk);
            this.transferEvents.add(new FileTransferChunkEvent(chunkNumber, e.target.result.byteLength, null));
            chunkNumber++;
            offset += e.target.result.byteLength;
            if (offset < maxSize) {
                readSlice(offset);
            } else {
                const finish = DataChannelProtocolService.createCompleteFileProtocol(this.id);
                this.channel.send(finish);
                this.transferEvents.add(new FileTransferCompleteEvent(null));

                if (this.messageSubscription !== null) {
                    this.messageSubscription.unsubscribe();
                }
            }
        });

        const readSlice = o => {
            // console.log('readSlice ', o);
            const slice = this.file.slice(offset, o + DataChannelProtocolService.CHUNK_SIZE);
            this.fileReader.readAsArrayBuffer(slice);
        };
        readSlice(0);
    }

    public cancel() {
        this.fileReader.abort();
        this.channel.send(DataChannelProtocolService.createCancelFileProtocol(this.id));

        if (this.messageSubscription !== null) {
            this.messageSubscription.unsubscribe();
        }
    }

    private handleError(event: ErrorEvent) {
        this.transferEvents.add(new FileTransferErrorEvent(FileTransferErrorEvent.CAN_NOT_OPEN_FILE));

        if (this.messageSubscription !== null) {
            this.messageSubscription.unsubscribe();
        }
    }
}
