import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { TimeHelperService } from '../time-helpers/time-helper.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export const DEFAULT_START_DATE_TIME = '1970-01-01 00:00:00';
export const DEFAULT_END_DATE_TIME = '1970-01-02 00:00:00';
export const DEFAULT_START_DATE = '1971-01-01';
export const DEFAULT_END_DATE = '1971-01-02';

export interface TimeListItem {
    id: string;
    name: string;
    timeOnly: string;
}

@Component({
    selector: 'app-time-picker',
    template: `
        <select
                id="{{id}}"
                class="form-control input-select"
                [compareWith]="compareByItem"
                [(ngModel)]="selectedTime"
                (change)="onChangeOption()">
            <option *ngFor="let timeListItem of timeList"
                    [ngValue]="timeListItem"
                    [disabled]="isBeforeLimit(timeListItem)">{{timeListItem.name}}
            </option>
        </select>
    `,
    styleUrls: ['./time-picker.component.scss']
})

export class TimePickerComponent implements OnInit {
    public timeList: TimeListItem[] = [];

    @Input() selectedTime: TimeListItem;
    @Input() id: string;
    @Input() interval: number;
    @Input() startDateTime: string;
    @Input() endDateTime: string;
    @Input() disabledBelow: TimeListItem;
    @Input() required: boolean;


    @Output() selectedTimeEmitter = new EventEmitter<TimeListItem>();


    @Input()
    get selectedTimeListItem() {
        return this.selectedTime;
    }

    set selectedTimeListItem(val) {
        this.selectedTime = val;
        this.selectedTimeEmitter.emit(this.selectedTime);
    }

    ngOnInit() {
        if (!this.interval) {
            this.interval = 15;
        }
        if (!this.selectedTime) {
            this.selectedTime = {id: '08:00', name: '08:00', timeOnly: '08:00:00'};
        }

        if (!this.startDateTime) {
            this.startDateTime = DEFAULT_START_DATE_TIME;
        }

        if (!this.endDateTime) {
            this.endDateTime = DEFAULT_END_DATE_TIME;
        }
        this.initTimeList();
        this.onChangeOption();
    }

    private initTimeList() {
        const start = TimeHelperService.getMomentDateTime(this.startDateTime);
        const end = TimeHelperService.getMomentDateTime(this.endDateTime);
        let timeIndex: string;
        this.timeList = [];
        while (start < end) {
            timeIndex = start.format('HH:mm');
            const timeListItem: TimeListItem = {
                id: timeIndex,
                name: timeIndex,
                timeOnly: start.format('HH:mm:ss'),
            };
            this.timeList.push(timeListItem);
            start.add(this.interval, 'm');
        }
    }

    public onChangeOption() {
        this.selectedTimeEmitter.emit(this.selectedTime);
    }

    public isBeforeLimit(item: TimeListItem): boolean {
        if (this.disabledBelow) {
            return (item.id <= this.disabledBelow.id);
        } else {
            return false;
        }
    }

    public compareByItem(item1: { id: number }, item2: { id: number }): boolean {
        if (!item1 || !item2) {
            return false;
        }

        return item1.id === item2.id;
    }

    public selectNextEntryOf(giventime: TimeListItem) {
        let index = this.timeList.findIndex(el => el.id === giventime.id);
        index += 1;
        this.selectedTime = this.timeList[index];
    }

    public getSelectedTime(): TimeListItem {
        return this.selectedTime;
    }
}

