<div class="overflow-wrapper">
    <div class="what-is-heading payment">
        <div class="content-wrapper">
            <h2>{{ 'SETTING.PAYMENT-TAB.WHAT-IS' | translate}}</h2>
            <p>{{ 'SETTING.PAYMENT-TAB.WHAT-IS-DESCRIPTION' | translate}}</p>
        </div>
        <img class="header-illustration" src="/assets/img/illustrations/payment.png" alt="">
    </div>
</div>
<div class="setting-payment-content">
    <div class="coming-soon">
        <div class="title">
            <h4>
                <span class="fas fa-wrench"></span> {{ 'SETTING.COMING-SOON.TITLE' | translate}}</h4>
        </div>
        <div class="description">
            {{ 'SETTING.COMING-SOON.DESCRIPTION' | translate}}
            <a href="https://survey.zohopublic.eu/zs/PyCCfA?id={{ (profileData$ | async)?.id }}&email={{ (profileData$ | async)?.email }}"
               target="_blank">{{ 'SETTING.COMING-SOON.LINKTEXT' | translate}}</a>
        </div>
    </div>
</div>
