import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandlerService } from './providers/global-error-handler/global-error-handler.service';


@NgModule({
    imports: [
        HttpClientModule
    ]
})
export class CustomErrorHandlerModule {
    static forRoot(isProductionMode: boolean): ModuleWithProviders<CustomErrorHandlerModule> {
        return {
            ngModule: CustomErrorHandlerModule,
            providers: [
                {
                    provide: ErrorHandler,
                    useClass: GlobalErrorHandlerService
                }
            ]
        };
    }
}
