export interface CompanyType {
    id: string;
    name: string;
}

export const CompanyTypes = [
    { id: 'BUSINESS', name: 'Business' },
    { id: 'ORGANIZATION', name: 'Organization' },
    { id: 'SOLETRADER', name: 'Sole-trader' },
];

