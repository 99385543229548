import { Observable, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Profile } from '../../../../profile/store/profile.entity';
import { AppointmentInfoService } from '../../../providers/appointment-info.service';
import { TimeHelperService } from '../../../../utils/time-helpers/time-helper.service';
import { AppointmentInfoType } from '../appointment-info';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { map, filter } from 'rxjs';
import { StaticUtils } from '../../../../utils/static-utils';
import { MultiUserAppointment } from '../../../store/appointment/multi-user-appointment.entity';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../waitingroom/store/waitingroom.state';
import { Conference } from '../../../../conference-v2/store/conference.model';
import { JitsiIframeDataService } from '../../../../../providers/jitsi-iframe-data/jitsi-iframe-data.service';
import { waitingRoomStartMUCVideoConference } from '../../../../waitingroom/store/waitingroom.action';
import { appointmentStatus } from '../../../store/appointment/appointment-status';
import { environment } from '../../../../../../environments/environment';
import { ProfileSelectService } from '../../../../profile/providers/profile-select.service';
import { VonageConfigurationService } from '../../../../../providers/vonage-configuration/vonage-configuration.service';
import {
    VonageBrandingSettingsService
} from '../../../../../providers/vonage-branding-settings/vonage-branding-settings.service';

@Component({
    selector: 'app-multi-user-appointment-info',
    templateUrl: './multi-user-appointment-info.component.html',
    styleUrls: ['./multi-user-appointment-info.component.scss']
})
export class MultiUserAppointmentInfoComponent implements OnInit, OnDestroy {
    @Input() appointment: MultiUserAppointment;
    @Input() appointmentInfoType: AppointmentInfoType;
    currentProfileId: number;
    participants$: Observable<Profile[]>;
    participants: Profile[];
    tanLoginLink = `${window.location.origin}/tan-login?tan=`;
    isAppointmentToday: boolean;
    upComingAppointmentInfoType = AppointmentInfoType.UPCOMING;
    readonly defaultVisibleParticipants = 3;
    visibleParticipants: number = this.defaultVisibleParticipants;
    conference: Conference;
    callStarted = false;
    readonly canceledAppointment = appointmentStatus.canceled;
    vonageLoading$: Observable<boolean>;
    appointmentInfo = {
        participants: []
    };
    ovsProviderSubscription: Subscription = null;
    ovsProvider: string = environment.ovsProvider;

    constructor(private appointmentInfoService: AppointmentInfoService,
                private profileSelectService: ProfileSelectService,
                private store: Store<AppState>,
                private jitsiIframeData: JitsiIframeDataService,
                private vonageConfig: VonageConfigurationService,
                private videoLibSettings: VonageBrandingSettingsService) {
        this.vonageLoading$ = this.vonageConfig.loading$.pipe(
            filter(loading => loading && this.appointment.id === this.vonageConfig.appointmentId)
        );
        this.ovsProviderSubscription = this.videoLibSettings.ovsProvider$
            .subscribe(ovsProvider => this.ovsProvider = ovsProvider);
    }

    ngOnInit() {
        const currentProfileId = this.appointmentInfoService.getCurrentProfileId();
        this.participants$ = this.appointmentInfoService.loadMUCParticipants(this.appointment, currentProfileId)
            .pipe(
                map((participants: Profile[]) => {
                    this.participants = StaticUtils.sortAscending(participants, 'fullName');
                    return this.participants;
                })
            );
        this.isAppointmentToday = TimeHelperService.isToday(this.appointment?.startDateTime);

        this.appointmentInfoService.loadConference(this.appointment.conferenceId)
            .subscribe((conference: Conference) => {
                this.conference = conference;
            });
    }

    ngOnDestroy(): void {
        this.ovsProviderSubscription?.unsubscribe();
    }

    toggleTooltip(tooltip: NgbTooltip): void {
        tooltip.open();
        setTimeout(() => {
            tooltip.close();
        }, 2000);
    }

    toggleParticipants(allParticipants: number): void {
        if (this.visibleParticipants === this.defaultVisibleParticipants) {
            this.visibleParticipants = allParticipants;
        } else {
            this.visibleParticipants = this.defaultVisibleParticipants;
        }
    }

    public startVideoCall() {
        this.callStarted = true;
        this.jitsiIframeData.update({
            isMUC: this.participants.length > 1,
            participants: this.participants
        });
        this.store.dispatch(waitingRoomStartMUCVideoConference({payload: this.conference}));
    }

    public startVonage() {
        const profile = this.profileSelectService.getCurrentProfile();
        const otherProfiles = this.participants;
        this.vonageConfig.start(this.conference, this.appointment, profile, otherProfiles);
    }

    start() {
        if (this.ovsProvider === 'vonage') {
            this.startVonage();
        } else {
            this.startVideoCall();
        }
    }
}
