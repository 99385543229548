
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PeerService } from './peer.service';
import { filter, map } from 'rxjs/operators';


@Injectable()
export class OncallProvider {
    constructor(protected peeService: PeerService) { }

    public isSwitchOffline(): Observable<boolean> {
        return this.peeService.isConnected().pipe(
            filter(f => f === false),
            map(() => true)
        );
    }
}
