import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/jitsi.reducer';
import { JitsiCleanAction, JitsiCloseAction, JitsiDeviceDetectionFinishedAction, JitsiOpenAction } from '../store/jitsi.action';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JitsiStateService {

    private readonly event: Subject<boolean>;

    constructor(private store: Store<AppState>) {
        this.event = new Subject<boolean>();
    }

    public openView() {
        this.store.dispatch(new JitsiOpenAction());
    }

    public closeView() {
        this.store.dispatch(new JitsiCloseAction());
    }

    public isJitsiDeviceDetectionFinished(): Observable<boolean> {
        return this.store.select(s => s.jitsi.isFinished).pipe(filter(s => s !== null), distinctUntilChanged());
    }

    public isJitsiOpen(): Observable<boolean> {
        return this.store.select(s => s.jitsi.isOpen).pipe(filter(s => s !== null), distinctUntilChanged());
    }

    public openSettings() {
        this.event.next(true);
    }

    public openSettingsEvenTriggered(): Observable<boolean> {
        return this.event.pipe(filter(s => s !== null));
    }

    public setSettingsFinish() {
        this.store.dispatch(new JitsiDeviceDetectionFinishedAction());
    }

    public reset() {
        this.store.dispatch(new JitsiCleanAction());
    }
}
