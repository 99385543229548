/**
 * Public online state object
 */
export enum UserPresenceStatus {
    UNKNOWN = 'unknown',
    AWAY = 'away',
    DND = 'dnd',
    OFFLINE = 'offline',
    ONLINE = 'online'
}

