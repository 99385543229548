import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MediaService } from '../../media/provider/media.service';
import { StepType } from '../rtc-detection.entities';
import { RtcDetectionStepComponent } from '../rtc-detection-step-component.interface';
import { BrowserService } from '../../media/provider/browser.service';

@Component({
    templateUrl: './step-hardware-verify.component.html',
    styleUrls: ['../rtc-detection.component.scss', './step-hardware-verify.component.scss']
})
export class StepHardwareVerifyComponent implements RtcDetectionStepComponent, OnInit {
    nextStep: (arg) => void;
    dismissModal: () => void;
    closeModal: () => void;
    cancelStep: () => void;

    cameraIsRunning = false;
    microIsOn = false;


    constructor(private translate: TranslateService,
                private mediaService: MediaService,
                private browser: BrowserService) {
    }

    ngOnInit(): void {
        this.mediaService.localStreamIsActive().subscribe(active => {
            this.cameraIsRunning = active;
        });

        this.mediaService.microphoneIsActive().subscribe(active => {
            this.microIsOn = active;
        });

    }

    public continue() {
        if (this.browser.isChrome()) {
            this.nextStep(StepType.CHROME_EXTENSION);
        } else {
            this.nextStep(StepType.SUCCESS);
        }
    }
}

