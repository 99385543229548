import { RtcDetectionStepComponent } from '../rtc-detection-step-component.interface';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../store/rtc-detection.state';
import { Store } from '@ngrx/store';
import { RtcDetectionFinishedAction } from '../store/rtc-detection.action';

@Component({
    templateUrl: './step-success.component.html',
    styleUrls: ['../rtc-detection.component.scss', './step-success.component.scss']
})
export class StepSuccessComponent implements RtcDetectionStepComponent {

    nextStep: (arg) => void;
    dismissModal: () => void;
    closeModal: () => void;
    cancelStep: () => void;

    constructor(private translate: TranslateService,
                private store: Store<AppState>) {
    }

    public finish(): void {
        this.store.dispatch(new RtcDetectionFinishedAction());
        this.closeModal();
    }
}
