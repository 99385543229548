import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { WAITINGROOM_START_VIDEO_CONFERENCE, WaitingroomAction } from '../../waitingroom/store/waitingroom.action';
import { CountdownService } from '../providers/countdown.service';
import {
    COUNTDOWN_FINISHED,
    COUNTDOWN_NEW_SYNC_VALUE,
    COUNTDOWN_NEW_VALUE,
    COUNTDOWN_REQUEST_INIT,
    COUNTDOWN_STOP
} from './countdown.action';
import { SoundService } from '../../media/provider/sound.service';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';

@Injectable()
export class CountdownEffects {
    constructor(private actions$: Actions,
                private countdownService: CountdownService,
                private soundService: SoundService,
                private tracer: LogAppointmentService) {
    }

    // Countdown was started remotely
    // @Effect({dispatch: false}) initCountdown$: Observable<Action> = this.actions$.pipe(
    //     ofType(COUNTDOWN_REQUEST_INIT),
    //     tap((action: any) => {
    //         this.countdownService.initCountdown(action.payload);
    //         this.countdownService.startPatientCounter();
    //
    //         this.tracer.logAppointmentCountdownStarted();
    //     }));

    // Countdown was started by click start
    // @Effect({dispatch: false}) startCountdown$: Observable<Action> = this.actions$.pipe(
    //     ofType(WAITINGROOM_START_VIDEO_CONFERENCE),
    //     tap((action: WaitingroomAction) => {
    //         this.countdownService.initCountdown(action.payload.userTokens[0].profileId);
    //         this.countdownService.startCounter(action.payload.myToken.profileId, action.payload.userTokens[0].profileId);
    //
    //         this.tracer.logAppointmentCountdownStarted();
    //     }));

    // @Effect({dispatch: false}) stopCountdown$: Observable<Action> = this.actions$.pipe(
    //     ofType(COUNTDOWN_STOP),
    //     tap(() => {
    //         this.countdownService.stopCounter();
    //         this.countdownService.destroyCounter();
    //     })
    // );

    // @Effect({dispatch: false}) logVideoConferenceAfterCountdown$: Observable<Action> = this.actions$.pipe(
    //     ofType(COUNTDOWN_FINISHED),
    //     tap(() => this.tracer.logAppointmentCountdownFinished()));
    //
    // @Effect({dispatch: false}) playCountdownSound$: Observable<Action> = this.actions$.pipe(
    //     ofType(COUNTDOWN_NEW_SYNC_VALUE, COUNTDOWN_NEW_VALUE),
    //     tap(() => this.soundService.playCountdownSound()));
}
