import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {MEDIA_CALL_END, MEDIA_CALL_START} from '../../media/store/media.action';
import {COUNTDOWN_INIT} from '../../countdown/store/countdown.action';
import {HeaderHideLogoAction, HeaderSetRemoteVisibilityAction} from './header.action';

@Injectable()
export class HeaderEffects {

    constructor(private actions$: Actions) {
    }

     hideLogoHeader$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CALL_START),
        map(() => new HeaderHideLogoAction(false))));

     endCallCollapseHeader$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CALL_END),
        map(() => new HeaderSetRemoteVisibilityAction(false))));

     startCallEnlargeHeader$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(COUNTDOWN_INIT),
        map(() => new HeaderSetRemoteVisibilityAction(true))));
}
