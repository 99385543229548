import { FileTransferEvent } from './file-transfer-event.model';
import { Subject } from 'rxjs';
import {
    FileTransferCancelledState,
    FileTransferCloseState,
    FileTransferErrorState,
    FileTransferEventQueue
} from './file-transfer-event-queue';

export interface FileData {
    name: string;
    size: number;
    type: string;
    blob?: Blob;
}

export interface FileTransmitter {
    transferEvents: Subject<FileTransferEvent>;
    cancel: () => void;
    getFileData: () => FileData;
    isFinished: () => boolean;
    hasError: () => boolean;
    getError: () => string;
    wasCanceled: () => boolean;
    getId: () => string;
}

export abstract class AbstractFileTransmitter implements FileTransmitter {
    public transferEvents = new FileTransferEventQueue();
    protected id: string;
    protected fileData: FileData;

    public getFileData(): FileData {
        return this.fileData;
    }

    public getId(): string {
        return this.id;
    }

    public getError(): string {
        if (this.hasError()) {
            return (<FileTransferErrorState>this.transferEvents.getCurrentState()).getError();
        } else {
            return '';
        }
    }

    public hasError(): boolean {
        return this.transferEvents.getCurrentState() instanceof FileTransferErrorState;
    }

    public wasCanceled(): boolean {
        return this.transferEvents.getCurrentState() instanceof FileTransferCancelledState;
    }

    public isFinished(): boolean {
        return this.transferEvents.getCurrentState() instanceof FileTransferCloseState;
    }

    public abstract cancel();
}
