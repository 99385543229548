import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/login/login.state';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TAN_LOGIN_ROUTE } from '../../routes';
import { RouterHelperService } from '../utils/router-helper/router-helper.service';

@Injectable()
export class AuthGuard implements CanActivate {
    private isAuthenticated$: Observable<boolean>;

    constructor(private store: Store<AppState>,
                private router: RouterHelperService) {
        this.isAuthenticated$ = this.store.select(s => s.login.loggedIn);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.isAuthenticated$.pipe(map((authenticated: boolean) => {
            if (!authenticated) {
                this.router.navigate([TAN_LOGIN_ROUTE]);
                return false;
            }
            return true;
        }));
    }
}
