<div class="bottom-line">
    <div *ngIf="((brandingData$ | async)?.feedbackLink !== '')" class="left">
        <i class="fas fa-paper-plane"></i>
        <a href="{{ (brandingData$ | async)?.feedbackLink ? (brandingData$ | async)?.feedbackLink : 'GENERAL.SURVEY-BASE-LINK' | translate }}?id={{ (profileData$ | async)?.id }}&email={{ (profileData$ | async)?.email }}"
           target="_blank">
            {{'RTC_DETECTION.MSG' | translate }}
        </a>
    </div>
    <div *ngIf="(brandingData$ | async)?.faqLink !== false" class="left">
        <i class="fas fa-question-circle"></i>
        <a target="_blank"
           href="{{ 'GENERAL.FAQ-LINK' | translate }}" >
            {{'RTC_DETECTION.FAQ' | translate }}
        </a>
    </div>
    <div class="right">
        <i class="fas fa-phone fa-flip-horizontal"></i>
        {{ (brandingData$ | async)?.telephone ? (brandingData$ | async)?.telephone : ('PATIENTUS.PHONE' | translate) }}
    </div>
</div>
