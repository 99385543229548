import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {concat, concatMap, delay, filter, skip, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';

export const MAX_POLLING_NUMBER_REACHED = 'Polling Timeout, The max number of polling requests was reached!';

/**
 * Polling Client to polling. You can use a filter to get only the important response from the server. The condition depends on your http
 * response object from your http rest endpoint.
 *
 * const filterCondition = filter(response => response.status === 'FINISHED' || response.status === 'ERROR');
 *
 * const subscription = client.get(POLLING_URL)
 *       .pipe(filterCondition)
 *       .subscribe((response) => {
 *           subscription.unsubscribe();
 *
 *           if(response.status === 'FINISHED') {
 *              do something with the result
 *           }
 *
 *           if(response.status === 'ERROR') {
 *              do something with backend errors
 *           }
 *       }, (error: Error) => {
 *          subscription.unsubscribe();
 *
 *          if(error.message === MAX_POLLING_NUMBER_REACHED) {
 *              do something with polling timeout
 *          }
 *       });
 */
@Injectable()
export class PollingClientService {

    private period = 1000;

    private load$ = new BehaviorSubject('');

    constructor(private http: HttpClient) {

    }

    get(url, maxPollingNumber = 30): Observable<HttpResponse<any>> {
        const methode$ = this.http.get(url);
        return this.polling(methode$, maxPollingNumber);
    }

    polling(method$: Observable<any>, maxPollingNumber: number): any {
        let pollingNumber = 0;

        const abortCondition = tap(s => {
            if (pollingNumber === maxPollingNumber) {
                throw new Error(MAX_POLLING_NUMBER_REACHED);
            } else {
                pollingNumber++;
            }
            return s;
        });

        const whenToRefresh$ = of('').pipe(
            delay(this.period),
            tap(_ => this.load$.next('')),
            skip(1),
        );

        return this.load$.pipe(
            concatMap(_ => method$.pipe(concat(whenToRefresh$))),
            abortCondition,
            filter(response => response !== null)
        );
    }

    setPeriod(period: number) {
        this.period = period;
    }
}
