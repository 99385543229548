import { filter, map, mapTo, tap } from 'rxjs/operators';
import { Media } from '../../media/model/media-video.model';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import {
    WEBRTC_CLOSE_MAIN_CONNECTION,
    WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION,
    WEBRTC_CONNECTION_GOT_INTERRUPTED,
    WebrtcCloseMainConnectionAction,
    WebrtcCloseMainConnectionSuccessAction,
    WebrtcCloseScreenShareConnectionAction,
    WebrtcCloseScreenShareConnectionSuccessAction,
    WebrtcResetAction
} from './webrtc.action';
import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../providers/store/app.action';
import { WAITINGROOM_START_VIDEO_CONFERENCE, WaitingroomStartVideoConference } from '../../waitingroom/store/waitingroom.action';
import {
    MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS,
    MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS,
    MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS,
    MediaReceivedLocalScreenStreamSuccessAction,
    MediaReceivedLocalStreamSuccessAction,
    MediaStartClosingLocalScreenStreamAction
} from '../../media/store/media.action';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';
import { CONFERENCE_ACTIVATE, ConferenceActivateAction } from '../../conference/store/conference.action';
import { PeerService } from '../provider/peer.service';
import { SignalService } from '../provider/signal.service';
import { HeartbeatService } from '../provider/heartbeat/heartbeat.service';
import { ConnectionConfigurationService } from '../provider/configurations/connection-configuration.service';

@Injectable()
export class WebrtcEffects {

    constructor(private actions$: Actions,
                private heartbeatService: HeartbeatService,
                private webrtcProvider: PeerService,
                private webrtcSignalService: SignalService,
                private tracer: LogAppointmentService,
                private connectionConfigService: ConnectionConfigurationService) {
    }

     connectionGotInterrupted$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CONNECTION_GOT_INTERRUPTED),
        mapTo(new WebrtcCloseMainConnectionAction(true))));

     setLocalStreamForWebrtcConnection$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_RECEIVED_LOCAL_STREAM_SUCCESS),
        filter((action: MediaReceivedLocalStreamSuccessAction) => action.payload.stream !== null),
        tap((action: MediaReceivedLocalStreamSuccessAction) => {
            const media: Media = <Media>action.payload;
            this.webrtcProvider.setLocalStream(media.stream);
        })), {dispatch: false});

     createOffer$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WAITINGROOM_START_VIDEO_CONFERENCE),
        tap((action: WaitingroomStartVideoConference) => {
            // this.webrtcProvider.startCall();
        })), {dispatch: false});

     endCall$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CLOSE_MAIN_CONNECTION),
        map((action: WebrtcCloseMainConnectionAction) => {
            if (!action.payload.peerHangup) {
                this.webrtcSignalService.sendHangup(action.connectionType);
                this.tracer.logAppointmentHangup();
                this.webrtcProvider.close();
            } else {
                this.webrtcProvider.close();
            }
        }),
        map(() => new WebrtcCloseMainConnectionSuccessAction())));

     updateConnectionConfigurationOnConferenceChange: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(CONFERENCE_ACTIVATE),
        tap((action: ConferenceActivateAction) => this.connectionConfigService.updateConnectionConfigurationByConference(action.payload))), {dispatch: false});

     setLocalScreenStreamForWebrtcConnection$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_RECEIVED_LOCAL_SCREEN_STREAM_SUCCESS),
        filter((action: MediaReceivedLocalScreenStreamSuccessAction) => action.payload.stream !== null),
        tap((action: MediaReceivedLocalScreenStreamSuccessAction) => {
            const media: Media = <Media>action.payload;
            this.webrtcProvider.startScreenShare(media.stream);
        })), {dispatch: false});

     endScreenWhenLostStream$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(MEDIA_CLOSED_LOCAL_SCREEN_STREAM_SUCCESS),
        map(() => new WebrtcCloseScreenShareConnectionAction())));

     endScreenShare$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WEBRTC_CLOSE_SCREEN_SHARE_CONNECTION),
        map((action: MediaStartClosingLocalScreenStreamAction) => {
            this.webrtcProvider.stopScreenShare();
        }),
        map(() => new WebrtcCloseScreenShareConnectionSuccessAction())));

     doReset$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new WebrtcResetAction())));
}
