<div [ngClass]="state === SenderContainerState.STATE_ERROR ? 'fileSelectError' : 'fileSelect'">
    <div *ngIf="state === SenderContainerState.STATE_INIT">
        <form [formGroup]="fileUploaderForm">
            <label for="fileUploader" class="customFileUpload fileUploader">
                {{'FILE_TRANSFER.BODY.SELECT_FILE' | translate}}
                <i class="fa fa-folder-open" aria-hidden="true"></i>
            </label>
            <input type="file" id="fileUploader" name="fileUploader" (change)="onFileSelect($event)">
        </form>
    </div>
    <div class="fileContainer" *ngIf="state === SenderContainerState.STATE_FILE_SELECTED">
        <div class="arrangeLeft">
            <span class="blueLight displayText" [ngbTooltip]="(getFileName().length > 20) ? getFileName() : ''">
                {{ getFileNameRefactored() }}
            </span>
            <button id="clearFile" type="button" class="close blueLight"
                    [disabled]="(state !== SenderContainerState.STATE_FILE_SELECTED)" (click)="clearFile()">
                <i class="fa fa-times-circle cancelIcon" aria-hidden="true"></i>
            </button>
        </div>
        <div class="arrangeRight">
            <button id="sendFile" type="button" class="btn btn-primary btn-sm weight-600 btn-radius btn-send"
                    [disabled]="(state !== SenderContainerState.STATE_FILE_SELECTED)" (click)="send()">
                {{'FILE_TRANSFER.BODY.UPLOAD_FILE' | translate}}
                <i class="fa fa-upload" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="fileContainer" *ngIf="state === SenderContainerState.STATE_UPLOADING">
        <div id="senders">
            <app-progressbar [transmitter]="fileSender" [type]="ProgressbarComponentType.SENDING"
                             (progressEvent)="onChildEvent($event)"></app-progressbar>
        </div>
    </div>
    <div class="fileContainer" *ngIf="state === SenderContainerState.STATE_FILE_SENT">
        <span class="blueLight">
            <i class="fal fa-check-circle"></i>
            {{ getFileNameRefactored() }} {{'FILE_TRANSFER.BODY.WAS_TRANSFERRED' | translate}}
        </span>
    </div>
    <div class="fileContainer" *ngIf="state === SenderContainerState.STATE_SEND_CANCELED ">
        <span class="weight-400 blueLight">
            <i class="fal fa-times-circle" aria-hidden="true"></i>
            {{ getFileNameRefactored() }} {{'FILE_TRANSFER.BODY.CANCELED' | translate}}
        </span>
    </div>
    <div class="errorContainer" *ngIf="state === SenderContainerState.STATE_ERROR">
        <span class="weight-400 redColor">
            <i class="fal fa-exclamation-triangle" aria-hidden="true"></i>
            {{ getError() | translate }}.
        </span>
    </div>
</div>
