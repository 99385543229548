import { catchError, filter, map, retry, zip } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { PaymentAccountStoreService } from './payment-account-store.service';
import { PaymentAccount, PaymentAccountSum } from '../store/payment-account.model';
import { environment } from '../../../../environments/environment';
import { PaymentAccountDto } from './dto/payment-account-dto.model';
import { PaymentAccountMapperService } from './dto/payment-account-mapper';
import { TimeHelperService } from '../../../modules/utils/time-helpers/time-helper.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class PaymentAccountService {

    private paymentGetOrdersStatusPayIn = environment.paymentGetOrdersStatusPayIn;
    private paymentGetOrdersStatusPaid = environment.paymentGetOrdersStatusPaid;

    constructor(private http: HttpClient,
                private storeService: PaymentAccountStoreService) {
    }

    public readPaymentAccountPayIn(timestamp: number): Observable<PaymentAccountSum> {
        return this.http
            .get(this.paymentGetOrdersStatusPayIn,
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/json'),
                    params: new HttpParams().set('timestamp', String(timestamp))
                })
            .pipe(
                retry(3),
                filter(e => !(e === null)),
                map((paymentAccountDto: PaymentAccountDto) =>
                    PaymentAccountMapperService.mapPayInDtoToEntity(paymentAccountDto)
                ),
                map((paymentAccount: PaymentAccount) => {
                    this.storeService.setPaymentAccountPayInSumInStore(paymentAccount.statusSums.payInSum);
                    this.storeService.setPaymentAccountTimestampInStore(paymentAccount.timestamp);
                    this.storeService.addPaymentAccountOrdersInStore(paymentAccount.orders);
                    return paymentAccount.statusSums;
                }),
                catchError(this.handleError)
            );
    }

    public readPaymentAccountPaid(timestamp: number): Observable<PaymentAccountSum> {
        return this.http
            .get(this.paymentGetOrdersStatusPaid,
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/json'),
                    params: new HttpParams().set('timestamp', String(timestamp))
                }
            )
            .pipe(
                retry(3),
                filter(e => !(e === null)),
                map((paymentAccountDto: PaymentAccountDto) =>
                    PaymentAccountMapperService.mapPaidDtoToEntity(paymentAccountDto)
                ),
                map((paymentAccount: PaymentAccount) => {
                    this.storeService.setPaymentAccountPaidSumInStore(paymentAccount.statusSums.paidSum);
                    this.storeService.setPaymentAccountTimestampInStore(paymentAccount.timestamp);
                    this.storeService.addPaymentAccountOrdersInStore(paymentAccount.orders);
                    return paymentAccount.statusSums;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in PaymentAccountService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    public refreshPaymentAccountPayInState(ts: number = TimeHelperService.getCurrentTimestamp()): void {
        this.readPaymentAccountPayIn(ts).subscribe();
    }

    public refreshPaymentAccountPaidState(ts: number = TimeHelperService.getCurrentTimestamp()): void {
        this.readPaymentAccountPaid(ts).subscribe();
    }

    public refreshAllPaymentAccountStates(ts: number = TimeHelperService.getCurrentTimestamp(),
                                          callback: (arg) => any = () => null): void {
        this.readPaymentAccountPayIn(ts).pipe(zip(
            this.readPaymentAccountPaid(ts)
        )).subscribe(mergeAnswer => callback(mergeAnswer));
    }
}
