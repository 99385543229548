import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { WaitingroomEffects } from './store/waitingroom.effect';
import { waitingroomReducer } from './store/waitingroom.reducer';
import { NextStepService } from '../rtc-detection/providers/next-step.service';
import { RtcDetectionModule } from '../rtc-detection/rtc-detection.module';
import { AppointmentModule } from '../appointment/appointment.module';
import { CommunicationService } from '../appointment/providers/communication.service';
import { LogAppointmentService } from '../logging/providers/log-appointment.service';
import { BrandingModule } from '../branding/branding.module';
import { JitsiModule } from '../jitsi/jitsi.module';
import { InstitutionSettingsModule } from '../institution-settings/institution-settings.module';
import { WaitingroomComponent } from './single-user-waitingroom/waitingroom.component';
import { MultiUserWaitingroomComponent } from './multi-user-waitingroom/multi-user-waitingroom.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        RtcDetectionModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('waitingroom', waitingroomReducer),
        EffectsModule.forFeature([WaitingroomEffects]),
        AppointmentModule,
        BrandingModule,
        JitsiModule,
        NgbPaginationModule
    ],
    declarations: [
        WaitingroomComponent,
        MultiUserWaitingroomComponent
    ],
    providers: [
        Store,
        NextStepService,
        CommunicationService,
        LogAppointmentService,
        InstitutionSettingsModule
    ],
    exports: []
})

export class WaitingroomModule {
}
