import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { GeneralMessageBusService, GeneralStateType } from '../../message-bus/providers/general-message-bus.service';
import {
    CountdownFinishedAction,
    CountdownInitAction,
    CountdownNewValueAction
} from '../store/countdown.action';
import { AppState, CountdownState } from '../store/countdown.state';
import { CountdownSyncService } from './countdown-sync.service';

const startCounterValue = 10;

@Injectable()
export class CountdownService implements OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private generalMessageBusService: GeneralMessageBusService,
        private countdownSyncService: CountdownSyncService
    ) {
    }

    public initCountdown(participantId: number): void {
        this.store.select(s => s.countdown).pipe(
            filter((countdown: CountdownState) => countdown !== null && (countdown.value === 0 || countdown.isStopped)),
            take(1),
            tap(() => this.countdownSyncService.sendCountdownSyncMessage(false, participantId, false, null))
        ).subscribe();

        this.store.dispatch(new CountdownInitAction());
        this.generalMessageBusService.add(GeneralStateType.STATE_COUNT_DOWN_START);
    }

    public startCounter(doctorId: number, patientId: number): void {
        this.countdownSyncService.sendCountdownSyncMessage(true, patientId, true, doctorId);
        this.getCounter(startCounterValue)
            .pipe(takeUntil(this.destroy$))
            .subscribe((counterValue: number) => {
                this.setNewCounterValue(counterValue);
            });
    }

    public startPatientCounter(): void {
        this.getCounter(startCounterValue)
            .pipe(takeUntil(this.destroy$))
            .subscribe((counterValue: number) => {
                this.setNewCounterValue(counterValue);
            });
    }

    public setNewCounterValue(value: number): void {
        this.store.dispatch(new CountdownNewValueAction(value));
    }

    public getCountdown(): Observable<number> {
        return this.store.select(s => s.countdown.value);
    }

    public getCountdownState(): Observable<boolean> {
        return this.store.select(s => s.countdown.running);
    }

    public destroyCounter(): void {
        this.destroy$.next();
    }

    public stopCounter(): void {
        this.generalMessageBusService.add(GeneralStateType.STATE_COUNT_DOWN_STOP);
        this.store.dispatch(new CountdownFinishedAction());
    }

    public ngOnDestroy(): void {
        this.destroyCounter();
    }

    private getCounter(startValue: number): Observable<number> {
        return timer(1000, 1000).pipe(
            map(i => startValue - i),
            take(startValue + 1)
        );
    }
}
