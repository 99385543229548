import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/waitingroom.state';
import { MediaStreamProvider } from '../../media/provider/media-stream.provider';
import { JitsiSettingsDetectionService } from '../../jitsi/provider/jitsi-settings-detection.service';
import { LogAppointmentService } from '../../logging/providers/log-appointment.service';
import { BrowserService } from '../../media/provider/browser.service';
import { BrandingService } from '../../branding/providers/branding.service';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MultiUserAppointmentService } from '../../appointment/providers/multi-user-appointment.service';
import {
    MultiUserAppointment,
    MultiUserAppointmentRequestType
} from '../../appointment/store/appointment/multi-user-appointment.entity';
import { getIsOnCallSelector } from '../store/waitingroom.selector';

@Component({
    selector: 'app-multi-user-waitingroom',
    templateUrl: './multi-user-waitingroom.component.html',
    styleUrls: ['./multi-user-waitingroom.component.scss']
})
export class MultiUserWaitingroomComponent implements OnInit, OnDestroy {
    public appointments$: Observable<MultiUserAppointment[]>;
    public isRemoteStreamActive$: Observable<boolean>;
    public backgroundImg$: Observable<{ backgroundImage: string }>;

    @ViewChild('itemsRef') itemsRef: ElementRef;
    private isOnCall = false;
    private readonly subscriptionIsOnCall: Subscription = null;
    private readonly defaultBgImage = '/assets/img/waitingroom/waitingroom-patients.jpg';

    constructor(private store: Store<AppState>,
                private appointmentService: MultiUserAppointmentService,
                private mediaStreamService: MediaStreamProvider,
                private settingsService: JitsiSettingsDetectionService,
                private tracer: LogAppointmentService,
                private browserService: BrowserService,
                private brandingService: BrandingService) {
        this.appointments$ = this.appointmentService.getAllMultiUserAppointments$();

        this.subscriptionIsOnCall = this.store
            .select(getIsOnCallSelector).pipe(
                distinctUntilChanged())
            .subscribe(onCall => this.isOnCall = onCall);

        this.backgroundImg$ = this.brandingService.getBrandingSettingsFromStore().pipe(
            map(settings => {
                const backgroundImage = settings.bgImage_patientWaitingRoom || this.defaultBgImage;
                return {backgroundImage};
            }));
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: BeforeUnloadEvent) {
        if (this.isOnCall) {
            this.tracer.logAppointmentQuit();
        }
        event.stopPropagation();
    }

    ngOnInit() {
        this.isRemoteStreamActive$ = this.mediaStreamService.isRemoteStreamActive();
        this.appointmentService.getAppointments(MultiUserAppointmentRequestType.ALL);
        setTimeout(() => {
            if (!this.browserService.isMobile()) {
                setTimeout(() => this.settingsService.checkSettings());
            }
        }, 700);
    }

    ngOnDestroy(): void {
        if (this.subscriptionIsOnCall !== null) {
            this.subscriptionIsOnCall.unsubscribe();
        }
    }
}
