import { take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BankAccountFormBuilder } from './bank-account.form-builder';
import { CountryService, CountryType } from '../../../../../entities/payment-country/provider/country.service';
import { PaymentBankAccountService } from '../../../../../entities/payment-bank-account/provider/payment-bank-account.service';
import { InvalidDirective } from '../../../../../forms/directives/invalid.directive';
import { PaymentBankAccount } from '../../../../../entities/payment-bank-account/store/payment-bank-account.model';
import { Child, ChildResponseType, ResponseType } from '../payment-info-tab/payment-info-tab.component';

@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent implements OnInit, OnDestroy {
    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;
    @Output() editFinishedEmitter = new EventEmitter<ChildResponseType>();
    @Input() bankAccount: PaymentBankAccount;
    @Input() isEditing: boolean;

    bankAccountForm: FormGroup;
    countries: CountryType[];
    countrySubscription: Subscription = null;
    public isSubmitting: boolean;

    constructor(private formBuilder: FormBuilder,
                private countryService: CountryService,
                private bankAccountService: PaymentBankAccountService) {
    }

    ngOnInit() {
        this.bankAccountForm = BankAccountFormBuilder.build(this.formBuilder);
        this.countrySubscription = this.countryService.getCountries().subscribe(
            countries => this.countries = countries
        );
        if (!!this.bankAccount) {
            this.updateInputFieldsOnUpdate();
        }
    }

    private updateInputFieldsOnUpdate() {
        this.bankAccountForm.setValue({
            iban: this.bankAccount.iban,
            bic: this.bankAccount.bic,
            firstName: this.bankAccount.firstName,
            lastName: this.bankAccount.lastName,
            country: {
                id: this.bankAccount.country.id,
                name: this.bankAccount.country.name,
            },
            city: this.bankAccount.city,
            plz: this.bankAccount.plz,
            addressLine: this.bankAccount.addressLine
        });
    }

    public cancelHandler() {
        this.editFinishedEmitter.emit({Child: Child.BANKINFO, ResponseType: ResponseType.CANCELED});
    }

    private validateForm() {
        Object.keys(this.bankAccountForm.controls).map(key => {
            this.bankAccountForm.get(key).markAsDirty();
        });
        this.invalidDirectives.map((directive) => {
            directive.triggerTooltip();
        });
    }

    public submit(): void {
        this.validateForm();

        if (this.bankAccountForm.valid) {
            this.isSubmitting = true;
            if (!this.bankAccount) {
                this.bankAccountService
                    .setPaymentBankAccount(this.getPayload()).pipe(
                    tap(_ => {
                        /* start spinner */
                    }),
                    take(1))
                    .subscribe(newBankAccount => {
                        /* stop spinner */
                        this.isSubmitting = false;
                        this.editFinishedEmitter.emit({Child: Child.BANKINFO, ResponseType: ResponseType.CREATED});
                    });
            } else {
                this.bankAccountService
                    .updatePaymentBankAccount(this.getPayload()).pipe(
                    tap(_ => {
                        /* start spinner */
                    }),
                    take(1))
                    .subscribe(newBankAccount => {
                        /* stop spinner */
                        this.isSubmitting = false;
                        this.editFinishedEmitter.emit({Child: Child.BANKINFO, ResponseType: ResponseType.UPDATED});
                    });
            }
        }
    }

    public compareByCountry(item1, item2): boolean {
        return item1.name === item2.name;
    }

    public getWholeAddressLine(): string {
        if (!this.bankAccount) {
            return;
        }

        return `${this.bankAccount.country.name}, ${this.bankAccount.plz} ${this.bankAccount.city}, ${this.bankAccount.addressLine}`;
    }

    private getPayload(): PaymentBankAccount {
        const form = this.bankAccountForm.controls;
        return <PaymentBankAccount>{
            iban: form['iban'].value,
            bic: form['bic'].value,
            firstName: form['firstName'].value,
            lastName: form['lastName'].value,
            country: form['country'].value,
            city: form['city'].value,
            plz: form['plz'].value,
            addressLine: form['addressLine'].value,
        };
    }

    ngOnDestroy(): void {
        if (this.countrySubscription !== null) {
            this.countrySubscription.unsubscribe();
        }
    }
}
