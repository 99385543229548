import { Injectable } from '@angular/core';
import { SignalConnection, SignalConnectorInterface } from '../../../signal/providers/signal.connection';
import { SchedulerListener } from '../scheduler/scheduler.service';
import { ConnectionStatsService } from '../../../../entities/webrtc-connection-stats';
import { map, take, tap } from 'rxjs/operators';

export interface HeartbeatMessage {
    heartbeatRequest?: 'ping';
    heartbeatResponse?: 'pong';
    stats?: { bandwidth: number };
}

/**
 *  A - The Ping Pong
 *  ----------------------------------------------------------------
 *    Calling a heartbeat Request      |     | responding
 *    ..............................................................
 *    {heartbeatRequest: ping}         | --> |
 *                                     | <-- | {heartbeatResponse: pong
 *                                     |     |  stats: {
 *                                     |     |        bandwidth: number
 *                                     |     |      }
 *                                     |     | }
 */
@Injectable()
export class HeartbeatService implements SignalConnectorInterface, SchedulerListener {

    private WAITING_TIME = 20000;
    public onConnectionLostCallback: () => void;
    private lastHeartbeatTimestamp = 0;

    constructor(
        private webSocketConnection: SignalConnection<HeartbeatMessage>,
        private connectionStatsService: ConnectionStatsService) {
        this.webSocketConnection.register(this);
    }

    /**
     * @override Signal Connector interface
     */
    public onOpenConnection() {
        this.webSocketConnection.subscribeMessages((msg) => this.handle(msg));
    }

    /**
     * @override Signal Connector interface
     */
    public onCloseConnection() {
        this.lastHeartbeatTimestamp = 0;
    }

    /**
     * @override SchedulerListener
     */
    public startSchedule(): void {
        this.lastHeartbeatTimestamp = new Date().getTime();
    }

    /**
     * @override SchedulerListener
     */
    public doSchedule(pc: RTCPeerConnection): void {
        const isNotOutdated = this.isLastAnswerInMeantime(this.lastHeartbeatTimestamp);
        if (isNotOutdated) {
            this.webSocketConnection.send({heartbeatRequest: 'ping'});
        } else {
            if (this.onConnectionLostCallback !== undefined) {
                this.onConnectionLostCallback();
            }
        }
    }

    /**
     * @override SchedulerListener
     */
    public stopSchedule(): void {
        this.lastHeartbeatTimestamp = 0;
    }

    // Handle Signal server events
    private handle(message: HeartbeatMessage) {
        if (message.heartbeatRequest) {
            this.onHeartbeatRequest();
        }

        if (message.heartbeatResponse) {
            this.lastHeartbeatTimestamp = new Date().getTime();
            this.connectionStatsService.setPeerBandwidth(message.stats.bandwidth);
        }
    }

    private onHeartbeatRequest() {
        this.connectionStatsService.getLocalStats().pipe(
            take(1),
            map((stats): HeartbeatMessage => ({
                heartbeatResponse: 'pong',
                stats
            })),
            tap((data) => this.webSocketConnection.send(data))
        ).subscribe();
    }

    private isLastAnswerInMeantime(lastHeartbeatTimestamp: number): boolean {
        const currentTimestamp = new Date().getTime();
        const delta = currentTimestamp - lastHeartbeatTimestamp;
        return delta < this.WAITING_TIME;
    }
}
