import { Injectable } from '@angular/core';
import moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StaticUtils } from '../static-utils';

@Injectable()
export class DatePickerDateCompareService {

    public isLesserThanToday(dateStruct: NgbDateStruct): boolean {
        const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
        const date = moment({ year: dateStruct.year, month: (dateStruct.month - 1), day: dateStruct.day });

        return date < today;
    }


    public formatISO(date: NgbDateStruct | null): string {
        return date ?
            `${date.year}-${StaticUtils.isNumber(date.month) ? StaticUtils.padNumber(date.month) : ''}-${StaticUtils.isNumber(date.day) ? StaticUtils.padNumber(date.day) : ''}` :
            '';
    }
}
