import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserPresence } from '../store/user-presence.model';
import { DeleteUserPresence, UpsertUserPresence } from '../store/user-presence.actions';
import { Observable } from 'rxjs';
import { selectAllUserPresences, selectUserPresences } from '../store/user-presence.selectors';

@Injectable()
export class UserPresenceStoreService {

    constructor(private store: Store<UserPresence>) {
    }

    // @TODO Handle resources in reducer
    public upsertPresence(userPresence: UserPresence): void {
        this.store.dispatch(new UpsertUserPresence({userPresence}));
    }

    // @TODO Handle resources in reducer
    public removePresence(profileId: number) {
        this.store.dispatch(new DeleteUserPresence({id: profileId}));
    }

    public getPresence(profileId: number): Observable<UserPresence> {
        return this.store.select(selectUserPresences(profileId));
    }

    public getAllUserPresences(): Observable<UserPresence[]> {
        return this.store.select(selectAllUserPresences);
    }
}
