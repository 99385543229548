import {AfterViewInit, Component} from '@angular/core';
import {RtcDetectionStepComponent} from '../rtc-detection-step-component.interface';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store/rtc-detection.state';
import {DoLogoutAction} from '../../auth/store/login/login.action';
import {SETTING_ROUTE} from '../../../routes';
import {BrowserService} from '../../media/provider/browser.service';
import {StepType} from '../rtc-detection.entities';
import {RtcDetectionNextStepAction} from '../store/rtc-detection.action';
import {RouterHelperService} from '../../utils/router-helper/router-helper.service';

@Component({
    selector: 'app-step-browser-check',
    templateUrl: './step-browser-check.component.html',
    styleUrls: ['../rtc-detection.component.scss', './step-browser-check.component.scss']
})
export class StepBrowserCheckComponent implements RtcDetectionStepComponent, AfterViewInit {

    nextStep: (arg?) => void;
    cancelStep: () => void;
    dismissModal: () => void;
    closeModal: () => void;


    constructor(private translate: TranslateService,
                private store: Store<AppState>,
                private router: RouterHelperService,
                private browserService: BrowserService) {
    }

    ngAfterViewInit(): void {
        this.store.dispatch(new RtcDetectionNextStepAction(StepType.CAM_TEST));
    }

    ignoreNoticeClicked() {
        // TODO change to dashboard when it exitsts
        this.router.navigate([SETTING_ROUTE]);
        this.dismissModal();
    }

    logoutClicked() {
        this.dismissModal();
        this.store.dispatch(new DoLogoutAction());
    }

}
