<div *ngIf="fileStats !== null">
    <div>
        <div *ngIf="fileStats.state === ProgressBarStates.STATE_IN_PROGRESS">
            <div class="blueLight positionElements">
                <i class="fa fa-spinner fa-spin"></i>
                <span>{{ getFileNameRefactored() }} ({{ getPercentage() }})</span>
                <button id="clearFile" type="button" class="close closeBtn" (click)="doCancel()">
                    <i class="fa fa-times-circle blueLight"></i>
                </button>
            </div>
            <div>
                <ngb-progressbar height="5px" class="progressBar"
                                 [value]="fileStats.value" [max]="fileStats.max"></ngb-progressbar>
            </div>
        </div>
        <div *ngIf="fileStats.state === ProgressBarStates.STATE_ERROR">
            <span>{{fileStats.fileName}} : {{ errorMessage }}</span>
        </div>
        <div *ngIf="fileStats.state === ProgressBarStates.STATE_CANCELED && showCancelText === true">
            <span class="weight-400 grayCancelText"
                  [ngbTooltip]="(fileStats.fileName.length > 30) ? fileStats.fileName : ''">
                <i class="fal fa-times-circle" aria-hidden="true"></i>
                {{ getFileNameRefactored() }} {{'FILE_TRANSFER.BODY.CANCELED' | translate}}
            </span>
        </div>
    </div>

    <!-- ================================ Complete ================================================= -->
    <div [ngSwitch]="type">
        <!-- ============================ Receive progress bar complete ============================ -->
        <div class="fileDetailsAlignment" *ngSwitchCase="ProgressbarComponentType.RECEIVING">
            <div *ngIf="fileStats.state === ProgressBarStates.STATE_COMPLETED">
                <span>
                    <a href="javascript:void(0)" (click)="onDownload()"
                        [ngbTooltip]="(fileStats.fileName.length > 25) ? fileStats.fileName : ''"
                        [ngClass]="checkFileDownloaded ? 'weight-400' : 'weight-600'"
                        class="darkBlue" id="download">
                            <i [ngClass]="checkFileDownloaded ? 'fal fa-download' : 'fas fa-download'"
                               aria-hidden="true"></i>
                            {{ getFileNameRefactored() }} ({{ getFileSizeInMb() }})
                    </a>
                </span>
            </div>
        </div>

        <!-- ============================ Sending progress bar complete, uploaded file ============= -->
        <div class="fileDetailsAlignment darkBlue" *ngSwitchCase="ProgressbarComponentType.UPLOADED">
            <div *ngIf="fileStats.state === ProgressBarStates.STATE_COMPLETED">
                <span>
                    <i class="fal fa-upload"></i> {{ getFileNameRefactored() }} ({{ getFileSizeInMb() }})
                </span>
            </div>
        </div>

        <!-- ============================ Sending progress bar complete ============================ -->
        <div *ngSwitchCase="ProgressbarComponentType.SENDING">
            <div *ngIf="fileStats.state ===  ProgressBarStates.STATE_COMPLETED">
                <span> The file {{ getFileNameRefactored() }} ({{ getFileSizeInMb() }}) has been sent!</span>
            </div>
        </div>
    </div>
</div>

